import React, { useState, useEffect } from 'react'
import Navbar from '../../components/Navbar'
import UserHeader from '../../components/UserHeader'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import TeachersLayout from '../../layout/TeachersLayout'
import { IoEaselOutline } from 'react-icons/io5'
import { FaChalkboardTeacher } from 'react-icons/fa'
import { MdCalendarMonth, MdFlag } from 'react-icons/md'
import { tidyDate } from '../../utils/helper'

export default function StaffFeedbackPage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = useFetchUserData()

  const [feedbackData, setFeedbackData] = useState([])


  const fetchFeedback = async () => {
    let endpoint = config.endpoint + `/feedback/staff/id/`
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      setFeedbackData(data.data)
    }
  }


  useEffect(() => {
    fetchFeedback()
  }, [])

  return (
    <TeachersLayout navSelected={"Feedback & Review"}>
      <p className='mb-4' style={{ fontSize: 30, fontWeight: "500" }}>Feedback & Review</p>
      {feedbackData?.length > 0 ? feedbackData.map((data, index) => {
        return (
          <div className='p-all row justify-between gap-20 items-center w-full mt-4' style={{ borderLeft: "4px solid var(--primary)", backgroundColor: "white", borderTopLeftRadius: "5px", borderBottomLeftRadius: "5px", borderRadius: "5px", paddingLeft: 10 }}>
            <div className="column">
              <p className="card-title">{data?.class_data?.name}</p>
              <div className="row items-center gap-20">
                <div className="row gap-4 items-center">
                  <IoEaselOutline size={15} />
                  <p style={{ color: "gray", fontSize: 12 }}>{data?.class_data?.course_data?.name}</p>
                </div>
                <div className="row gap-4 items-center">
                  <FaChalkboardTeacher size={15} />
                  <p style={{ color: "gray", fontSize: 12 }}>You</p>
                </div>
                <div className="row gap-4 items-center">
                  <MdCalendarMonth size={15} />
                  <p style={{ color: "gray", fontSize: 12 }}>by {tidyDate(data?.completion_deadline)}</p>
                </div>
              </div>
              {/* <pre>{JSON.stringify(data?.user_data,null,2)}</pre> */}
            </div>
            <button className="button"
              onClick={() => {
                navigate(`/staff/feedback/review?request_id=${data?.id}`)
              }}
            >View</button>
          </div>
        )
      })
        :
        <div className='card shadow-md rounded-md p-4'>
          <MdFlag style={{ fontSize: 50, color: "gray" }} />
          <p style={{ color: "gray", fontSize: 12 }}>You have no feedback or review request at the moment.</p>
        </div>
      }
    </TeachersLayout>
  )
}
