import React, { useEffect, useState, useRef } from 'react'
import Navbar from '../../components/Navbar'
import UserHeader from '../../components/UserHeader'
import { useNavigate, useParams } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import UserLayout from '../../layout/UserLayout'
import { MdAdd, MdComment, MdDelete, MdEdit, MdOutlineArrowDownward, MdOutlineArrowUpward, MdOutlineComment, MdPerson } from 'react-icons/md'
import ModalLayout from '../../layout/ModalLayout'
import EmojiPicker from 'emoji-picker-react';
import JoditEditor from 'jodit-react';
import notify from '../../utils/IANotification'
import IAPrompt from '../../utils/IAPrompt'
import { calculate_time_difference_tidy } from '../../utils/helper'
import { BsThreeDots } from 'react-icons/bs'
import Switch from "react-switch";

export default function ForumsPostHomePage() {
  const modalRef = useRef(null)
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = useFetchUserData()
  let post_id = new URLSearchParams(window.location.search).get("post_id")
  let category_id = new URLSearchParams(window.location.search).get("category_id")
  const [showModerationModal, setShowModerationModal] = useState(false)
  const [selectedUser, setSelectedUser] = useState(null)
  const [permissions, setPermissions] = useState({})

  const [forumPostData, setForumPostData] = useState({
    title: "",
    content: "",
    category_id: category_id,
    type: "forum",
  })

  const [commentData, setCommentData] = useState({
    content: "",
    type: "comment",
    category_id: category_id,
  })
  const [forumCommentData, setForumCommentData] = useState([])
  const [pageState, setPageState] = useState(post_id === "new" ? "editing" : "view")
  const [showCommentModal, setShowCommentModal] = useState(false)

  const editor = useRef(null);

  const liveConfig = {
    height: 500,
    placeholder: "Start typing...",
    // set custom buttons
    buttons: [
      'bold',
      'italic',
      'underline',
      'strikethrough',
      'superscript',
      'subscript',
      'ul',
      'ol',
      'outdent',
      'indent',
      'font',
      'fontsize',
      'brush',
      'paragraph',
      'table',
      'link',
      'undo',
      'redo',
      'eraser',
      'copyformat',
    ],
    askBeforePasteHTML: false,
    hotkeys: {
      redo: 'ctrl+z',
      undo: 'ctrl+y,ctrl+shift+z',
      indent: 'ctrl+]',
      outdent: 'ctrl+[',
      bold: 'ctrl+b',
      italic: 'ctrl+i',
      removeFormat: 'ctrl+shift+m',
      insertOrderedList: 'ctrl+shift+7',
      insertUnorderedList: 'ctrl+shift+8',
      openSearchDialog: 'ctrl+f',
      openReplaceDialog: 'ctrl+r',
    },
  };


  useEffect(() => {
    fetchPostById()
  }, [post_id])


  useEffect(() => {
    fetchUserPermissions()
  }, [selectedUser])

  const fetchUserPermissions = async () => {
    if (!selectedUser) return
    let endpoint = config.endpoint + "/forums/moderation/user/id/" + selectedUser
    let response = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await response.json()
    if (data.status === 200) {
      setPermissions(data.data)
    }
  }

  const updatePermissions = async () => {
    if (!selectedUser) return
    let endpoint = config.endpoint + "/forums/moderation/user/id/" + selectedUser
    let response = await fetch(endpoint, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(permissions)
    })
    let data = await response.json()
    if (data.status === 200) {
      notify({ message: "Permissions updated", type: "success" })
      fetchUserPermissions()
      setShowModerationModal(false)
    } else {
      notify({ message: "Unable to update permissions", type: "error" })
    }
  }

  const fetchPostById = async () => {
    if (!post_id) return
    if (post_id === "new") return
    let endpoint = config.endpoint + "/forums/id/" + post_id
    let response = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await response.json()
    if (data.status === 200) {
      setForumPostData(data.data?.forum)
      setForumCommentData(data.data?.comments)
    }
  }


  const createForumPost = async () => {
    let endpoint = config.endpoint + "/forums"
    let response = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        ...forumPostData,
        type: "forum"
      })
    })
    let data = await response.json()
    if (data.status === 200) {
      notify({ message: "Post created", type: "success" })
      setPageState("view")
      navigate("/forums/category/post?post_id=" + data?.data?.post_id)
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  const createComment = async () => {
    let endpoint = config.endpoint + "/forums"
    let response = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        ...commentData,
        type: "comment",
        forum_id: post_id
      })
    })
    let data = await response.json()
    if (data.status === 200) {
      notify({ message: "Comment Added", type: "success" })
      fetchPostById()
      setCommentData({ ...commentData, comment: "" })
      setShowCommentModal(false)
    } else {
      notify({ message: data.message, type: "error" })
    }
  }


  const updateForumPost = async () => {
    let endpoint = config.endpoint + "/forums/id/" + post_id
    let response = await fetch(endpoint, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(forumPostData)
    })
    let data = await response.json()
    if (data.status === 200) {
      notify({ message: "Post updated", type: "success" })
      setPageState("view")
      window.location.reload()
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  const deleteForumPost = async (post_id) => {
    if (!post_id) return 
    let endpoint = config.endpoint + "/forums/id/" + post_id
    let response = await fetch(endpoint, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await response.json()
    if (data.status === 200) {
      notify({ message: "Post deleted", type: "success" })
      navigate(-1)
    } else {
      notify({ message: "Unable to delete post", type: "error" })
    }
  }


  const upvoteForumPost = async (post_id) => {
    if (!post_id) return
    let endpoint = config.endpoint + "/forums/upvote/id/" + post_id
    let response = await fetch(endpoint, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await response.json()
    if (data.status === 200) {
      // notify({ message: "Upvoted", type: "success" })
      fetchPostById()
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  const downvoteForumPost = async (post_id) => {
    if (!post_id) return
    let endpoint = config.endpoint + "/forums/downvote/id/" + post_id
    let response = await fetch(endpoint, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await response.json()
    if (data.status === 200) {
      // notify({ message: "Downvoted", type: "success" })
      fetchPostById()
    } else {
      notify({ message: data.message, type: "error" })
    }
  }


  return (
    <UserLayout navSelected={"Forums"}>
      {/* Permissions */}
      <ModalLayout modalTitle="Moderation" isVisible={showModerationModal} setIsVisible={setShowModerationModal}>
        <div style={{ flexDirection: "column", alignItems: "flex-start", justifyContent: "left", width: "100%" }} className="font-['Poppins']">
          <p style={{ fontSize: 18, fontWeight: "900" }}>{permissions?.user_data?.first_name} {permissions?.user_data?.last_name}</p>
          <p style={{ fontSize: 13, color: "gray" }}>{permissions?.user_data?.email}</p>

          <p className="card-title mt-4">Permissions</p>
          <div>
            <div className="row gap-20 items-center mt-4 justify-between">
              <p style={{ fontSize: 12, fontWeight: "normal", width: "100px" }}>Post</p>
              <Switch onChange={(checked) => {
                setPermissions({ ...permissions, can_create: checked ? "1" : "0" })
              }} checked={permissions?.can_create == 1} />
            </div>
            <div className="row gap-20 items-center mt-4 justify-between">
              <p style={{ fontSize: 12, fontWeight: "normal", width: "100px" }}>Comment</p>
              <Switch onChange={(checked) => {
                setPermissions({ ...permissions, can_comment: checked ? "1" : "0" })
              }} checked={permissions?.can_comment == 1} />
            </div>
            <div className="row gap-20 items-center mt-4 justify-between">
              <p style={{ fontSize: 12, fontWeight: "normal", width: "100px" }}>Vote</p>
              <Switch onChange={(checked) => {
                setPermissions({ ...permissions, can_vote: checked ? "1" : "0" })
              }} checked={permissions?.can_vote == 1} />
            </div>
            <div className="row gap-20 items-center mt-4 justify-between">
              <p style={{ fontSize: 12, fontWeight: "normal", width: "100px" }}>View</p>
              <Switch onChange={(checked) => {
                setPermissions({ ...permissions, can_read: checked ? "1" : "0" })
              }} checked={permissions?.can_read == 1} />
            </div>
          </div>
          {/* <pre>{JSON.stringify(permissions, null, 2)}</pre> */}

          <div className="input-container mt-4">
            <label htmlFor="" className="input-label">Remarks</label>
            <textarea className="textarea-input" value={permissions?.comments} onChange={(e) => {
              setPermissions({ ...permissions, comments: e.target.value })
            }}></textarea>
          </div>

          <button className="rounded-md button shadow-md px-4 py-2 text-white text-sm mt-4 w-full"
            onClick={() => {
              updatePermissions()
            }}
          >
            Update
          </button>
        </div>
      </ModalLayout>




      <div className="row items-center justify-between w-full mb-20">
        <p style={{ fontWeight: "normal", fontSize: 25, }}>{post_id === "new" ? "Write a post..." : forumPostData?.title}</p>
        {post_id !== "new" && forumPostData?.author_user_id == user_data?.id && <button className="button row gap-4 items-center" onClick={() => {
          setPageState("editing")
        }}>
          <MdEdit size={12} color='white' />
          <p style={{ fontWeight: "normal", fontSize: 12 }}>Edit</p>
        </button>}
      </div>
      {pageState === "view" ?
        <>
          {/* Post */}
          <div className="card shadow-md rounded-md">
            <div className="row justify-between items-center w-full">
              <div className="row gap-4 items-center">
                {forumPostData?.user_data?.file_data?.ext_file_path ?
                  <img src={`${config.endpoint}${forumPostData?.user_data?.file_data?.ext_file_path}`} alt="profile" style={{ width: 20, height: 20, borderRadius: "50%" }} />
                  :
                  <div style={{ backgroundColor: "#DDD", borderRadius: "50%", padding: 10 }}>
                    <MdPerson size={15} color='gray' />
                  </div>
                }
                <p style={{
                  fontWeight: "normal", fontSize: 12,
                  color: forumPostData?.user_id === user_data?.user_id ? "var(--green)" : "black"
                }}>{forumPostData?.user_id === user_data?.user_id ? "You" : `By ${forumPostData?.user_data?.first_name} ${forumPostData?.user_data?.last_name}`}</p>
              </div>
              <div className="row gap-4 items-center">
                <p style={{
                  fontSize: 12,
                  fontStyle: "italic",
                }}>{calculate_time_difference_tidy(new Date(forumPostData?.updatedAt), new Date())}</p>
                {
                  authorisationConverter({ requirement: "staff", current_role: user_data?.account_type }) && <>
                    <button className="backgroundLess">
                      <MdDelete size={20} color='var(--red)' onClick={() => {
                        IAPrompt({
                          title: "Delete post",
                          content: "Are you sure you want to delete this post?",
                          buttons: [
                            {
                              label: "No",
                              onClick: () => { }
                            },
                            {
                              label: "Yes",
                              onClick: () => {
                                deleteForumPost( forumPostData?.id)
                              }
                            },

                          ]
                        })
                      }} />
                    </button>
                    <button className="backgroundLess"
                      onClick={() => {
                        setSelectedUser(forumPostData?.author_user_id)
                        setShowModerationModal(true)
                      }}
                    >
                      <BsThreeDots />
                    </button>
                  </>
                }
              </div>
            </div>
            <div className='mt-4' dangerouslySetInnerHTML={{ __html: forumPostData?.content }}></div>

            <div className="column right w-full alignEnd mt-40">
              <div className="row gap-4 items-center">
                <MdOutlineArrowDownward className='cursor-pointer' size={20} color={forumPostData?.downvote?.includes(user_data?.user_id) ? "var(--red)" : "gray"} onClick={() => {
                  downvoteForumPost(forumPostData?.id)
                }} />
                <p>{isNaN(forumPostData?.upvote?.length - forumPostData?.downvote?.length) ? 0 : forumPostData?.upvote?.length - forumPostData?.downvote?.length}</p>
                <MdOutlineArrowUpward className='cursor-pointer' size={20} color={forumPostData?.upvote?.includes(user_data?.user_id) ? "var(--green)" : "gray"} onClick={() => {
                  upvoteForumPost(forumPostData?.id)
                }} />
              </div>
            </div>
          </div>

          {/* <pre>{JSON.stringify(forumPostData, null, 2)}</pre> */}
          {/* Comments */}
          <div className="row mt-4 items-center justify-between w-full">
            <p >Comments ({forumCommentData?.length || 0})</p>
            <button className="button-cta row gap-4 items-center"
              onClick={() => {
                setShowCommentModal(!showCommentModal)
              }}>
              <MdOutlineComment size={12} />
              <p style={{ fontWeight: "normal", fontSize: 12 }}>Comment</p>
            </button>
          </div>

          {showCommentModal && <div className="card shadow-md rounded-md mt-4 mb-4">
            <div className="input-container">
              <textarea name="" id="" cols="30" rows="10" className="textarea-input"
                placeholder="Write a comment..."
                value={commentData?.content}
                onChange={(e) => setCommentData({ ...commentData, content: e.target.value })}
              ></textarea>
            </div>
            <div className="column right alignEnd w-full">
              <button className="button" onClick={() => {
                createComment()
              }}>Comment</button>
            </div>
          </div>}

          <div>
            {forumCommentData?.length > 0 ?
              forumCommentData?.map((comment, index) => {
                return (
                  <div style={{ paddingLeft: 20, borderLeft: "5px solid #DDD", marginLeft: 20, paddingBottom: 5, paddingTop: 5 }}>
                    <div className="card shadow-md rounded-md mt-4" key={index}>
                      <div className="row justify-between items-center w-full">
                        <div className="row gap-4 items-center">
                          {comment?.user_data?.file_data?.ext_file_path ?
                            <img src={`${config.endpoint}${comment?.user_data?.file_data?.ext_file_path}`} alt="profile" style={{ width: 20, height: 20, borderRadius: "50%" }} />
                            :
                            <div style={{ backgroundColor: "#DDD", borderRadius: "50%", padding: 10 }}>
                              <MdPerson size={15} color='gray' />
                            </div>
                          }
                          <p style={{
                            fontWeight: "normal", fontSize: 12,
                            color: comment?.user_id === user_data?.user_id ? "var(--green)" : "black"
                          }}>{comment?.user_id === user_data?.user_id ? "You" : `By ${comment?.user_data?.first_name} ${comment?.user_data?.last_name}`}</p>
                        </div>
                        <div className="row gap-4 items-center">
                          <p style={{
                            fontSize: 12,
                            fontStyle: "italic",
                          }}>{calculate_time_difference_tidy(new Date(forumPostData?.updatedAt), new Date())}</p>
                          {
                            authorisationConverter({ requirement: "staff", current_role: user_data?.account_type }) && <>
                              <button className="backgroundLess">
                                <MdDelete size={20} color='var(--red)' onClick={() => {
                                  IAPrompt({
                                    title: "Delete comment",
                                    content: "Are you sure you want to delete this comment?",
                                    buttons: [
                                      {
                                        label: "No",
                                        onClick: () => { }
                                      },
                                      {
                                        label: "Yes",
                                        onClick: () => {
                                          deleteForumPost(comment?.id)
                                        }
                                      },

                                    ]
                                  })
                                }} />
                              </button>
                              <button className="backgroundLess"
                                onClick={() => {
                                  setSelectedUser(forumPostData?.author_user_id)
                                  setShowModerationModal(true)
                                }}
                              >
                                <BsThreeDots />
                              </button>
                            </>
                          }
                        </div>
                      </div>
                      <div className='mt-4' dangerouslySetInnerHTML={{ __html: comment?.content }}></div>

                      <div className="column right w-full alignEnd mt-40">
                        <div className="row gap-4 items-center">
                          <MdOutlineArrowDownward className='cursor-pointer' size={20} color={comment?.downvote?.includes(user_data?.user_id) ? "var(--red)" : "gray"} onClick={() => {
                            downvoteForumPost(comment?.id)
                          }} />
                          <p>{isNaN(comment?.upvote?.length - comment?.downvote?.length) ? 0 : comment?.upvote?.length - comment?.downvote?.length}</p>
                          <MdOutlineArrowUpward className='cursor-pointer' size={20} color={comment?.upvote?.includes(user_data?.user_id) ? "var(--green)" : "gray"} onClick={() => {
                            upvoteForumPost(comment?.id)
                          }} />
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })
              :
              <div className="card rounded-md shadow-md p-4 mt-4">
                <MdComment size={20} color='gray' />
                <p>No comments so far</p>
              </div>
            }
          </div>
        </>
        :
        <div className="card rounded-md shadow-md p-4 mt-4">
          <div className="input-container">
            <input
              placeholder="Title"
              value={forumPostData?.title}
              onChange={(e) => setForumPostData({ ...forumPostData, title: e.target.value })}
              className="text-input"
            />
          </div>
          <JoditEditor
            ref={editor}
            value={forumPostData?.content}
            config={liveConfig}
            tabIndex={1}
            onBlur={newContent => {
              setForumPostData({ ...forumPostData, content: newContent })
            }}
          />
          <div className="column right alignEnd w-full">
            <div className="row items-center mt-4 gap-4">
              {post_id !== "new" && <button className="button-delete" onClick={() => {
                IAPrompt({
                  title: "Delete post",
                  content: "Are you sure you want to delete this post?",
                  buttons: [
                    {
                      label: "No",
                      onClick: () => { }
                    },
                    {
                      label: "Yes",
                      onClick: () => {
                        deleteForumPost(forumPostData?.id)
                      }
                    },

                  ]
                })
              }}>Delete</button>}
              <button className="button" onClick={() => {
                if (post_id === "new") {
                  createForumPost()
                } else {
                  updateForumPost()
                }
              }}>{post_id === "new" ? "Create" : "Update"}</button>
            </div>
          </div>
        </div>
      }
    </UserLayout>
  )
}
