import React, { useLayoutEffect, useState } from 'react'
import { MdAppBlocking, MdClass, MdGroup, MdHome, MdSettings } from 'react-icons/md'
import { useParams } from 'react-router-dom'
import { TbReport } from "react-icons/tb";
import { IoDocumentsOutline, IoEaselOutline, IoHomeOutline, IoJournalOutline, IoLibraryOutline, IoMegaphone, IoMegaphoneOutline, IoNewspaperOutline, IoPeopleOutline, IoSettingsOutline } from "react-icons/io5";

export default function AdminCourseNavbar({ selected }) {

    const course_id = new URLSearchParams(window.location.search)?.get("course_id")
    const lesson_id = new URLSearchParams(window.location.search)?.get("lesson_id")

    const [headerHeight, setHeaderHeight] = useState(document?.getElementById("admin-header")?.clientHeight + 1)

    useLayoutEffect(() => {
        setHeaderHeight(document?.getElementById("admin-header")?.clientHeight + 1)
    }, [])

    const ICON_SIZE = 20

    const ITEMS = [
        {
            "name": "General",
            "link": `/admin/courses/details?course_id=${course_id}`,
            "icon": <IoHomeOutline size={ICON_SIZE} />,
        },
        {
            "name": "Announcements",
            "link": `/admin/courses/announcements?course_id=${course_id}`,
            "icon": <IoMegaphoneOutline size={ICON_SIZE} />,
        },
        {
            "name": "Classes",
            "link": `/admin/courses/classes?course_id=${course_id}`,
            "icon": <IoEaselOutline size={ICON_SIZE} />,
        },
        {
            "name": "Students",
            "link": `/admin/courses/students?course_id=${course_id}`,
            "icon": <IoPeopleOutline size={ICON_SIZE} />,
        },
        {
            "name": "Lessons",
            "link": `/admin/courses/lessons?course_id=${course_id}`,
            "icon": <IoLibraryOutline size={ICON_SIZE} />,
        },
        {
            "name": "Assessments",
            "link": `/admin/courses/assessments?course_id=${course_id}`,
            "icon": <IoNewspaperOutline size={ICON_SIZE} />,
        },
        {
            "name": "Assignments",
            "link": `/admin/courses/assessments/type?course_id=${course_id}&type=assignments`,
            "icon": <IoJournalOutline size={ICON_SIZE} />,
        },
        {
            "name": "Examinations",
            "link": `/admin/courses/assessments/type?course_id=${course_id}&type=examinations`,
            "icon": <IoJournalOutline size={ICON_SIZE} />,
        },
        {
            "name": "Materials",
            "link": `/admin/courses/materials?course_id=${course_id}`,
            "icon": <IoDocumentsOutline size={ICON_SIZE} />,
        },
        // {
        //     "name": "Settings",
        //     "link": `/admin/courses/settings?course_id=${course_id}`,
        //     "icon": <IoSettingsOutline size={ICON_SIZE} />,
        // }
    ]
    return (
        <div id='course-nav' className='row mb-6 items-center hide-scrollbars bg-slate-100 rounded-md shadow-sm w-fit' style={{
            borderBottom: "1px solid #E5E5E5",
            position: "sticky",
            top: headerHeight,
            zIndex: 5,
            backgroundColor: "white",
            overflowY: "auto",
        }}>
            {ITEMS.map((item, index) => {
                return (
                    <div 
                    className={`row gap-4 items-center px-4 py-2 cursor-pointer`} 
                    style={{ borderBottom: selected === item.name ? "2px solid var(--primary)" : "",}} onClick={() => {
                        window.location.href = item.link
                    }}>
                        {item.icon}
                        <p style={{ fontSize: 12, fontWeight: 500 }}>{item.name}</p>
                    </div>
                )
            })}
        </div>
    )
}
