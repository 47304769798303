import React, { useState, useEffect } from 'react'
import Navbar from '../../components/Navbar'
import UserHeader from '../../components/UserHeader'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import ParentsLayout from '../../layout/ParentsLayout'
import notify from '../../utils/IANotification'
import Select from 'react-select'
import { MdCalendarMonth, MdLock } from 'react-icons/md'
import { FaChalkboardTeacher } from 'react-icons/fa'
import { IoEaselOutline } from 'react-icons/io5'
import { tidyDate } from '../../utils/helper'

export default function ParentsFeedbackPage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = useFetchUserData()

  const [childData, setChildData] = useState([])
  const [selectedChild, setSelectedChild] = useState({})
  const [feedbackData, setFeedbackData] = useState([])

  const fetchFamilyData = async () => {
    let endpoint = config.endpoint + `/family/parent/id/`
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      let temp = data.data?.members?.filter((item) => {
        return item.role === "student"
      })
      setChildData(temp)
      setSelectedChild(temp?.[0])
    }
  }

  const fetchFeedbackData = async () => {
    let endpoint = config.endpoint + `/feedback/parent/`
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      setFeedbackData(data.data)
    }

  }

  useEffect(() => {
    fetchFeedbackData()
    fetchFamilyData()
  }, [])


  return (
    <ParentsLayout navSelected={"Feedback & Review"}>
      <p className="text-2xl">Feedback & Review</p>
      <p className="text-sm text-slate-500 mb-4">An Assessment of your child's performance</p>
      {feedbackData?.length > 0 ?
        feedbackData.map((item, index) => {
          return (
            <div className="row items-center justify-between card rounded-md shadow-md p-4 mb-4 cursor-pointer"
              onClick={()=>{
                navigate(`/parents/feedback/review/form?request_id=${item?.request_id}&student_id=${item?.student_id}&form_id=${item?.form_id}`)
              }}
              style={{ borderLeft: "4px solid var(--primary)", backgroundColor: "white", padding: 10, borderTopLeftRadius: "5px", borderBottomLeftRadius: "5px", borderRadius: "5px", paddingLeft: 10 }}
            >
              <div className="column">
                <p style={{ fontSize: 15, fontWeight: "900" }}>{item?.user_data?.first_name} {item?.user_data?.last_name}</p>
                {/* <p style={{ fontSize: 12, fontWeight: "normal" }}>{item?.class_data?.name}</p> */}
                <div className="row items-center gap-20">
                  <div className="row gap-4 items-center">
                    <IoEaselOutline size={15} />
                    <p style={{ color: "gray", fontSize: 12 }}>{item?.class_data?.course_data?.name}</p>
                  </div>
                  <div className="row gap-4 items-center">
                    <FaChalkboardTeacher size={15} />
                    <p style={{ color: "gray", fontSize: 12 }}>{item?.staff_data?.first_name} {item?.staff_data?.last_name}</p>
                  </div>
                  <div className="row gap-4 items-center">
                    <MdCalendarMonth size={15} />
                    <p style={{ color: "gray", fontSize: 12 }}>by {tidyDate(item?.updatedAt)}</p>
                  </div>
                </div>
              </div>
            </div>
          )
        })
        :
        <div className='card rounded-md shadow-md p-4' style={{ display: "flex", flexDirection: "column", justifyContent: "left", alignItems: "flex-start", height: "100%" }}>
          <FaChalkboardTeacher size={50} color={"gray"} />
          <p style={{ fontSize: 15, fontWeight: "normal",  }}>No Feedback Available, if you are expecting a feedback, please contact the school</p>
        </div>
      }
      {/* <pre>{JSON.stringify(feedbackData, null, 2)}</pre> */}
    </ParentsLayout>
  )
}
