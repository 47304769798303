import React, { useEffect, useState } from 'react'
import Navbar from '../../components/Navbar'
import UserHeader from '../../components/UserHeader'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import TeachersLayout from '../../layout/TeachersLayout'
import { useParams } from 'react-router'
import AdminCourseNavbar from '../../components/AdminCourseNavbar'
import notify from '../../utils/IANotification'
import Switch from "react-switch";
import { tidyDate, tidyDateRaw } from '../../utils/helper'
import AttendanceForm from '../../subpages/AttendanceForm'
import StaffCourseNavbar from '../../components/StaffCourseNavbar'

export default function StaffClassAttendanceFormPage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = useFetchUserData()

  const course_id = new URLSearchParams(window.location.search)?.get("course_id")
  const class_id = new URLSearchParams(window.location.search)?.get("class_id")
  const session_id = new URLSearchParams(window.location.search)?.get("session_id")


  const [attendanceData, setAttendanceData] = useState({})
  const [studentData, setStudentData] = useState([])
  const [startDateTime, setStartDateTime] = useState(new Date())
  const [endDateTime, setEndDateTime] = useState(new Date())
  const [remarks, setRemarks] = useState("")


  const getStudentsByClassId = async () => {
    if (class_id === "new") return
    if (!class_id) return
    let endpoint = config.endpoint + "/class/student/class/id/" + class_id
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      let students = data.data
      if (session_id === "new") {
        let attendance = students.map((student) => {
          return {
            user_id: student.user_id,
            attendance: "present", // default to present - present, absent, late
            class_id,
          }
        })

        setAttendanceData(attendance)
      }
      setStudentData(students)
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  const getAttendanceSessionById = async () => {
    if (class_id === "new") return
    if (!class_id) return
    if (session_id === "new") return
    let endpoint = config.endpoint + "/attendance/mark/session/id/" + session_id
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      setEndDateTime(data.data?.class_session_data?.end_datetime)
      setStartDateTime(data.data?.class_session_data?.start_datetime)
      setRemarks(data.data?.class_session_data?.remarks)
      setAttendanceData(data.data?.attendance_records)
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  const submitAttendance = async () => {
    if (class_id === "new") return
    if (!class_id) return
    let endpoint = config.endpoint + "/attendance/mark/class/id/"
    let fetchResponse = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        class_id,
        students: attendanceData,
        start_datetime: startDateTime,
        end_datetime: endDateTime,
        remarks,
      })
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      getAttendanceSessionById()
      getStudentsByClassId()
      notify({ message: data.message, type: "success" })
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  useEffect(() => {
    getAttendanceSessionById()
    getStudentsByClassId()
  }, [])

  return (
    <TeachersLayout navSelected={"Classes"}>
      <p className='mb-4' style={{ fontSize: 30, fontWeight: "500" }}>Class Attendance</p>
      <StaffCourseNavbar selected={"Class"} course_id={course_id} />
      <AttendanceForm type={"Staff"} />
    </TeachersLayout >
  )
}
