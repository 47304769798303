import React from 'react'
import ModalLayout from '../layout/ModalLayout'
import TextComponent from './lesson/TextComponent'
import JoditEditor from 'jodit-react'
import config from '../app-config'

export default function AnnouncementCard({ data, show, setShowModal }) {
  return (
    <ModalLayout
      modalTitle={data?.title || "Announcement"}
      isVisible={show}
      setIsVisible={setShowModal}
    >
      <img src={config.images.logo_wo_text} alt="announcement" style={{ width: 200, height: 50, objectFit: "contain", alignContent: "flex-start", position: "relative", bottom: 10, right: 10, opacity: 1, marginTop: 20 }} />
      <div dangerouslySetInnerHTML={{ __html: data?.content || "" }} style={{ maxWidth: 500 }} />
    </ModalLayout>
  )
}
