import React, { useEffect, useState } from 'react'
import Navbar from '../../components/Navbar'
import UserHeader from '../../components/UserHeader'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import UserLayout from '../../layout/UserLayout'
import notify from '../../utils/IANotification'
import { MdChevronRight, MdLock } from 'react-icons/md'

export default function CoursesPage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = useFetchUserData()

  const [studentCoursesData, setStudentCoursesData] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [searchQuery, setSearchQuery] = useState("")

  const fetchAllStudentCourses = async () => {
    setIsLoading(true)
    let endpoint = config.endpoint + "/course/student/"
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      setStudentCoursesData(data.data)
    } else {
      notify({ message: data.message, type: "error" })
    }
    setIsLoading(false)
  }


  useEffect(() => {
    fetchAllStudentCourses()
  }, [])

  return (
    <UserLayout navSelected={"Courses"}>
      <p style={{ fontWeight: "normal", fontSize: 25, marginBottom: 10 }}>My Courses</p>
      <div className="input-container">
        <input type="text" placeholder="Search Courses" onChange={(e) => setSearchQuery(e.target.value)} className='text-input' />
      </div>
      {studentCoursesData.length > 0 ?
        <>
          {studentCoursesData.map((course, index) => {
            if (searchQuery && !JSON.stringify(course).toLowerCase().includes(searchQuery.toLowerCase())) return null
            return (
              <div className="card mb-4 cursor-pointer items-center row gap-4 rounded-md shadow-lg" style={{ padding: 0, borderRadius: 5 }}
                onClick={() => {
                  navigate(`/courses/${course.id}`)
                }}
                key={index}
              >
                <img src={course?.course_data?.file_data?.ext_file_path ? `${config.endpoint}${course?.course_data?.file_data?.ext_file_path}` : "/default-course.png"} alt="" style={{ height: "200px", width: "300px", objectFit: "cover", borderRadius: 5 }} />
                <div style={{ padding: 10, width: "100%", height: "100%" }} className='row items-center, justify-between'>
                  <div>
                    <p className="card-title">{course.name}</p>
                    <p className="card-subtitle">{course.description}</p>
                  </div>
                  <div className="row gap-4 items-center hover-underline">
                    <p style={{fontSize: 12 }} className="text-slate-500">Enter Course</p>
                    <MdChevronRight />
                  </div>
                </div>
                {/* <pre>{JSON.stringify(course, null, 2)}</pre> */}
              </div>
            )
          })}
          <p style={{ fontWeight: "normal", fontSize: 12, color: "gray", textAlign: "center", marginBottom: 10 }}>Not finding what you're looking for? Please contact your instructor or admin for more information.</p>

        </>
        :
        isLoading ?
          <div className="card rounded-md shadow-md p-4 mt-4">
            <p style={{ fontWeight: "normal", fontSize: 15, marginBottom: 10 }}>Loading...</p>
          </div>
          :
          <div className="card rounded-md shadow-md p-4 mt-4">
            <MdLock style={{ fontSize: 50, color: "gray" }} />
            <p style={{ fontWeight: "normal", fontSize: 15, marginBottom: 10 }}>No courses available yet, please check back later!</p>
          </div>
      }
    </UserLayout>
  )
}
