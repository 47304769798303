import React, { useEffect, useState } from 'react'
import Navbar from '../../components/Navbar'
import UserHeader from '../../components/UserHeader'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import AdminLayout from '../../layout/AdminLayout'
import notify from '../../utils/IANotification'
import EnrolmentList from '../../subpages/EnrolmentList'
import FamilyList from '../../subpages/FamilyList'

export default function AdminFamilyPage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("staff")
  let user_data = useFetchUserData()
  const [searchQuery, setSearchQuery] = useState("")

  return (
    <AdminLayout navSelected={"Families"}>
      <p className='mb-4' style={{ fontSize: 30, fontWeight: "500" }}>Families</p>
      <div className="input-container">
        <input type="text" className="text-input" placeholder="Search..." value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
      </div>
      <div className="row gap-4 items-center mb-20">
        <button className="button"
          onClick={() => {
            navigate("/admin/family/details?family_id=new")
          }}
        >Add Family</button>
      </div>
      <FamilyList type={"admin"} filter={searchQuery} />
    </AdminLayout>
  )
}
