import React, { useState, useEffect, useRef } from 'react'
import Navbar from '../../components/Navbar'
import UserHeader from '../../components/UserHeader'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import AdminLayout from '../../layout/AdminLayout'
import { useParams } from 'react-router'
import AdminCourseNavbar from '../../components/AdminCourseNavbar'
import notify from '../../utils/IANotification'
import ClassSchedule from '../../subpages/ClassSchedule'

export default function AdminCourseClassFormPage() {
  const currentDate = '2018-11-01'
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("staff")
  let user_data = useFetchUserData()

  const course_id = new URLSearchParams(window.location.search)?.get("course_id")
  const lesson_id = new URLSearchParams(window.location.search)?.get("lesson_id")
  const class_id = new URLSearchParams(window.location.search)?.get("class_id")
  const [heightOfArea, setHeightOfArea] = useState(window.innerHeight - document.getElementById("admin-header")?.clientHeight - document.getElementById("mid-header")?.clientHeight)
  useEffect(() => {
    setHeightOfArea(window.innerHeight - document.getElementById("admin-header")?.clientHeight - document.getElementById("mid-header")?.clientHeight)
  }, [window.innerHeight, document.getElementById("admin-header")?.clientHeight, document.getElementById("mid-header")?.clientHeight])

  function tidy_date(date) {
    let year = date.getFullYear()
    let month = date.getMonth() + 1
    let day = date.getDate()
    if (month < 10) {
      month = `0${month}`
    }
    if (day < 10) {
      day = `0${day}`
    }

    return `${year}-${month}-${day}`
  }


  const [scheduleData, setScheduleData] = useState([
    // { startDate: '2023-05-10T12:00', endDate: '2023-05-10T13:30', title: 'Go to a gym', payload: "hi" },
    // { startDate: '2023-05-10T12:00', endDate: '2023-05-10T13:30', title: 'Go to a gym', payload: "hi" },
    // { startDate: '2023-05-10T09:45', endDate: '2023-05-10T11:00', title: 'Meeting', payload: "hi" },
    // { startDate: '2023-05-10T12:00', endDate: '2023-05-10T13:30', title: 'Go to a gym', payload: "hi" },
  ])
  const [selectedSchedule, setSelectedSchedule] = useState()


  const [classData, setClassData] = useState({
    name: "",
    description: "",
    course_id: "",
    start_datetime: "",
    end_datetime: "",
    is_forever: "0",
    instructions: ""
  })

  const fetchClassById = async () => {
    if (class_id === "new") return
    if (!class_id) return
    let endpoint = config.endpoint + "/class/id/" + class_id
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      setClassData(data.data)
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  const fetchScheduleById = async () => {
    if (!scheduleData?.id) return
    let endpoint = config.endpoint + "/schedule/id/" + scheduleData?.id
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      setClassData(data.data)
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  const getClassSchedule = async () => {
    let endpoint = config.endpoint + "/schedule/class/id/" + class_id
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      let temp = data.data.map((schedule) => {
        return {
          ...schedule,
          startDate: schedule.start_datetime,
          endDate: schedule.end_datetime,
          title: schedule.name,
        }
      })
      setScheduleData(temp)
    } else {
      notify({ message: data.message, type: "error" })
    }
  }


  useEffect(() => {
    fetchScheduleById()
  }, [selectedSchedule])

  useEffect(() => {
    fetchClassById()
    getClassSchedule()
  }, [])

  return (
    <AdminLayout navSelected={"Courses"}>
      <p className='mb-4' style={{ fontSize: 30, fontWeight: "500" }}>{classData?.name} Schedule</p>
      <AdminCourseNavbar selected={"Classes"} course_id={course_id} />
      <ClassSchedule type={"admin"}/>
    </AdminLayout>
  )
}
