import React, { useEffect, useState, useLayoutEffect } from 'react'
import config from '../app-config'
import { useFetchUserData } from '../utils/AppFunctions'
import { MdCalendarToday, MdDoorbell, MdNotifications, MdOutlineNotifications, MdPerson } from 'react-icons/md'
import ProfileMenu from './ProfileMenu'
import CalendarModal from './CalendarModal'
import AnnouncementModal from './AnnouncementModal'
import { isMobile } from '../utils/helper'

export default function UserHeader({ type, setShowNavbar, showNavbar }) {
    // const userData = useFetchUserData()
    const [width, setWidth] = useState(window.innerWidth);
    return (
        <>
            <div className="row justify-between items-center shadow sticky top-0" style={{ backgroundColor: "white",
                position: isMobile(width) && "sticky",
                top: 0,
                zIndex:1999,
             }}
                onClick={() => {
                    if (isMobile(width)) {
                        setShowNavbar(!showNavbar)
                    }
                }}
            >
                {isMobile(width) && <div>
                    <img src={config.images.logo_wo_text} alt="" 
                    // style={{ width: 50, height: 50, objectFit: "contain" }} 
                    className="size-8 object-contain"
                    />
                </div>}
                <div style={{ width: "100%", padding: 10, backgroundColor: "white", display: "flex", justifyContent: "left", alignItems: "center", flexDirection: "row", borderBottom: "0px solid black", position: "sticky", top: 0, zIndex: 100 }} id='user-header'>
                    <div className="row items-center w-full gap-4 pr-4" style={{ justifyContent: "right" }}>
                        {/* Calendar */}
                        <CalendarModal type={type} />

                        {/* Notifications */}
                        <AnnouncementModal type={type} />
                        {/* Profile */}
                        <ProfileMenu />
                    </div>
                </div>
            </div>
        </>
    )
}
