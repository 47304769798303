import React, { useState, useRef, useMemo } from 'react';
import JoditEditor from 'jodit-react';


export default function TextComponent({ readOnly, data, onChange }) {
    const editor = useRef(null);

    const placeholder = "Start typing...";
  
    const liveConfig = {
        height: 500,
        placeholder,
        // set custom buttons
        buttons: [
            'bold',
            'italic',
            'underline',
            'strikethrough',
            'superscript',
            'subscript',
            'ul',
            'ol',
            'outdent',
            'indent',
            'font',
            'fontsize',
            'brush',
            'paragraph',
            'table',
            'link',
            'undo',
            'redo',
            'eraser',
            'copyformat',
        ],
        askBeforePasteHTML: false,
        hotkeys: {
            redo: 'ctrl+z',
            undo: 'ctrl+y,ctrl+shift+z',
            indent: 'ctrl+]',
            outdent: 'ctrl+[',
            bold: 'ctrl+b',
            italic: 'ctrl+i',
            removeFormat: 'ctrl+shift+m',
            insertOrderedList: 'ctrl+shift+7',
            insertUnorderedList: 'ctrl+shift+8',
            openSearchDialog: 'ctrl+f',
            openReplaceDialog: 'ctrl+r',
        },
    };

    const readOnlyConfig = {
        height: 500,
        readonly: true,
        placeholder,
        toolbar: false,
        borderColor: '#fff',
    };

    return (
        <>
            {
                readOnly ?
                    <>
                        <div dangerouslySetInnerHTML={{ __html: data ||""}} />
                    </>
                    :
                    <>
                        <JoditEditor
                            ref={editor}
                            value={data}
                            config={liveConfig}
                            tabIndex={1} // tabIndex of textarea
                            onBlur={newContent =>{
                                onChange(newContent);
                            }}
                            
                        />
                    </>
            }
        </>
    )
}
