import React, { useLayoutEffect, useState } from 'react'
import { MdAppBlocking, MdClass, MdGroup, MdHome, MdSettings } from 'react-icons/md'
import { useParams } from 'react-router-dom'
import { TbReport } from "react-icons/tb";
import { IoDocumentsOutline, IoEaselOutline, IoHomeOutline, IoJournalOutline, IoLibraryOutline, IoNewspaperOutline, IoPeopleOutline, IoSettingsOutline } from "react-icons/io5";

export default function StudentCourseNavbar({ selected, setSelected }) {

    const { course_id } = useParams()

    const [headerHeight, setHeaderHeight] = useState(document?.getElementById("user-header")?.clientHeight + 1)

    useLayoutEffect(() => {
        setHeaderHeight(document?.getElementById("user-header")?.clientHeight + 1)
    }, [])

    const ITEMS = [
        {
            "name": "Lessons",
            "icon": <IoLibraryOutline />,
        },
        {
            "name": "Assignments",
            "icon": <IoJournalOutline />,
        },
        {
            "name": "Examinations",
            "icon": <IoJournalOutline />,
        },
    ]
    return (
        <div id='course-nav' className='mt-4 row items-center rounded-t-md overflow-hidden' style={{
            width: "100%",
            // borderTop: "2px solid #E5E5E5",
            position: "sticky",
            top: headerHeight,
            zIndex: 5,

        }}>
            {ITEMS.map((item, index) => {
                return (
                    <div className={`
                    row gap-4 items-center pr-20 pl-20 cursor-pointer
                    ${ selected === item.name ? "border-t-2 border-t-blue-500 bg-white" : "bg-slate-200"}
                    `} 
                        onClick={() => {
                            setSelected(item.name)
                        }}>
                        <p className="text-sm py-1" style={{ fontWeight: 500 }}>{item.name}</p>
                    </div>
                )
            })}
        </div>
    )
}
