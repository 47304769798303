import React, { useEffect, useRef, useState } from 'react'
import Navbar from '../components/Navbar'
import UserHeader from '../components/UserHeader'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../utils/AppFunctions'
import config from '../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import AdminLayout from '../layout/AdminLayout'
import { useParams } from 'react-router'
import notify from '../utils/IANotification'
import Select from 'react-select'
import { FaCertificate } from 'react-icons/fa'
import { IoClose } from 'react-icons/io5'
import { MdPrint } from 'react-icons/md'
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import { isMobile, tidyDateRaw } from '../utils/helper'
import "../print.css"
import Line from '../components/shapes/Line'

export default function CertificateView() {
  const navigate = useNavigate()

  const certificateRef = useRef(null)
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = useFetchUserData()

  const certificate_id = new URLSearchParams(window.location.search).get("certificate_id")
  const user_id = new URLSearchParams(window.location.search).get("user_id")
  const course_id = new URLSearchParams(window.location.search).get("course_id")


  const [courseData, setCourseData] = useState([])
  const [studentData, setStudentData] = useState([])
  const [showCertificate, setShowCertificate] = useState(false)

  const [certificateForm, setCertificateForm] = useState({
    user_id: user_id,
    course_id: course_id,
  })

  // const fetchAllCourses = async () => {
  //   let endpoint = config.endpoint + "/course/"
  //   let fetchResponse = await fetch(endpoint, {
  //     method: "GET",
  //     credentials: "include",
  //     headers: {
  //       "Content-Type": "application/json"
  //     }
  //   })
  //   let response = await fetchResponse.json()
  //   if (response.status === 200) {
  //     setCourseData(response.data)
  //   }
  // }

  const fetchCertificateById = async () => {
    if (!certificate_id) return
    if (certificate_id === "new") return
    let endpoint = config.endpoint + "/certificate/id/" + certificate_id
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      }
    })
    let response = await fetchResponse.json()
    if (response.status === 200) {
      setCertificateForm(response.data)
    }
  }

  useEffect(() => {
    // fetchAllCourses()
    fetchCertificateById()
  }, [])


  // print certificate on browser - landscape

  // window.onafterprint = function () {
  //   setShowCertificate(false)
  // }

  const handlePrint = useReactToPrint({
    content: () => certificateRef.current,
    onBeforeGetContent: () => {
      setShowCertificate(false)
    },
    pageStyle: ` @page {
      size: A4 landscape;
      margin-top: 100;
    }`,
  })

  useEffect(() => {
    if (certificateForm?.user_id && certificateForm?.course_id && courseData.length > 0) {
      handlePrint()
    }
  }, [certificateForm.user_id, certificateForm.course_id, courseData.length])

  return (
    <div className="column" style={{ width: "100%", height: "100dvh", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "gray", zoom: isMobile(window.innerWidth) && "50%" }}>

      {/* Certificate */}
      <div className="" style={{ backgroundColor: "white", width: "90%", height: "90%", display: "flex", alignItems: "center", justifyContent: "center", }}>
        <div ref={certificateRef} className='column center items-center' style={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center", }}>
          <div style={{ position: "absolute", zIndex: 0, width: "100%", height: "100%", flexDirection: "column", display: "flex", alignItems: "center", justifyContent: "center" }}>
            <img src="/certificate/frame_2.png" alt="frame" style={{ width: "90%", height: "90%", objectFit: "cover", }} />
          </div>
          <div style={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center", position: "relative", flexDirection: "column", zIndex: 2 }}>
            <div className="center items-center row gap-4 mb-20">
              <img src={config.images.logo_wo_text} width={100} height={50} alt="logo" style={{ objectFit: "contain" }} />
              <p style={{ fontSize: 25, fontWeight: "900", lineHeight: 1.5, }}>{config.client_title}<br /><span style={{ letterSpacing: 3, fontWeight: "normal" }}>{config.client_subtitle}</span></p>
            </div>
            <p>This is to Certify that</p>
            <p style={{ textDecoration: "underline", width: "80%", fontSize: 50, textAlign: "center", }}>{certificateForm.user_data?.first_name} {certificateForm.user_data?.last_name}</p>
            <p style={{ marginTop: 20 }}>has successfully completed the course</p>
            <p
              style={{
                width: "80%",
                fontSize: 30,
                textAlign: "center",
              }}
            >{certificateForm.course_data?.name}</p>
            <p>on {tidyDateRaw(certificateForm.createdAt).day} {tidyDateRaw(certificateForm.createdAt).month} {tidyDateRaw(certificateForm.createdAt).year}</p>
            <div style={{ position: "absolute", bottom: `calc(10% + 20px)`, left: `calc(10% + 20px)`, width: "200px" }}>
              <p style={{ fontSize: 30, paddingLeft: 20, fontWeight: "500", fontStyle: "italic", fontFamily: "cursive" }}>{certificateForm.issuer_data?.first_name} {certificateForm.issuer_data?.last_name}</p>
              <Line width={300} />
              <p style={{ fontSize: 15 }}>{certificateForm.issuer_data?.first_name} {certificateForm.issuer_data?.last_name}</p>
              <p style={{ fontSize: 14, color: "gray", fontWeight: "bold" }}>{config.client_name}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
