import React, { useState, useEffect } from 'react'
import Navbar from '../../components/Navbar'
import UserHeader from '../../components/UserHeader'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import AdminLayout from '../../layout/AdminLayout'
import { useParams } from 'react-router'
import AdminCourseNavbar from '../../components/AdminCourseNavbar'
import notify from '../../utils/IANotification'
import { IoAdd, IoTrash } from 'react-icons/io5'
import Line from '../../components/shapes/Line'
import { MdReorder } from 'react-icons/md'
import EditorJSComponent from '../../components/lesson/EditorJSComponent'
import TextComponent from '../../components/lesson/TextComponent'
import ImageComponent from '../../components/lesson/ImageComponent'
import Switch from 'react-switch'
import CodeComponent from '../../components/lesson/CodeComponent'
import VideoComponent from '../../components/lesson/VideoComponent'
import DocumentComponent from '../../components/lesson/DocumentComponent'
import AudioComponent from '../../components/lesson/AudioComponent'
import { PiDotsSixVerticalBold } from "react-icons/pi";
import { Draggable } from "react-drag-reorder";
import SingleSelectAssessmentComponent from '../../components/assessment/SingleSelectAssessmentComponent'
import SingleTextAssessmentComponent from '../../components/assessment/SingleTextAssessmentComponent'
import MultipleSelectAssessmentComponent from '../../components/assessment/MultipleSelectAssessmentComponent'
import FileUploadAssessmentComponent from '../../components/assessment/FileUploadAssessmentComponent'
import FileUploadField from '../../components/FileUploadField'
import CodeEditorAssessmentComponent from '../../components/assessment/CodeEditorAssessmentComponent'
import AssessmentForm from '../../subpages/AssessmentForm'

export default function AdminCourseAssessmentFormPage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("staff")
  let user_data = useFetchUserData()

  const course_id = new URLSearchParams(window.location.search)?.get("course_id")
  const lesson_id = new URLSearchParams(window.location.search)?.get("lesson_id")
  const assessment_id = new URLSearchParams(window.location.search)?.get("assessment_id")

  const [showPreview, setShowPreview] = useState(false)
  const [isLoaded, setIsLoaded] = useState(true)

  let modal = {
    question: "",
    answers: "",
    question_options: [], // [option1, option2, option3, option4]
    type: "single-text", // single-text, multi-text, single-select, multi-select, code, file,
    internal_notes: "",
    max_marks: 0,
    options: {},
    order: 0,
  }


  const [fileAttachments, setFileAttachments] = useState([])


  const [assessmentData, setAssessmentData] = useState({
    name: "",
    description: "",
    instructions: "",
    time_limit: 0, // in minutes
    is_unlimited_time: 0,
    attempts: 0,
    is_unlimited_attempts: 0,
    system_marking: 0,

    // Future
    // allow_backtracking: 0,
    // allow_skipping: 0,

  })
  const [componentData, setComponentData] = useState([
    { ...modal },
  ])

  const createAssessment = async () => {
    let endpoint = config.endpoint + "/assessment/"
    let fetchResponse = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        ...assessmentData,
        course_id
      })
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      let id = data.data.id
      navigate("/admin/courses/assessments/details?course_id=" + course_id + "&assessment_id=" + id)
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  const editAssessment = async () => {
    if (assessment_id === "new") return
    let endpoint = config.endpoint + "/assessment/id/" + assessment_id
    let fetchResponse = await fetch(endpoint, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        ...assessmentData,
        course_id
      })
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      let id = data.data.id
      notify({ message: data.message, type: "success" })
    } else {
      notify({ message: data.message, type: "error" })
    }
  }


  const saveComponent = async () => {
    let endpoint = config.endpoint + "/assessment/component/"
    let fetchResponse = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        assessment_id,
        course_id,
        component_data: componentData,
        file_attachments: fileAttachments
      })
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      fetchAssessmentById()
      fetchAssessmentComponentsById()
      notify({ message: data.message, type: "success" })
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  const fetchAssessmentById = async () => {
    if (assessment_id === "new") return
    let endpoint = config.endpoint + "/assessment/id/" + assessment_id
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      setAssessmentData(data.data)
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  const fetchAssessmentComponentsById = async () => {
    if (assessment_id === "new") return
    setIsLoaded(false)
    let endpoint = `${config.endpoint}/assessment/component/assessment/id/${assessment_id}`
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      setComponentData(data.data)
      setIsLoaded(true)
      notify({ message: data.message, type: "success" })
    } else {
      notify({ message: data.message, type: "error" })
    }
  }


  useEffect(() => {
    fetchAssessmentById()
    fetchAssessmentComponentsById()
  }, [])

  return (
    <AdminLayout navSelected={"Courses"}>
      <p className='mb-4' style={{ fontSize: 30, fontWeight: "500" }}>{assessment_id === "new" ? "New Assessment" : assessmentData?.name}</p>
      <AdminCourseNavbar selected={"Assessments"} course_id={course_id} />
      <AssessmentForm type={"admin"} />
    </AdminLayout>
  )
}