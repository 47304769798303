import React, { useState } from 'react'
import { IoAdd, IoClose } from 'react-icons/io5'
import Slider, { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';
import { isMobile } from '../../utils/helper';

export default function SliderFeedbackComponent({ state, data, onChange, item_index, onAnswered, userAnswer, onAnswer, readonly }) {
    const [width, setWidth] = useState(window.innerWidth);
    
    return (
        <>
            {state === "builder" && <>
                <div className='mt-4'>
                    <label htmlFor="" className="input-label mb-4">Range</label>
                    <div className={`${isMobile(width) ? "column" : "row"}  gap-4 items-center`}>
                        <div className="input-container" style={{ margin: 0, padding: 0 }}>
                            <input type="number" className="text-input" placeholder='Start' required value={data?.options?.min || 1} onChange={(e) => {
                                let temp = { ...data }
                                temp.options.min = e.target.value
                                onChange(temp)
                            }} />
                        </div>
                        <p>to</p>
                        <div className="input-container" style={{ margin: 0, padding: 0 }}>
                            <input type="number" className="text-input" placeholder='End' required value={data?.options?.max || 5} onChange={(e) => {
                                let temp = { ...data }
                                temp.options.max = e.target.value
                                onChange(temp)
                            }} />
                        </div>
                    </div>
                </div>
            </>}
            {state === "answer" && <>
                <Slider
                    min={data?.options?.min || 1}
                    max={data?.options?.max || 5}
                    value={userAnswer}
                    onChange={(e) => {
                        onAnswer(e)
                    }}
                    startPoint={userAnswer}
                    marks={{
                        [data?.options?.min || 1]: data?.options?.min || 1,
                        [data?.options?.max || 5]: data?.options?.max || 5
                    }}

                    disabled={readonly}

                    style={{
                        width: "100%",
                        margin: "20px 0"
                    }}

                    // styles={{
                    //     track: {
                    //         backgroundColor: 'black',
                    //         height: 10
                    //     },
                    //     active: {
                    //         backgroundColor: 'yellow',
                    //         height: 10
                    //     },
                    //     thumb: {
                    //         width: 20,
                    //         height: 20,
                    //         backgroundColor: 'black',
                    //         border: '1px solid black'
                    //     }
                    // }}
                />
            </>}
        </>
    )
}
