import React from 'react'
import { IoAdd, IoClose, IoTrash } from 'react-icons/io5'
import { MdBolt, MdEdit, MdShortText, MdTextSnippet } from 'react-icons/md'
import Switch from 'react-switch'

export default function SingleTextAssessmentComponent({ data, options, onOptionChange, onChange, onAnswered, state, userAnswer, revealAnswer }) {
    /*
    
    */
    return (
        <>
            {state === "builder" && <>
                <p>Text Response</p>
                <div className="w-full row gap-30 right alignEnd">

                    <button className="backgroundLess row gap-4 items-center"
                        onClick={() => {
                            onChange({ ...data, system_graded: (data?.system_graded == 1 ? 0 : 1) })
                        }}
                    >
                        {<>
                            {data?.system_graded == 1 ? <MdBolt size={20} /> : <MdEdit size={20} />}
                            {data?.system_graded == 1 ? "System Graded" : "Manual Grading"}
                        </>}
                    </button>

                    <button className="backgroundLess row gap-4 items-center"
                        onClick={() => {
                            onOptionChange({ ...options, type: (options?.type == "text" ? "long_text" : "text") })
                        }}
                    >
                        {<>
                            {options?.type == "text" ? <MdShortText size={20} /> : <MdTextSnippet size={20} />}
                            {options?.type == "text" ? "Short Text" : "Long Text"}
                        </>}
                    </button>
                </div>
                {data?.system_graded == 1 && <div className='column gap-4 mb-4'>
                    <div className="input-container">
                        <label htmlFor="" className="input-label">Answers</label>
                        <input type="text" className="text-input" value={data?.answers} onChange={(e) => {
                            onChange({ ...data, answers: e.target.value })
                        }} />
                    </div>
                </div>}
            </>}
            {state === "question" && <>
                <div className='column gap-4 mb-4'>
                    <div className="input-container">
                        {options?.type == "text" ?
                            <input type="text" className="text-input w-full" value={userAnswer} onChange={(e) => {
                                onAnswered(e.target.value)
                            }} />
                            :
                            <textarea type="text" className="textarea-input" value={userAnswer} onChange={(e) => {
                                onAnswered(e.target.value)
                            }} />
                        }
                        {revealAnswer && <p className="input-label">Answer: {data?.answers}</p>}
                    </div>
                </div>
            </>}
        </>
    )
}
