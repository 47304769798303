import React from 'react'
import Navbar from '../../components/Navbar'
import UserHeader from '../../components/UserHeader'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import AdminLayout from '../../layout/AdminLayout'
import { useParams } from 'react-router'
import UserProfilePage from '../../subpages/UserProfilePage'

export default function AdminUserProfilePage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("staff")
  let user_data = useFetchUserData()
  
  const { course_id } = useParams()
  return (
    <AdminLayout navSelected={"Settings"}>
      <UserProfilePage type="admin" />
    </AdminLayout>
  )
}
