import React, { useEffect, useLayoutEffect, useState } from 'react'
import config from '../app-config'
import { MdCalendarMonth, MdCalendarToday, MdOutlineNotifications } from 'react-icons/md'
import { tidyDate, tidyDateRaw } from '../utils/helper'
import AnnouncementCard from './AnnouncementCard'


export default function AnnouncementModal({ type }) {
  const [announcementData, setAnnouncementData] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [buttonPos, setButtonPos] = useState(document?.getElementById("announcement-button"))
  const [showAnnouncement, setShowAnnouncement] = useState(false)
  const [selectedAnnouncement, setSelectedAnnouncement] = useState({})


  const fetchAnnouncement = async () => {
    let endpoint = config.endpoint + `/announcement/${type}/id/`
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      setAnnouncementData(data.data)
    }
  }

  useLayoutEffect(() => {
    setButtonPos(document?.getElementById("announcement-button"))
  }, [document?.getElementById("announcement-button")])


  useEffect(() => {
    fetchAnnouncement()
  }, [])

  window.addEventListener("scroll", () => {
    setShowModal(false)
  })


  const Modal = () => {

    return (
      <div className="rounded-md shadow-md px-4" style={{
        position: "absolute",
        top: buttonPos?.getClientRects()[0]?.bottom + 30,
        right: 10,
        zIndex: 100,
        width: 400,
        backgroundColor: "white",
        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.2)",
      }}>
        {announcementData?.length > 0 ? announcementData.map((item, index) => {
          if (index > 3) return
          return (
            <div className='p-all row justify-between gap-20 items-center w-full' style={{ backgroundColor: "white", paddingLeft: 10 }}>
              <div className="" style={{ maxHeight: "50px", textOverflow: "ellipsis", overflow: "clip" }}>
                <p className="card-subtitle" style={{ color: "gray" }}>{tidyDate(item?.start_datetime)}</p>
                <h5 className="card-title">{item.title}</h5>
              </div>
              <button className="button"
                onClick={() => {
                  setSelectedAnnouncement(item)
                  setShowAnnouncement(true)
                }}
              >View</button>
            </div>
          )
        })
          :
          <div className='p-all row justify-between gap-20 items-center w-full' style={{ backgroundColor: "white", paddingLeft: 10 }}>
            <div className="" style={{ maxHeight: "50px", textOverflow: "ellipsis", overflow: "clip" }}>
              <p className="card-subtitle" style={{ color: "gray" }}>No announcements</p>
            </div>
          </div>
        }

      </div>
    )
  }


  return (
    <div>
      {showModal && <Modal />}
      <AnnouncementCard data={selectedAnnouncement} show={showAnnouncement} setShowModal={setShowAnnouncement} />
      <div className="row gap-4 items-center cursor-pointer" id='announcement-button'
        onClick={() => {
          setShowModal(!showModal)
        }}
      >
        <MdOutlineNotifications size={20} color='gray' />
      </div>
    </div>
  )
}
