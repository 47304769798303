const config = {
    endpoint: "https://lms-backend.ec.ourcodelab.com",
    code_api: "http://codestack-backend.ec.ourcodelab.com",
    local_url: "https://lms.ec.ourcodelab.com",
    codestack_url: "http://codestack-editor.ec.ourcodelab.com",
    codestack_api_url: "http://codestack-backend.ec.ourcodelab.com",
    version: "1.0.0 (120624)",
    developer: "OurCodeLab",
    developer_url: "https://www,ourcodelab.com",
    developer_email: "support@ourcodelab.com",

    images: {
        // logo: "/logo.png",
        // logo_wo_text: "/logoWOText.png",
        logo: "/empire-code/logo.png",
        logo_wo_text: "/empire-code/logoWOText.png",
    },
    client_name: "Empire Code Education",
    client_title: "Empire Code",
    client_subtitle: "Education",
    client_url: "https://www.empirecode.co",

    support_email: "hello@empirecode.co",
    privacy: "https://empirecode.co/privacy/#:~:text=We%20use%20cookies%20and%20similar,and%20stored%20on%20your%20device.",


    functions: {
        // Anything that relates to the functions of the app
        certificate_signature: "Felicia Chua",
    },

    feature_flagging: {
        // This is where you can enable or disable features in the app
        allow_registration: false,
    },
}

export default config