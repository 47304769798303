import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import UserLayout from '../../layout/UserLayout'
import { MdCake, MdCalculate, MdCalendarMonth, MdChevronRight, MdCode, MdList, MdLock, MdNote, MdTimer, MdViewKanban } from 'react-icons/md'
import notify from '../../utils/IANotification'
import { FaClipboardList } from 'react-icons/fa'
import { tidyDate, tidyDateRaw } from '../../utils/helper'
import AnnouncementCard from '../../components/AnnouncementCard'

export default function TestCodePage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = useFetchUserData()

  const datetime = () => {
    let dateObj = new Date()
    let hours = dateObj.getHours()
    let minutes = dateObj.getMinutes()
    let seconds = dateObj.getSeconds()
    let day = dateObj.getDay()
    let date = dateObj.getDate()
    let month = dateObj.getMonth()
    let year = dateObj.getFullYear()
    const MONTHS = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
    const DAYS = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
    return { date: `${date} ${MONTHS[month].slice(0, 3)}`, time: `${hours}:${minutes}:${seconds}`, day: DAYS[day], month, year }
  }

  const [headerHeight, setHeaderHeight] = useState(document?.getElementById("user-header")?.clientHeight)
  const [courseContent, setCourseContent] = useState(document?.getElementById("course-content")?.clientWidth)

  useLayoutEffect(() => {
    setHeaderHeight(document?.getElementById("user-header")?.clientHeight)
    setCourseContent(document?.getElementById("course-content")?.clientWidth)
  }, [document?.getElementById("user-header")?.clientHeight, document?.getElementById("course-content")?.clientWidth])

  const [studentCoursesData, setStudentCoursesData] = useState([])
  const [studentAnnouncementData, setStudentAnnouncementData] = useState([])
  const [studentScheduleData, setStudentScheduleData] = useState([])
  const [showAnnouncement, setShowAnnouncement] = useState(false)
  const [selectedAnnouncement, setSelectedAnnouncement] = useState({})


  const fetchAllStudentCourses = async () => {
    let endpoint = config.endpoint + "/course/student/"
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      setStudentCoursesData(data.data)
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  const fetchAllStudentAnnouncements = async () => {
    let endpoint = config.endpoint + "/announcement/student/id/"
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      setStudentAnnouncementData(data.data)
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  const fetchAllStudentSchedules = async () => {
    let endpoint = config.endpoint + "/schedule/student/id/"
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      setStudentScheduleData(data.data)
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  useEffect(() => {
    fetchAllStudentCourses()
    fetchAllStudentAnnouncements()
    fetchAllStudentSchedules()
  }, [])


  const quick_tools = [
    {
      name: "Clock",
      icon: <MdTimer size={25} color='white' />,
      link: "/tools/clock",
    },
    {
      name: "Notebook",
      icon: <MdNote size={25} color='white' />,
      link: "/tools/notebook",
    },
    {
      name: "Code playground",
      icon: <MdCode size={25} color='white' />,
      link: "/tools/code-editor",
    },
    {
      name: "Calculator",
      icon: <MdCalculate size={25} color='white' />,
      link: "/tools/calculator",
    },
    {
      name: "Calendar",
      icon: <MdCalendarMonth size={25} color='white' />,
      link: "/tools/calendar",
    },
    {
      name: "Kanban",
      icon: <MdViewKanban size={25} color='white' />,
      link: "/tools/kanban",
    }
  ]



  return (
    <UserLayout navSelected={"Home"} hideNav noParentPadding={true}>
      <AnnouncementCard data={selectedAnnouncement} show={showAnnouncement} setShowModal={setShowAnnouncement} />
      <div className="row justify-between w-full" style={{
        maxHeight: "100%",
        height: "calc(100vh - 108px)",
      }}>
        <iframe src="http://localhost:5173" width="1920px" style={{
          maxHeight: "100%",
          height: "100%"
        }}></iframe>
      </div>
    </UserLayout>
  )
}
