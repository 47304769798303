import React, { useEffect, useState, useLayoutEffect } from 'react'
import Navbar from '../../components/Navbar'
import UserHeader from '../../components/UserHeader'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import UserLayout from '../../layout/UserLayout'
import notify from '../../utils/IANotification'

export default function ProfilePage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = useFetchUserData()

  const [userProfileData, setUserProfileData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    contact_number: "",
    user_bio: "",
  })

  const fetchUserData = async () => {
    const endpoint = config.endpoint + "/auth/profile";
    const response = await fetch(endpoint, {
      credentials: "include",
      method: "GET",
    });
    const data = await response?.json();
    if (data.status === 200) {
      setUserProfileData(data.data)
    } else {
      setUserProfileData({})
      notify({ message: data.message, type: "error" })
    }
  }

  useEffect(() => {
    fetchUserData()
  }, [])

  const updateProfile = async () => {
    const endpoint = config.endpoint + "/auth/profile";
    const response = await fetch(endpoint, {
      credentials: "include",
      method: "POST",
      body: JSON.stringify(userProfileData),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response?.json();
    if (data.status === 200) {
      setUserProfileData(data.data)
      notify({ message: data.message, type: "success" })
    } else {
      notify({ message: data.message, type: "error" })
    }
  }



  return (
    <UserLayout navSelected={""}>
      <div className="column center items-center">
        <div className="card rounded-md shadow-md p-4 mt-4" style={{ width: "60%" }}>
          <h1 style={{ fontWeight: 500, fontSize: 20 }}>Profile</h1>
          <div className="input-container-horizontal">
            <label style={{ width: 150, fontSize: 13 }}>First Name</label>
            <input type="text" className='text-input' style={{ width: "100%" }} disabled={!authorisationConverter({ requirement: "parent", current_role: user_data?.account_type })} value={userProfileData.first_name} onChange={(e) => setUserProfileData({ ...userProfileData, name: e.target.value })} placeholder='e.g. John' />
          </div>
          <div className="input-container-horizontal">
            <label style={{ width: 150, fontSize: 13 }}>Last Name</label>
            <input type="text" className='text-input' style={{ width: "100%" }} disabled={!authorisationConverter({ requirement: "parent", current_role: user_data?.account_type })} value={userProfileData.last_name} onChange={(e) => setUserProfileData({ ...userProfileData, name: e.target.value })} placeholder='e.g. Tan' />
          </div>

          <div className="input-container-horizontal mt-30">
            <label style={{ width: 150, fontSize: 13 }}>Email</label>
            <input type="email" className='text-input' style={{ width: "100%" }} disabled={!authorisationConverter({ requirement: "parent", current_role: user_data?.account_type })} value={userProfileData.email} onChange={(e) => setUserProfileData({ ...userProfileData, name: e.target.value })} placeholder='e.g. abc@ocl.com' />
          </div>

          <div className="input-container-horizontal">
            <label style={{ width: 150, fontSize: 13 }}>Contact Number</label>
            <input type="tel" className='text-input' style={{ width: "100%" }} disabled={!authorisationConverter({ requirement: "parent", current_role: user_data?.account_type })} value={userProfileData.contact_number} onChange={(e) => setUserProfileData({ ...userProfileData, name: e.target.value })} placeholder='e.g. 8123 4567' />
          </div>

          <div className="input-container-horizontal mt-30 items-start row" style={{ alignItems: "flex-start" }}>
            <label style={{ width: 150, fontSize: 13 }}>Bio</label>
            <textarea className='textarea-input' style={{ width: "100%", height: 100 }} value={userProfileData.user_bio} onChange={(e) => setUserProfileData({ ...userProfileData, user_bio: e.target.value })} placeholder='e.g. I love coding! Especially Python print("Hello, World!")' />
          </div>


          <div className="column right alignEnd">
            <button className="button" style={{ width: 100, marginTop: 20 }} onClick={() => {
              updateProfile()
            }}>Save</button>
          </div>

        </div>
      </div>
    </UserLayout>
  )
}
