import React from 'react'
import Header from '../components/Header'
import config from '../app-config'

export default function ErrorPage() {
  return (
    <div className='min-h-screen'>
      <Header />
      <div className="w-full">
        <div className="container mx-auto">
          <div className="flex justify-center items-center mt-52">
            <div className="text-center">
              <h1 className="text-6xl text-gray-800 font-bold">404</h1>
              <h2 className="text-2xl text-gray-800 font-semibold">Page Not Found</h2>
              <p className="text-gray-600">The page you are looking for might have been removed, had its name changed or is temporarily unavailable.</p>
              <div className="flex flex-row gap-4 items-center justify-center mt-10">
                <a href="/" className="text-blue-500">Go back to Home</a>
                <span>•</span>
                <button className="text-blue-500"
                  onClick={() => {
                    let message = "Hello, I am using the Empire Code LMS and I would like some help with the following: \n"
                    let subject = "Empire Code LMS Support"
                    let email = config.support_email
                    let cc_email = config.developer_email
                    window.location.href = `mailto:${email}?subject=${subject}&body=${message}&cc=${cc_email}`
                  }}
                >Contact Support</button>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
