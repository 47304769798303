import React, { useEffect } from 'react'
import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header';
import List from '@editorjs/list';
import Checklist from '@editorjs/checklist';
import Embed from '@editorjs/embed';
import Link from '@editorjs/link';
import Quote from '@editorjs/quote';
import RawTool from '@editorjs/raw';
import { createReactEditorJS } from 'react-editor-js'




// import Embed from '@editorjs/embed'
import Table from '@editorjs/table'
// import Paragraph from '@editorjs/paragraph'
// import List from '@editorjs/list'
// import Warning from '@editorjs/warning'
// import Code from '@editorjs/code'
// import LinkTool from '@editorjs/link'
// import Image from '@editorjs/image'
// import Raw from '@editorjs/raw'
// import Header from '@editorjs/header'
// import Quote from '@editorjs/quote'
// import Marker from '@editorjs/marker'
// import CheckList from '@editorjs/checklist'
// import Delimiter from '@editorjs/delimiter'
// import InlineCode from '@editorjs/inline-code'
// import SimpleImage from '@editorjs/simple-image'

export const EDITOR_JS_TOOLS = {
    // NOTE: Paragraph is default tool. Declare only when you want to change paragraph option.
    // paragraph: Paragraph,
    embed: Embed,
    table: Table,
    list: List,
    //   warning: Warning,
    //   code: Code,
    //   linkTool: LinkTool,
    image: Image,
    //   raw: Raw,
    header: Header,
    quote: Quote,
    //   marker: Marker,
    //   checklist: CheckList,
    //   delimiter: Delimiter,
    //   inlineCode: InlineCode,
    //   simpleImage: SimpleImage,
}


export default function EditorJSComponent({ id, data, readOnly, onChange }) {


    const ReactEditorJS = createReactEditorJS()

    return (
        <>
        <p>Test</p>
            <ReactEditorJS defaultValue={data} tools={EDITOR_JS_TOOLS} />
        </>
    )
}
