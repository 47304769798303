import React, { useState, useEffect } from 'react'
import Navbar from '../../components/Navbar'
import UserHeader from '../../components/UserHeader'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import AdminLayout from '../../layout/AdminLayout'
import { useParams } from 'react-router'
import notify from '../../utils/IANotification'
import AdminCourseNavbar from '../../components/AdminCourseNavbar'

export default function AdminStudentCompletionPage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("staff")
  let user_data = useFetchUserData()

  const course_id = new URLSearchParams(window.location.search)?.get("course_id")

  const [lessonData, setlessonData] = useState([])
  const [completionData, setCompletionData] = useState([])
  const [studentData, setStudentData] = useState([])

  const DATA_CELLS_WIDTH = "200px"

  const getLesson = async () => {
    if (!course_id) return notify({ message: "Unable to fetch lessons", type: "error" })
    let endpoint = config.endpoint + "/lesson/course/id/" + course_id
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      setlessonData(data.data)
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  const getCompletionDataByCourse = async () => {
    if (!course_id) return notify({ message: "Unable to fetch completion data", type: "error" })
    let endpoint = config.endpoint + "/completion/course/id/" + course_id
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      setCompletionData(data.data?.completion_data)
      setStudentData(data.data?.student_data)
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  useEffect(() => {
    getLesson()
    getCompletionDataByCourse()
  }, [])

  return (
    <AdminLayout navSelected={"Courses"}>
      <p className='mb-4' style={{ fontSize: 30, fontWeight: "500" }}>Completion</p>
      {course_id != "new" && <AdminCourseNavbar selected={"Lessons"} course_id={course_id} />}
      <div style={{ border: "0px solid black", overflowY: "hidden" }} className='hide-scrollbars'>
        <div className="row" style={{ position: "sticky", top: 0 }}>
          <div style={{ border: "1px solid black", padding: 10, whiteSpace: "nowrap", minWidth: "300px", backgroundColor: "#0022FF", color: "white" }}>
            <p>Name/Lesson</p>
          </div>
          {lessonData?.length > 0 && lessonData?.map((data, i) => {
            return (
              <div style={{ border: "1px solid black", padding: 10, minWidth: DATA_CELLS_WIDTH, backgroundColor: "#0022FF", color: "white" }}>
                <p style={{ fontSize: 14, fontWeight: "900" }}>Lesson {i + 1}</p>
                <p style={{ fontSize: 15 }}>{data?.name}</p>
              </div>
            )
          })}
        </div>

        {studentData?.ongoing?.length > 0 && studentData?.ongoing?.map((data, i) => {
          let student_id = data?.user_id
          let components = completionData?.filter((completion) => {
            return completion?.student_id == student_id
          })[0]?.components

          return (
            <div className="row">
              <div style={{ border: "1px solid black", padding: 10, whiteSpace: "nowrap", minWidth: "300px", position: "static", backgroundColor: "#0022FF", color: "white" }}>
                <p style={{ fontWeight: "bold" }}>{data?.first_name} {data?.last_name}</p>
              </div>
              {components?.length > 0 && components?.map((component, i) => {
                let indexed = components?.find((component) => component?.lesson_id == lessonData[i]?.id)
                let completion_percentage = (indexed?.completed / indexed?.components * 100).toFixed(2)
                if (isNaN(completion_percentage)) completion_percentage = 0
                if (completion_percentage > 100) completion_percentage = 100
                let color = "black"
                let bgColor = "white"

                if (completion_percentage == 100) {
                  color = "white"
                  bgColor = "var(--green)"
                } else if (completion_percentage < 100 && completion_percentage > 0) {
                  color = "white"
                  bgColor = "orange"
                } else if (completion_percentage == 0) {
                  color = "white"
                  bgColor = "red"
                } else {
                  color = "black"
                  bgColor = "white"
                }

                return (
                  <div style={{ border: "1px solid black", padding: 10, backgroundColor: bgColor, minWidth: DATA_CELLS_WIDTH }}>
                    {/* <p style={{ fontSize: 14, fontWeight: "900", color: color }}>Lesson {i + 1}</p> */}
                    <p style={{ fontSize: 15, color: color }}>{completion_percentage}%</p>
                  </div>
                )
              })}
            </div>
          )
        })}
      </div>
    </AdminLayout >
  )
}
