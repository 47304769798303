import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import UserLayout from '../../layout/UserLayout'
import { MdCake, MdCalculate, MdCalendarMonth, MdChevronRight, MdCode, MdList, MdLock, MdNote, MdTimer, MdViewKanban } from 'react-icons/md'
import notify from '../../utils/IANotification'
import { FaClipboardList } from 'react-icons/fa'
import { tidyDate, tidyDateRaw } from '../../utils/helper'
import AnnouncementCard from '../../components/AnnouncementCard'

export default function HomePage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = useFetchUserData()

  const datetime = () => {
    let dateObj = new Date()
    let hours = dateObj.getHours()
    let minutes = dateObj.getMinutes()
    let seconds = dateObj.getSeconds()
    let day = dateObj.getDay()
    let date = dateObj.getDate()
    let month = dateObj.getMonth()
    let year = dateObj.getFullYear()
    const MONTHS = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
    const DAYS = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
    return { date: `${date} ${MONTHS[month].slice(0, 3)}`, time: `${hours}:${minutes}:${seconds}`, day: DAYS[day], month, year }
  }

  const [headerHeight, setHeaderHeight] = useState(document?.getElementById("user-header")?.clientHeight)
  const [courseContent, setCourseContent] = useState(document?.getElementById("course-content")?.clientWidth)

  useLayoutEffect(() => {
    setHeaderHeight(document?.getElementById("user-header")?.clientHeight)
    setCourseContent(document?.getElementById("course-content")?.clientWidth)
  }, [document?.getElementById("user-header")?.clientHeight, document?.getElementById("course-content")?.clientWidth])

  const [studentCoursesData, setStudentCoursesData] = useState([])
  const [studentAnnouncementData, setStudentAnnouncementData] = useState([])
  const [studentScheduleData, setStudentScheduleData] = useState([])
  const [showAnnouncement, setShowAnnouncement] = useState(false)
  const [selectedAnnouncement, setSelectedAnnouncement] = useState({})


  const fetchAllStudentCourses = async () => {
    let endpoint = config.endpoint + "/course/student/"
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      setStudentCoursesData(data.data)
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  const fetchAllStudentAnnouncements = async () => {
    let endpoint = config.endpoint + "/announcement/student/id/"
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      setStudentAnnouncementData(data.data)
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  const fetchAllStudentSchedules = async () => {
    let endpoint = config.endpoint + "/schedule/student/id/"
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      setStudentScheduleData(data.data)
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  useEffect(() => {
    fetchAllStudentCourses()
    fetchAllStudentAnnouncements()
    fetchAllStudentSchedules()
  }, [])


  const quick_tools = [
    {
      name: "Clock",
      icon: <MdTimer size={25} color='white' />,
      link: "/tools/clock",
    },
    {
      name: "Notebook",
      icon: <MdNote size={25} color='white' />,
      link: "/tools/notebook",
    },
    {
      name: "Code playground",
      icon: <MdCode size={25} color='white' />,
      link: "/tools/code-editor",
    },
    // {
    //   name: "Calculator",
    //   icon: <MdCalculate size={25} color='white' />,
    //   link: "/tools/calculator",
    // },
    // {
    //   name: "Calendar",
    //   icon: <MdCalendarMonth size={25} color='white' />,
    //   link: "/tools/calendar",
    // },
    {
      name: "Kanban",
      icon: <MdViewKanban size={25} color='white' />,
      link: "/tools/kanban",
    }
  ]



  return (
    <UserLayout navSelected={"Home"}>
      <AnnouncementCard data={selectedAnnouncement} show={showAnnouncement} setShowModal={setShowAnnouncement} />
      <div className="row justify-between items-center w-full p-all">
        <div>
          <p style={{ fontWeight: "normal", fontSize: 25 }}>Welcome back <span style={{ fontWeight: "600" }}>{user_data?.first_name}</span>,</p>
          <p style={{ marginTop: 10 }} className="text-slate-500">It's {datetime().day}, {datetime().date}</p>
        </div>
        <img src="/landing.png" alt="studying" style={{ height: 200, width: 500, objectFit: "contain" }} />
      </div>
      {/* Content */}
      <div className='row gap-4 items-start mt-30' >
        <div className="w-60 column gap-4">

          {/* Courses */}
          <div className="card shadow-lg rounded-md p-4" style={{ resize: "none", flexGrow: 0, flexShrink: 1, }} id='course-content' >
            <div className="row items-center justify-between w-full">
              <p className=' text-md font-semibold'>My Courses</p>
              <div className="row gap-2 items-center hover-underline cursor-pointer"
                onClick={() => {
                  navigate("/courses")
                }}
              >
                <p className='text-sm' style={{ color: "gray" }}>View all</p>
                <MdChevronRight size={20} color='gray' />
              </div>
            </div>
            <div className='mt-30 row items-start gap-20 hide-scrollbars mb-4 pb-10' style={{ overflowX: "scroll", maxWidth: courseContent > 0 && courseContent - 20 }}>
              {studentCoursesData.length > 0 ?
                studentCoursesData.map((course, index) => {
                  return (
                    <div className="cursor-pointer rounded-md transition-shadow bg-indigo-100/40 shadow-md hover:shadow-md"
                      style={{
                        width: 200,
                        height: 200,
                      }}
                      onClick={() => {
                        navigate(`/courses/${course.id}`)
                      }}
                      key={index}
                    >
                      <img
                        src={course?.file_data?.ext_file_path ? `${config.endpoint}${course?.file_data?.ext_file_path}` : "/default-course.png"} alt=""
                        style={{ height: 100, width: "100%", objectFit: "cover" }}
                        className="rounded-t" />
                      <div style={{ padding: 10 }}>
                        {/* <pre>{JSON.stringify(course,null,2)}</pre> */}
                        <p className="card-title">{course.name}</p>
                        <p className="card-subtitle" style={{ color: "gray", maxHeight: 50, textOverflow: "ellipsis", overflow: "hidden" }}>{course.description}</p>
                      </div>
                    </div>
                  )
                })
                :
                <div className="column gap-4 w-full h-100">
                  <MdLock size={50} color='gray' />
                  <p style={{ color: "gray" }}>Stay tuned, your courses will be added by your tutor 😄!</p>
                </div>
              }
            </div>
          </div>

          {/* Tools */}
          <div className="card shadow-lg rounded-md">
            <p className='card-title'>Quick Tools</p>
            <div className='row justify-around gap-20 mt-30 wrap-row'>
              {quick_tools.map((item, index) => {
                return (
                  <div className="cursor-pointer column items-center center gap-4 w-12">
                    <div
                      className="row items-center center gap-2"
                      style={{
                        backgroundColor: "var(--primary)",
                        borderRadius: "50%", padding: 10
                      }}
                      onClick={() => {
                        navigate(item.link)
                      }}
                    >
                      {item.icon}
                    </div>
                    <p style={{ fontSize: 15 }} className="text-center">{item.name}</p>
                  </div>
                )
              })}
            </div>
          </div>
        </div>

        <div className='gap-4 column w-40'>
          <div className="card shadow-lg rounded-md">
            <p className="card-title">Upcoming events</p>
            {studentScheduleData?.length > 0 ? studentScheduleData.map((item, index) => {
              if (index > 7) return
              return (
                <div className='p-all row justify-between gap-20 items-center w-full mt-4 border-l-2 border-l-blue-500' style={{ backgroundColor: "white", paddingLeft: 10 }}>
                  <div className="row gap-4 items-center">
                    <div>
                      <p className="card-subtitle" style={{ minWidth: 0, textAlign: "center", width: 50 }}>{tidyDateRaw(item?.start_datetime).day}</p>
                      <p className="card-subtitle" style={{ minWidth: 0, textAlign: "center", width: 50 }}>{tidyDateRaw(item?.start_datetime).month}</p>
                    </div>
                    <div className="column gap-4">
                      <div className="flex flex-col gap-1" style={{ maxHeight: "50px", textOverflow: "ellipsis", overflow: "clip" }}>
                        <h5 className="card-title">{item.title || item.name}</h5>
                        <p className="text-xs text-slate-500">{tidyDate(item?.start_datetime)}</p>
                      </div>
                    </div>
                  </div>
                  {/* <button className="button"
                    onClick={() => {

                    }}
                  >View</button> */}
                </div>
              )
            })
              :
              <p className="card-subtitle" style={{ color: "gray" }}>No upcoming events have been scheduled</p>
            }
          </div>
          <div className="card shadow-lg rounded-md">
            <p className="card-title">Latest news</p>
            {studentAnnouncementData?.length > 0 ? studentAnnouncementData.map((item, index) => {
              if (index > 3) return
              return (
                <div className='p-all row justify-between gap-20 items-center w-full mt-4' style={{ borderLeft: "4px solid var(--primary)", backgroundColor: "white", borderTopLeftRadius: "5px", borderBottomLeftRadius: "5px", borderRadius: "5px", paddingLeft: 10 }}>
                  <div className="" style={{ maxHeight: "50px", textOverflow: "ellipsis", overflow: "clip" }}>
                    <p className="card-subtitle" style={{ color: "gray" }}>{tidyDate(item?.start_datetime)}</p>
                    <h5 className="card-title">{item.title}</h5>
                  </div>
                  <button className="button"
                    onClick={() => {
                      setSelectedAnnouncement(item)
                      setShowAnnouncement(true)
                    }}
                  >View</button>
                </div>
              )
            })
              :
              <p className="card-subtitle" style={{ color: "gray" }}>No news have been posted yet</p>
            }
          </div>
        </div>
      </div>

    </UserLayout>
  )
}
