import React, { useEffect, useState } from 'react'
import Navbar from '../../components/Navbar'
import UserHeader from '../../components/UserHeader'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import AdminLayout from '../../layout/AdminLayout'
import notify from '../../utils/IANotification'
import EnrolmentList from '../../subpages/EnrolmentList'
import TeachersLayout from '../../layout/TeachersLayout'

export default function StaffEnrolmentPage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = useFetchUserData()

  return (
    <TeachersLayout navSelected={"Enrolments"}>
      <p className='mb-4' style={{ fontSize: 30, fontWeight: "500" }}>Enrolments</p>
      <EnrolmentList type={"staff"} />
    </TeachersLayout>
  )
}
