import React, { useState, useEffect } from 'react'
import Navbar from '../../components/Navbar'
import UserHeader from '../../components/UserHeader'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import AdminLayout from '../../layout/AdminLayout'
import { useParams } from 'react-router'
import notify from '../../utils/IANotification'
import { MdAdd } from 'react-icons/md'
import Switch from "react-switch"
import TextFeedbackComponent from '../../components/feedback/TextFeedbackComponent'
import RadioFeedbackComponent from '../../components/feedback/RadioFeedbackComponent'
import CheckboxFeedbackComponent from '../../components/feedback/CheckboxFeedbackComponent'
import SliderFeedbackComponent from '../../components/feedback/SliderFeedbackComponent'
import IAPrompt from '../../utils/IAPrompt'
import { isMobile } from '../../utils/helper'

export default function AdminStudentFeedbackFormPage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("staff")
  let user_data = useFetchUserData()

  const [preview, setPreview] = useState(false)
  const [width, setWidth] = useState(window.innerWidth)

  const form_id = new URLSearchParams(window.location.search).get("form_id")
  const [feedbackFormData, setFeedbackFormData] = useState({
    form_id: "new",
    title: "",
    description: "",
  })

  const [questionComponents, setQuestionComponents] = useState([]) // {question_id, question, rubrics, type, options}
  // Question Types - text, radio, checkbox, slider

  const fetchFormDataById = async () => {
    if (form_id === "new") return
    if (!form_id) return
    let endpoint = config.endpoint + "/feedback/form/id/" + form_id
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      }
    })
    let response = await fetchResponse.json()
    if (response.status === 200) {
      setFeedbackFormData(response.data?.form)
      setQuestionComponents(response.data?.questions || [])
    } else {
      notify({
        message: response.message,
        type: "error"
      })
    }
  }

  useEffect(() => {
    fetchFormDataById()
  }, [])


  const createForm = async () => {
    let endpoint = config.endpoint + "/feedback/form"
    let fetchResponse = await fetch(endpoint, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(feedbackFormData)
    })
    let response = await fetchResponse.json()
    if (response.status === 200) {
      notify({
        message: response.message,
        type: "success"
      })
      navigate("/admin/feedback/form?form_id=" + response.data.form_id)
    } else {
      notify({
        message: response.message,
        type: "error"
      })
    }
  }

  const updateForm = async () => {
    let endpoint = config.endpoint + "/feedback/form/id/" + form_id
    let fetchResponse = await fetch(endpoint, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        ...feedbackFormData,
        questions: questionComponents
      })
    })
    let response = await fetchResponse.json()
    if (response.status === 200) {
      notify({
        message: response.message,
        type: "success"
      })
      fetchFormDataById()
      // navigate("/admin/feedback/form?form_id=" + response.data.form_id)
    } else {
      notify({
        message: response.message,
        type: "error"
      })
    }
  }

  const deleteForm = async () => {
    let endpoint = config.endpoint + "/feedback/form/id/" + form_id
    let fetchResponse = await fetch(endpoint, {
      method: "DELETE",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      }
    })
    let response = await fetchResponse.json()
    if (response.status === 200) {
      notify({
        message: response.message,
        type: "success"
      })
      navigate("/admin/feedback")
    } else {
      notify({
        message: response.message,
        type: "error"
      })
    }
  }

  return (
    <AdminLayout navSelected={"Feedback & Review"}>
      <p className='mb-4' style={{ fontSize: 30, fontWeight: "500" }}>{form_id === "new" ? "New" : "Edit"} Feedback Form</p>
      <div className="card rounded-md shadow-md p-4 mt-4">
        <p className="card-title">Form Details</p>
        <div className="input-container">
          <p className="input-label">Title</p>
          <input type="text" className="text-input" value={feedbackFormData.title} onChange={(e) => setFeedbackFormData({ ...feedbackFormData, title: e.target.value })} />
        </div>
        <div className="input-container">
          <p className="input-label">Description</p>
          <textarea className="textarea-input" value={feedbackFormData.description} onChange={(e) => setFeedbackFormData({ ...feedbackFormData, description: e.target.value })} />
        </div>
        <div className="column right alignEnd">
          <div className="row gap-4 items-center">
            {form_id !== "new" && form_id &&
              <button className="button-delete"
                onClick={() => {
                  IAPrompt({
                    title: "Delete Form",
                    message: "Are you sure you want to delete this form?",
                    buttons: [
                      {
                        label: "Cancel",
                        onClick: () => { }
                      },
                      {
                        label: "Delete",
                        onClick: () => {
                          deleteForm()
                        }
                      }
                    ]
                  })
                }}
              >
                Delete
              </button>
            }
            <button className="button"
              onClick={() => {
                if (form_id === "new") {
                  createForm()
                } else {
                  updateForm()
                }
              }}>
              {form_id === "new" ? "Create" : "Update"} Form
            </button>
          </div>
        </div>
      </div>
      {form_id !== "new" && <div className="mt-4 card rounded-md shadow-md p-4">
        <div className="row justify-between items-center w-full">
          <p className="card-title">Feedback Questions</p>
          <div className="row gap-4 items-center">
            <div className="row gap-4 items-center">
              <p className="input-label">Preview</p>
              <Switch onChange={(checked) => {
                setPreview(checked)
              }} checked={preview} />
            </div>
            <button className="button-cta items-center gap-4 row"
              onClick={() => {
                setQuestionComponents([...questionComponents, {
                  question_id: "new",
                  question: "",
                  rubrics: "",
                  type: "text", // text, radio, checkbox, slider
                  options: {},
                  allow_remarks: 1,
                  question_options: [{ text: "", }]
                }])
              }}
            >
              <MdAdd size={15} />
              Question
            </button>
          </div>
        </div>

        {questionComponents?.length > 0 && questionComponents.map((question, index) => {
          return (
            <div key={index} className="mt-4">
              <p className="card-title">Question {index + 1}.</p>
              <div
                className={preview ? `${isMobile(width) ? "column" : "row"} items-start gap-4` : "column"}
              >
                {!preview && <>
                  <div className={`${isMobile(width) ? "column" : "row items-start"}  gap-4`}>
                    <div className="input-container">
                      <p className="input-label">Question</p>
                      <input type="text" className="text-input" placeholder='E.g. Is {{student_name}} able to understand calculus?' value={question.question} onChange={(e) => {
                        let temp = [...questionComponents]
                        temp[index].question = e.target.value
                        setQuestionComponents(temp)
                      }} />
                    </div>
                    <div className="input-container">
                      <p className="input-label">Type</p>
                      <select className="select-input"
                        value={question.type}
                        onChange={(e) => {
                          let temp = [...questionComponents]
                          temp[index].type = e.target.value
                          setQuestionComponents(temp)
                        }}
                      >
                        <option value="text">Text</option>
                        <option value="radio">Radio</option>
                        <option value="checkbox">Checkbox</option>
                        <option value="slider">Slider</option>
                      </select>
                      {question.type === "text" && <p style={{ color: "gray", fontSize: 12 }}>For open ended questions (E.g. feedback on the student)</p>}
                      {question.type === "radio" && <p style={{ color: "gray", fontSize: 12 }}>For single selection (E.g. rating of the course)</p>}
                      {question.type === "checkbox" && <p style={{ color: "gray", fontSize: 12 }}>For multiple selection (E.g. selection of skills acquired)</p>}
                      {question.type === "slider" && <p style={{ color: "gray", fontSize: 12 }}>For rating (E.g. 1 to 5)</p>}
                    </div>
                    <div className="row gap-4 items-center">
                      <Switch onChange={(checked) => {
                        let temp = [...questionComponents]
                        temp[index].allow_remarks = checked ? 1 : 0
                        setQuestionComponents(temp)
                      }} checked={question.allow_remarks == 1} />
                      <p style={{ whiteSpace: "nowrap" }} className="input-label">Allow Remarks</p>
                    </div>
                  </div>
                  <div className="input-container mt-4">
                    <p className="input-label">Rubrics</p>
                    <textarea type="text" className="textarea-input" placeholder='E.g. Choose 1 - If he is able to demonstrate low skill level, Choose 5 if he is able to demonstrate high level knowledge' value={question.rubrics} onChange={(e) => {
                      let temp = [...questionComponents]
                      temp[index].rubrics = e.target.value
                      setQuestionComponents(temp)
                    }} />
                  </div>
                </>}

                {preview &&
                  <div className='mt-4' style={{ minWidth: "500px", maxWidth: 500 }}>
                    <p>{question.question}</p>
                    <p style={{ whiteSpace: "pre-wrap", color: "gray", fontSize: 14 }}>{question.rubrics}</p>
                  </div>
                }

                {question.type === "text" && <TextFeedbackComponent state={preview ? "answer" : "builder"} data={question.options} onAnswer={(value) => { }} index={index} />}
                {question.type === "radio" && <RadioFeedbackComponent state={preview ? "answer" : "builder"} data={question} index={index}
                  item_index={index}
                  onAnswer={(value) => {
                    let temp = [...questionComponents]
                    temp[index].userAnswer = value
                    setQuestionComponents(temp)
                  }}
                  userAnswer={question.userAnswer}
                  onChange={(value) => {
                    let temp = [...questionComponents]
                    temp[index] = value
                    setQuestionComponents(temp)
                  }}
                />}
                {question.type === "checkbox" && <CheckboxFeedbackComponent state={preview ? "answer" : "builder"} data={question} index={index}
                  item_index={index}
                  onAnswer={(value) => {
                    let temp = [...questionComponents]
                    temp[index].userAnswer = value
                    setQuestionComponents(temp)
                  }}
                  userAnswer={question.userAnswer}
                  onChange={(value) => {
                    let temp = [...questionComponents]
                    temp[index] = value
                    setQuestionComponents(temp)
                  }}
                />}
                {question.type === "slider" && <SliderFeedbackComponent state={preview ? "answer" : "builder"} data={question} index={index}
                  item_index={index}
                  onAnswer={(value) => {
                    let temp = [...questionComponents]
                    temp[index].userAnswer = value
                    setQuestionComponents(temp)
                  }}
                  userAnswer={question.userAnswer}
                  onChange={(value) => {
                    let temp = [...questionComponents]
                    temp[index] = value
                    setQuestionComponents(temp)
                  }}
                />}
              </div>
            </div>
          )
        })}
        {/* <pre>{JSON.stringify(feedbackFormData, null, 2)}</pre>
        <pre>{JSON.stringify(questionComponents, null, 2)}</pre> */}
      </div>}
    </AdminLayout>
  )
}
