import React, { useEffect, useLayoutEffect, useState } from 'react'
import config from '../app-config'
import { MdCalendarMonth, MdCalendarToday } from 'react-icons/md'
import { isMobile, tidyDate, tidyDateRaw } from '../utils/helper'


export default function CalendarModal({ type }) {
    const [scheduleData, setScheduleData] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [buttonPos, setButtonPos] = useState(document?.getElementById("calendar-button"))
    const [width, setWidth] = useState(window.innerWidth);


    const fetchScheduleData = async () => {
        let endpoint = config.endpoint + `/schedule/${type}/id/`
        let fetchResponse = await fetch(endpoint, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
        })
        let data = await fetchResponse.json()
        if (data.status === 200) {
            setScheduleData(data.data)
        }
    }

    useLayoutEffect(() => {
        setButtonPos(document?.getElementById("calendar-button"))
    }, [document?.getElementById("calendar-button")])

    useEffect(() => {
        fetchScheduleData()
    }, [])

    window.addEventListener("scroll", () => {
        setShowModal(false)
    })


    const Modal = () => {

        return (
            <div className="shadow-md rounded-md overflow-hidden" style={{
                position: "absolute",
                top: buttonPos?.getClientRects()[0]?.bottom + 30,
                right: isMobile(width) ? 0 : 10,
                zIndex: 100,
                width: isMobile(width) ? "100dvw" : 400,
                backgroundColor: "white",
                // boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.2)",
            }}>
                {scheduleData?.length > 0 ? scheduleData.map((item, index) => {
                    if (index > 7) return
                    return (
                        <div className='p-all row justify-between items-center w-full card' style={{ backgroundColor: "white", paddingLeft: 10 }}>
                            <div className="row gap-4 items-center">
                                <div>
                                    <p className="card-subtitle" style={{ minWidth: 0, textAlign: "center", width: 50 }}>{tidyDateRaw(item?.start_datetime).day}</p>
                                    <p className="card-subtitle" style={{ minWidth: 0, textAlign: "center", width: 50 }}>{tidyDateRaw(item?.start_datetime).month}</p>
                                </div>
                                <div className="column gap-4">
                                    <div className="" style={{ maxHeight: "50px", textOverflow: "ellipsis", overflow: "clip" }}>
                                        <p className="card-subtitle" style={{ color: "gray" }}>{tidyDate(item?.start_datetime)}</p>
                                        <h5 className="card-title">{item.title || item.name}</h5>
                                    </div>
                                </div>
                            </div>
                            {/* <button className="button"
                                onClick={() => {

                                }}
                            >View</button> */}
                        </div>
                    )
                }) :
                    <div className='p-all row justify-between gap-20 items-center w-full mt-4' style={{ backgroundColor: "white", paddingLeft: 10 }}>
                        <div className="" style={{ maxHeight: "50px", textOverflow: "ellipsis", overflow: "clip" }}>
                            <p className="card-subtitle" style={{ color: "gray" }}>No upcoming schedule</p>
                        </div>
                    </div>
                }
            </div>
        )
    }

    return (
        <div>
            {showModal && <Modal />}
            <div className="row gap-4 items-center cursor-pointer" id='calendar-button'
                onClick={() => {
                    setShowModal(!showModal)
                }}
            >
                <MdCalendarToday size={16} color='gray' />
            </div>
        </div>
    )
}
