import React, { useState, useEffect } from 'react'
import Navbar from '../../components/Navbar'
import UserHeader from '../../components/UserHeader'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import AdminLayout from '../../layout/AdminLayout'
import { useParams } from 'react-router'
import AdminCourseNavbar from '../../components/AdminCourseNavbar'
import notify from '../../utils/IANotification'

export default function AdminCourseMaterialsPage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("staff")
  let user_data = useFetchUserData()

  const course_id = new URLSearchParams(window.location.search)?.get("course_id")
  const lesson_id = new URLSearchParams(window.location.search)?.get("lesson_id")
  const class_id = new URLSearchParams(window.location.search)?.get("class_id")

  const [materialsData, setMaterialsData] = useState([])

  const getMaterials = async () => {
    if (!course_id) return notify({ message: "Unable to fetch materials", type: "error" })
    let endpoint = config.endpoint + "/materials/course/id/" + course_id
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      setMaterialsData(data.data)
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  useEffect(() => {
    getMaterials()
  }, [])

  return (
    <AdminLayout navSelected={"Courses"}>
      <p className='mb-4' style={{ fontSize: 30, fontWeight: "500" }}>Materials</p>
      {course_id != "new" && <AdminCourseNavbar selected={"Materials"} course_id={course_id} />}
      <button className="button"
        onClick={() => {
          navigate(`/admin/courses/materials/details?material_id=new&course_id=${course_id}`)
        }}
      >New Material</button>

      {materialsData.length > 0 ?
        materialsData.map((data, index) => {
          return (
            <div key={index} className="card mb-4 row items-center justify-between">
              <div className="">
                <h5 className="card-title">{data.name}</h5>
                <p>{data.description}</p>
              </div>
              <button className="button"
                onClick={() => {
                  navigate(`/admin/courses/materials/details?course_id=${course_id}&material_id=${data.id}`)
                }}
              >Edit</button>
            </div>
          )
        })
        :
        <div className="card rounded-md shadow-md p-4 mt-4">
          <h5 className="card-title">No materials found</h5>
          <p className="card-text">There are no materials uploaded for this course.</p>
        </div>}
    </AdminLayout>
  )
}
