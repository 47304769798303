import React, { useState } from 'react'
import Navbar from '../components/Navbar'
import UserHeader from '../components/UserHeader'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../utils/AppFunctions'
import config from '../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import AdminNavbar from '../components/AdminNavbar'
import AdminHeader from '../components/AdminHeader'
import { isMobile } from '../utils/helper'

export default function AdminLayout({ children, navSelected, noParentPadding, defHeight, }) {
  const navigate = useNavigate()
  // const { isAuthorised, userRole } = useFetchAuthorisation("user")
  // let user_data = useFetchUserData()
  const [showNavbar, setShowNavbar] = useState(isMobile(window.innerWidth) ? false : true)


  return (
    <div style={{ width: "100vw", }}>
      <ToastContainer />
      <div className='row' style={{ width: "100%" }}>
        {showNavbar && <AdminNavbar selected={navSelected} setShowNavbar={setShowNavbar} showNavbar={showNavbar} />}
        <div style={{ width: "100%", maxHeight: "100dvh", overflowY: "scroll" }}>
          <UserHeader type={"student"} setShowNavbar={setShowNavbar} showNavbar={showNavbar} />
          <div className="reg-container" style={{ padding: noParentPadding && 0, height: defHeight && defHeight }}>
            {children}
          </div>
        </div>
      </div>
    </div >
  )
}
