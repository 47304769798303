import React from 'react'
import { IoAdd, IoClose } from 'react-icons/io5'

export default function CheckboxFeedbackComponent({ state, data, onChange, item_index, readonly, userAnswer, onAnswer }) {
    return (
        <>
            {state === "builder" && <>

                <p>Allow Multiple Selection</p>
                <div className='column gap-4'>
                    {data?.question_options?.length > 0 && data?.question_options.map((option, index) => {
                        return (
                            <div key={index} className="w-full row gap-4 items-center">
                                <p style={{ width: 50 }}>{index + 1}.</p>
                                <input type="text" className="text-input" required value={option.text} onChange={(e) => {
                                    let temp = [...data.question_options]
                                    temp[index].text = e.target.value
                                    onChange({ ...data, question_options: temp })
                                }} />
                                <button className="backgroundLess"
                                    onClick={() => {
                                        let temp = [...data.question_options]
                                        temp.splice(index, 1)
                                        onChange({ ...data, question_options: temp })
                                    }}
                                >
                                    <IoClose size={20} />
                                </button>
                            </div>
                        )
                    })}
                    <button className="backgroundLess row gap-4 items-center"
                        onClick={() => {
                            onChange({ ...data, question_options: [...data.question_options, { text: "", }] })
                        }}
                    >
                        <IoAdd size={20} />
                        Add Option
                    </button>
                </div>
            </>}
            {state === "answer" && <>
                <div className="column gap-4">
                    {data?.question_options?.length > 0 && data?.question_options.map((option, index) => {
                        return (
                            <div key={index} className="w-full row gap-4 items-center "
                                style={{
                                    borderRadius: 5,
                                    padding: 10
                                }}>
                                <input type="checkbox" disabled={readonly} className="text-input" required checked={userAnswer?.split("/c/")?.includes(option?.text)} onChange={(e) => {
                                    let temp = [...userAnswer.split("/c/")] || []
                                    if (temp.includes(option.text)) {
                                        temp = temp.filter((i) => i !== option.text)
                                    } else {
                                        temp.push(option.text)
                                    }
                                    temp = temp.filter((i) => i !== "")
                                    onAnswer(temp.join("/c/"))
                                }} />
                                
                                <p>{option.text}</p>
                            </div>
                        )
                    })}
                </div>
            </>}
        </>
    )
}
