import React, { useState, useEffect } from 'react'
import Navbar from '../../components/Navbar'
import UserHeader from '../../components/UserHeader'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import TeachersLayout from '../../layout/TeachersLayout'
import { useParams } from 'react-router'
import AdminCourseNavbar from '../../components/AdminCourseNavbar'
import notify from '../../utils/IANotification'
import { capitaliseFirstLetter } from '../../utils/helper'
import { MdEdit, MdEditDocument } from 'react-icons/md'
import AssessmentTypePage from '../../subpages/AssessmentTypePage'
import StaffCourseNavbar from '../../components/StaffCourseNavbar'

export default function StaffAssessmentTypePage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = useFetchUserData()

  const course_id = new URLSearchParams(window.location.search)?.get("course_id")
  const lesson_id = new URLSearchParams(window.location.search)?.get("lesson_id")
  const type = new URLSearchParams(window.location.search)?.get("type")

  const [assessmentData, setAssessmentData] = useState([])

  const getScheduledAssessmentByType = async () => {
    if (!course_id) return notify({ message: "Unable to fetch lessons", type: "error" })
    let endpoint = config.endpoint + `/assessment/schedule/type/${type ? `${type}` : ""}/course/id/${course_id}`
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      setAssessmentData(data.data)
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  useEffect(() => {
    getScheduledAssessmentByType()
  }, [])

  return (
    <TeachersLayout navSelected={"Classes"}>
      <p className='mb-4' style={{ fontSize: 30, fontWeight: "500" }}>{`${capitaliseFirstLetter(type)}`} </p>
      {course_id != "new" && <StaffCourseNavbar selected={`${capitaliseFirstLetter(type)}`} course_id={course_id} />}
      <div className="row gap-4 items-center">
        <button className="button"
          onClick={() => {
            navigate(`/staff/courses/assessments/schedule?course_id=${course_id}&schedule_id=new`)
          }}
        >Schedule Assessment</button>
      </div>
     <AssessmentTypePage user_type="staff" />
    </TeachersLayout>
  )
}
