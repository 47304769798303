import React, { useState, useEffect, useLayoutEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { authorisationConverter, logout, useFetchAuthorisation } from '../utils/AppFunctions'
import { FaAngleRight } from 'react-icons/fa'
import { AiFillDashboard, AiOutlineDashboard } from 'react-icons/ai'
import { MdAccountBalance, MdBusiness, MdCalendarToday, MdClass, MdEditDocument, MdExitToApp, MdFamilyRestroom, MdFlag, MdForum, MdGroup, MdLeaderboard, MdOutlineAccountBalance, MdOutlineAirplanemodeActive, MdOutlineClass, MdOutlineEventNote, MdOutlineFamilyRestroom, MdOutlineFlag, MdOutlineGroup, MdOutlineNewspaper, MdOutlineSchool, MdOutlineSettings, MdPeople, MdPerson2, MdSchool, MdSettings } from 'react-icons/md'
import { FiAward, FiHome } from 'react-icons/fi'
import "../hover.css"
import ProfileSwitcher from './ProfileSwitcher'
import { isMobile } from '../utils/helper'
import config from '../app-config'

export default function AdminNavbar({ selected, setShowNavbar, showNavbar }) {
    let { isAuthorised, userRole } = useFetchAuthorisation("user")

    const size = 25
    const color = "var"
    const navigate = useNavigate()
    const [headerHeight, setHeaderHeight] = useState(document?.getElementById("admin-header")?.clientHeight + 10)



    useLayoutEffect(() => {
        setHeaderHeight(document?.getElementById("admin-header")?.clientHeight)
    }, [document?.getElementById("admin-header")?.clientHeight])



    let items = [
        {
            name: "Home",
            children: [
                {
                    name: "Dashboard",
                    icon: <AiOutlineDashboard size={size} className="text-slate-500" />,
                    link: "/admin",
                    authorisation: "staff",
                },
                {
                    name: "Classes",
                    icon: <MdOutlineClass size={size} className="text-slate-500"  />,
                    link: "/admin/classes",
                    authorisation: "staff",
                },
            ],
        },
        {
            name: "Course",
            children: [
                {
                    name: "Courses",
                    icon: <FiAward size={size} className="text-slate-500" />,
                    link: "/admin/courses",
                    authorisation: "staff",
                },
                {
                    name: "Certificates",
                    icon: <MdBusiness size={size} className="text-slate-500" />,
                    link: "/admin/certificates",
                    authorisation: "staff",
                },
                {
                    name: "Feedback & Review",
                    icon: <MdOutlineFlag size={size} className="text-slate-500" />,
                    authorisation: "staff",
                    link: "/admin/feedback",
                },
            ]
        },
        {
            name: "Users",
            children: [
                {
                    name: "Teachers",
                    icon: <MdOutlineGroup size={size} className="text-slate-500"/>,
                    authorisation: "staff",
                    link: "/admin/teachers",
                },
                {
                    name: "Enrolments",
                    icon: <MdOutlineSchool size={size} className="text-slate-500" />,
                    authorisation: "staff",
                    link: "/admin/enrolment",
                },
                {
                    name: "Families",
                    icon: <MdOutlineFamilyRestroom size={size} className="text-slate-500" />,
                    authorisation: "staff",
                    link: "/admin/family",
                },
            ]
        },
        {
            name: "Administration",
            children: [
                {
                    name: "Settings",
                    icon: <MdOutlineSettings size={size} className="text-slate-500" />,
                    authorisation: "staff",
                    link: "/admin/settings?state=general",
                },
                {
                    name: "Announcements",
                    icon: <MdOutlineNewspaper size={size} className="text-slate-500" />,
                    authorisation: "staff",
                    link: "/admin/announcements",
                }
            ]
        }
        // {
        //     name: "Courses",
        //     icon: <FiAward size={size} className="text-slate-500" />,
        //     link: "/admin/courses",
        //     authorisation: "staff",
        // },

        // {
        //     name: "Classes",
        //     icon: <MdOutlineClass size={size} className="text-slate-500" />,
        //     authorisation: "staff",
        //     link: "/admin/classes",
        // },
        // {
        //     name: "Certificates",
        //     icon: <MdBusiness size={size} className="text-slate-500" />,
        //     authorisation: "staff",
        //     link: "/admin/certificates",
        // },

        // {
        //     name: "Settings",
        //     icon: <MdOutlineSettings size={size} className="text-slate-500" />,
        //     authorisation: "staff",
        //     link: "/admin/settings",
        // },
    ]

    const width = window.innerWidth

    return (
        <>
        <div
            style={{width: `calc(100% - 300px)`, height: "100dvh", position: "fixed", top: 0, right: 0, zIndex: 1000, display: isMobile(width) ? "block" : "none"}}
            onClick={() => {
                setShowNavbar(false)
            }}
        />
        <div className='navbar shadow' id='navbar' style={{
            height: "100dvh",
            maxHeight: "100dvh",
            backgroundColor: "white",
            position: isMobile(width) && "fixed",
            zIndex: 2000,
        }}>
            <div className='column justify-between items-start' style={{ height: "100dvh", width: "100%" }}>
                <div style={{ width: "100%", height:"80%" }}>
                    <div className="row gap-4 pr-20" style={{ padding: 20 }}>
                        <img src={config.images.logo_wo_text} style={{ height: 50, width: 50, objectFit: "contain", }} alt="" />
                        <div>
                            <p style={{ fontWeight: "900", fontSize: 20 ,color:"var(--primary)", }}>{config.client_title}</p>
                            <p style={{ fontWeight: "600", fontSize: 12, color: "gray", letterSpacing: 2 }}>{config.client_subtitle}</p>
                        </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", padding: 10, width: "100%", overflowY: "scroll", maxHeight: isMobile(width) && "70%" }} className=' hide-scrollbars'
                    >
                        {items.map((main_item, index) => {
                            return (
                                <div style={{ marginBottom: 10 }}>
                                    <p className="text-xs font-semibold text-slate-500 mb-1">{main_item?.name?.toUpperCase()}</p>
                                    {main_item?.children?.map((item, index) => {
                                        if (!authorisationConverter({ requirement: item?.authorisation, current_role: userRole })) return null
                                        return (
                                            <div
                                            className={`navbarItem rounded-md w-full 
                                            ${ selected === item.name ? "bg-indigo-100" : ""}
                                            `}  key={index} id={`navitem-${index}`}>
                                                <div className='navbarItemIcon'
                                                    onClick={() => {
                                                        navigate(item.link)
                                                    }}
                                                >
                                                    {item.icon}
                                                    <p 
                                                    className={`text-sm py-1 ${ selected === item.name ? "text-indigo-500" : "text-slate-500"}`}
                                                    >{item.name}</p>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div style={{ width: "100%", justifyContent: "left", display: "flex", flexDirection: "column", alignItems: "flex-start", borderTop: "1px solid #E9E3F3" }} id='profile-button'>
                    {authorisationConverter({ requirement: "admin", current_role: userRole }) &&
                        <ProfileSwitcher />
                    }
                    <div className="px-2">
                        <div className='backgroundLess items-center gap-2 row p-2'
                            style={{ width: "100%", textAlign: "left", justifyContent: "left", display: "flex" }}
                            onClick={() => {
                                logout()
                                navigate("/auth/login")
                            }}
                        >
                            <MdExitToApp size={20} className="text-rose-500"/>
                            <p className="text-sm text-rose-500">Logout</p>
                        </div>
                        <p className="text-slate-500 text-xs text-center p-2">Built by <a href='www.ourcodelab.com' className='coy-website' style={{ textDecoration: "none" }}> OurCodeLab LLP</a></p>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}
