import React, { useState, useEffect, useRef } from 'react'
import Navbar from '../components/Navbar'
import UserHeader from '../components/UserHeader'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../utils/AppFunctions'
import config from '../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import AdminLayout from '../layout/AdminLayout'
import { useParams } from 'react-router'
import AdminCourseNavbar from '../components/AdminCourseNavbar'
import notify from '../utils/IANotification'
import { IoAdd, IoCloudDownload, IoCloudUpload, IoPersonAdd, IoSchool, IoTrash } from 'react-icons/io5'
import { MdImportExport, MdTableView } from 'react-icons/md'
import { FaFileExport, FaFileImport } from 'react-icons/fa'
import ModalLayout from '../layout/ModalLayout'
import IAPrompt from '../utils/IAPrompt'
import Select from 'react-select'
import { isMobile } from '../utils/helper'

export default function ClassFormPage({ type }) {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = useFetchUserData()

  const course_id = new URLSearchParams(window.location.search)?.get("course_id")
  const lesson_id = new URLSearchParams(window.location.search)?.get("lesson_id")
  const class_id = new URLSearchParams(window.location.search)?.get("class_id")

  const [showPreview, setShowPreview] = useState(false)
  const [isLoaded, setIsLoaded] = useState(true)
  const [showModal, setShowModal] = useState(false)
  const [selectedType, setSelectedType] = useState("") // existing, new

  const [query, setQuery] = useState("")
  const [width, setWidth] = useState(window.innerWidth)
  const { currentModal } = useRef(null)

  let modal = {

  }

  const studentModal = {
    email: "",
    first_name: "",
    last_name: "",
  }


  const [studentFormData, setStudentFormData] = useState()
  const [studentData, setStudentData] = useState()
  const [allStudentsData, setAllStudentsData] = useState([])
  const [selectedExistingStudent, setSelectedExistingStudent] = useState()
  const [teacherData, setTeacherData] = useState([])


  const [classData, setClassData] = useState({
    name: "",
    description: "",
    course_id: "",
    start_datetime: "",
    end_datetime: "",
    is_forever: "0",
    instructions: "",
    staff_id: "",
  })

  const createClass = async () => {
    let endpoint = config.endpoint + "/class/"
    let fetchResponse = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        ...classData,
        course_id
      })
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      let id = data.data.id
      navigate("/admin/courses/classes/details?course_id=" + course_id + "&class_id=" + id)
      window.location.reload()
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  const fetchAllTeachers = async () => {
    let endpoint = config.endpoint + "/teacher/"
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      }
    })
    let response = await fetchResponse.json()
    if (response.status === 200) {
      setTeacherData(response.data)
    } else {
      notify({
        message: response.message,
        type: "error"
      })
    }
  }

  const editClass = async () => {
    if (class_id === "new") return
    let endpoint = config.endpoint + "/class/id/" + class_id
    let fetchResponse = await fetch(endpoint, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        ...classData,
        course_id
      })
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      let id = data.data.id
      notify({ message: data.message, type: "success" })
    } else {
      notify({ message: data.message, type: "error" })
    }
  }


  const fetchClassById = async () => {
    if (class_id === "new") return
    let endpoint = config.endpoint + "/class/id/" + class_id
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      setClassData(data.data)
    } else {
      notify({ message: data.message, type: "error" })
    }
  }


  const createStudent = async () => {
    let endpoint = config.endpoint + "/enrolment/"
    let fetchResponse = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        ...studentFormData,
        class_id
      })
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      let id = data.data.id
      setShowModal(false)
      setStudentFormData(studentModal)
      getStudentsByClassId()
      notify({ message: data.message, type: "success" })
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  const addExistingStudent = async () => {
    let endpoint = config.endpoint + "/enrolment/existing"
    let fetchResponse = await fetch(endpoint, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        user_id: selectedExistingStudent?.value?.id,
        class_id,
      })
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      let id = data.data.id
      setShowModal(false)
      setStudentFormData(studentModal)
      setSelectedExistingStudent()
      getStudentsByClassId()
      setSelectedType("")
      notify({ message: data.message, type: "success" })
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  const getStudentsByClassId = async () => {
    if (class_id === "new") return
    if (!class_id) return
    let endpoint = config.endpoint + "/class/student/class/id/" + class_id
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
      },
      credentials: "include",
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      setStudentData(data.data)
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  const removeStudentFromClass = async (student_id) => {
    let endpoint = config.endpoint + "/enrolment/id/" + student_id
    let fetchResponse = await fetch(endpoint, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      getStudentsByClassId()
      notify({ message: data.message, type: "success" })
    } else {
      notify({ message: data.message, type: "error" })
    }
  }


  const get_all_students_from_school = async () => {
    let endpoint = config.endpoint + "/students/"
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      setAllStudentsData(data.data)
    } else {
      notify({ message: data.message, type: "error" })
    }
  }



  useEffect(() => {
    fetchClassById()
    getStudentsByClassId()
    get_all_students_from_school()
    fetchAllTeachers()
  }, [])





  // const AddStudentModal = () => {
  //   return (
  //     <></>
  //   )
  // }

  return (
    <>
      {class_id !== "new" && <div className="row gap-4">
        <button className="button"
          onClick={() => {
            navigate(`/${type}/courses/classes/schedule?class_id=${classData?.id}&course_id=${course_id}`)
          }}
        >Class Schedule</button>
        <button className="button"
          onClick={() => {
            navigate(`/${type}/courses/classes/attendance?class_id=${classData?.id}&course_id=${course_id}`)
          }}
        >
          Class Attendance</button>
      </div>}
      {showModal &&
        <ModalLayout modalTitle={"Add Student"} isVisible={showModal} setIsVisible={setShowModal} key={"modal"} ref={currentModal}>
          {selectedType === "" && <>
            <div className="row gap-4" style={{ width: "100%" }}>
              <button
                className="button items-center row gap-4" style={{ width: "100%" }}
                onClick={() => {
                  setSelectedType("new")
                }}
              >
                <IoSchool size={20} />
                New Student
              </button>
              <button
                className="button items-center row gap-4" style={{ width: "100%" }}
                onClick={() => {
                  setSelectedType("existing")
                }}
              >
                <MdTableView size={20} />
                Existing Student
              </button>
            </div>
          </>}
          {selectedType === "existing" && <>
            <div className="input-container">
              <label htmlFor="" className="input-label">Student</label>
              <Select
                options={allStudentsData?.map((data, index) => {
                  return {
                    value: data,
                    label: `${data?.first_name} ${data?.last_name} - ${data?.email}`
                  }
                })}
                onChange={(e) => {
                  setSelectedExistingStudent(e)
                }}
                value={selectedExistingStudent}
              />
            </div>
            <div className="button-container">
              <button className="button" onClick={() => {
                addExistingStudent()
              }}>Add Student</button>
            </div>
          </>}
          {selectedType === "new" && <>
            <div className="row gap-4 items-center">
              <div className="input-container">
                <label htmlFor="" className="input-label">First Name</label>
                <input type="text" className="text-input" placeholder="E.g. John" value={studentFormData?.first_name}
                  onChange={(e) => {
                    setStudentFormData({ ...studentFormData, first_name: e.target.value })
                  }} />
              </div>
              <div className="input-container">
                <label htmlFor="" className="input-label">Last Name</label>
                <input type="text" className="text-input" placeholder="E.g. Smith" value={studentFormData?.last_name}
                  onChange={(e) => {
                    setStudentFormData({ ...studentFormData, last_name: e.target.value })
                  }} />
              </div>
            </div>
            <div className="input-container">
              <label htmlFor="" className="input-label">Student Email</label>
              <input type="text" className="text-input" placeholder="E.g. johnsmith@gmail.com" value={studentFormData?.email}
                onChange={(e) => {
                  setStudentFormData({ ...studentFormData, email: e.target.value })
                }} />
            </div>
            <div className="button-container">
              <button className="button" onClick={() => {
                createStudent()
              }}>Add Student</button>
            </div>
          </>}
        </ModalLayout>
      }



      <div className="card rounded-md shadow-md p-4 mt-4">
        <div className={`${isMobile(width) ? "column " : "row"} gap-4 items-start`}>
          <div className="input-container">
            <label htmlFor="" className="input-label">Class Name</label>
            <input type="text" className="text-input" value={classData.name}
              disabled={!authorisationConverter({ requirement: "admin", current_role: user_data?.account_type })}
              placeholder='E.g Class 1'
              onChange={(e) => {
                setClassData({ ...classData, name: e.target.value })
              }} />
          </div>
          <div className="input-container">
            <label htmlFor="" className="input-label">Teacher</label>
            <Select
              isDisabled={!authorisationConverter({ requirement: "admin", current_role: user_data?.account_type })}
              options={teacherData.map((teacher) => {
                return {
                  value: teacher,
                  label: teacher.first_name + " " + teacher.last_name
                }
              })}
              value={teacherData?.map((teacher) => {
                return {
                  value: teacher?.id,
                  label: teacher.first_name + " " + teacher.last_name
                }
              })?.find((teacher) => teacher.value == classData?.staff_id)}
              onChange={(selected) => {
                setClassData({ ...classData, staff_id: selected.value?.id })
              }}
              styles={{
                control: (styles) => ({
                  ...styles,
                  // width: 200
                })
              }}
            />
          </div>
        </div>
        <div className={`${isMobile(width) ? "column " : "row items-center"} gap-4 `}>
          <div className="input-container">
            <label htmlFor="" className="input-label">Start Date</label>
            <input type="datetime-local" className="text-input" value={classData.start_datetime}
              disabled={!authorisationConverter({ requirement: "admin", current_role: user_data?.account_type })}
              onChange={(e) => {
                setClassData({ ...classData, start_datetime: e.target.value })
              }} />
          </div>
          <div className="input-container">
            <label htmlFor="" className="input-label">End Date</label>
            <input type="datetime-local" className="text-input" value={classData.end_datetime}
              disabled={!authorisationConverter({ requirement: "admin", current_role: user_data?.account_type })}
              onChange={(e) => {
                setClassData({ ...classData, end_datetime: e.target.value })
              }} />
          </div>
          <div className="row gap-4 items-center">
            <input type="checkbox" className="checkbox-input" checked={classData.is_forever === "1"}
              onChange={(e) => {
                setClassData({ ...classData, is_forever: e.target.checked ? "1" : "0" })
              }}
            />
            <label htmlFor="" className="input-label" style={{ whiteSpace: "nowrap" }}>No end date</label>
          </div>
        </div>
        <div className="input-container mt-4">
          <label htmlFor="" className="input-label">Class Description</label>
          <textarea type="text" className="textarea-input" value={classData.description} onChange={(e) => {
            setClassData({ ...classData, description: e.target.value })
          }} />
        </div>

        {class_id == "new" ?
          <div className="button-container">
            <button className="button" onClick={() => {
              createClass()
            }}>Create Class</button>
          </div>
          :
          <div className="button-container">
            <button className="button" onClick={() => {
              editClass()
            }}>Save Changes</button>
          </div>
        }
      </div>

      {class_id !== "new" && <>
        <div className="card rounded-md shadow-md p-4 mt-4">
          <p className="card-title">Students</p>
          <div className="row gap-20 items-center mt-4 hide-scrollbars" style={{ overflowX: "scroll" }}>
            <button className="backgroundLess row gap-4 items-center" style={{ whiteSpace: "nowrap" }}
              onClick={() => {
                setShowModal(true)
              }}
            >
              <IoPersonAdd size={20} />
              New Student
            </button>
            <button className="backgroundLess row gap-4 items-center" style={{ whiteSpace: "nowrap" }}>
              <IoCloudUpload size={20} />
              Upload CSV
            </button>
            <button className="backgroundLess row gap-4 items-center" style={{ whiteSpace: "nowrap" }}>
              <IoCloudDownload size={20} />
              Export List
            </button>
          </div>
          <div className="mt-4 input-container">
            <input type="text" className="text-input" placeholder="Search for students..." value={query} onChange={(e) => {
              setQuery(e.target.value)
            }} />
          </div>


          {/* Students */}
          {studentData?.length > 0 &&
            <>
              {studentData.map((data, index) => {
                if (query !== "" && !JSON.stringify(data).toLowerCase().includes(query.toLowerCase())) return null
                return (
                  <div className={`${isMobile(width) ? "column " : "row items-center"} justify-between gap-20 items-center w-full mt-4`} style={{ borderLeft: "4px solid var(--primary)", borderTopLeftRadius: "5px", borderBottomLeftRadius: "5px", borderRadius: "5px", paddingLeft: 10 }}>
                    <div className="column p-all">
                      <p className="card-title">{data?.first_name} {data?.last_name}</p>
                      <p className="card-text">{data?.email}</p>
                    </div>
                    {authorisationConverter({ requirement: "admin", current_role: user_data?.account_type }) && <button className="backgroundLess row gap-4 items-center"
                      onClick={() => {
                        IAPrompt({
                          title: "Remove Student",
                          message: "Are you sure you want to remove this student?",
                          buttons: [
                            {
                              label: "Cancel",
                              onClick: () => {
                                console.log("cancelled")
                              }
                            },
                            {
                              label: "Remove",
                              onClick: () => {
                                removeStudentFromClass(data?.membership_id)
                              }
                            },
                          ]
                        })
                      }}
                    >
                      <IoTrash size={20} />
                      Remove
                    </button>}
                  </div>
                )
              })}
            </>
          }
        </div>
      </>}
    </>
  )
}
