import React from 'react'
import Navbar from '../../components/Navbar'
import UserHeader from '../../components/UserHeader'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import AdminLayout from '../../layout/AdminLayout'

export default function AdminHomePage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("staff")
  let user_data = useFetchUserData()

  return (
    <AdminLayout navSelected={"Dashboard"}>
      <p className='mb-4' style={{ fontSize: 30, fontWeight: "500" }}>Hello {user_data?.first_name} {user_data?.last_nbame}</p>

    </AdminLayout>
  )
}
