import React, { useState, useEffect } from 'react'
import Navbar from '../../components/Navbar'
import UserHeader from '../../components/UserHeader'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import AdminLayout from '../../layout/AdminLayout'
import { useParams } from 'react-router'
import AnnouncementForm from '../../subpages/AnnouncementForm'

export default function AdminAnnouncementFormPage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("staff")
  let user_data = useFetchUserData()

  const { course_id } = useParams()
  const announcement_id = new URLSearchParams(window.location.search).get("announcement_id")

  return (
    <AdminLayout navSelected={"Announcements"}>
      <p className='mb-4' style={{ fontSize: 30, fontWeight: "500" }}>{announcement_id ? "Edit" : "New"} Announcement</p>
      <AnnouncementForm />
    </AdminLayout>
  )
}
