import React, { useState, useEffect } from 'react'
import Navbar from '../../components/Navbar'
import UserHeader from '../../components/UserHeader'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import AdminLayout from '../../layout/AdminLayout'
import notify from '../../utils/IANotification'
import { MdAdd } from 'react-icons/md'

export default function AdminCoursePage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("staff")
  let user_data = useFetchUserData()
  const [searchQuery, setSearchQuery] = useState("")

  const [courseData, setCourseData] = useState([])

  const fetchAllCourses = async () => {
    let endpoint = config.endpoint + "/course/"
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      }
    })
    let response = await fetchResponse.json()
    if (response.status === 200) {
      setCourseData(response.data)
    } else {
      notify({
        message: response.message,
        type: "error"
      })
    }
  }

  useEffect(() => {
    fetchAllCourses()
  }, [isAuthorised, userRole])

  return (
    <AdminLayout navSelected={"Courses"}>
      <p className='mb-4' style={{ fontSize: 30, fontWeight: "500" }}>Courses</p>

      <div className="input-container">
        <input type="text" className="text-input" placeholder="Search courses..." value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
      </div>
      <div className="w-full">
        <button className="button row gap-4 items-center"
          onClick={() => {
            navigate("/admin/courses/details?course_id=new")
          }}
        >
          {/* <MdAdd size={15} /> */}
          New Course</button>
      </div>
      <div style={{ width: "100%", marginTop: 10 }}>
        {courseData.length > 0 ?
          courseData.map((data, index) => {
            if (searchQuery && !JSON.stringify(data).toLowerCase().includes(searchQuery.toLowerCase())) return null
            return (
              <div key={index} className="card mb-4 row items-center justify-between cursor-pointer" style={{ borderLeft: "4px solid var(--primary)", borderTopLeftRadius: "5px", borderBottomLeftRadius: "5px", borderRadius: "5px", paddingLeft: 10 }}
                onClick={() => {
                  navigate(`/admin/courses/details?course_id=${data.id}`)
                }}
              >
                <div>
                  <h5 className="card-title">{data.name}</h5>
                  <p>{data.description}</p>
                </div>
                {/* <button className="button"
                  onClick={() => {
                    navigate(`/admin/courses/details?course_id=${data.id}`)
                  }}
                >Edit</button> */}
              </div>
            )
          })
          :
          <div className="card rounded-md shadow-md p-4 mt-4">
            <h5 className="card-title">No Courses</h5>
            <p className="card-text">There are no courses in the system.</p>
          </div>}
      </div>
    </AdminLayout>
  )
}
