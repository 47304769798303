import React, { useState, useEffect } from 'react'
import Navbar from '../../components/Navbar'
import UserHeader from '../../components/UserHeader'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import TeachersLayout from '../../layout/TeachersLayout'
import { useParams } from 'react-router'
import AdminCourseNavbar from '../../components/AdminCourseNavbar'
import notify from '../../utils/IANotification'
import AssessmentList from '../../subpages/AssessmentList'
import StaffCourseNavbar from '../../components/StaffCourseNavbar'

export default function StaffAssessmentPage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = useFetchUserData()

  const course_id = new URLSearchParams(window.location.search)?.get("course_id")
  const lesson_id = new URLSearchParams(window.location.search)?.get("lesson_id")

  return (
    <TeachersLayout navSelected={"Classes"}>
      <p className='mb-4' style={{ fontSize: 30, fontWeight: "500" }}>Assessments</p>
      {course_id != "new" && <StaffCourseNavbar selected={"Assessments"} course_id={course_id} />}
      <div className="row gap-4 items-center">
        <button className="button"
          onClick={() => {
            navigate(`/staff/courses/assessments/details?course_id=${course_id}&assessment_id=new`)
          }}
        >New Assessment</button>

        <button className="button"
          onClick={() => {
            navigate(`/staff/courses/assessments/schedule?course_id=${course_id}&schedule_id=new`)
          }}
        >Schedule Assessment</button>
      </div>
      <AssessmentList type={"staff"} course_id={course_id} />
    </TeachersLayout>
  )
}
