import React from 'react'
import Navbar from '../components/Navbar'
import UserHeader from '../components/UserHeader'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../utils/AppFunctions'
import config from '../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import AssessmentHeader from '../components/AssessmentHeader'

export default function AssessmentLayout({ children,time_left }) {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = useFetchUserData()

  return (
    <div style={{ width: "100vw", }}>
      <ToastContainer />
      <div className='row' style={{ width: "100%" }}>
        <div style={{ width: "100%", maxHeight: "100vh", overflowY: "scroll" }}>
          <AssessmentHeader time_left={time_left}/>
          <div className="container column center items-center">
            <div className="inner-container" style={{ maxWidth: 700 }}>
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
