import React, { useEffect, useState } from 'react'
import Navbar from '../components/Navbar'
import UserHeader from '../components/UserHeader'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../utils/AppFunctions'
import config from '../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import AdminLayout from '../layout/AdminLayout'
import { useParams } from 'react-router'
import AdminCourseNavbar from '../components/AdminCourseNavbar'
import notify from '../utils/IANotification'
import Switch from "react-switch";
import { tidyDate } from '../utils/helper'
import { MdEdit } from 'react-icons/md'

export default function AttendanceList({ type }) {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = useFetchUserData()

  const course_id = new URLSearchParams(window.location.search)?.get("course_id")
  const class_id = new URLSearchParams(window.location.search)?.get("class_id")

  const [classSessionData, setClassSessionData] = useState([])


  const getClassSessionsByClassId = async () => {
    if (class_id === "new") return
    if (!class_id) return
    let endpoint = config.endpoint + "/attendance/class/id/" + class_id
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      setClassSessionData(data.data?.reverse())
    } else {
      notify({ message: data.message, type: "error" })
    }
  }


  useEffect(() => {
    getClassSessionsByClassId()
  }, [class_id])

  return (
    <>
      {classSessionData.length > 0 ?
        classSessionData.map((session, index) => {
          return (
            <div className="row gap-4 justify-between items-center mt-4" key={index} style={{ borderLeft: "4px solid var(--primary)", borderTopLeftRadius: "5px", borderBottomLeftRadius: "5px", borderRadius: "5px", paddingLeft: 10 }}>
              <div>
                <p style={{ fontWeight: "bold" }}>Class #{classSessionData.length - index}</p>
                <p style={{ color: "gray" }}>{tidyDate(session.start_datetime)} - {tidyDate(session.end_datetime)}</p>
              </div>
              <button className="button row gap-4 items-center"
                onClick={() => {
                  navigate(`/${type}/courses/classes/attendance/details?course_id=${course_id}&class_id=${class_id}&session_id=${session.id}`)
                }}
              >
                <MdEdit size={20} />
                Attendance</button>
            </div>
          )
        })
        :
        <p>No class sessions found</p>
      }
    </>
  )
}
