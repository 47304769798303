import React from 'react'
import { useState, useLayoutEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { MdBedroomParent, MdEditDocument, MdFamilyRestroom, MdLeaderboard, MdPeople } from 'react-icons/md'
import { authorisationConverter, useFetchUserData } from '../utils/AppFunctions'


export default function ProfileSwitcher() {

    const size = 20
    const color = "gray"
    const navigate = useNavigate()
    const [buttonPos, setButtonPos] = useState(document?.getElementById("profile-button"))
    const [showProfileSwitcher, setShowProfileSwitcher] = useState(false)
    const user_data = useFetchUserData()


    useLayoutEffect(() => {
        setButtonPos(document?.getElementById("profile-button"))
    }, [document?.getElementById("admin-header")?.clientHeight, document?.getElementById("profile-button")])

    window.addEventListener("scroll", () => {
        setShowProfileSwitcher(false)
    })

    const profiles = [
        {
            name: "Admin",
            icon: <MdLeaderboard size={size} color={"white"} />,
            color: "var(--primary)",
            authorisation: "admin",
            link: "/admin",
        },
        {
            name: "Teachers",
            icon: <MdEditDocument size={size} color={"white"} />,
            color: "var(--yellow)",
            authorisation: "staff",
            link: "/staff"
        },
        {
            name: "Parents",
            icon: <MdFamilyRestroom size={size} color={"white"} />,
            color: "var(--orange)",
            authorisation: "parents",
            link: "/parents"
        },
        {
            name: "Student",
            icon: <MdPeople size={size} color={"white"} />,
            color: "var(--secondary)",
            authorisation: "student",
            link: "/home"
        }
    ]


    const ProfileModal = () => {
        return (
            <div className="rounded-md shadow-md overflow-hidden" style={{
                position: "absolute",
                bottom: buttonPos?.getClientRects()[0]?.height + 30 + 5, // 30 is the margin from the button
                left: 15,
                zIndex: 100,
                width: 220,
                backgroundColor: "white",
                boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.2)",
                borderRadius: 10,
            }}>
                {profiles.map((profile, index) => {
                    if (!authorisationConverter({requirement: profile.authorisation, current_role: user_data?.account_type})) return
                    return (
                        <div className="profile-hover">
                            <div className="card row items-center gap-20"
                                key={index}
                                style={{
                                    padding: 10,
                                    cursor: "pointer",
                                    color: "white",
                                }}
                                onClick={() => {
                                    navigate(profile.link)
                                }}
                            >
                                <div
                                    style={{
                                        borderRadius: "50%",
                                        backgroundColor: profile.color,
                                        padding: 4,
                                    }}>
                                    {profile.icon}
                                </div>
                                <p className="text-black text-sm">{profile.name}</p>
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    }

    let currentState = "Student"
    if (window.location.pathname.includes("admin")) {
        currentState = "Admin"
    } else if (window.location.pathname.includes("staff")) {
        currentState = "Teachers"
    } else if (window.location.pathname.includes("parents")) {
        currentState = "Parents"
    } else {
        currentState = "Student"
    }


    return (
        <>
            {showProfileSwitcher && <ProfileModal />}
            <button className="backgroundLess items-center gap-2 row p-2 px-4"
                style={{ width: "100%", 
                textAlign: "left",
                 justifyContent: "left", 
                 display: "flex", 
                 backgroundColor: profiles.find((profile) => profile.name === currentState).color, 
                 borderRadius: 0,
                color: "white" }}
                onClick={() => {
                    setShowProfileSwitcher(!showProfileSwitcher)
                }}
            >
                <div style={{ borderRadius: "50%", backgroundColor: profiles.find((profile) => profile.name === currentState).color }}>
                    {profiles.map((profile, index) => {
                        if (profile.name === currentState) {
                            return profile.icon
                        }
                    })}
                </div>
                <p className="text-sm text-white">{currentState}</p>
            </button>
        </>
    )
}
