import React, { useEffect, useState } from 'react'
import Navbar from '../../components/Navbar'
import UserHeader from '../../components/UserHeader'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import { useParams } from 'react-router'
import AdminCourseNavbar from '../../components/AdminCourseNavbar'
import notify from '../../utils/IANotification'
import Switch from "react-switch";
import { tidyDate } from '../../utils/helper'
import { MdEdit } from 'react-icons/md'
import AttendanceList from '../../subpages/AttendanceList'
import TeachersLayout from '../../layout/TeachersLayout'
import StaffCourseNavbar from '../../components/StaffCourseNavbar'

export default function StaffClassAttendancePage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = useFetchUserData()

  const course_id = new URLSearchParams(window.location.search)?.get("course_id")
  const class_id = new URLSearchParams(window.location.search)?.get("class_id")

  const [classSessionData, setClassSessionData] = useState([])


  const getClassSessionsByClassId = async () => {
    if (class_id === "new") return
    if (!class_id) return
    let endpoint = config.endpoint + "/attendance/class/id/" + class_id
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      setClassSessionData(data.data?.reverse())
    } else {
      notify({ message: data.message, type: "error" })
    }
  }


  useEffect(() => {
    getClassSessionsByClassId()
  }, [class_id])

  return (
    <TeachersLayout navSelected={"Classes"}>
      <p className='mb-4' style={{ fontSize: 30, fontWeight: "500" }}>Class Attendance</p>
      <StaffCourseNavbar selected={"Class"} course_id={course_id} />
      <button className="button row gap-4 items-center"
        onClick={() => {
          navigate(`/staff/courses/classes/attendance/details?course_id=${course_id}&class_id=${class_id}&session_id=new`)
        }}
      >
        <MdEdit size={20} />
        New Session</button>
      <div className="card shadow-md rounded-md mt-4">
        <p className="card-title">Class Sessions</p>
        <AttendanceList type={"staff"}/>
      </div>
    </TeachersLayout >
  )
}
