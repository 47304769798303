import React, { useEffect, useRef, useState } from 'react'
import Navbar from '../components/Navbar'
import UserHeader from '../components/UserHeader'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../utils/AppFunctions'
import config from '../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import AdminLayout from '../layout/AdminLayout'
import { useParams } from 'react-router'
import AdminCourseNavbar from '../components/AdminCourseNavbar'
import notify from '../utils/IANotification'
import JoditEditor from 'jodit-react';
import Switch from "react-switch";
import { isMobile } from '../utils/helper'

export default function AnnouncementForm() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  // let user_data = useFetchUserData()

  const course_id = new URLSearchParams(window.location.search)?.get("course_id")
  const announcement_id = new URLSearchParams(window.location.search)?.get("announcement_id")

  const editor = useRef(null);
  const [width, setWidth] = useState(window.innerWidth);

  const liveConfig = {
    height: 500,
    placeholder: "Start typing...",
    // set custom buttons
    buttons: [
      'bold',
      'italic',
      'underline',
      'strikethrough',
      'superscript',
      'subscript',
      'ul',
      'ol',
      'outdent',
      'indent',
      'font',
      'fontsize',
      'brush',
      'paragraph',
      'table',
      'link',
      'undo',
      'redo',
      'eraser',
      'copyformat',
    ],
    askBeforePasteHTML: false,
    hotkeys: {
      redo: 'ctrl+z',
      undo: 'ctrl+y,ctrl+shift+z',
      indent: 'ctrl+]',
      outdent: 'ctrl+[',
      bold: 'ctrl+b',
      italic: 'ctrl+i',
      removeFormat: 'ctrl+shift+m',
      insertOrderedList: 'ctrl+shift+7',
      insertUnorderedList: 'ctrl+shift+8',
      openSearchDialog: 'ctrl+f',
      openReplaceDialog: 'ctrl+r',
    },
  };

  const [announcementData, setAnnouncementData] = useState({
    title: "",
    content: "",
    course_id: course_id,
    start_datetime: "",
    end_datetime: "",
    is_published: "0",
    for_students: "0",
    for_tutors: "0",
    for_parents: "0",
    is_forever: "0",
  })

  const getCourseAnnouncementById = async () => {
    if (!announcement_id) return
    if (announcement_id === "new") return
    let endpoint = config.endpoint + "/announcement/id/" + announcement_id
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      setAnnouncementData(data.data)
    } else {
      notify({ message: data.message, type: "error" })
    }
  }


  const submitAnnouncement = async () => {
    let endpoint = config.endpoint + "/announcement/course/id/"
    let fetchResponse = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(announcementData)
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      notify({ message: data.message, type: "success" })
      //   navigate(`/admin/courses/announcements?course_id=${course_id}`)
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  const updateAnnouncement = async () => {
    if (!announcement_id) return
    if (announcement_id === "new") return
    let endpoint = config.endpoint + "/announcement/id/" + announcement_id
    let fetchResponse = await fetch(endpoint, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(announcementData)
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      notify({ message: data.message, type: "success" })
      //   navigate(`/admin/courses/announcements?course_id=${course_id}`)
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  useEffect(() => {
    getCourseAnnouncementById()
  }, [])


  return (
    <>
      <div className="card rounded-md shadow-md p-4 mt-4">
        <p className="card-title">Details</p>
        <div className={`${isMobile(width) ? "column " : "row items-center"}  gap-4 `}>
          <div className="input-container">
            <p className="input-label">Title</p>
            <input className="text-input" type="text" placeholder="e.g. Holiday" value={announcementData.title}
              onChange={(e) => {
                setAnnouncementData({ ...announcementData, title: e.target.value })
              }}
            />
          </div>
          <div className="row gap-4 items-center">
            <p className="input-label">Publish</p>
            <Switch checked={announcementData.is_published === "1"}
              onChange={(checked) => {
                setAnnouncementData({ ...announcementData, is_published: checked ? "1" : "0" })
              }}
            />
          </div>
        </div>
        <div className={`${isMobile(width) ? "column " : "row items-center"} gap-4 mt-4`}>
          <div className="input-container" style={{ width: isMobile(width) && "100%" }}>
            <p className="input-label">Start Date</p>
            <input className="text-input" type="datetime-local" placeholder="e.g. Holiday" value={announcementData.start_datetime} style={{ width: isMobile(width) && "100%" }}
              onChange={(e) => {
                setAnnouncementData({ ...announcementData, start_datetime: e.target.value })
              }}
            />
          </div>

          <div className="input-container" style={{ width: isMobile(width) && "100%" }}>
            <p className="input-label">End Date</p>
            <input className="text-input" type="datetime-local" placeholder="e.g. Holiday" value={announcementData.end_datetime} style={{ width: isMobile(width) && "100%" }}
              onChange={(e) => {
                setAnnouncementData({ ...announcementData, end_datetime: e.target.value })
              }}
            />
          </div>

          <div className="row gap-4 items-center" style={{ width: isMobile(width) && "100%" }}>
            <p className="input-label" style={{ whiteSpace: "nowrap" }}>No End Date</p>
            <Switch checked={announcementData.is_forever === "1"}
              onChange={(checked) => {
                setAnnouncementData({ ...announcementData, is_forever: checked ? "1" : "0" })
              }}
            />
          </div>
        </div>

        <p className="input-label">For</p>
        <div className="column gap-4 mb-20">
          <div className="row gap-4 items-center">
            <Switch checked={announcementData.for_students === "1"}
              onChange={(checked) => {
                setAnnouncementData({ ...announcementData, for_students: checked ? "1" : "0" })
              }}
            />
            <p className="input-label" style={{ whiteSpace: "nowrap" }}>Students</p>
          </div>
          <div className="row gap-4 items-center">
            <Switch checked={announcementData.for_tutors === "1"}
              onChange={(checked) => {
                setAnnouncementData({ ...announcementData, for_tutors: checked ? "1" : "0" })
              }}
            />
            <p className="input-label" style={{ whiteSpace: "nowrap" }}>Teachers</p>
          </div>
          <div className="row gap-4 items-center">
            <Switch checked={announcementData.for_parents === "1"}
              onChange={(checked) => {
                setAnnouncementData({ ...announcementData, for_parents: checked ? "1" : "0" })
              }}
            />
            <p className="input-label" style={{ whiteSpace: "nowrap" }}>Parents</p>
          </div>
        </div>

        <div className="input-container">
          <JoditEditor
            ref={editor}
            value={announcementData.content}
            config={liveConfig}
            tabIndex={1} // tabIndex of textarea
            onBlur={newContent => {
              setAnnouncementData({ ...announcementData, content: newContent })
            }}
          />
        </div>

        <div className="row gap-4 right alignEnd">
          <button className="button"
            onClick={() => {
              navigate(-1)
            }}
          >Cancel</button>
          <button className="button"
            onClick={() => {
              if (announcement_id === "new" || !announcement_id) {
                submitAnnouncement()
              } else {
                updateAnnouncement()
              }
            }}
          >Save</button>
        </div>
      </div>

    </>
  )
}
