import {
  BrowserRouter,
  Routes,
  Route,
  Link,
} from "react-router-dom";
import './App.css';
import './globals.css'
import './hover.css'
import IndexPage from "./pages/IndexPage";
import AuthPage from "./pages/AuthPage";
import HomePage from "./pages/user/HomePage";
import AdminHomePage from "./pages/admin/AdminHomePage";
import AdminCoursePage from "./pages/admin/AdminCoursePage";
import AdminCourseFormGeneralPage from "./pages/admin/AdminCourseFormGeneralPage";
import AdminCourseLessonPage from "./pages/admin/AdminCourseLessonPage";
import AdminCourseLessonFormPage from "./pages/admin/AdminCourseLessonFormPage";
import AdminCourseClassPage from "./pages/admin/AdminCourseClassPage";
import AdminCourseClassFormPage from "./pages/admin/AdminCourseClassFormPage";
import AdminCourseStudentPage from "./pages/admin/AdminCourseStudentPage";
import AdminCourseAssessmentPage from "./pages/admin/AdminCourseAssessmentPage";
import AdminCourseAssessmentFormPage from "./pages/admin/AdminCourseAssessmentFormPage";
import AdminCourseScheduleAssessmentPage from "./pages/admin/AdminCourseScheduleAssessmentPage";
import AdminCourseAssessmentTypePage from "./pages/admin/AdminCourseAssessmentTypePage";
import AdminCourseMaterialsPage from "./pages/admin/AdminCourseMaterialsPage";
import AdminCourseMaterialsFormPage from "./pages/admin/AdminCourseMaterialsFormPage";
import CoursesPage from "./pages/user/CoursesPage";
import CourseHomePage from "./pages/user/CourseHomePage";
import LessonPage from "./pages/user/LessonPage";
import AssessmentPage from "./pages/user/AssessmentPage";
import AssessmentAttemptPage from "./pages/user/AssessmentAttemptPage";
import AdminAssessmentSubmissionPage from "./pages/admin/AdminAssessmentSubmissionPage";
import AdminAssessmentSubmissionAttemptPage from "./pages/admin/AdminAssessmentSubmissionAttemptPage";
import AdminStudentCompletionPage from "./pages/admin/AdminStudentCompletionPage";
import AdminCourseAnnouncementPage from "./pages/admin/AdminCourseAnnouncementPage";
import AdminCourseAnnouncementFormPage from "./pages/admin/AdminCourseAnnouncementFormPage";
import AdminCourseClassSchedulePage from "./pages/admin/AdminCourseClassSchedulePage";
import ProfilePage from "./pages/user/ProfilePage";
import ClockPage from "./pages/user/tools/ClockPage";
import NotebookPage from "./pages/user/tools/NotebookPage";
import AdminTeacherPage from "./pages/admin/AdminTeacherPage";
import AdminTeacherFormPage from "./pages/admin/AdminTeacherFormPage";
import AdminEnrolmentPage from "./pages/admin/AdminEnrolmentPage";
import AdminClassesPage from "./pages/admin/AdminClassesPage";
import AdminFamilyFormPage from "./pages/admin/AdminFamilyFormPage";
import CodeEditorPage from "./pages/admin/CodeEditorPage";
import ParentsHomePage from "./pages/parents/ParentsHomePage";
import AdminCourseClassAttendancePage from "./pages/admin/AdminCourseClassAttendancePage";
import AdminCourseClassAttendanceFormPage from "./pages/admin/AdminCourseClassAttendanceFormPage";
import StafffHomePage from "./pages/teachers/StafffHomePage";
import StaffTeacherPage from "./pages/teachers/StaffTeacherPage";
import StaffTeacherFormPage from "./pages/teachers/StaffTeacherFormPage";
import StaffEnrolmentPage from "./pages/teachers/StaffEnrolmentPage";
import StaffClassesPage from "./pages/teachers/StaffClassesPage";
import StaffCourseClassFormPage from "./pages/teachers/StaffClassFormPage";
import StaffClassFormPage from "./pages/teachers/StaffClassFormPage";
import StaffClassSchedulePage from "./pages/teachers/StaffClassSchedulePage";
import StaffClassAttendancePage from "./pages/teachers/StaffClassAttendancePage";
import StaffClassAttendanceFormPage from "./pages/teachers/StaffClassAttendanceFormPage";
import StaffLessonPage from "./pages/teachers/StaffLessonPage";
import StaffLessonFormPage from "./pages/teachers/StaffLessonFormPage";
import StaffAssessmentPage from "./pages/teachers/StaffAssessmentPage";
import StaffAssessmentFormPage from "./pages/teachers/StaffAssessmentFormPage";
import StaffScheduleAssessmentPage from "./pages/teachers/StaffScheduleAssessmentPage";
import StaffAssessmentTypePage from "./pages/teachers/StaffAssessmentTypePage";
import StaffAssessmentSubmissionPage from "./pages/teachers/StaffAssessmentSubmissionPage";
import StaffAssessmentSubmissionAttemptPage from "./pages/teachers/StaffAssessmentSubmissionAttemptPage";
import GradesPage from "./pages/user/GradesPage";
import AdminFamilyPage from "./pages/admin/AdminFamilyPage";
import AdminStudentFormPage from "./pages/admin/AdminStudentFormPage";
import AdminCertificatePage from "./pages/admin/AdminCertificatePage";
import AdminCertificateFormPage from "./pages/admin/AdminCertificateFormPage";
import AdminCertificateViewPage from "./pages/admin/AdminCertificateViewPage";
import ParentsGradesPage from "./pages/parents/ParentsGradesPage";
import ForumsPage from "./pages/user/ForumsPage";
import ForumsPostPage from "./pages/user/ForumsPostPage";
import ForumsPostHomePage from "./pages/user/ForumsPostHomePage";
import AdminStudentFeedbackPage from "./pages/admin/AdminStudentFeedbackPage";
import AdminStudentFeedbackRequestFormPage from "./pages/admin/AdminStudentFeedbackRequestFormPage";
import AdminStudentFeedbackSubmissionPage from "./pages/admin/AdminStudentFeedbackSubmissionPage";
import AdminStudentFeedbackRequestPage from "./pages/admin/AdminStudentFeedbackRequestPage";
import AdminStudentFeedbackFormPage from "./pages/admin/AdminStudentFeedbackFormPage";
import AdminAnnouncementFormPage from "./pages/admin/AdminAnnouncementFormPage";
import AdminAnnouncementPage from "./pages/admin/AdminAnnouncementPage";
import AdminSettingsPage from "./pages/admin/AdminSettingsPage";
import StaffFeedbackReviewPage from "./pages/teachers/StaffFeedbackReviewPage";
import StaffFeedbackReviewFormPage from "./pages/teachers/StaffFeedbackReviewFormPage";
import StaffFeedbackPage from "./pages/teachers/StaffFeedbackPage";
import AdminFeedbackReviewPage from "./pages/admin/AdminFeedbackReviewPage";
import AdminFeedbackReviewFormPage from "./pages/admin/AdminFeedbackReviewFormPage";
import ParentsFeedbackPage from "./pages/parents/ParentsFeedbackPage";
import ParentsFeedbackReviewFormPage from "./pages/parents/ParentsFeedbackReviewFormPage";
import ParentsCertificatePage from "./pages/parents/ParentsCertificatePage";
import CertificateView from "./subpages/CertificateView";
import KanbanPage from "./pages/user/tools/KanbanPage";
import AdminUserProfilePage from "./pages/admin/AdminUserProfilePage";
import TestCodePage from "./pages/user/TestCodePage";
import ErrorPage from "./pages/ErrorPage";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<AuthPage pageState={"login"} />} />
        {/* <Route path="/" element={<IndexPage />} /> */}
        <Route path="/auth/login" element={<AuthPage pageState={"login"} />} />
        <Route path="/auth/register" element={<AuthPage pageState={"register"} />} />
        <Route path="/auth/activate" element={<AuthPage pageState={"activate"} />} />

        {/* Test Routes */}
        <Route path="/test" element={<CodeEditorPage />} />


        {/* Parents */}
        <Route path="/parents" element={<ParentsHomePage />} />
        <Route path="/parents/grades" element={<ParentsGradesPage />} />
        <Route path="/parents/feedback" element={<ParentsFeedbackPage />} />
        <Route path="/parents/feedback/review/form" element={<ParentsFeedbackReviewFormPage />} />
        <Route path="/parents/certificates" element={<ParentsCertificatePage />} />
        <Route path="/parents/certificates/view" element={<CertificateView />} />


        {/* Staff */}
        <Route path="/staff" element={<StafffHomePage />} />
        <Route path="/staff/teachers" element={<StaffTeacherPage />} />
        <Route path="/staff/teachers/details" element={<StaffTeacherFormPage />} />
        <Route path="/staff/enrolment" element={<StaffEnrolmentPage />} />
        <Route path="/staff/enrolment/details" element={<StaffEnrolmentPage />} />
        <Route path="/staff/classes" element={<StaffClassesPage />} />
        <Route path="/staff/courses/classes/details" element={<StaffClassFormPage />} />
        <Route path="/staff/courses/classes/schedule" element={<StaffClassSchedulePage />} />
        <Route path="/staff/courses/classes/attendance" element={<StaffClassAttendancePage />} />
        <Route path="/staff/courses/classes/attendance/details" element={<StaffClassAttendanceFormPage />} />
        <Route path="/staff/courses/lessons" element={<StaffLessonPage />} />
        {/* <Route path="/staff/courses/lessons/completion" element={<StaffLessonFormPage />} /> */}
        <Route path="/staff/courses/lessons/details" element={<StaffLessonFormPage />} />
        <Route path="/staff/courses/assessments" element={<StaffAssessmentPage />} />
        <Route path="/staff/courses/assessments/details" element={<StaffAssessmentFormPage />} />
        <Route path="/staff/courses/assessments/schedule" element={<StaffScheduleAssessmentPage />} />

        <Route path="/staff/courses/assessments/type" element={<StaffAssessmentTypePage />} />
        <Route path="/staff/courses/assessments/submissions" element={<StaffAssessmentSubmissionPage />} />
        <Route path="/staff/courses/assessments/submissions/attempt" element={<StaffAssessmentSubmissionAttemptPage />} />


        <Route path="/staff/feedback" element={<StaffFeedbackPage />} />
        <Route path="/staff/feedback/review" element={<StaffFeedbackReviewPage />} />
        <Route path="/staff/feedback/review/form" element={<StaffFeedbackReviewFormPage />} />



        {/* Admin */}
        <Route path="/admin" element={<AdminHomePage />} />

        {/* Feedback and Review */}
        <Route path="/admin/feedback" element={<AdminStudentFeedbackPage />} />
        <Route path="/admin/feedback/form" element={<AdminStudentFeedbackFormPage />} />
        <Route path="/admin/feedback/request" element={<AdminStudentFeedbackRequestPage />} />
        <Route path="/admin/feedback/request/form" element={<AdminStudentFeedbackRequestFormPage />} />
        <Route path="/admin/feedback/submission" element={<AdminStudentFeedbackSubmissionPage />} />

        <Route path="/admin/feedback/review" element={<AdminFeedbackReviewPage />} />
        <Route path="/admin/feedback/review/form" element={<AdminFeedbackReviewFormPage />} />


        {/* Settings */}
        <Route path="/admin/settings" element={<AdminSettingsPage />} />
        <Route path="/admin/user" element={<AdminUserProfilePage />} />


        {/* Announcements */}
        <Route path="/admin/announcements" element={<AdminAnnouncementPage />} />
        <Route path="/admin/announcements/form" element={<AdminAnnouncementFormPage />} />


        {/* Teachers */}
        <Route path="/admin/teachers" element={<AdminTeacherPage />} />
        <Route path="/admin/teachers/details" element={<AdminTeacherFormPage />} />


        {/* Students */}
        <Route path="/admin/enrolment" element={<AdminEnrolmentPage />} />
        <Route path="/admin/students/details" element={<AdminStudentFormPage />} />
        <Route path="/admin/family" element={<AdminFamilyPage />} />
        <Route path="/admin/family/details" element={<AdminFamilyFormPage />} />

        {/* Clases */}
        <Route path="/admin/classes" element={<AdminClassesPage />} />

        {/* Courses */}
        <Route path="/admin/courses" element={<AdminCoursePage />} />
        <Route path="/admin/courses/details" element={<AdminCourseFormGeneralPage />} />

        {/* Announcements */}
        <Route path="/admin/courses/announcements" element={<AdminCourseAnnouncementPage />} />
        <Route path="/admin/courses/announcements/details" element={<AdminCourseAnnouncementFormPage />} />

        {/* Lessons */}
        <Route path="/admin/courses/lessons" element={<AdminCourseLessonPage />} />
        <Route path="/admin/courses/lessons/completion" element={<AdminStudentCompletionPage />} />
        <Route path="/admin/courses/lessons/details" element={<AdminCourseLessonFormPage />} />

        {/* Classes */}
        <Route path="/admin/courses/classes" element={<AdminCourseClassPage />} />
        <Route path="/admin/courses/classes/details" element={<AdminCourseClassFormPage />} />
        <Route path="/admin/courses/classes/schedule" element={<AdminCourseClassSchedulePage />} />
        <Route path="/admin/courses/classes/attendance" element={<AdminCourseClassAttendancePage />} />
        <Route path="/admin/courses/classes/attendance/details" element={<AdminCourseClassAttendanceFormPage />} />

        {/* Students */}
        <Route path="/admin/courses/students" element={<AdminCourseStudentPage />} />
        <Route path="/admin/courses/students/details" element={<AdminCourseStudentPage />} />
        <Route path="/admin/courses/question-builder" element={<AdminCourseAssessmentPage />} />

        {/* Assessments */}
        <Route path="/admin/courses/assessments" element={<AdminCourseAssessmentPage />} />
        <Route path="/admin/courses/assessments/details" element={<AdminCourseAssessmentFormPage />} />
        <Route path="/admin/courses/assessments/schedule" element={<AdminCourseScheduleAssessmentPage />} />
        <Route path="/admin/courses/assessments/type" element={<AdminCourseAssessmentTypePage />} />
        <Route path="/admin/courses/assessments/submissions" element={<AdminAssessmentSubmissionPage />} />
        <Route path="/admin/courses/assessments/submissions/attempt" element={<AdminAssessmentSubmissionAttemptPage />} />

        {/* Materials */}
        <Route path="/admin/courses/materials" element={<AdminCourseMaterialsPage />} />
        <Route path="/admin/courses/materials/details" element={<AdminCourseMaterialsFormPage />} />


        {/* Certificates */}
        <Route path="/admin/certificates" element={<AdminCertificatePage />} />
        <Route path="/admin/certificates/details" element={<AdminCertificateFormPage />} />
        <Route path="/admin/certificates/view" element={<CertificateView />} />


        {/* User */}
        <Route path="/home" element={<HomePage />} />
        <Route path="/profile" element={<ProfilePage />} />
        <Route path="/settings" element={<HomePage />} />
        <Route path="/courses" element={<CoursesPage />} />
        <Route path="/grades" element={<GradesPage />} />
        <Route path="/courses/:course_id" element={<CourseHomePage />} />
        <Route path="/lesson/:lesson_id" element={<LessonPage />} />
        <Route path="/assessment/:assessment_id" element={<AssessmentPage />} />
        <Route path="/assessment/attempt/submission" element={<AssessmentAttemptPage />} />
        <Route path="/forums" element={<ForumsPage />} />
        <Route path="/forums/category/:category_id" element={<ForumsPostPage />} />
        <Route path="/forums/category/post" element={<ForumsPostHomePage />} />
        <Route path="/playground" element={<TestCodePage />} />


        {/* Tools */}
        <Route path="/tools/clock" element={<ClockPage />} />
        <Route path="/tools/notebook" element={<NotebookPage />} />
        <Route path="/tools/code-editor" element={<CodeEditorPage />} />
        <Route path="/tools/kanban" element={<KanbanPage />} />

        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
