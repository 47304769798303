import React, { useLayoutEffect, useState } from 'react'
import { MdAppBlocking, MdClass, MdGroup, MdHome, MdInbox, MdList, MdOutbond, MdOutbox, MdSettings } from 'react-icons/md'
import { useParams } from 'react-router-dom'
import { TbReport } from "react-icons/tb";
import { IoDocumentsOutline, IoEaselOutline, IoHomeOutline, IoJournalOutline, IoLibraryOutline, IoMegaphone, IoMegaphoneOutline, IoNewspaperOutline, IoPeopleOutline, IoSettingsOutline } from "react-icons/io5";

export default function AdminFeedbackNavbar({ selected }) {

    const course_id = new URLSearchParams(window.location.search)?.get("course_id")
    const lesson_id = new URLSearchParams(window.location.search)?.get("lesson_id")

    const [headerHeight, setHeaderHeight] = useState(document?.getElementById("admin-header")?.clientHeight + 1)

    useLayoutEffect(() => {
        setHeaderHeight(document?.getElementById("admin-header")?.clientHeight + 1)
    }, [])

    const ICON_SIZE = 20

    const ITEMS = [
        {
            "name": "List",
            "link": `/admin/feedback`,
            "icon": <MdList size={ICON_SIZE} className="text-slate-600" />,
        },
        {
            "name": "Request",
            "link": `/admin/feedback/request`,
            "icon": <MdInbox size={ICON_SIZE} className="text-slate-600" />,
        },
        {
            "name": "Submission",
            "link": `/admin/feedback/submission`,
            "icon": <MdOutbox size={ICON_SIZE} className="text-slate-600" />,
        }
    ]
return (
    <div id='course-nav' className='row gap-4 mb-4 items-center rounded-md shadow-md px-2 hide-scrollbars' style={{
        width: "100%",
        // borderBottom: "1px solid #E5E5E5",
        position: "sticky",
        top: headerHeight,
        zIndex: 5,
        backgroundColor: "white",
        overflowY: "auto",
    }}>
        {ITEMS.map((item, index) => {
            return (
                <div className={`row gap-4 items-center py-2 pr-4 cursor-pointer`} style={{ borderBottom: selected === item.name ? "2px solid var(--primary)" : "", }} onClick={() => {
                    window.location.href = item.link
                }}>
                    {item.icon}
                    <p style={{ fontSize: 12, fontWeight: 500 }}>{item.name}</p>
                </div>
            )
        })}
    </div>
)
}
