import React, { useEffect, useState } from 'react'
import Navbar from '../../components/Navbar'
import UserHeader from '../../components/UserHeader'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import AdminLayout from '../../layout/AdminLayout'
import notify from '../../utils/IANotification'
import TeacherList from '../../subpages/TeacherList'

export default function AdminTeacherPage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("staff")
  let user_data = useFetchUserData()
  const [searchQuery, setSearchQuery] = useState("")

  return (
    <AdminLayout navSelected={"Teachers"}>
      <p className='mb-4' style={{ fontSize: 30, fontWeight: "500" }}>Teachers</p>

      <div className="input-container">
        <input type="text" className="text-input" placeholder="Search..." value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
      </div>
      <button className="button"
        onClick={() => {
          navigate("/admin/teachers/details?teacher_id=new")
        }}
      >Add Teacher</button>
      <TeacherList type={"admin"} filter={searchQuery} />
    </AdminLayout>
  )
}
