import React, { useEffect, useRef, useState } from 'react'
import Navbar from '../../components/Navbar'
import UserHeader from '../../components/UserHeader'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import AdminLayout from '../../layout/AdminLayout'
import { useParams } from 'react-router'
import notify from '../../utils/IANotification'
import Select from 'react-select'
import { FaCertificate } from 'react-icons/fa'
import { IoClose } from 'react-icons/io5'
import { MdPrint } from 'react-icons/md'
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import { tidyDateRaw } from '../../utils/helper'

export default function AdminCertificateFormPage() {
  const navigate = useNavigate()

  const certificateRef = useRef(null)
  const { isAuthorised, userRole } = useFetchAuthorisation("staff")
  let user_data = useFetchUserData()

  const { course_id } = useParams()

  const certificate_id = new URLSearchParams(window.location.search).get("certificate_id")


  const [courseData, setCourseData] = useState([])
  const [studentData, setStudentData] = useState([])
  const [showCertificate, setShowCertificate] = useState(false)

  const [certificateForm, setCertificateForm] = useState({
    user_id: "",
    course_id: "",
  })

  const fetchAllCourses = async () => {
    let endpoint = config.endpoint + "/course/"
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      }
    })
    let response = await fetchResponse.json()
    if (response.status === 200) {
      setCourseData(response.data)
    }
  }

  const fetchAllStudents = async () => {
    let endpoint = config.endpoint + "/students/"
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      }
    })
    let response = await fetchResponse.json()
    if (response.status === 200) {
      setStudentData(response.data)
    }
  }

  const fetchCertificateById = async () => {
    if (!certificate_id) return
    if (certificate_id === "new") return
    let endpoint = config.endpoint + "/certificate/id/" + certificate_id
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      }
    })
    let response = await fetchResponse.json()
    if (response.status === 200) {
      setCertificateForm(response.data)
    }
  }

  const editCertificate = async () => {
    let endpoint = config.endpoint + "/certificate/id/" + certificate_id
    let fetchResponse = await fetch(endpoint, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(certificateForm)
    })
    let response = await fetchResponse.json()
    if (response.status === 200) {
      notify({ message: "Certificate updated successfully", type: "success" })
    } else {
      notify({ message: response.message, type: "error" })
    }
  }

  const issueCertificate = async () => {
    let endpoint = config.endpoint + "/certificate/"
    let fetchResponse = await fetch(endpoint, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(certificateForm)
    })
    let response = await fetchResponse.json()
    if (response.status === 200) {
      let certificate_id = response.data.id
      navigate(`/admin/certificates/details?certificate_id=${certificate_id}`)
      notify({ message: "Certificate issued successfully", type: "success" })
    } else {
      notify({ message: response.message, type: "error" })
    }
  }

  const deleteCertificate = async () => {
    let endpoint = config.endpoint + "/certificate/id/" + certificate_id
    let fetchResponse = await fetch(endpoint, {
      method: "DELETE",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      }
    })
    let response = await fetchResponse.json()
    if (response.status === 200) {
      navigate(-1)
    } else {
      notify({ message: response.message, type: "error" })
    }
  }


  useEffect(() => {
    fetchAllCourses()
    fetchAllStudents()
    fetchCertificateById()
  }, [])


  const handlePrint = useReactToPrint({
    content: () => certificateRef.current,
  });


  const CertificateModal = () => {
    // print certificate on browser - landscape

    // window.onafterprint = function () {
    //   setShowCertificate(false)
    // }

    // window.print()



    return (
      <div className="modal-container column">
        <div className="row items-center justify-between" style={{ width: "100%", padding: 10 }}>
          <h5 className="modal-title" style={{ color: "white", fontSize: 30, textAlign: "left", width: "100%", }}>Certificate</h5>
          <div className="row gap-4 items-center">
            <ReactToPrint
              trigger={() => {
                return (
                  <button className="button row gap-4 items-center center" style={{ width: "auto" }}>
                    <MdPrint size={20} />
                    Print
                  </button>
                )
              }}
              onBeforeGetContent={() => {
                setShowCertificate(false)
              }}

              content={() => certificateRef.current}
            />
            {/* <button className="button row gap-4 items-center center" style={{ width: "auto" }}
              onClick={handlePrint}
            >
              <MdPrint size={20} />
              Print
            </button> */}
            <button className="button row gap-4 items-center center" onClick={() => {
              setShowCertificate(false)
            }}>
              <IoClose size={20} />
              Close
            </button>
          </div>
        </div>

        <div className="modal-content" style={{backgroundColor:"white",  }}>
          <div ref={certificateRef} className='column center items-center' style={{ width: 960, height: 600, display: "flex", alignItems: "center", justifyContent: "center" }} >
            <div className="center items-center row gap-4 mb-20">
              <img src={config.images.logo_wo_text} width={100} height={50} alt="logo" style={{ objectFit: "contain" }} />
              <p style={{ fontSize: 25, fontWeight: "900", lineHeight: 1.5, }}>{config.client_title} <br /><span style={{ letterSpacing: 3, fontWeight: "normal" }}>{config.client_subtitle}</span></p>
            </div>
            <p>This is to Certisfy that</p>
            <p style={{ textDecoration: "underline", width: "80%", fontSize: 50, textAlign: "center", }}>{certificateForm.user_data?.first_name} {certificateForm.user_data?.last_name}</p>
            <p style={{ marginTop: 20 }}>has successfully completed the course</p>
            <p
              style={{
                width: "80%",
                fontSize: 30,
                textAlign: "center",
              }}
            >{certificateForm.course_data?.name}</p>
            <p>on {tidyDateRaw(certificateForm.createdAt).day} {tidyDateRaw(certificateForm.createdAt).month} {tidyDateRaw(certificateForm.createdAt).year}</p>
            <div style={{ position: "absolute", bottom: 20, right: 20 }}>
              <p>Issued by</p>
              <p>{config.client_name}</p>
            </div>
          </div>
        </div>
      </div>
    )
  }


  return (
    <AdminLayout navSelected={"Certificates"}>
      <p className='mb-4' style={{ fontSize: 30, fontWeight: "500" }}>{certificate_id !== "new" ? "Edit" : "Issue"} Certificate</p>
      {showCertificate && <CertificateModal />}

      <div className="card rounded-md shadow-md p-4 mt-4">
        <div className="row items-center justify-between w-full">
          <p className="card-title">Certificate</p>
          {certificate_id && certificate_id !== "new" && <button className="backgroundLess row gap-4 items-center" style={{ fontSize: 15 }}
            onClick={() =>{
              window.open(`/admin/certificates/view?certificate_id=${certificate_id}&user_id=${certificateForm.user_id}&course_id=${certificateForm.course_id}`, `_blank`)
            }}
          >
            <FaCertificate size={15} />
            View Certificate
          </button>}
        </div>
        <div className="row gap-4 items-center justifyContent">
          <div className="input-container">
            <label htmlFor="" className="input-label">Student</label>
            <Select
              options={studentData.map((student) => {
                return {
                  value: student.id,
                  label: `${student.first_name} ${student.last_name}`
                }
              })}
              onChange={(e) => {
                setCertificateForm({ ...certificateForm, user_id: e.value })
              }}
              value={studentData.map((student) => {
                return {
                  value: student.id,
                  label: `${student.first_name} ${student.last_name}`
                }
              }).filter((student) => student.value == certificateForm.user_id)}
            />
          </div>
          <div className="input-container">
            <label htmlFor="" className="input-label">Course</label>
            <Select
              options={courseData.map((course) => {
                return {
                  value: course.id,
                  label: course.name
                }
              })}
              onChange={(e) => {
                setCertificateForm({ ...certificateForm, course_id: e.value })
              }}
              value={courseData.map((course) => {
                return {
                  value: course.id,
                  label: course.name
                }
              }).filter((course) => course.value == certificateForm.course_id)}
            />
          </div>
        </div>

        <div className="column right alignEnd">
          <div className="row gap-4">
            {certificate_id !== "new" && <button className="button-delete" onClick={deleteCertificate}>Delete</button>}
            <button className="button" onClick={() => {
              if (certificate_id === "new") {
                issueCertificate()
              } else {
                editCertificate()
              }
            }}>{certificate_id === "new" ? "Issue" : "Update"}</button>
          </div>
        </div>

      </div>
    </AdminLayout>
  )
}
