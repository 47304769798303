import React, { useState } from 'react'
import Editor from "@monaco-editor/react";
import { capitaliseFirstLetter, isMobile } from '../../utils/helper';
import { MdRadioButtonChecked, MdRadioButtonUnchecked } from 'react-icons/md';

export default function CodeEditorAssessmentComponent({ data, options, onOptionChange, onChange, onAnswered, state, userAnswer, item_index, output }) {
    // const LANGUAGES = [
    //     "javascript",
    //     "typescript",
    //     "python",
    //     "java",
    //     "c",
    //     "cpp",
    //     "csharp",
    //     "go",
    //     "ruby",
    //     "rust",
    //     "swift",
    //     "kotlin",
    //     "php",
    //     "sql",
    //     "plaintext",
    // ]
    const [width, setWidth] = useState(window.innerWidth)
    const LANGUAGES = [
        { name: "JavaScript", value: "javascript" },
        { name: "TypeScript", value: "typescript" },
        { name: "Python", value: "python" },
        { name: "Java", value: "java" },
        { name: "C", value: "c" },
        { name: "C++", value: "cpp" },
        { name: "C#", value: "csharp" },
        { name: "Go", value: "go" },
        { name: "Ruby", value: "ruby" },
        { name: "Rust", value: "rust" },
        { name: "Swift", value: "swift" },
        { name: "Kotlin", value: "kotlin" },
        { name: "PHP", value: "php" },
        { name: "SQL", value: "sql" },
        { name: "Plain Text", value: "plaintext" },
    ]

    const [currentState, setCurrentState] = useState("prepend") // template, prepend, append, output

    return (
        <>
            {state === "builder" && <>
                <div className="input-container">
                    <p className="input-label">Language</p>
                    <select className="select-input" value={options?.language} onChange={(e) => {
                        onOptionChange({ ...options, language: e.target.value })
                    }}>
                        {LANGUAGES.map((language) => {
                            return (
                                <option value={language.value}>{language.name}</option>
                            )
                        })}
                    </select>
                </div>
                <div className={`${isMobile(width) ? "column" : "row items-start"} gap`} style={{ width: "100%" }}>
                    <div style={{ width: !isMobile(width) ? "400px" : "100%" }}>
                        <div style={{ marginBottom: 10, backgroundColor: currentState === "prepend" ? "var(--primary)" : "gray", padding: "5px 20px" }}
                            onClick={() => {
                                setCurrentState("prepend")
                            }}
                        >
                            <p style={{ color: "white", fontSize: 12, fontWeight: "bold" }}>Prepend Code</p>
                            {currentState === "prepend" && <p style={{ color: "white", fontSize: 12 }}>Code to be prepended to the submitted code</p>}
                        </div>
                        <div style={{ marginBottom: 10, backgroundColor: currentState === "template" ? "var(--primary)" : "gray", padding: "5px 20px" }}
                            onClick={() => {
                                setCurrentState("template")
                            }}
                        >
                            <p style={{ color: "white", fontSize: 12, fontWeight: "bold" }}>Template Code</p>
                            {currentState === "template" && <p style={{ color: "white", fontSize: 12 }}>Code to be displayed to the user</p>}
                        </div>
                        <div style={{ marginBottom: 10, backgroundColor: currentState === "append" ? "var(--primary)" : "gray", padding: "5px 20px" }}
                            onClick={() => {
                                setCurrentState("append")
                            }}
                        >
                            <p style={{ color: "white", fontSize: 12, fontWeight: "bold" }}>Append Code</p>
                            {currentState === "append" && <p style={{ color: "white", fontSize: 12 }}>Code to be appended to the submitted code</p>}
                        </div>

                        <div style={{ marginBottom: 10, backgroundColor: currentState === "output" ? "var(--primary)" : "gray", padding: "5px 20px" }}
                            onClick={() => {
                                setCurrentState("output")
                            }}
                        >
                            <p style={{ color: "white", fontSize: 12, fontWeight: "bold" }}>Expected Output</p>
                            {currentState === "output" && <p style={{ color: "white", fontSize: 12 }}>Expected code output</p>}
                        </div>

                    </div>
                    <div style={{ width: "100%", fontFamily: "monospace" }}>
                        <Editor
                            height="500px"
                            language={options?.language || "python"}
                            theme="vs-dark"
                            value={options?.[currentState] || ""}
                            // onChange={(value) => {
                            //     onChange(value)
                            // }}

                            onChange={(value) => {
                                console.log({ ...options, [currentState]: value })
                                onOptionChange({ ...options, [currentState]: value })
                            }}

                            style={{ fontFamily: "source-code-pro" }}

                            options={{
                                inlineSuggest: true,
                                fontSize: "14px",
                                letterSpacing: "0.5px",
                                formatOnType: true,
                                autoClosingBrackets: true,
                                minimap: { scale: 10 },
                                fontFamily: "Courier New",
                                readOnly: false,
                                fontLigatures: true,
                            }}
                        />
                    </div>
                </div>
            </>}

            {state === "question" && <>
                <div className="row gap items-start" style={{ width: "100%", marginTop: 10 }}>
                    <div style={{ width: "100%", fontFamily: "monospace" }}>
                        <Editor
                            height="500px"
                            language={options?.language || "python"}
                            theme="vs-dark"
                            value={userAnswer || data?.options?.template || ""}
                            // onChange={(value) => {
                            //     onChange(value)
                            // }}

                            onChange={(value) => {
                                onAnswered(value)
                            }}

                            style={{ fontFamily: "source-code-pro" }}

                            options={{
                                inlineSuggest: true,
                                fontSize: "14px",
                                letterSpacing: "0.5px",
                                formatOnType: true,
                                autoClosingBrackets: true,
                                minimap: { scale: 10 },
                                fontFamily: "Courier New",
                                readOnly: false,
                                fontLigatures: true,
                            }}
                        />
                    </div>
                </div>
            </>}

            {state === "submission" && <>
                <div className="row gap items-start" style={{ width: "100%", marginTop: 10 }}>
                    <div style={{ width: "100%", fontFamily: "monospace" }}>
                        <p style={{ fontWeight: "bold", marginTop: 20, marginBottom: 10, }}>User's Code</p>
                        <Editor
                            height="500px"
                            language={options?.language || "python"}
                            theme="vs-dark"
                            value={userAnswer || ""}
                            // onChange={(value) => {
                            //     onChange(value)
                            // }}

                            onChange={(value) => {
                                console.log({ ...options, [currentState]: value })
                                onOptionChange({ ...options, [currentState]: value })
                            }}

                            onAnswered={(value) => {
                                onAnswered(value)
                            }}

                            style={{ fontFamily: "source-code-pro" }}

                            options={{
                                inlineSuggest: true,
                                fontSize: "14px",
                                letterSpacing: "0.5px",
                                formatOnType: true,
                                autoClosingBrackets: true,
                                minimap: { scale: 10 },
                                fontFamily: "Courier New",
                                readOnly: true,
                                fontLigatures: true,
                            }}

                        />

                        <p style={{ fontWeight: "bold", marginTop: 20, marginBottom: 10, }}>User's Output</p>
                        <Editor
                            height="500px"
                            language={options?.language || "python"}
                            theme="vs-dark"
                            value={output || ""}
                            // onChange={(value) => {
                            //     onChange(value)
                            // }}

                            onChange={(value) => {
                                console.log({ ...options, [currentState]: value })
                                onOptionChange({ ...options, [currentState]: value })
                            }}

                            onAnswered={(value) => {
                                onAnswered(value)
                            }}

                            style={{ fontFamily: "source-code-pro" }}

                            options={{
                                inlineSuggest: true,
                                fontSize: "14px",
                                letterSpacing: "0.5px",
                                formatOnType: true,
                                autoClosingBrackets: true,
                                minimap: { scale: 10 },
                                fontFamily: "Courier New",
                                readOnly: true,
                                fontLigatures: true,
                            }}

                        />
                    </div>
                </div>
            </>}
            {/* <pre>{JSON.stringify({
                "userAnswer": userAnswer,
                "data": data,
                "options": options,
                "state": state,
                "item_index": item_index,
            }, null, 2)}</pre> */}
        </>
    )
}
