import React, { useEffect, useState } from 'react'
import Navbar from '../../../components/Navbar'
import UserHeader from '../../../components/UserHeader'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../../utils/AppFunctions'
import config from '../../../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import UserLayout from '../../../layout/UserLayout'
import { capitaliseFirstLetter, tidyDateRaw } from '../../../utils/helper'

export default function ClockPage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = useFetchUserData()
  const [selectedFunction, setSelectedFunction] = useState("clock") // clock, timer, stopwatch, alarm

  // Clock
  const [time, setTime] = useState()
  const [date, setDate] = useState()

  // Timer
  const [timer, setTimer] = useState()
  const [timerRunning, setTimerRunning] = useState(false)
  const [timerEnd, setTimerEnd] = useState()
  const [timerFinished, setTimerFinished] = useState(false)
  const [selectedHours, setSelectedHours] = useState(0)
  const [selectedMinutes, setSelectedMinutes] = useState(0)
  const [selectedSeconds, setSelectedSeconds] = useState(0)

  // Stopwatch
  const [stopwatch, setStopwatch] = useState()
  const [stopwatchRunning, setStopwatchRunning] = useState(false)
  const [stopwatchEnd, setStopwatchEnd] = useState()
  const [stopwatchFinished, setStopwatchFinished] = useState(false)
  const [stopwatchLap, setStopwatchLap] = useState([]) // [{lap: 1, time: 0}, {lap: 2, time: 0}]



  useEffect(() => {
    let interval = setInterval(() => {
      // Clock
      let datetime = new Date()
      let dateRaw = tidyDateRaw(datetime)
      setTime(`${dateRaw.hour}:${dateRaw.minute}:${dateRaw.seconds}`)
      setDate(`${dateRaw.day} ${dateRaw.month} ${dateRaw.year}`)

    }, 900)
  }, [])


  // Timer
  useEffect(() => {
    let interval
    if (timerRunning) {
      interval = setInterval(() => {
        let now = new Date().getTime() / 1000
        let timeLeft = timerEnd - now
        if (timeLeft <= 0) {
          setTimerRunning(false)
          setTimerFinished(true)
        } else {
          let hours = Math.floor(timeLeft / 3600)
          let minutes = Math.floor((timeLeft - (hours * 3600)) / 60)
          let seconds = Math.floor(timeLeft - (hours * 3600) - (minutes * 60))

          if (hours < 10) {
            hours = "0" + hours
          }

          if (minutes < 10) {
            minutes = "0" + minutes
          }

          if (seconds < 10) {
            seconds = "0" + seconds
          }
          setSelectedHours(hours)
          setSelectedMinutes(minutes)
          setSelectedSeconds(seconds)
        }
      }, 200)
    } else {
      clearInterval(interval)
    }
    return () => clearInterval(interval)
  }, [timerRunning])

  // Stopwatch
  useEffect(() => {
    let interval
    if (stopwatchRunning) {
      interval = setInterval(() => {
        let now = new Date().getTime() / 1000
        let timeLeft = now - stopwatchEnd
        let hours = Math.floor(timeLeft / 3600)
        let minutes = Math.floor((timeLeft - (hours * 3600)) / 60)
        let seconds = Math.floor(timeLeft - (hours * 3600) - (minutes * 60))
        let milliseconds = Math.floor((timeLeft - (hours * 3600) - (minutes * 60) - seconds) * 1000)

        if (hours < 10) {
          hours = "0" + hours
        }

        if (minutes < 10) {
          minutes = "0" + minutes
        }

        if (seconds < 10) {
          seconds = "0" + seconds
        }

        if (milliseconds < 10) {
          milliseconds = "0" + milliseconds
        }

        setStopwatch(`${hours}:${minutes}:${seconds}.${milliseconds}`)
      }, 200)
    } else {
      clearInterval(interval)
    }
    return () => clearInterval(interval)
  }, [stopwatchRunning])


  return (
    <UserLayout navSelected={""}>
      <div className="card rounded-md shadow-md p-4 mt-4">
        <p style={{ fontWeight: 500, fontSize: 15 }}>{capitaliseFirstLetter(selectedFunction)}</p>
        <div className="row w-full right alignEnd">
          <div className="row">
            <button className="button" style={{ borderRadius: 0, border: "1px solid #0022FF", backgroundColor: selectedFunction === "clock" ? "#0022FF" : "white", color: selectedFunction === "clock" ? "white" : "black" }} onClick={() => setSelectedFunction("clock")}>Clock</button>
            <button className="button" style={{ borderRadius: 0, border: "1px solid #0022FF", backgroundColor: selectedFunction === "timer" ? "#0022FF" : "white", color: selectedFunction === "timer" ? "white" : "black" }} onClick={() => setSelectedFunction("timer")}>Timer</button>
            <button className="button" style={{ borderRadius: 0, border: "1px solid #0022FF", backgroundColor: selectedFunction === "stopwatch" ? "#0022FF" : "white", color: selectedFunction === "stopwatch" ? "white" : "black" }} onClick={() => setSelectedFunction("stopwatch")}>Stopwatch</button>
            {/* <button className="button" style={{ borderRadius: 0, border: "1px solid #0022FF", backgroundColor: selectedFunction === "alarm" ? "#0022FF" : "white", color: selectedFunction === "alarm" ? "white" : "black" }} onClick={() => setSelectedFunction("alarm")}>Alarm</button> */}
          </div>
        </div>

        {selectedFunction === "clock" && <div className="w-full column center items-center mt-40 pb-40">
          <p style={{ fontWeight: 500, fontSize: 50 }}>{time}</p>
          <p style={{ fontWeight: 500, fontSize: 35 }}>{date}</p>
        </div>}

        {selectedFunction === "timer" && <div className="w-full column center items-center mt-40 pb-40">
          {!timerRunning && !timerFinished && <div className="row mb-4 gap">
            <input type="number" className="text-input" style={{ width: 150, textAlign: "center" }} value={selectedHours} onChange={(e) => setSelectedHours(e.target.value)} />
            <p style={{ fontWeight: 500, fontSize: 35 }}>:</p>
            <input type="number" className="text-input" style={{ width: 150, textAlign: "center" }} value={selectedMinutes} onChange={(e) => setSelectedMinutes(e.target.value)} />
            <p style={{ fontWeight: 500, fontSize: 35 }}>:</p>
            <input type="number" className="text-input" style={{ width: 150, textAlign: "center" }} value={selectedSeconds} onChange={(e) => setSelectedSeconds(e.target.value)} />
          </div>}
          {timerRunning && <p style={{ fontWeight: 500, fontSize: 50 }}>{`${selectedHours}:${selectedMinutes}:${selectedSeconds}`}</p>}
          {timerFinished && <p style={{ fontWeight: 500, fontSize: 50, marginBottom: 20 }}>Time's up!</p>}
          <div className="row gap-4">
            <button className="button" style={{ borderRadius: 0, backgroundColor: timerRunning ? "#FF0000" : "#0022FF", color: "white", width: 150 }} onClick={() => {
              if (timerRunning) {
                setTimerRunning(false)
              } else {
                let hours = selectedHours
                let minutes = selectedMinutes
                let seconds = selectedSeconds
                let time = (hours * 3600) + (minutes * 60) + seconds
                setTimerEnd((new Date().getTime() / 1000) + time)
                setTimerRunning(true)
              }
            }}>{timerRunning ? "Pause" : "Start"}</button>
            <button className="button-delete" style={{ borderRadius: 0, width: 150 }} onClick={() => {
              setTimerRunning(false)
              setTimerFinished(false)
              setSelectedHours(0)
              setSelectedMinutes(0)
              setSelectedSeconds(0)
            }}>Reset</button>
          </div>
        </div>}

        {selectedFunction === "stopwatch" && <div className="w-full column center items-center mt-40 pb-40">
          <p style={{ fontWeight: 500, fontSize: 50 }}>{stopwatch}</p>
          <div className="row gap-4">
            <button className="button" style={{ borderRadius: 0, backgroundColor: stopwatchRunning ? "#FF0000" : "#0022FF", color: "white", width: 150 }} onClick={() => {
              if (stopwatchRunning) {
                setStopwatchRunning(false)
              } else {
                setStopwatchEnd(new Date().getTime() / 1000)
                setStopwatchRunning(true)
              }
            }}>{stopwatchRunning ? "Pause" : "Start"}</button>
            <button className="button-delete" style={{ borderRadius: 0, width: 150 }} onClick={() => {
              setStopwatchRunning(false)
              setStopwatchFinished(false)
              setStopwatch("00:00:00.000")
              setStopwatchLap([])
            }}>Reset</button>
            <button className="button" style={{ borderRadius: 0, width: 150 }} onClick={() => {
              let now = new Date().getTime() / 1000
              let timeLeft = now - stopwatchEnd
              let hours = Math.floor(timeLeft / 3600)
              let minutes = Math.floor((timeLeft - (hours * 3600)) / 60)
              let seconds = Math.floor(timeLeft - (hours * 3600) - (minutes * 60))
              let milliseconds = Math.floor((timeLeft - (hours * 3600) - (minutes * 60) - seconds) * 100)

              if (hours < 10) {
                hours = "0" + hours
              }

              if (minutes < 10) {
                minutes = "0" + minutes
              }

              if (seconds < 10) {
                seconds = "0" + seconds
              }

              if (milliseconds < 10) {
                milliseconds = "0" + milliseconds
              }

              setStopwatchLap([...stopwatchLap, { lap: stopwatchLap.length + 1, time: `${hours}:${minutes}:${seconds}.${milliseconds}` }])
            }}>Lap</button>
          </div>
          {stopwatchLap.length > 0 && <div className="row w-full mt-4">
            <div className="w-full column">
              <p style={{ fontWeight: 500, fontSize: 20 }}>Lap</p>
              {stopwatchLap.map((lap, index) => {
                return <p style={{ fontWeight: 500, fontSize: 15 }}>{`${lap.lap}. ${lap.time}`}</p>
              })}
            </div>
          </div>
          }
        </div>}

      </div>
    </UserLayout>
  )
}
