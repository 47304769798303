import React, { useEffect, useState } from 'react'
import { IoAdd, IoClose, IoCloudUpload, IoDownload, IoTrash } from 'react-icons/io5'
import { MdBolt, MdEdit, MdShortText, MdTextSnippet, MdUploadFile } from 'react-icons/md'
import Switch from 'react-switch'
import notify from '../../utils/IANotification'
import config from '../../app-config'
import { isMobile } from '../../utils/helper'

export default function FileUploadAssessmentComponent({ data, options, onOptionChange, onChange, onAnswered, state, userAnswer, item_index }) {
    const [filesToUpload, setFilesToUpload] = useState([])
    const [width, setWidth] = useState(window.innerWidth)

    const submitDocuments = async () => {
        let formData = new FormData()
        for (let i = 0; i < filesToUpload.length; i++) {
            formData.append("files", filesToUpload[i])
        }
        let endpoint = config.endpoint + "/file/assessments/attempt"
        let fetchResponse = await fetch(endpoint, {
            method: "POST",
            body: formData,
            credentials: "include"
        })
        let data = await fetchResponse.json()
        if (data.status === 200) {
            onAnswered(data.data.files)
            notify({ message: "Files uploaded successfully", type: "success" })
            setFilesToUpload([])
        } else {
            // error
            notify({ message: data.message, type: "error" })
        }
    }

    useEffect(() => {
        let fileInput = document?.getElementById(`file-input-${item_index}`)
        let fileInputContainer = document?.getElementById(`file-input-container-${item_index}`)
        if (!fileInput || !fileInputContainer) return
        fileInput.addEventListener("dragover", (e) => {
            // add a class hover
            e.preventDefault()
            e.stopPropagation()
            fileInputContainer.classList.add("hover")
        })
        fileInput.addEventListener("dragleave", (e) => {
            e.preventDefault()
            e.stopPropagation()
            fileInputContainer.classList.remove("hover")
        })

        fileInput.addEventListener("drop", (e) => {
            e.preventDefault()
            e.stopPropagation()
            fileInputContainer.classList.remove("hover")
            // get the files
            let files = e.dataTransfer.files
            // convert to array
            files = [...files]
            // check if the file is valid
            if (files.length > 0) {
                // set the file input to the files
                fileInput.files = files
                // call the function to upload the files
                setFilesToUpload(files.slice(0, 1))
            }
        })
    }, [])

    return (
        <>
            {state === "builder" && <>
                <p>File Upload</p>
                <div className="w-full row gap-30 right alignEnd">
                    <button className="backgroundLess row gap-4 items-center"
                        onClick={() => {
                            onChange({ ...data, system_graded: 0 })
                            notify({ message: "Requires manual grading", type: "information" })
                        }}
                    >
                        {<>
                            {data?.system_graded == 1 ? <MdBolt size={20} /> : <MdEdit size={20} />}
                            {data?.system_graded == 1 ? "System Graded" : "Manual Grading"}
                        </>}
                    </button>
                </div>
                {data?.system_graded == 1 && <div className='column gap-4 mb-4'>
                    <div className="input-container">
                        <label htmlFor="" className="input-label">Answers</label>
                        <input type="text" className="text-input" value={data?.answers} onChange={(e) => {
                            onChange({ ...data, answers: e.target.value })
                        }} />
                    </div>
                </div>}
                <div className={`${isMobile(width) ? "column" : "row"} gap-4 items-center`}>
                    <div className="input-container">
                        <p className="input-label">Maximum Upload Size</p>
                        <input type="number" className="text-input" value={options?.max_file_size} onChange={(e) => {
                            onOptionChange({ ...options, max_file_size: e.target.value })
                        }} />
                    </div>
                    <div className="input-container">
                        <p className="input-label">Number of Files</p>
                        <input type="number" className="text-input" value={options?.max_files} onChange={(e) => {
                            onOptionChange({ ...options, max_files: e.target.value })
                        }} />
                    </div>
                    {/* <div className="input-container">
                        <p className="input-label">Maximum Upload Size</p>
                        <input type="number" className="text-input" value={options?.max_file_size} onChange={(e) => {
                            onOptionChange({ ...options, max_file_size: e.target.value })
                        }} />
                    </div> */}
                </div>
            </>}
            {state === "question" && <>
                <div className='column gap-4 mb-4'>
                    <div className="input-container" id={`file-input-${item_index}`}>
                        <input className="input" type="file" multiple style={{ display: "none" }} id={`file-tag-${item_index}`} onChange={(e) => {
                            let files = e.target.files
                            files = [...files]
                            if (files.length > 0) {
                                setFilesToUpload(files.slice(0, options?.max_files))
                            }
                        }} />

                        <div className="file-input-container cursor-pointer" id={`file-input-container-${item_index}`} onClick={() => {
                            document.getElementById(`file-tag-${item_index}`).click()
                        }}>
                            <MdUploadFile size={20} color={"black"} />
                            <p className="file-input-label">{filesToUpload.length > 0 ? `${filesToUpload.length} document attached` : "Upload Document"}</p>
                            <p style={{ fontSize: 10 }}>Maximum Upload Size: {options?.max_file_size} GB | Maximum Number of Files: {options?.max_files}</p>
                        </div>
                    </div>
                </div>
                {filesToUpload.length > 0 &&
                    <>
                        <div style={{ display: "flex", flexWrap: "wrap", flexDirection: "row", gap: 10, marginTop: 10 }}>
                            {filesToUpload.map((file, index) => {
                                return (
                                    <div style={{
                                        maxWidth: 200,
                                        backgroundColor: "gray",
                                        display: "flex",
                                        gap: 10,
                                        flexDirection: "row",
                                        alignItems: "center",
                                        padding: "5px 10px",
                                        cursor: "pointer",
                                        borderRadius: 5
                                    }}
                                        onClick={() => {
                                            let newFilesToUpload = filesToUpload
                                            newFilesToUpload.splice(index, 1)
                                            setFilesToUpload([...newFilesToUpload])
                                        }}
                                    >
                                        <p style={{
                                            fontSize: 12,
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                            color: "white"
                                        }}>{file.name}</p>
                                    </div>
                                )
                            })}
                        </div>
                    </>
                }

                {filesToUpload.length > 0 && <div className="row gap-4 right w-full">
                    <button className="backgroundLess row gap-4 items-center" onClick={() => {
                        submitDocuments()
                    }}>
                        <IoCloudUpload size={20} />
                        Upload</button>
                </div>}
            </>}

            {state === "submission" && <>
                {/* <p>{`${config.endpoint}${userAnswer?.file_data?.ext_file_path}`}</p> */}
                {/* <pre>{JSON.stringify(userAnswer, null, 2)}</pre> */}
                <iframe src={`${config.endpoint}${userAnswer?.file_data?.ext_file_path}`} frameborder="0"
                    style={{ width: "100%", height: 700, border: "none" }}
                />

                <button className='button mt-4 row items-center gap-4'>
                    <IoDownload size={20} />
                    <a href={`${config.endpoint}${userAnswer?.file_data?.ext_file_path}`} download className='backgroundLess' style={{ color: "white", textDecoration: "none", }}>
                        Download
                    </a>
                </button>
            </>}
        </>
    )
}
