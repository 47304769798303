import React, { useState, useEffect, useRef } from 'react'
import Navbar from '../components/Navbar'
import UserHeader from '../components/UserHeader'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../utils/AppFunctions'
import config from '../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import AdminLayout from '../layout/AdminLayout'
import { useParams } from 'react-router'
import AdminCourseNavbar from '../components/AdminCourseNavbar'
import notify from '../utils/IANotification'
import { IoAdd, IoCloudDownload, IoCloudUpload, IoPersonAdd, IoTrash } from 'react-icons/io5'
import { MdImportExport } from 'react-icons/md'
import { FaFileExport, FaFileImport } from 'react-icons/fa'
import ModalLayout from '../layout/ModalLayout'
import IAPrompt from '../utils/IAPrompt'
import Paper from '@mui/material/Paper';
import { ViewState, EditingState, IntegratedEditing, } from '@devexpress/dx-react-scheduler';
import {
  Scheduler,
  DayView,
  WeekView,
  Toolbar,
  DateNavigator,
  Appointments,
  AppointmentTooltip,
  TodayButton,
  MonthView,
} from '@devexpress/dx-react-scheduler-material-ui';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs'
import { IoClose } from 'react-icons/io5'

export default function ClassSchedule({ type }) {
  const currentDate = '2018-11-01'
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = useFetchUserData()

  const course_id = new URLSearchParams(window.location.search)?.get("course_id")
  const lesson_id = new URLSearchParams(window.location.search)?.get("lesson_id")
  const class_id = new URLSearchParams(window.location.search)?.get("class_id")
  const [heightOfArea, setHeightOfArea] = useState(window.innerHeight - document.getElementById("admin-header")?.clientHeight - document.getElementById("mid-header")?.clientHeight)
  const [header, setHeader] = useState(document.getElementById("user-header")?.clientHeight)
  const [selectedDate, setSelectedDate] = useState(tidy_date(new Date()))
  const [selectedView, setSelectedView] = useState("day") // day, week, month, custom

  useEffect(() => {
    setHeightOfArea(window.innerHeight - document.getElementById("admin-header")?.clientHeight - document.getElementById("mid-header")?.clientHeight)
    setHeader(document.getElementById("user-header")?.clientHeight)
  }, [window.innerHeight, document.getElementById("admin-header")?.clientHeight, document.getElementById("mid-header")?.clientHeight, document.getElementById("user-header")?.clientHeight])

  function tidy_date(date) {
    let year = date.getFullYear()
    let month = date.getMonth() + 1
    let day = date.getDate()
    if (month < 10) {
      month = `0${month}`
    }
    if (day < 10) {
      day = `0${day}`
    }

    return `${year}-${month}-${day}`
  }


  const [scheduleData, setScheduleData] = useState([
    // { startDate: '2023-05-10T12:00', endDate: '2023-05-10T13:30', title: 'Go to a gym', payload: "hi" },
    // { startDate: '2023-05-10T12:00', endDate: '2023-05-10T13:30', title: 'Go to a gym', payload: "hi" },
    // { startDate: '2023-05-10T09:45', endDate: '2023-05-10T11:00', title: 'Meeting', payload: "hi" },
    // { startDate: '2023-05-10T12:00', endDate: '2023-05-10T13:30', title: 'Go to a gym', payload: "hi" },
  ])
  const [selectedSchedule, setSelectedSchedule] = useState()


  const [classData, setClassData] = useState({
    name: "",
    description: "",
    course_id: "",
    start_datetime: "",
    end_datetime: "",
    is_forever: "0",
    instructions: ""
  })

  let scheduleModel = {
    name: "",
    description: "",
    file_id: "",
    start_datetime: "",
    end_datetime: "",
  }

  const createSchedule = async () => {
    let endpoint = config.endpoint + "/schedule/class/id"
    let fetchResponse = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        ...selectedSchedule,
        class_id,
      })
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      getClassSchedule()
      setScheduleData()
      notify({ message: data.message, type: "success" })
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  const editSchedule = async () => {
    if (!selectedSchedule?.id) return
    let endpoint = config.endpoint + "/schedule/id/" + selectedSchedule?.id
    let fetchResponse = await fetch(endpoint, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        ...selectedSchedule,
      })
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      getClassSchedule()
      setScheduleData()
      notify({ message: data.message, type: "success" })
    } else {
      notify({ message: data.message, type: "error" })
    }
  }


  const fetchClassById = async () => {
    if (class_id === "new") return
    if (!class_id) return
    let endpoint = config.endpoint + "/class/id/" + class_id
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      setClassData(data.data)
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  const fetchScheduleById = async () => {
    if (!scheduleData?.id) return
    let endpoint = config.endpoint + "/schedule/id/" + scheduleData?.id
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      setClassData(data.data)
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  const getClassSchedule = async () => {
    let endpoint = config.endpoint + "/schedule/class/id/" + class_id
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      let temp = data.data.map((schedule) => {
        return {
          ...schedule,
          startDate: schedule.start_datetime,
          endDate: schedule.end_datetime,
          title: schedule.name,
        }
      })
      setScheduleData(temp)
    } else {
      notify({ message: data.message, type: "error" })
    }
  }


  const deleteSchedule = async () => {
    let endpoint = config.endpoint + "/schedule/id/" + selectedSchedule?.id
    fetch(endpoint, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    }).then((res) => res.json()).then((data) => {
      if (data.status === 200) {
        notify({ message: data.message, type: "success" })
        setSelectedSchedule()
        getClassSchedule()
      } else {
        notify({ message: data.message, type: "error" })
      }
    })
  }

  function uiDate(date) { //day, Date Month Year
    let date_day = date.split("-")[2]
    let date_month = date.split("-")[1]
    let date_year = date.split("-")[0]

    let dateObj = new Date(date_year, date_month - 1, date_day)

    let year = dateObj.getFullYear()
    let month = dateObj.getMonth() + 1
    let day = dateObj.getDate()
    let dayOfWeek = dateObj.getDay()
    let monthName = dateObj.toLocaleString('default', { month: 'long' })
    let dayName = dateObj.toLocaleString('default', { weekday: 'long' })
    return `${dayName}, ${day} ${monthName} ${year}`
  }

  const MyAppointment = ({ children, style, ...restProps }) => {
    return (
      <Appointments.Appointment
        {...restProps}
        style={{
          ...style,
          backgroundColor: "#12344d",
          borderRadius: "10px",
          opacity: selectedSchedule?.id === restProps?.data?.id ? "1" : "0.5",
        }}
        onClick={() => {
          setSelectedSchedule(restProps.data)

        }}
      >
        {children}
      </Appointments.Appointment>
    );
  }


  useEffect(() => {
    fetchScheduleById()
  }, [selectedSchedule])

  useEffect(() => {
    fetchClassById()
    getClassSchedule()
  }, [])

  return (
    <>
      {!selectedSchedule && <button className="button gap items-center" style={{ position: "fixed", bottom: 30, right: 30, zIndex: 6, display: "flex", padding: "10px 15px", borderRadius: "20px" }}
        onClick={() => {
          setSelectedSchedule({
            ...scheduleModel,
          })
        }}
      >
        <IoAdd />
        <span>Schedule</span>
      </button>}
      <div className="component row gap items-center justify-between w-full" id='mid-header' style={{}}>
        <div className="row items-center gap">
          <button className="button-cta"
            onClick={() => {
              setSelectedDate(tidy_date(new Date()))
            }}
          >Today</button>
        </div>
        <div className="row gap-4 items-center">
          <button className="backgroundLess"
            onClick={() => {
              let date = selectedDate
              let date_day = date.split("-")[2]
              let date_month = date.split("-")[1]
              let date_year = date.split("-")[0]
              let dateObj = new Date(date_year, date_month - 1, date_day)
              dateObj.setDate(dateObj.getDate() - 1)
              setSelectedDate(tidy_date(dateObj))
              // setSelectedSchedule({
              //   start_datetime: dateObj,
              //   end_datetime: dateObj,
              // })
            }}
          >
            {<BsChevronLeft size={20} />}
          </button>
          <p>{uiDate(selectedDate)}</p>
          <button className="backgroundLess"
            onClick={() => {
              let date = selectedDate
              let date_day = date.split("-")[2]
              let date_month = date.split("-")[1]
              let date_year = date.split("-")[0]
              let dateObj = new Date(date_year, date_month - 1, date_day)
              dateObj.setDate(dateObj.getDate() + 1)
              setSelectedDate(tidy_date(dateObj))
              // setSelectedSchedule({
              //   start_datetime: dateObj,
              //   end_datetime: dateObj,
              // })
            }}
          >
            {<BsChevronRight size={20} />}
          </button>
        </div>

        <div className="row items-center">
          <button className="backgroundLess pr-20 pl-20 pt-10 pb-10" style={selectedView === "day" ? {
            backgroundColor: "var(--primary)",
            color: "white",
            border: "1px solid var(--primary)",
          } : {
            backgroundColor: "white",
            color: "black",
            border: "1px solid var(--primary)"
          }}
            onClick={() => {
              setSelectedView("day")
            }}
          >
            <p style={{ fontSize: 13 }}>Day</p>
          </button>
          <button className="backgroundLess pr-20 pl-20 pt-10 pb-10" style={selectedView === "week" ? {
            backgroundColor: "var(--primary)",
            color: "white",
            border: "1px solid var(--primary)",
          } : {
            backgroundColor: "white",
            color: "black",
            border: "1px solid var(--primary)"
          }}
            onClick={() => {
              setSelectedView("week")
            }}
          >
            <p style={{ fontSize: 13 }}>Week</p>
          </button>
          <button className="backgroundLess pr-20 pl-20 pt-10 pb-10" style={selectedView === "month" ? {
            backgroundColor: "var(--primary)",
            color: "white",
            border: "1px solid var(--primary)",
          } : {
            backgroundColor: "white",
            color: "black",
            border: "1px solid var(--primary)"
          }}
            onClick={() => {
              setSelectedView("month")
            }}
          >
            <p style={{ fontSize: 13 }}>Month</p>
          </button>
          <button className="backgroundLess pr-20 pl-20 pt-10 pb-10" style={selectedView === "custom" ? {
            backgroundColor: "var(--primary)",
            color: "white",
            border: "1px solid var(--primary)",
          } : {
            backgroundColor: "white",
            color: "black",
            border: "1px solid var(--primary)"
          }}
            onClick={() => {
              setSelectedView("custom")
            }}
          >
            <p style={{ fontSize: 13 }}>Custom</p>
          </button>
          {selectedView === "custom" &&
            <input type="date" className='text-input ml-20'
              value={selectedDate}
              onChange={(e) => {

                let date = e.target.value
                let date_day = date.split("-")[2]
                let date_month = date.split("-")[1]
                let date_year = date.split("-")[0]
                let dateObj = new Date(date_year, date_month - 1, date_day)
                setSelectedDate(tidy_date(dateObj))
              }}
            />
          }
        </div>
      </div>

      <div className="row gap-4 items-start">
        <Paper>
          <Scheduler
            data={scheduleData}
            height={heightOfArea}
          >
            <ViewState currentDate={selectedDate ? selectedDate : currentDate} />
            {(selectedView === "day" || selectedView === "custom") && <DayView startDayHour={0} endDayHour={24} />}
            {(selectedView === "month" || selectedView === "custom") && <MonthView />}
            {(selectedView === "week" || selectedView === "custom") && <WeekView startDayHour={0} endDayHour={24} />}

            <Appointments appointmentComponent={MyAppointment} />
          </Scheduler>
        </Paper>
        {selectedSchedule &&
          <div className='card' style={{ height: heightOfArea, position:"sticky", top:header+10, }}>
            <div className="row items-center w-full justify-between">
              <p className="card-title">{selectedSchedule?.name ? selectedSchedule?.name : "Schedule"}</p>
              <button className="backgroundLess row gap-4 items-center" onClick={() => {
                setSelectedSchedule()
              }}>
                <IoClose size={20} />
                <p style={{ fontSize: 13 }}>Close</p>
              </button>
            </div>
            <div className="input-container">
              <label className="input-label">Name</label>
              <input type="text" className='text-input'
                value={selectedSchedule?.name}
                disabled={selectedSchedule?.schedule_type === "assessment"}
                onChange={(e) => {
                  setSelectedSchedule({ ...selectedSchedule, name: e.target.value })
                }}
              />
            </div>
            <div className="input-container">
              <label className="input-label">Start</label>
              <input type="datetime-local" className='text-input'
                value={selectedSchedule?.start_datetime}
                disabled={selectedSchedule?.schedule_type === "assessment"}
                onChange={(e) => {
                  setSelectedSchedule({ ...selectedSchedule, start_datetime: e.target.value })
                }}
              />
            </div>
            <div className="input-container">
              <label className="input-label">End</label>
              <input type="datetime-local" className='text-input'
                value={selectedSchedule?.end_datetime}
                disabled={selectedSchedule?.schedule_type === "assessment"}
                onChange={(e) => {
                  setSelectedSchedule({ ...selectedSchedule, end_datetime: e.target.value })
                }}
              />
            </div>

            <div className="input-container">
              <label className="input-label">Description</label>
              <textarea type="text" className='textarea-input'
                value={selectedSchedule?.description}
                disabled={selectedSchedule?.schedule_type === "assessment"}
                onChange={(e) => {
                  setSelectedSchedule({ ...selectedSchedule, description: e.target.value })
                }}
              />
            </div>


            <div>
              {selectedSchedule?.schedule_type !== "assessment" &&
                <>
                  <button className="button mt-4 w-full"
                    onClick={() => {
                      if (selectedSchedule?.id) {
                        editSchedule()
                      } else {
                        createSchedule()
                      }
                    }}
                  >{selectedSchedule?.id ? "Save" : "Create"}</button>
                  {selectedSchedule?.id &&
                    <button className="button mt-4 w-full" style={{ backgroundColor: "red" }}
                      onClick={() => {
                        IAPrompt({
                          title: "Delete Schedule",
                          message: "Are you sure you want to delete this schedule?",
                          buttons: [
                            {
                              label: "Cancel",
                              onClick: () => { }
                            },
                            {
                              label: "Delete",
                              onClick: () => {
                                deleteSchedule()
                              }
                            }
                          ]
                        })
                      }}
                    >Delete</button>
                  }
                </>
              }
            </div>
          </div>
        }
      </div>
    </>
  )
}
