import React, { useState, useEffect } from 'react'
import Navbar from '../../components/Navbar'
import UserHeader from '../../components/UserHeader'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import AdminLayout from '../../layout/AdminLayout'
import { useParams } from 'react-router'
import AdminCourseNavbar from '../../components/AdminCourseNavbar'
import notify from '../../utils/IANotification'
import Switch from 'react-switch'
import { MdUploadFile } from 'react-icons/md'
import IAPrompt from '../../utils/IAPrompt'
import { isMobile } from '../../utils/helper'

export default function AdminCourseMaterialsFormPage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("staff")
  let user_data = useFetchUserData()

  const course_id = new URLSearchParams(window.location.search)?.get("course_id")
  const lesson_id = new URLSearchParams(window.location.search)?.get("lesson_id")
  const class_id = new URLSearchParams(window.location.search)?.get("class_id")
  const material_id = new URLSearchParams(window.location.search)?.get("material_id")
  const [width, setWidth] = useState(window.innerWidth);


  const [materialsData, setMaterialsData] = useState({
    name: "",
    allow_download: "1",
  })

  const getMaterialById = async () => {
    if (!material_id) return
    if (material_id === "new") return
    let endpoint = config.endpoint + "/materials/id/" + material_id
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      setMaterialsData(data.data)
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  useEffect(() => {
    getMaterialById()
  }, [])


  const [filesToUpload, setFilesToUpload] = useState([])

  const submitDocuments = async () => {
    let formData = new FormData()
    formData.append("course_id", course_id)
    formData.append("name", materialsData.name)
    formData.append("allow_download", materialsData.allow_download)
    for (let i = 0; i < filesToUpload.length; i++) {
      formData.append("files", filesToUpload[i])
    }
    let endpoint = config.endpoint + "/materials/"
    let fetchResponse = await fetch(endpoint, {
      method: "POST",
      body: formData,
      credentials: "include"
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      notify({ message: "Files uploaded successfully", type: "success" })
      setFilesToUpload([])
    } else {
      // error
      notify({ message: data.message, type: "error" })
    }
  }

  const updateDocuments = async () => {
    let formData = new FormData()
    formData.append("name", materialsData.name)
    formData.append("allow_download", materialsData.allow_download)
    for (let i = 0; i < filesToUpload.length; i++) {
      formData.append("files", filesToUpload[i])
    }
    let endpoint = config.endpoint + "/materials/id/" + material_id
    let fetchResponse = await fetch(endpoint, {
      method: "PUT",
      body: formData,
      credentials: "include"
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      notify({ message: "Files uploaded successfully", type: "success" })
      setFilesToUpload([])
    } else {
      // error
      notify({ message: data.message, type: "error" })
    }
  }

  const deleteFile = async () => {
    if (!material_id) return notify({ message: "Unable to delete material", type: "error" })
    if (material_id === "new") return notify({ message: "Unable to delete material", type: "error" })

    let endpoint = config.endpoint + "/materials/id/" + material_id
    let fetchResponse = await fetch(endpoint, {
      method: "DELETE",
      credentials: "include"
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      notify({ message: "Files deleted successfully", type: "success" })
      navigate(`/admin/courses/materials?course_id=${course_id}`)
    } else {
      // error
      notify({ message: data.message, type: "error" })
    }
  }

  useEffect(() => {
    let fileInput = document?.getElementById(`file-input`)
    let fileInputContainer = document?.getElementById(`file-input-container`)
    if (!fileInput || !fileInputContainer) return
    fileInput.addEventListener("dragover", (e) => {
      // add a class hover
      e.preventDefault()
      e.stopPropagation()
      fileInputContainer.classList.add("hover")
    })
    fileInput.addEventListener("dragleave", (e) => {
      e.preventDefault()
      e.stopPropagation()
      fileInputContainer.classList.remove("hover")
    })

    fileInput.addEventListener("drop", (e) => {
      e.preventDefault()
      e.stopPropagation()
      fileInputContainer.classList.remove("hover")
      // get the files
      let files = e.dataTransfer.files
      // convert to array
      files = [...files]
      // check if the file is valid
      if (files.length > 0) {
        // set the file input to the files
        fileInput.files = files
        // call the function to upload the files
        setFilesToUpload(files.slice(0, 1))
      }
    })
  }, [])

  return (
    <AdminLayout navSelected={"Courses"}>
      <p className='mb-4' style={{ fontSize: 30, fontWeight: "500" }}>Materials</p>
      {course_id != "new" && <AdminCourseNavbar selected={"Materials"} course_id={course_id} />}
      <div className="card rounded-md shadow-md mt-4 p-4">
        <div className={`row items-center justify-between`}>
          <p className="card-title">Material Details</p>
        </div>
        <div className={`${isMobile(width)?"column":"row"}  gap-4`}>
          <div className="input-container">
            <p className="input-label">Name</p>
            <input type="text" className="text-input" required value={materialsData.name} onChange={(e) => {
              let temp = { ...materialsData }
              temp.name = e.target.value
              setMaterialsData(temp)
            }} />
          </div>
          <div className="row gap-4 items-center">
            <Switch onChange={(e) => {
              let temp = { ...materialsData }
              temp.allow_download = (e ? 1 : 0)
              setMaterialsData(temp)
            }} checked={materialsData.allow_download == 1} />
            <p className="input-label">Allow Download</p>
          </div>
        </div>
        <div className="input-container mt-4" id={`file-input`}>
          <p className="input-label">File(s)</p>
          <input className="input" type="file" multiple style={{ display: "none" }} id={`file-tag`} onChange={(e) => {
            let files = e.target.files
            files = [...files]
            if (files.length > 0) {
              setFilesToUpload(files.slice(0, 1))
            }
          }} />

          <div className="file-input-container cursor-pointer" id={`file-input-container`} onClick={() => {
            document.getElementById(`file-tag`).click()
          }}>
            <MdUploadFile size={20} color={"black"} />
            <p className="file-input-label">{filesToUpload.length > 0 ? `${filesToUpload.length} material attached` : "Upload Materials"}</p>
          </div>

          {filesToUpload.length > 0 &&
            <>
              <div style={{ display: "flex", flexWrap: "wrap", flexDirection: "row", gap: 10, marginTop: 10 }}>
                {filesToUpload.map((file, index) => {
                  return (
                    <div style={{
                      maxWidth: 200,
                      backgroundColor: "gray",
                      display: "flex",
                      gap: 10,
                      flexDirection: "row",
                      alignItems: "center",
                      padding: "5px 10px",
                      cursor: "pointer",
                      borderRadius: 5
                    }}
                      onClick={() => {
                        let newFilesToUpload = filesToUpload
                        newFilesToUpload.splice(index, 1)
                        setFilesToUpload([...newFilesToUpload])
                      }}
                    >
                      <p style={{
                        fontSize: 12,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        color: "white"
                      }}>{file.name}</p>
                    </div>
                  )
                })}
              </div>
            </>
          }
        </div>


        <div className="row right mt-4 w-full">
          <div className="row gap-4">
            {material_id === "new" ?
              <button className="button"
                onClick={() => {
                  navigate(`/admin/courses/materials?course_id=${course_id}`)
                }}
              >Cancel</button> :
              <button className="button-delete"
                onClick={() => {
                  IAPrompt({
                    title: "Delete Material",
                    message: "Are you sure you want to delete this material?",
                    buttons: [

                      {
                        label: "No",
                        onClick: () => { }
                      },
                      {
                        label: "Yes",
                        onClick: () => {
                          deleteFile()
                        }
                      },
                    ]
                  })
                }}
              >Delete</button>}
            <button className="button"
              onClick={() => {
                if (material_id === "new") {
                  return submitDocuments()
                } else {
                  return updateDocuments()
                }
              }}
            >Save</button>
          </div>
        </div>
      </div >
    </AdminLayout >
  )
}
