import React, { useState, useEffect } from 'react'
import Navbar from '../../components/Navbar'
import UserHeader from '../../components/UserHeader'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import AdminLayout from '../../layout/AdminLayout'
import { useParams } from 'react-router'
import notify from '../../utils/IANotification'
import { tidyDate } from '../../utils/helper'

export default function AdminAnnouncementPage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("staff")
  let user_data = useFetchUserData()
  const [filter, setFilter] = useState("")
  const { course_id } = useParams()

  const [announcementData, setAnnouncementData] = useState([])

  const fetchAllAnnouncement = async () => {
    let endpoint = config.endpoint + "/announcement/"
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      setAnnouncementData(data.data)
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  useEffect(() => {
    fetchAllAnnouncement()
  }, [])




  return (
    <AdminLayout navSelected={"Announcements"}>
      <p className='mb-4' style={{ fontSize: 30, fontWeight: "500" }}>Announcements</p>
      <div className="input-container">
        <input type="text" className="text-input" placeholder="Search..." value={filter} onChange={(e) => setFilter(e.target.value)} />
      </div>
      {announcementData?.length > 0 &&
        <>
          {announcementData.map((data, index) => {
            let bgColor = data?.is_published == 1 ? "var(--green)" : "var(--primary)"
            let fgColor = data?.is_published == 1 ? "white" : "white"
            if (filter && !JSON.stringify(data).toLowerCase().includes(filter.toLowerCase())) return null
            return (
              <div className='row justify-between gap-20 items-center w-full mt-4 card cursor-pointer' 
              style={{ borderLeft: "4px solid var(--primary)", borderTopLeftRadius: "5px", borderBottomLeftRadius: "5px", borderRadius: "5px", paddingLeft: 10 }}
                key={index}
                onClick={() => {
                  navigate(`/admin/announcements/form?announcement_id=${data?.id}`)
                }}
              >
                <div className="column">
                  <p className="card-title">{data?.title}</p>
                  <p className="card-text" style={{color:"gray"}}>{tidyDate(data?.start_datetime)}</p>
                </div>
                <p
                  style={{ fontSize: 12, color: fgColor, backgroundColor: bgColor, padding: "5px 10px", borderRadius: "5px" }}
                >{data?.is_published == 1 ? "Published" : "Draft"}</p>
              </div>
            )
          })}
        </>
      }
    </AdminLayout>
  )
}
