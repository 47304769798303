import React, { useState, useEffect } from 'react'
import Navbar from '../../components/Navbar'
import UserHeader from '../../components/UserHeader'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import UserLayout from '../../layout/UserLayout'
import { useParams } from 'react-router'
import Editor from "@monaco-editor/react";
import { IoCode } from 'react-icons/io5'
import notify from '../../utils/IANotification'


export default function CodeEditorPage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = useFetchUserData()

  const { course_id } = useParams()

  const [selectedLangauge, setSelectedLangauge] = useState("javascript")
  const [code, setCode] = useState("")
  const [output, setOutput] = useState("")
  const [codestackApiKey, setCodestackApiKey] = useState("")

  useEffect(() => {
    fetch(`${config.endpoint}/code/api_key`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${user_data?.token}`
      },
    }).then((response) => response.json())
      .then((data) => {
        if (data?.status === 200) {
          setCodestackApiKey(data?.data[0].api_key)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  const LANGUAGES = [
    { name: "JavaScript", value: "javascript", extension: "js" },
    { name: "Python", value: "python", extension: "py" },
    { name: "C", value: "c", extension: "c" },
    // { name: "TypeScript", value: "typescript", extension: "ts" },
    // { name: "Java", value: "java", extension: "java" },
    // { name: "C++", value: "cpp", extension: "cpp" },
    // { name: "C#", value: "csharp", extension: "cs" },
    // { name: "Go", value: "go", extension: "go" },
    // { name: "Ruby", value: "ruby", extension: "rb" },
    // { name: "Rust", value: "rust", extension: "rs" },
    // { name: "Swift", value: "swift", extension: "swift" },
    // { name: "Kotlin", value: "kotlin", extension: "kt" },
    // { name: "PHP", value: "php", extension: "php" },
    // { name: "SQL", value: "sql", extension: "sql" },
    // { name: "Plain Text", value: "plaintext", extension: "txt" },
  ]


  const submitCode = async () => {
    setOutput("")
    let endpoint = config.endpoint + "/code/run"
    let data = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        code: code,
        language: selectedLangauge
      }),
    }).then((response) => response.json())
      .catch((err) => {
        console.log(err)
        notify({ message: "Something went wrong", type: "error" })
      })

    if (data?.status === 200) {
      if (data?.data?.error) {
        setOutput(data?.data?.error)
      } else {
        setOutput(data?.data?.output)
      }
    } else {
      notify({ message: data?.message, type: "error" })
    }
  }


  return (
    <UserLayout navSelected={"Home"} defHeight={"calc(100% - 52px)"} defWidth={"100%"}>
      {codestackApiKey && (
        <iframe
          src={`${config.codestack_url}?api_key=${codestackApiKey}&api_url=${config.codestack_api_url}`}
          className="w-full"
          style={{
            flexGrow: 1,
            // width: "calc(100vw - 300px)"
          }}
        ></iframe>
      )}
    </UserLayout>
  )
}
