import React, { useEffect, useState } from 'react'
import Navbar from '../../components/Navbar'
import UserHeader from '../../components/UserHeader'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import AdminLayout from '../../layout/AdminLayout'
import notify from '../../utils/IANotification'
import Switch from 'react-switch'
import { MdAdd, MdDelete, MdTableView } from 'react-icons/md'
import ModalLayout from '../../layout/ModalLayout'
import { IoSchool } from 'react-icons/io5'
import Select from 'react-select'
import IAPrompt from '../../utils/IAPrompt'
import { isMobile } from '../../utils/helper'

export default function AdminFamilyFormPage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("staff")
  let user_data = useFetchUserData()
  let family_id = new URLSearchParams(window.location.search)?.get("family_id")
  const [adminHeader, setAdminHeader] = useState(document?.getElementById("user-header")?.clientHeight)
  const [selectedType, setSelectedType] = useState("")
  const [showModal, setShowModal] = useState(false)

  const [width, setWidth] = useState(window.innerWidth)

  useEffect(() => {
    setAdminHeader(document?.getElementById("user-header")?.clientHeight)
  }, [adminHeader])


  const [familyData, setFamilyData] = useState({
    family_name: "",
    internal_notes: "",
  })

  const [parentData, setParentData] = useState([
    {
      first_name: "",
      last_name: "",
      email: "",
      contact_number: "",
      is_primary: 1,
      remarks: ""
    }
  ]) // {first_name, last_name, email, contact_number, is_primary}

  const [studentData, setStudentData] = useState([
    {
      first_name: "",
      last_name: "",
      email: "",
      contact_number: "",
      date_of_birth: "",
      remarks: ""
    }
  ]) // {first_name, last_name, email, contact_number, is_primary}

  const [allStudentsData, setAllStudentsData] = useState([])

  const createFamily = async () => {
    let endpoint = config.endpoint + "/family/"
    let fetchResponse = await fetch(endpoint, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        family_data: familyData,
        parents_data: parentData,
        students_data: studentData
      })
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      window.location.href = "/admin/family/details?family_id=" + data.data.id
    } else {
      notify({ message: data.message, type: "error" })
    }
  }


  const editFamily = async () => {
    if (!family_id) return
    let endpoint = config.endpoint + "/family/id/" + family_id
    let fetchResponse = await fetch(endpoint, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        family_data: familyData,
        parents_data: parentData,
        students_data: studentData
      })
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      notify({ message: "Family Updated", type: "success" })
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  const deleteFamily = async () => {
    if (!family_id) return
    let endpoint = config.endpoint + "/family/id/" + family_id
    let fetchResponse = await fetch(endpoint, {
      method: "DELETE",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      },
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      navigate(-1)
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  const fetchFamilyById = async () => {
    if (!family_id) return
    if (family_id === "new") return
    let endpoint = config.endpoint + "/family/id/" + family_id
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      },
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      setFamilyData(data.data.family_data)
      setParentData(data.data.parents_data)
      setStudentData(data.data.students_data)
    } else {
      notify({ message: data.message, type: "error" })
    }
  }


  useEffect(() => {
    fetchFamilyById()
    fetchAllStudents()
  }, [family_id])

  useEffect(() => {
    if (parentData.length === 1) {
      let temp = [...parentData]
      temp[0].is_primary = 1
      setParentData(temp)
    } else
      if (parentData.length === 0) {
        setParentData([
          {
            first_name: "",
            last_name: "",
            email: "",
            contact_number: "",
            is_primary: 1
          }
        ])
      }
  }, [parentData.length])


  useEffect(() => {
    if (studentData.length === 0) {
      setStudentData([
        {
          first_name: "",
          last_name: "",
          email: "",
          contact_number: "",
          date_of_birth: "",
          remarks: ""
        }
      ])
    }
  }, [studentData.length])


  const fetchAllStudents = async () => {
    let endpoint = config.endpoint + "/students/"
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      },
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      setAllStudentsData(data.data)
    } else {
      notify({ message: data.message, type: "error" })
    }
  }


  const NewStudentModal = () => {
    return (
      <ModalLayout
        modalTitle={"Add Student to family"}
        isVisible={showModal}
        setIsVisible={setShowModal}
      >
        {selectedType === "" && <div className="row gap-4 mt-4" style={{ width: "100%" }}>
          <button
            className="button items-center row gap-4" style={{ width: "100%" }}
            onClick={() => {
              let temp = [...studentData]
              temp.push({
                first_name: "",
                last_name: "",
                email: "",
                contact_number: "",
                date_of_birth: "",
                remarks: ""
              })
              setStudentData(temp)
              setShowModal(false)
              setSelectedType("new")
            }}
          >
            <IoSchool size={20} />
            New Student
          </button>
          <button
            className="button items-center row gap-4" style={{ width: "100%" }}
            onClick={() => {
              setSelectedType("existing")
            }}
          >
            <MdTableView size={20} />
            Existing Student
          </button>
        </div>}
        {selectedType === "existing" && <>
          <div className="input-container">
            <label htmlFor="" className="input-label">Student</label>
            <Select
              options={allStudentsData.map((student) => {
                return {
                  value: student,
                  label: `${student.first_name} ${student.last_name} - ${student.email}`
                }
              })}
              onChange={(e) => {
                let temp = [...studentData]
                temp.push({
                  ...e.value,
                  first_name: e.value.first_name,
                  last_name: e.value.last_name,
                  email: e.value.email,
                  contact_number: e.value.contact_number,
                  date_of_birth: e.value.date_of_birth,
                  remarks: ""
                })
                setStudentData(temp)
                setShowModal(false)
              }}
            />
          </div>
        </>}
      </ModalLayout>
    )
  }


  return (
    <AdminLayout navSelected={"Families"} noParentPadding defHeight={`calc(100vh - ${adminHeader}px)`}>
      {showModal && <NewStudentModal />}
      <div className="row gap-4 items-start" style={{ height: "100%", overflow: "scroll" }}>
        {/* Left */}
        {!isMobile(width) && <div className='card' style={{ height: "100%", width: "300px", position: "sticky", top: "0px", }} >
          <p className="text-slate-500 font-semibold text-md">FAMILY</p>
          <p style={{ fontSize: 15, color: "black" }} className='hover-underline cursor-pointer'
            onClick={() => {
              // Scroll to to id=family-info
              let element = document.getElementById("family-info")
              element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })
            }}
          >• Family Info</p>

          <p className="text-slate-500 font-semibold text-md mt-4">PARENTS</p>
          {parentData.map((parent, index) => {
            return (
              <p key={index} style={{ fontSize: 15, color: "black" }} className='hover-underline cursor-pointer'
                onClick={() => {
                  // Scroll to to id=family-info
                  let element = document.getElementById(`parent-${index}`)
                  element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })
                }}
              >{parent?.first_name ? `• ${parent?.first_name} ${parent?.last_name}` : `• Parent ${index + 1}`}</p>
            )
          })}

          <p className="text-slate-500 font-semibold text-md mt-4">STUDENTS</p>
          {studentData.map((student, index) => {
            return (
              <p key={index} style={{ fontSize: 15, color: "black" }} className='hover-underline cursor-pointer'
                onClick={() => {
                  // Scroll to to id=family-info
                  let element = document.getElementById(`student-${index}`)
                  element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })
                }}
              >{student?.first_name ? `• ${student?.first_name} ${student?.last_name}` : `• Student ${index + 1}`}</p>
            )
          })}
        </div>}

        {/* Right */}
        <div style={{ padding: 10, width: "100%" }}>
          <p className='mb-4' style={{ fontSize: 30, fontWeight: "500" }}>{family_id === "new" ? "New Family" : "Edit Family"}</p>
          <div className="mt-4 card rounded-md shadow-md p-4" id='family-info'>
            <p className="card-title">Family Information</p>
            <div className="row gap-4 items-center">
              <div className="input-container">
                <label htmlFor="" className="input-label">Family Name</label>
                <input type="text" className="text-input" value={familyData.family_name} onChange={(e) => setFamilyData({ ...familyData, family_name: e.target.value })} />
              </div>
            </div>
            <div className="input-container">
              <label htmlFor="" className="input-label">Internal Notes</label>
              <textarea className="textarea-input" value={familyData.internal_notes} onChange={(e) => setFamilyData({ ...familyData, internal_notes: e.target.value })} />
            </div>
          </div>
          <div className="row gap-20 items-center mt-30 mb-30">
            <div style={{ width: "100%", height: 2, backgroundColor: "gray", marginBottom: 10, marginTop: 10 }} />
            <p style={{ color: "gray", fontWeight: "600", letterSpacing: 2 }}>PARENTS</p>
            <div style={{ width: "100%", height: 2, backgroundColor: "gray", marginBottom: 10, marginTop: 10 }} />
          </div>
          <div className="card rounded-md shadow-md p-4 mt-4">
            {parentData.map((parent, index) => {
              return (
                <>
                  <div className="row items-center justify-between mt-4">
                    <p className="card-title"
                      id={`parent-${index}`}
                    >Parent {index + 1}</p>
                    <div className="row gap-4 items-center ">
                      {parentData?.length > 1 && <button className="button-delete backgroundLess row gap-4 items-center"
                        onClick={() => {
                          let temp = [...parentData]
                          temp.splice(index, 1)
                          setParentData(temp)
                        }}
                      >
                        <MdDelete style={{ fontSize: 18 }} />
                        Delete</button>}
                      <button className="button backgroundLess row gap-1 items-center"
                        style={{ backgroundColor: "var(--green)", color: "white" }}
                        onClick={() => {
                          let temp = [...parentData]
                          temp.push({
                            first_name: "",
                            last_name: "",
                            email: "",
                            contact_number: "",
                            is_primary: 0
                          })
                          setParentData(temp)
                        }}
                      >
                        <MdAdd style={{ fontSize: 18 }} />
                        Add</button>
                    </div>
                  </div>
                  <div className="w-full column right alignEnd mt-4">
                    {/* <div className="row gap-4 items-center">
                      <label htmlFor="" className="input-label">Primary Contact</label>
                      <Switch checked={parentData[index].is_primary == 1} onChange={(e) => {
                        let temp = [...parentData]
                        for (let i = 0; i < temp.length; i++) {
                          temp[i].is_primary = 0
                        }

                        if (!e) {
                          temp[0].is_primary = 1
                          setParentData(temp)
                          return
                        }

                        temp[index].is_primary = e ? 1 : 0
                        setParentData(temp)
                      }} />
                    </div> */}
                  </div>
                  <div className={`${isMobile(width) ? "column" : "row"}  gap-4 items-center`}>
                    <div className="input-container">
                      <label htmlFor="" className="input-label">First Name</label>
                      <input type="text" className="text-input" value={parentData[index]?.first_name} onChange={(e) => {
                        let temp = [...parentData]
                        temp[index].first_name = e.target.value
                        setParentData(temp)
                      }} />
                    </div>
                    <div className="input-container">
                      <label htmlFor="" className="input-label">Last Name</label>
                      <input type="text" className="text-input" value={parentData[index]?.last_name} onChange={(e) => {
                        let temp = [...parentData]
                        temp[index].last_name = e.target.value
                        setParentData(temp)
                      }} />
                    </div>
                  </div>
                  <div className={`${isMobile(width) ? "column" : "row"}  gap-4 items-center`}>
                    <div className="input-container">
                      <label htmlFor="" className="input-label">Email</label>
                      <input type="text" className="text-input" value={parentData[index]?.email} onChange={(e) => {
                        let temp = [...parentData]
                        temp[index].email = e.target.value
                        setParentData(temp)
                      }} />
                    </div>
                    <div className="input-container">
                      <label htmlFor="" className="input-label">Contact Number</label>
                      <input type="text" className="text-input" value={parentData[index]?.contact_number} onChange={(e) => {
                        let temp = [...parentData]
                        temp[index].contact_number = e.target.value
                        setParentData(temp)
                      }} />
                    </div>
                  </div>
                  <div className="input-container">
                    <label htmlFor="" className="input-label">Remarks</label>
                    <textarea type="text" className="textarea-input" value={parentData[index]?.remarks} onChange={(e) => {
                      let temp = [...parentData]
                      temp[index].remarks = e.target.value
                      setParentData(temp)
                    }} />
                  </div>
                </>
              )
            })}
          </div>

          <div className="row gap-20 items-center mt-30 mb-30">
            <div style={{ width: "100%", height: 2, backgroundColor: "gray", marginBottom: 10, marginTop: 10 }} />
            <p style={{ color: "gray", fontWeight: "600", letterSpacing: 2 }}>STUDENTS</p>
            <div style={{ width: "100%", height: 2, backgroundColor: "gray", marginBottom: 10, marginTop: 10 }} />
          </div>

          <div className="card rounded-md shadow-md p-4 mt-4">
            {studentData.map((student, index) => {
              return (
                <>
                  <div className="row items-center justify-between mt-4">
                    <p className="card-title"
                      id={`student-${index}`}
                    >Student {index + 1}</p>
                    <div className="row gap-4 items-center ">
                      {studentData?.length > 1 && <button className="button-delete backgroundLess row gap-4 items-center"
                        onClick={() => {
                          let temp = [...studentData]
                          temp.splice(index, 1)
                          setStudentData(temp)
                        }}
                      >
                        <MdDelete style={{ fontSize: 18 }} />
                        Delete</button>}
                      <button className="button backgroundLess row gap-1 items-center"
                        style={{ backgroundColor: "var(--green)", color: "white" }}
                        onClick={() => {
                          // let temp = [...studentData]
                          // temp.push({
                          //   first_name: "",
                          //   last_name: "",
                          //   email: "",
                          //   contact_number: "",
                          //   is_primary: 0
                          // })
                          // setStudentData(temp)
                          setShowModal(true)
                          setSelectedType("")
                        }}
                      >
                        <MdAdd style={{ fontSize: 18 }} />
                        Add</button>
                    </div>
                  </div>
                  {/* <pre>{JSON.stringify(studentData[index])}</pre> */}
                  <div className={`${isMobile(width) ? "column" : "row"}  gap-4 items-center`}>
                    <div className="input-container">
                      <label htmlFor="" className="input-label">First Name</label>
                      <input type="text" className="text-input" value={studentData[index].first_name} onChange={(e) => {
                        let temp = [...studentData]
                        temp[index].first_name = e.target.value
                        setStudentData(temp)
                      }} />
                    </div>
                    <div className="input-container">
                      <label htmlFor="" className="input-label">Last Name</label>
                      <input type="text" className="text-input" value={studentData[index].last_name} onChange={(e) => {
                        let temp = [...studentData]
                        temp[index].last_name = e.target.value
                        setStudentData(temp)
                      }} />
                    </div>
                  </div>
                  <div className={`${isMobile(width) ? "column" : "row"}  gap-4 items-center`}>
                    <div className="input-container">
                      <label htmlFor="" className="input-label">Email</label>
                      <input type="text" className="text-input" value={studentData[index].email} onChange={(e) => {
                        let temp = [...studentData]
                        temp[index].email = e.target.value
                        setStudentData(temp)
                      }
                      } />
                    </div>
                    <div className="input-container">
                      <label htmlFor="" className="input-label">Contact Number</label>
                      <input type="text" className="text-input" value={studentData[index].contact_number} onChange={(e) => {
                        let temp = [...studentData]
                        temp[index].contact_number = e.target.value
                        setStudentData(temp)
                      }} />
                    </div>
                    <div className="input-container">
                      <label htmlFor="" className="input-label">Date of Birth</label>
                      <input type="date" className="text-input" value={studentData[index]?.date_of_birth && new Date(studentData[index]?.date_of_birth)?.toISOString()?.split("T")?.[0]} onChange={(e) => {
                        let temp = [...studentData]
                        temp[index].date_of_birth = e.target.value
                        setStudentData(temp)
                      }} />
                    </div>
                  </div>
                  <div className="input-container">
                    <label htmlFor="" className="input-label">Remarks</label>
                    <textarea type="text" className="textarea-input" value={studentData[index]?.remarks} onChange={(e) => {
                      let temp = [...studentData]
                      temp[index].remarks = e.target.value
                      setStudentData(temp)
                    }} />
                  </div>
                </>
              )
            })}
          </div>

          <div className="column right alignEnd mt-4">
            <div className="row gap-4">
              {family_id !== "new" && <button className="button-delete"
                onClick={() => {
                  IAPrompt({
                    title: "Delete Family",
                    message: "Are you sure you want to delete this family?",
                    buttons: [
                      {
                        label: "No",
                        onClick: () => { }
                      },
                      {
                        label: "Yes",
                        onClick: () => {
                          deleteFamily()
                        }
                      },
                    ]
                  })

                }}
              >
                Delete</button>}
              <button className="button"
                onClick={() => {
                  if (family_id === "new") {
                    createFamily()
                  } else {
                    editFamily()
                  }
                }}
              >{family_id === "new" ? "Create" : "Save"}</button>
            </div>
          </div>
        </div>
      </div>
    </AdminLayout>
  )
}
