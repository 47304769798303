import React, { useEffect, useState } from 'react'
import Navbar from '../../components/Navbar'
import UserHeader from '../../components/UserHeader'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import ParentsLayout from '../../layout/ParentsLayout'
import { MdCalendarMonth, MdCalendarViewMonth, MdChildCare, MdFamilyRestroom, MdLiveTv } from 'react-icons/md'
import { tidyDate, tidyDateRaw } from '../../utils/helper'
import AnnouncementModal from '../../components/AnnouncementModal'
import AnnouncementCard from '../../components/AnnouncementCard'
import {isMobile} from '../../utils/helper'

export default function ParentsHomePage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = useFetchUserData()
  const [announcementData, setAnnouncementData] = useState([])
  const [scheduleData, setScheduleData] = useState([])
  const [childData, setChildData] = useState([])
  const [showAnnouncement, setShowAnnouncement] = useState(false)
  const [selectedAnnouncement, setSelectedAnnouncement] = useState({})


  const fetchAnnouncement = async () => {
    let endpoint = config.endpoint + `/announcement/parent/id/`
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      setAnnouncementData(data.data)
    }
  }

  const fetchSchedule = async () => {
    let endpoint = config.endpoint + `/schedule/parent/id/`
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      let schedule_ids = []
      let newData = []

      for (let i = 0; i < data.data.length; i++) {
        if (schedule_ids?.includes(data.data[i]?.id)) continue
        if (new Date(data.data[i]?.start_datetime) < new Date()) continue
        schedule_ids.push(data.data[i]?.id)
        newData.push(data.data[i])
      }
      setScheduleData(newData)
    }
  }

  const fetchFamilyData = async () => {
    let endpoint = config.endpoint + `/family/parent/id/`
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      setChildData(data.data)
    }
  }

  useEffect(() => {
    fetchSchedule()
    fetchAnnouncement()
    fetchFamilyData()
  }, [])

const [width, setWidth] = useState(window.innerWidth)

  return (
    <ParentsLayout navSelected={"Home"}>
      <AnnouncementCard data={selectedAnnouncement} show={showAnnouncement} setShowModal={setShowAnnouncement} />
      <p style={{ fontWeight: "normal", fontSize: 25 }}>Welcome back <span style={{ fontWeight: "600" }}>{user_data?.first_name}</span>,</p>
      <div className={`${isMobile(width)?"column":"row"} gap-4 items-start mt-4`}>
        <div className="card shadow-md rounded-md" style={{ width: isMobile(width) ? "100%":"66%" }}>
          <p className="card-title row gap-4 items-center"><MdLiveTv />Latest from {config.client_name}</p>
          {announcementData?.length > 0 ? announcementData.map((item, index) => {
            if (index > 3) return
            return (
              <div className='p-all row justify-between gap-20 items-center w-full mt-4' style={{ borderLeft: "4px solid var(--primary)", backgroundColor: "white", borderTopLeftRadius: "5px", borderBottomLeftRadius: "5px", borderRadius: "5px", paddingLeft: 10 }}>
                <div className="" style={{ maxHeight: "50px", textOverflow: "ellipsis", overflow: "clip" }}>
                  <p className="card-subtitle" style={{ color: "gray" }}>{tidyDate(item?.start_datetime)}</p>
                  <h5 className="card-title">{item.title}</h5>
                </div>
                <button className="button"
                  onClick={() => {
                    setSelectedAnnouncement(item)
                    setShowAnnouncement(true)
                  }}
                >View</button>
              </div>
            )
          })
            : <p style={{ color: "gray", fontSize: 12 }}>There are no announcements at the moment.</p>
          }
        </div>
        <div className='column gap-4' style={{ width: isMobile(width) ? "100%":"33%" }}>
          <div className="card shadow-md rounded-md">
            <p className="card-title row gap-4 items-center"><MdCalendarMonth />Upcoming Dates</p>
            {scheduleData?.length > 0 ? scheduleData.map((item, index) => {
              if (index > 7) return
              return (
                <div className='p-all row justify-between gap-20 items-center w-full mt-4' style={{ borderLeft: "4px solid var(--primary)", backgroundColor: "white", borderTopLeftRadius: "5px", borderBottomLeftRadius: "5px", borderRadius: "5px", paddingLeft: 10 }}>
                  <div className="row gap-4 items-center">
                    <div>
                      <p className="card-subtitle" style={{ minWidth: 0, textAlign: "center", width: 50 }}>{tidyDateRaw(item?.start_datetime).day}</p>
                      <p className="card-subtitle" style={{ minWidth: 0, textAlign: "center", width: 50 }}>{tidyDateRaw(item?.start_datetime).month}</p>
                    </div>
                    <div className="column gap-4">
                      <div className="" style={{ maxHeight: "50px", textOverflow: "ellipsis", overflow: "clip" }}>
                        <p className="card-subtitle" style={{ color: "gray" }}>{tidyDate(item?.start_datetime)}</p>
                        <h5 className="card-title">{item.title || item.name}</h5>
                      </div>
                    </div>
                  </div>
                  {/* <button className="button"
                    onClick={() => {

                    }}
                  >View</button> */}
                </div>
              )
            })
              : <p style={{ color: "gray", fontSize: 12 }}>There are no upcoming dates at the moment.</p>
            }
          </div>
          <div className="card shadow-md rounded-md">
            <p className="card-title row gap-4 items-center"><MdFamilyRestroom />My Children</p>
            {/* <pre>{JSON.stringify(childData, null, 2)}</pre> */}
            {childData?.members?.length > 0 && childData?.members.map((item, index) => {
              if (item.role !== "student") return
              return (
                <div className="cursor-pointer row gap-30 items-center mt-4">
                  <div style={{
                    padding: 10, backgroundColor: `rgb(${Math.floor(Math.random() * 255)},${Math.floor(Math.random() * 255)},${Math.floor(Math.random() * 255)})`,
                    borderRadius: "50%", width: 70, height: 70
                  }} className='card  shadow-md rounded-md row justifyCenter items-center'>
                    <p style={{ fontSize: 25, fontWeight: 900, color: "white" }}>{item?.user_data?.first_name?.charAt(0)}{item?.user_data?.last_name?.charAt(0)}</p>
                  </div>
                  <div>
                    <p style={{ fontSize: 25, fontWeight: 900, color: "black" }}>{item?.user_data?.first_name} {item?.user_data?.last_name}</p>
                    <p style={{ fontSize: 15, fontWeight: "normal", color: "gray" }}>{item?.user_data?.email}</p>
                  </div>
                </div>
              )
            })}
          </div>
        </div>

      </div>
    </ParentsLayout>
  )
}
