import React, { useEffect, useState } from 'react'
import Navbar from '../../components/Navbar'
import UserHeader from '../../components/UserHeader'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import AdminLayout from '../../layout/AdminLayout'
import { useParams } from 'react-router'
import { tidyDate } from '../../utils/helper'

export default function AdminCertificatePage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("staff")
  let user_data = useFetchUserData()


  const [certificateData, setCertificateData] = useState([])
  const [searchQuery, setSearchQuery] = useState("")

  useEffect(() => {
    fetchSchoolCertificates()
  }, [])

  const fetchSchoolCertificates = async () => {
    let endpoint = `${config.endpoint}/certificate/`
    let response = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application"
      },
      credentials: "include"
    })

    let data = await response.json()
    if (data.status === 200) {
      setCertificateData(data.data)
    }
  }

  const { course_id } = useParams()
  return (
    <AdminLayout navSelected={"Certificates"}>
      <p className='mb-4' style={{ fontSize: 30, fontWeight: "500" }}>Certificates</p>
      <div className="input-container">
        <input type="text" className="text-input" placeholder="Search..." value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
      </div>
      <button className="button"
        onClick={() => navigate("/admin/certificates/details?certificate_id=new")}
      >Issue Cerificate</button>
      <div style={{ width: "100%", marginTop: 10 }}>
        {certificateData.length > 0 ?
          certificateData.map((data, index) => {
            if (searchQuery && !JSON.stringify(data).toLowerCase().includes(searchQuery.toLowerCase())) return null
            return (
              <div key={index} className="card mb-4 row items-center justify-between cursor-pointer"
                onClick={() => {
                  navigate(`/admin/certificates/details?certificate_id=${data.id}`)
                }}
                style={{ borderLeft: "4px solid var(--primary)", borderTopLeftRadius: "5px", borderBottomLeftRadius: "5px", borderRadius: "5px", paddingLeft: 10 }}
              >
                <div>
                  <h5 className="card-title">{data.user_data?.first_name} {data.user_data?.last_name}</h5>
                  <p>{data.course_data?.name} • Issued on {tidyDate(data.createdAt)}</p>
                </div>
              </div>
            )
          })
          :
          <div className="card rounded-md shadow-md p-4 mt-4">
            <h5 className="card-title">No Certificates</h5>
            <p className="card-text">No certificates have been issued yet</p>
          </div>}
      </div>
    </AdminLayout>
  )
}
