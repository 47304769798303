import React, { useState, useEffect, useLayoutEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { authorisationConverter, useFetchAuthorisation } from '../utils/AppFunctions'
import { FaAngleRight, FaAward } from 'react-icons/fa'
import { AiOutlineDashboard } from 'react-icons/ai'
import { MdAccountBalance, MdCalendarToday, MdEditDocument, MdForum, MdGroup, MdOutlineAccountBalance, MdOutlineAirplanemodeActive, MdOutlineEdit, MdOutlineEventNote, MdOutlineFlag, MdOutlineForum } from 'react-icons/md'
import { FiAward, FiBarChart, FiHome } from 'react-icons/fi'
import { isMobile } from '../utils/helper'
import ProfileSwitcher from './ProfileSwitcher'
import config from '../app-config'

export default function ParentsNavbar({ selected, setShowNavbar, showNavbar }) {
    let { isAuthorised, userRole } = useFetchAuthorisation("user")

    const size = 25
    const color = "gray"
    const navigate = useNavigate()
    const [headerHeight, setHeaderHeight] = useState(document?.getElementById("user-header")?.clientHeight)

    useLayoutEffect(() => {
        setHeaderHeight(document?.getElementById("user-header")?.clientHeight)

    }, [])

    const [width, setWidth] = useState(window.innerWidth)

    let items = [
        {
            name: "Home",
            icon: <FiHome size={size} className="text-slate-500" />,
            link: "/parents",
            authorisation: "parent",
            children: []
        },
        {
            name: "Certificates",
            icon: <FiAward size={size} className="text-slate-500" />,
            link: "/parents/certificates",
            authorisation: "parent",
        },
        // {
        //     name: "Progress",
        //     icon: <FiBarChart size={size} className="text-slate-500" />,
        //     link: "/parents/progress",
        //     authorisation: "parent",
        // },
        {
            name: "Feedback & Review",
            icon: <MdOutlineFlag size={size} className="text-slate-500" />,
            link: "/parents/feedback",
            authorisation: "parent",
        },
        {
            name: "Grades",
            icon: <MdOutlineEdit size={size} className="text-slate-500" />,
            authorisation: "parent",
            link: "/parents/grades",
        },
    ]
    return (
        <>
            <div
                style={{ width: `calc(100% - 300px)`, height: "100dvh", position: "fixed", top: 0, right: 0, zIndex: 1000, display: isMobile(width) ? "block" : "none" }}
                onClick={() => {
                    setShowNavbar(false)
                }}
            />
            <div className='navbar' id='navbar' style={{
                height: "100dvh",
                backgroundColor: "white",
                //  height: "100dvh",s
                maxHeight: "100dvh",
                // backgroundColor: "white",s
                position: isMobile(width) && "fixed",
                zIndex: 2000,
                // "#E5F3FE"
            }}>
                <div className='column justify-between items-start' style={{ height: "100%", width: "100%" }}>
                    <div style={{ width: "100%" }}>
                        <div className="row gap-4 pr-20" style={{ padding: 20 }}>
                            <img src={config.images.logo_wo_text} style={{ height: 50, width: 50, objectFit: "contain", }} alt="" />
                            <div>
                                <p style={{ fontWeight: "900", fontSize: 20, color: "var(--primary)" }}>{config.client_title}</p>
                                <p style={{ fontWeight: "600", fontSize: 12, color: "gray", letterSpacing: 2 }}>{config.client_subtitle}</p>
                            </div>
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", padding: 10, width: "100%" }}>
                            {items.map((item, index) => {
                                if (!authorisationConverter({ requirement: item?.authorisation, current_role: userRole })) return null
                                return (
                                    <div className={`navbarItem rounded-md w-full 
                                ${selected === item.name ? "bg-indigo-100" : ""}
                                `}
                                        key={index} id={`navitem-${index}`}>
                                        <div className='navbarItemIcon'
                                            onClick={() => {
                                                navigate(item.link)
                                            }}
                                        >
                                            {item.icon}
                                            <p
                                                className={`text-sm py-1 ${selected === item.name ? "text-indigo-500" : "text-slate-500"}`}
                                            >{item.name}</p>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div style={{ width: "100%" }} className='mb-30' id='profile-button'>
                        {authorisationConverter({ requirement: "parent", current_role: userRole }) &&
                            <ProfileSwitcher />
                        }
                        <p className="text-slate-500 text-xs text-center mt-4">Built by <a href='www.ourcodelab.com' className='coy-website' style={{ textDecoration: "none" }}> OurCodeLab LLP</a></p>
                    </div>
                </div>
            </div>
        </>
    )
}
