import React, { useState, useEffect } from 'react'
import Navbar from '../components/Navbar'
import UserHeader from '../components/UserHeader'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../utils/AppFunctions'
import config from '../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import AdminLayout from '../layout/AdminLayout'
import { useParams } from 'react-router'
import AdminCourseNavbar from '../components/AdminCourseNavbar'
import notify from '../utils/IANotification'
import { IoAdd, IoTrash } from 'react-icons/io5'
import Line from '../components/shapes/Line'
import { MdReorder, MdSave } from 'react-icons/md'
import EditorJSComponent from '../components/lesson/EditorJSComponent'
import TextComponent from '../components/lesson/TextComponent'
import ImageComponent from '../components/lesson/ImageComponent'
import Switch from 'react-switch'
import CodeComponent from '../components/lesson/CodeComponent'
import VideoComponent from '../components/lesson/VideoComponent'
import DocumentComponent from '../components/lesson/DocumentComponent'
import AudioComponent from '../components/lesson/AudioComponent'
import { PiDotsSixVerticalBold } from "react-icons/pi";
import { Draggable } from "react-drag-reorder";
import WebComponent from '../components/lesson/WebComponent'
import IAPrompt from '../utils/IAPrompt'
import { isMobile } from '../utils/helper'

export default function CourseLessonFormPage({ type }) {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = useFetchUserData()

  const course_id = new URLSearchParams(window.location.search)?.get("course_id")
  const lesson_id = new URLSearchParams(window.location.search)?.get("lesson_id")

  const [showPreview, setShowPreview] = useState(type === "staff" ? true : false)
  const [isLoaded, setIsLoaded] = useState(true)
  const [width, setWidth] = useState(window.innerWidth)

  let modal = {
    id: "new",
    name: "",
    type: "text", // text, editor, image, video, audio, file, code
    content: "",
    url: "",
    file_id: "",
    order: 0,
    json_content: {}
  }


  const [lessonData, setLessonData] = useState({
    name: "",
    description: "",
  })
  const [componentData, setComponentData] = useState([
    { ...modal },
  ])

  const createLesson = async () => {
    let endpoint = config.endpoint + "/lesson/"
    let fetchResponse = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        ...lessonData,
        course_id
      })
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      let id = data.data.id
      navigate(`/${type}/courses/lessons/details?course_id=` + course_id + "&lesson_id=" + id)
      window.location.reload()
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  const editLesson = async () => {
    if (lesson_id === "new") return
    let endpoint = config.endpoint + "/lesson/id/" + lesson_id
    let fetchResponse = await fetch(endpoint, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        ...lessonData,
        course_id
      })
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      let id = data.data.id
      notify({ message: data.message, type: "success" })
    } else {
      notify({ message: data.message, type: "error" })
    }
  }


  const deleteLesson = async () => {
    if (lesson_id === "new") return
    let endpoint = config.endpoint + "/lesson/id/" + lesson_id
    let fetchResponse = await fetch(endpoint, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      navigate(`/${type}/courses/lessons?course_id=` + course_id)
      notify({ message: data.message, type: "success" })
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  const saveComponent = async () => {
    let endpoint = config.endpoint + "/lesson/component/"
    let fetchResponse = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        lesson_id,
        course_id,
        component_data: componentData
      })
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      fetchLessonById()
      notify({ message: data.message, type: "success" })
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  const fetchLessonById = async () => {
    if (lesson_id === "new") return
    let endpoint = config.endpoint + "/lesson/id/" + lesson_id
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      setLessonData(data.data)
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  const fetchLessonComponentsById = async () => {
    if (lesson_id === "new") return
    setIsLoaded(false)
    let endpoint = `${config.endpoint}/lesson/component/lesson/id/${lesson_id}`
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      setComponentData(data.data)
      setIsLoaded(true)
      notify({ message: data.message, type: "success" })
    } else {
      notify({ message: data.message, type: "error" })
    }
  }


  useEffect(() => {
    fetchLessonById()
    fetchLessonComponentsById()
  }, [])

  return (
    <>
      <div className="card rounded-md shadow-md p-4 mt-4">
        <div className="input-container">
          <label htmlFor="" className="input-label">Lesson Name</label>
          <input type="text" className="text-input" value={lessonData.name} onChange={(e) => {
            setLessonData({ ...lessonData, name: e.target.value })
          }} />
        </div>
        <div className="input-container">
          <label htmlFor="" className="input-label">Lesson Description</label>
          <textarea type="text" className="textarea-input" value={lessonData.description} onChange={(e) => {
            setLessonData({ ...lessonData, description: e.target.value })
          }} />
        </div>

        {lesson_id == "new" ?
          <div className="button-container">
            <button className="button" onClick={() => {
              createLesson()
            }}>Create Lesson</button>
          </div>
          :
          <>
            <div className="button-container row gap-4 items-center">
              <button className="button-delete"
                onClick={() => {
                  IAPrompt({
                    title: "Delete Lesson",
                    message: "Are you sure you want to delete this lesson?",
                    buttons: [
                      {
                        label: "Cancel",
                        onClick: () => { }
                      },
                      {
                        label: "Delete Lesson",
                        onClick: () => {
                          deleteLesson()
                        }
                      }
                    ]
                  })
                }}
              >
                Delete Lesson
              </button>
              <button className="button" onClick={() => {
                editLesson()
                saveComponent()
              }}>Save</button>
            </div>
          </>
        }
      </div>

      {lesson_id !== "new" && <div className="card rounded-md shadow-md p-4 mt-4">
        <div className={`${isMobile(width) ? "column" : "row items-center"} justify-between  mb-4`}>
          <p className="card-title" style={{ whiteSpace: "nowrap" }}>Lesson Component</p>
          <div className="row gap-20 items-center hide-scrollbars" style={{ overflowX: "scroll" }}>
            <div className="row gap-4 items-center">
              <Switch onChange={() => {
                setShowPreview(!showPreview)
              }} checked={showPreview} />
              <p>Preview</p>
            </div>

            {/* <button className="backgroundLess row items-center gap-4" onClick={() => {

            }}>
              <MdReorder size={15} />
              Reorder</button> */}
            <button className="button row gap-4 items-center" onClick={() => {
              setComponentData([...componentData, { ...modal }])
            }}>
              <IoAdd size={15} />
              Component</button>
          </div>
        </div>
        {/* {isLoaded &&
          <Draggable
            onPosChange={(newPos, oldPos) => {
              let temp = [...componentData]
              let temp2 = temp[oldPos]
              temp[oldPos] = temp[newPos]
              temp[newPos] = temp2
              setComponentData(temp)
            }}
          > */}
        {componentData.map((data, index) => {
          return (
            <div className="mb-4" key={index}>
              <div className='row justify-between gap-20 items-center w-full' style={{ borderLeft: !showPreview && "4px solid var(--primary)", borderTopLeftRadius: "5px", borderBottomLeftRadius: "5px", borderRadius: "5px", paddingLeft: 10 }}>
                {!showPreview && <PiDotsSixVerticalBold size={30} color='black' style={{ cursor: "grab" }} />}
                <div className='w-97'>
                  {!showPreview && <>
                    <div className="row justify-between items-center">
                      <p className="card-title">Component {index + 1}</p>
                      <button className="backgroundLess" onClick={() => {
                        let temp = [...componentData]
                        temp.splice(index, 1)
                        setComponentData(temp)
                      }}>
                        <IoTrash size={20} />
                      </button>
                    </div>
                    <div className="input-container">
                      <label htmlFor="" className="input-label">Name</label>
                      <input type="text" className="text-input" value={data.name} onChange={(e) => {
                        let temp = [...componentData]
                        temp[index].name = e.target.value
                        setComponentData(temp)
                      }} />
                    </div>
                    <div className="input-container">
                      <label htmlFor="" className="input-label">Type</label>
                      <select className="select-input" value={data.type} onChange={(e) => {
                        let temp = [...componentData]
                        temp[index].type = e.target.value
                        setComponentData(temp)
                      }}>
                        <option value="text">Text</option>
                        <option value="code">Code</option>
                        {/* <option value="editor">Editor</option> */}
                        <option value="image">Image</option>
                        <option value="video">Video</option>
                        <option value="audio">Audio</option>
                        <option value="file">File</option>
                        <option value="web">Web Embeded</option>
                      </select>
                    </div>
                  </>}
                  {data.type === "editor" &&
                    <div className="input-container">
                      {showPreview ?
                        <></>
                        : <label htmlFor="" className="input-label">Content</label>}
                      <EditorJSComponent />
                    </div>
                  }

                  {data.type === "text" &&
                    <div className="input-container">
                      {showPreview ?
                        <></>
                        : <label htmlFor="" className="input-label">Content</label>}
                      <TextComponent data={data?.content} readOnly={showPreview} onChange={(value) => {
                        let temp = [...componentData]
                        temp[index].content = value
                        setComponentData(temp)
                      }} />
                    </div>
                  }
                  {data.type === "image" &&
                    <div className="input-container">
                      <ImageComponent data={data} readOnly={showPreview}
                        onChangeFile={(value) => {
                          let temp = [...componentData]
                          temp[index].file_id = value
                          setComponentData(temp)
                        }}
                        onChangeUrl={(value) => {
                          let temp = [...componentData]
                          temp[index].url = value
                          setComponentData(temp)
                        }}
                      />
                    </div>
                  }

                  {data.type === "video" &&
                    <div className="input-container">
                      <VideoComponent id={index} data={data} readOnly={showPreview} onChangeUrl={(value) => {
                        let temp = [...componentData]
                        temp[index].url = value
                        setComponentData(temp)
                      }}
                        onChangeFile={(value) => {
                          let temp = [...componentData]
                          temp[index].file_id = value
                          setComponentData(temp)
                        }}
                      />
                    </div>
                  }

                  {data.type === "file" &&
                    <div className="input-container">
                      <DocumentComponent id={index} data={data} readOnly={showPreview} onChangeUrl={(value) => {
                        let temp = [...componentData]
                        temp[index].url = value
                        setComponentData(temp)
                      }}
                        onChangeFile={(value) => {
                          let temp = [...componentData]
                          temp[index].file_id = value
                          setComponentData(temp)
                        }}
                      />
                    </div>
                  }

                  {data.type === "audio" &&
                    <div className="input-container">
                      <AudioComponent id={index} data={data} readOnly={showPreview} onChangeUrl={(value) => {
                        let temp = [...componentData]
                        temp[index].url = value
                        setComponentData(temp)
                      }}
                        onChangeFile={(value) => {
                          let temp = [...componentData]
                          temp[index].file_id = value
                          setComponentData(temp)
                        }}
                      />
                    </div>
                  }

                  {data.type === "code" &&
                    <div className="input-container">
                      {showPreview ?
                        <></>
                        : <label htmlFor="" className="input-label">Code</label>}
                      <CodeComponent data={data} readOnly={showPreview} options={data?.options}
                        onChange={(value) => {
                          let temp = [...componentData]
                          temp[index].content = value
                          setComponentData(temp)
                        }}
                        onChangeOptions={(value) => {
                          console.log({ value })
                          let temp = [...componentData]
                          temp[index].options = {
                            ...temp[index].options,
                            ...value
                          }
                          setComponentData(temp)
                        }}

                      />

                    </div>
                  }

                  {data.type === "web" &&
                    <div className="input-container">
                      {showPreview ?
                        <></>
                        : <label htmlFor="" className="input-label"></label>}
                      <WebComponent data={data} readOnly={showPreview} onChangeUrl={(value) => {
                        let temp = [...componentData]
                        temp[index].url = value
                        setComponentData(temp)
                      }} />
                    </div>
                  }

                </div>
              </div>
            </div>
          )
        })}

        {/* Button controls */}
        <div className="justify-end w-100 flex flex-row">
          <div className="row gap-2 items-center flex flex-row">
            <button className="button flex flex-row gap-2 items-center" onClick={() => {
              saveComponent()
            }}>
              <MdSave size={15} />
              Save
            </button>
            <button className="button flex flex-row gap-2 items-center" onClick={() => {
              setComponentData([...componentData, { ...modal }])
            }}>
              <IoAdd size={15} />
              Add Component
            </button>
            
          </div>
        </div>

        {/* </Draggable>
        } */}
        <div style={{ height: 200 }} />
      </div>}
    </>
  )
}
