import React, { useState, useEffect } from 'react'
import Navbar from '../components/Navbar'
import UserHeader from '../components/UserHeader'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../utils/AppFunctions'
import config from '../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import TeachersLayout from '../layout/TeachersLayout'
import notify from '../utils/IANotification'
import { MdFlag } from 'react-icons/md'
import IAPrompt from '../utils/IAPrompt'

export default function FeedbackReviewPage({type}) {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = useFetchUserData()
  const [feedbackData, setFeedbackData] = useState([])
  let request_id = new URLSearchParams(window.location.search).get("request_id")
  const [classID, setClassID] = useState("")
  const [studentData, setStudentData] = useState([])
  const [completionData, setCompletionData] = useState([])
  const [filter, setFilter] = useState("")


  const fetchFeedbackById = async () => {
    if (!request_id) return notify({ message: "Unable to fetch review request", type: "error" })
    let endpoint = config.endpoint + `/feedback/schedule/id/${request_id}`
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      setFeedbackData(data.data)
      setClassID(data.data?.class_data?.id)
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  const getStudentsByClassId = async () => {
    if (classID === "new") return
    if (!classID) return
    let endpoint = config.endpoint + "/class/student/class/id/" + classID
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      setStudentData(data.data)
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  const fetchFeedbackCompletion = async () => {
    if (!request_id) return
    let endpoint = config.endpoint + `/feedback/completion/request/id/${request_id}`
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      setCompletionData(data.data)
    }
  }

  const submitFeedback = async () => {
    if (!request_id) return notify({ message: "Unable to mark as complete", type: "error" })
    let endpoint = config.endpoint + `/feedback/submit`
    let fetchResponse = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({ request_id })
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      notify({ message: "Marked as complete", type: "success" })
      navigate(-1)
    } else {
      notify({ message: data.message, type: "error" })
    }

  }

  useEffect(() => {
    fetchFeedbackById()
    fetchFeedbackCompletion()

  }, [])

  useEffect(() => {
    getStudentsByClassId()
  }, [classID])

  return (
    <>
      {/* View All Students in the class that require grading */}
      <div className="row justify-between w-full items-center">
        <p className='mb-4' style={{ fontSize: 30, fontWeight: "500" }}>{feedbackData?.class_data?.name}</p>
        <button className="button"
          onClick={() => {
            IAPrompt({
              title: "Mark as complete",
              message: "Are you sure you want to mark this feedback as complete?",
              buttons: [
                {
                  label: "Cancel",
                  style: "backgroundLess",
                  onClick: () => { }
                },
                {
                  label: "Mark as complete",
                  style: "button",
                  onClick: () => {
                    submitFeedback()
                  }
                }
              ]
            })
          }}
        >
          Mark as complete
        </button>
      </div>
      <div className="mt-4 input-container">
        <input type="text" className="text-input" placeholder="Search for students..." value={filter} onChange={(e) => {
          setFilter(e.target.value)
        }} />
      </div>
      {studentData?.length > 0 ? studentData.map((data, index) => {
        if (filter && !JSON.stringify(data)?.toLowerCase().includes(filter.toLowerCase())) return null
        let completed = completionData?.students?.find((item) => item?.user_id == data?.id).attempt
        let total = completionData?.total_questions
        let percentage = isNaN((parseInt(completed) / parseInt(total)) * 100) ? 0 : (completed / total) * 100
        return (
          <div className='p-all row justify-between gap-20 items-center w-full mt-4 cursor-pointer'
            onClick={() => {
              navigate(`/${type}/feedback/review/form?request_id=${request_id}&student_id=${data?.id}&form_id=${feedbackData?.form_id}`)
            }}
            style={{ borderLeft: "4px solid var(--primary)", backgroundColor: "white", borderTopLeftRadius: "5px", borderBottomLeftRadius: "5px", borderRadius: "5px", paddingLeft: 10 }}>
            <div className="column">
              <p className="card-title">{data?.first_name} {data?.last_name}</p>
              <div className="row items-center gap-20">
                <div className="row gap-4 items-center">
                  <p style={{ color: "gray", fontSize: 12 }}>{data?.email}</p>
                </div>
              </div>
            </div>
            {/* <button className="button"
              onClick={() => {
                navigate(`/staff/feedback/review/form?request_id=${request_id}&student_id=${data?.user_data?.id}`)
              }}
            >View</button> */}
            <div className="row gap-4 items-center">
              <div>
                <progress value={completed} max={total}></progress>
                <p style={{ fontWeight: "900", color: "gray", fontSize: 12 }}>{percentage?.toFixed(2)}%</p>
              </div>
            </div>
          </div>
        )
      })
        :
        <div className='card'>
          <MdFlag style={{ fontSize: 50, color: "gray" }} />
          <p style={{ color: "gray", fontSize: 12 }}>You have no student to review at the moment.</p>
        </div>
      }

      {/* <pre>{JSON.stringify(completionData, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(studentData, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(feedbackData,null,2)}</pre> */}
    </>
  )
}
