import React from 'react'
import Header from '../components/Header'
import { useState } from 'react'
import config from '../app-config'
import { ToastContainer } from 'react-toastify'
import notify from '../utils/IANotification'
import 'react-toastify/dist/ReactToastify.css';
import Line from '../components/shapes/Line'

export default function AuthPage({ pageState }) { // login, register, forgotPassword, resetPassword, activate
  const [user, setUser] = useState("")
  const [password, setPassword] = useState("")
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [contactNumber, setContactNumber] = useState("")
  const [passwordConfirm, setPasswordConfirm] = useState("")

  const register = async () => {
    let endpoint = config.endpoint + "/auth/register"
    let fetchResponse = await fetch(endpoint, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        first_name: firstName,
        last_name: lastName,
        email: email,
        contact_number: contactNumber,
        password: password,
      })
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      window.location.href = "/auth/login"
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  const login = async () => {
    let endpoint = config.endpoint + "/auth/login"
    let fetchResponse = await fetch(endpoint, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        user: user,
        password: password
      })
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      let login = data.login;
      if (login === "parent" || login === "parents") {
        window.location.href = "/parents"
      } else if (login === "staff") {
        window.location.href = "/teachers"
      } else if (login === "student") {
        window.location.href = "/home"
      } else if (login === "admin") {
        window.location.href = "/admin"
      } else if (login === "superadmin") {
        window.location.href = "/admin"
      } else {
        window.location.href = "/home"
      }
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  const activateAccount = async () => {
    if (!email || !password || !passwordConfirm) {
      notify({ message: "Please fill in all fields", type: "error" })
      return
    }
    if (password !== passwordConfirm) {
      notify({ message: "Password and password confirm does not match", type: "error" })
      return
    }
    if (password.length < 8) {
      notify({ message: "Password must be at least 8 characters long", type: "error" })
      return
    }
    if (!email.includes("@") || email.indexOf(".") < email.indexOf("@")) {
      notify({ message: "Please enter a valid email address", type: "error" })
      return
    }


    let endpoint = config.endpoint + "/auth/activate"
    let fetchResponse = await fetch(endpoint, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        email: email,
        password: password,
      })
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      window.location.href = "/auth/login"
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  return (
    <div style={{ width: "100%", maxHeight:"100dvh",height:"100%" }}>
      <Header />
      <div>
        <ToastContainer />
        <div className="column justifyCenter items-center  -mt-4 font-['Poppins']" style={{ width: "100%" }}>
          <div className="shadow-md rounded-md bg-white px-4 py-8 w-96 flex flex-col items-center mt-52">
            <img src={config.images.logo} alt="logo" style={{ width: "150px", height: "50px", objectFit: "contain" }} />
            {pageState === "login" && <>
              <h1 className="headerText mt-4 text-left w-full pl-[10px]" style={{ fontWeight: "800", fontSize: 30, }}>Sign In</h1>
              <p className='px-[10px] w-full text-sm mb-4'>Welcome to {config.client_title} Learning Management System</p>
              <div style={{ maxWidth: 400, width: "100%", padding: 10 }}>
                <div className="input-container">
                  <input type="text" placeholder="Phone Number, username or email address" className="text-input" style={{ marginBottom: 10 }} value={user} onChange={(e) => {
                    setUser(e.target.value)
                  }} />
                  <input type="password" placeholder="Password" className="text-input" style={{ marginBottom: 10 }} value={password} onChange={(e) => {
                    setPassword(e.target.value)
                  }} />
                </div>
                <div className="button-container" style={{ marginTop: 30 }}>
                  <button className="button" style={{ width: "100%" }}
                    onClick={() => {
                      login()
                    }}
                  >Log In</button>
                </div>

                {/* <div style={{ marginTop: 30 }}>
                <p style={{
                  backgroundColor: "#ebeff3", position: "relative", translate: "transformY(-50%)", top: "50%", bottom: -17, textAlign: "center", fontSize: 12, fontWeight: "500", color: "#8e8e8e", padding: 10, width: "fit-content", margin: "auto"
                }}>Or create an Account</p>
                <div style={{ width: "100%", height: 0.5, backgroundColor: "black" }} />
              </div> */}
                {config.feature_flagging.allow_registration && <>
                  <div className="row gap-4 items-center mt-40">
                    <Line style={{ width: "100%" }} />
                    <p style={{ fontSize: 12, fontWeight: "500", color: "#8e8e8e", padding: 10, whiteSpace: "nowrap" }}>Or create an Account</p>
                    <Line style={{ width: "100%" }} />
                  </div>

                  <div className="button-container" style={{ marginTop: 30 }}>
                    <button className="button-cta" style={{ width: "100%", padding: "10px 20px" }}
                      onClick={() => {
                        window.location.href = "/auth/register"
                      }}
                    >Create an Account</button>
                  </div>
                </>}
              </div>
            </>}

            {pageState === "register" && config.feature_flagging.allow_registration && <>
              <h1 className="headerText mt-4" style={{ fontWeight: "500", fontSize: 30, marginBottom: 20 }}>Welcome to {config.client_title} LMS.</h1>
              <div style={{ maxWidth: 400, width: "100%", padding: 10 }}>
                <div className="input-container">
                  <div className="row gap-4 items-center" style={{ marginBottom: 10 }}>
                    <input type="name" placeholder="First Name" className="text-input" style={{ width: "100%" }} value={firstName} onChange={(e) => {
                      setFirstName(e.target.value)
                    }} />
                    <input type="name" placeholder="Last Name" className="text-input" style={{ width: "100%" }} value={lastName} onChange={(e) => {
                      setLastName(e.target.value)
                    }} />
                  </div>
                  <input type="email" placeholder="Email address" className="text-input" style={{ marginBottom: 10 }} value={email} onChange={(e) => {
                    setEmail(e.target.value)
                  }} />
                  <input type="tel" placeholder="Phone number" className="text-input" style={{ marginBottom: 10 }} value={contactNumber} onChange={(e) => {
                    setContactNumber(e.target.value)
                  }} />
                  <input type="password" placeholder="Password" className="text-input" style={{ marginBottom: 10 }} value={password} onChange={(e) => {
                    setPassword(e.target.value)
                  }} />
                </div>
                <div className="button-container" style={{ marginTop: 30 }}>
                  <button className="button" style={{ width: "100%" }}
                    onClick={() => {
                      register()
                    }}
                  >Register</button>
                </div>

                <p style={{ marginTop: 20, textAlign: "center", padding: 20 }}>By proceeding, you agree to our <span style={{ color: "var(--primary)", fontWeight: "bold" }}>Terms and Condition</span> and <span style={{ color: "var(--primary)", fontWeight: "bold" }}>Privacy Policy</span></p>

                {/* <div style={{ marginTop: 30 }}>
                <p style={{
                  backgroundColor: "#ebeff3", position: "relative", translate: "transformY(-50%)", top: "50%", bottom: -17, textAlign: "center", fontSize: 12, fontWeight: "500", color: "#8e8e8e", padding: 10, width: "fit-content", margin: "auto"
                }}>Already have an account? Log In</p>
                <div style={{ width: "100%", height: 0.5, backgroundColor: "black" }} />
              </div> */}

                <div className="row gap-4 items-center">
                  <Line style={{ width: "100%" }} />
                  <p style={{ fontSize: 12, fontWeight: "500", color: "#8e8e8e", padding: 10, whiteSpace: "nowrap" }}>Already have an account? Log In</p>
                  <Line style={{ width: "100%" }} />
                </div>

                <div className="button-container" style={{ marginTop: 20 }}>
                  <button className="button-cta" style={{ width: "100%", padding: "10px 20px" }}
                    onClick={() => {
                      window.location.href = "/auth/login"
                    }}
                  >Log into your Account</button>
                </div>
              </div>
            </>}

            {pageState === "activate" && <>
              <h1 className="headerText" style={{ fontWeight: "500", fontSize: 30, marginBottom: 20 }}>Activate your Account</h1>
              <div style={{ maxWidth: 400, width: "100%", padding: 10 }}>
                <div className="input-container">
                  <input type="text" placeholder="Enter your email address" className="text-input" style={{ marginBottom: 10 }} value={email} onChange={(e) => {
                    setEmail(e.target.value)
                  }} />
                  <input type="password" placeholder="Create your password" className="text-input" style={{ marginBottom: 10 }} value={password} onChange={(e) => {
                    setPassword(e.target.value)
                  }} />
                  <input type="password" placeholder="Confirm your password" className="text-input" style={{ marginBottom: 10 }} value={passwordConfirm} onChange={(e) => {
                    setPasswordConfirm(e.target.value)
                  }} />
                </div>
                <div className="button-container" style={{ marginTop: 30 }}>
                  <button className="button" style={{ width: "100%" }}
                    onClick={() => {
                      activateAccount()
                    }}
                  >Activate Account</button>
                </div>
                <div className="row gap-4 items-center mt-40">
                  <Line style={{ width: "100%" }} />
                  <p style={{ fontSize: 12, fontWeight: "500", color: "#8e8e8e", padding: 10, whiteSpace: "nowrap" }}>Account already activate? Log In</p>
                  <Line style={{ width: "100%" }} />
                </div>

                <div className="button-container" style={{ marginTop: 30 }}>
                  <button className="button-cta" style={{ width: "100%", padding: "10px 20px" }}
                    onClick={() => {
                      window.location.href = "/auth/login"
                    }}
                  >Log into your Account</button>
                </div>
              </div>
            </>}
          </div>
        </div>
      </div>
    </div>
  )
}
