import React, { useLayoutEffect, useEffect, useState } from 'react'
import Navbar from '../../../components/Navbar'
import UserHeader from '../../../components/UserHeader'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../../utils/AppFunctions'
import config from '../../../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import UserLayout from '../../../layout/UserLayout'
import notify from '../../../utils/IANotification'
import ModalLayout from '../../../layout/ModalLayout'
import { MdAdd, MdViewKanban } from 'react-icons/md'
import { tidyDate } from '../../../utils/helper'

export default function KanbanPage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = useFetchUserData()

  const [headerHeight, setHeaderHeight] = useState(document?.getElementById("user-header")?.clientHeight)
  const [studentCoursesData, setStudentCoursesData] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [searchQuery, setSearchQuery] = useState("")
  const [selectedCourse, setSelectedCourse] = useState(null)

  const [showTaskModal, setShowTaskModal] = useState(false)

  useLayoutEffect(() => {
    setHeaderHeight(document?.getElementById("user-header")?.clientHeight)
  }, [document?.getElementById("user-header")?.clientHeight])

  const [buckets, setBuckets] = useState([
    {
      name: "Not Started",
      code: "not-started",
      backgroundColor: "lightgrey"
    },
    {
      name: "In Progress",
      code: "in-progress",
      backgroundColor: "lightblue"
    },
    {
      name: "Completed",
      code: "completed",
      backgroundColor: "lightgreen"
    },
  ])

  const [task, setTask] = useState([])
  const [selectedTask, setSelectedTask] = useState(null)
  const [isDragging, setIsDragging] = useState(false)



  const fetchAllStudentCourses = async () => {
    setIsLoading(true)
    let endpoint = config.endpoint + "/course/student/"
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      setStudentCoursesData(data.data)
    } else {
      notify({ message: data.message, type: "error" })
    }
    setIsLoading(false)
  }


  const fetchAllKanbanTask = async () => {
    let endpoint = config.endpoint + "/kanban/"
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      setTask(data.data)
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  const createKanbanTask = async (task) => {
    let endpoint = config.endpoint + "/kanban/"
    let fetchResponse = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        task: task,
        course_id: selectedCourse?.id,
      })
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      notify({ message: data.message, type: "success" })
      fetchAllKanbanTask()
    } else {
      notify({ message: data.message, type: "error" })
    }
  }


  const editKanbanTask = async (id, task) => {
    let endpoint = config.endpoint + "/kanban/id/" + id
    let fetchResponse = await fetch(endpoint, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        ...selectedTask,
        ...task,
        course_id: selectedCourse?.id,
      })
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      notify({ message: data.message, type: "success" })
      fetchAllKanbanTask()
    } else {
      notify({ message: data.message, type: "error" })
    }
  }


  const deleteKanbanTask = async (id) => {
    let endpoint = config.endpoint + "/kanban/id/" + id
    let fetchResponse = await fetch(endpoint, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      notify({ message: data.message, type: "success" })
      fetchAllKanbanTask()
    } else {
      notify({ message: data.message, type: "error" })
    }

  }



  useEffect(() => {
    fetchAllStudentCourses()
    fetchAllKanbanTask()
  }, [])

  return (
    <UserLayout navSelected={"Home"}
      noParentPadding={true}
      defHeight={`calc(100vh - ${headerHeight}px)`}
    >
      {/* task modal */}
      <ModalLayout isVisible={showTaskModal} setIsVisible={setShowTaskModal} modalTitle={selectedTask?.id ? "Edit Task" : "Create Task"}>
        <div className="input-container">
          <label htmlFor="" className="input-label">Task</label>
          <input type="text" placeholder="Task" className='text-input' value={selectedTask?.task} onChange={(e) => {
            setSelectedTask({ ...selectedTask, task: e.target.value })
          }} />
        </div>

        {selectedTask?.id && <>
          <div className="row gap-4 items-center w-full">
            <div className="input-container">
              <label htmlFor="" className="input-label">Deadline</label>
              <input type="datetime-local" placeholder="Deadline" className='text-input' value={selectedTask?.deadline} onChange={(e) => {
                setSelectedTask({ ...selectedTask, deadline: e.target.value })
              }} />
            </div>

            <div className="input-container">
              <label htmlFor="" className="input-label">Status</label>
              <select className="select-input" value={selectedTask?.status} onChange={(e) => {
                setSelectedTask({ ...selectedTask, status: e.target.value })
              }}>
                {buckets.map((bucket, index) => {
                  return (
                    <option value={bucket.code}>{bucket.name}</option>
                  )
                })}
              </select>
            </div>
          </div>
        </>}

        <div className="column right alignEnd w-full">
          <div className="row gap-4 items-center">
            {selectedTask?.id && <button className="button-delete" onClick={() => {
              deleteKanbanTask(selectedTask?.id)
              setShowTaskModal(false)
            }}>Delete</button>}
            <button className="button primary" onClick={() => {
              if (!selectedTask?.task) {
                notify({ message: "Task cannot be empty", type: "error" })
                return
              }
              if (selectedTask?.id) {
                // update task
                editKanbanTask(selectedTask?.id, selectedTask?.task)
              } else {
                createKanbanTask(selectedTask?.task)
              }
              setShowTaskModal(false)
            }}>{selectedTask?.id ? "Update" : "Create"}</button>
          </div>
        </div>
      </ModalLayout>


      <div className="row gap-4" style={{ height: "100%", overflow: "scroll" }}>

        {/* Navigation */}
        <div className="card rounded-md shadow-md p-4 mt-4" style={{ width: "15%", position: "sticky", top: `0px`, padding: 0 }}>
          <div style={{ padding: 10 }}>
            <p className="card-title" style={{ fontSize: 25 }}>Kanban</p>
            <p style={{ fontSize: 12, marginBottom: 10 }}>Organise your learning and plan your tasks</p>
          </div>
          {studentCoursesData?.length > 0 && studentCoursesData?.map((course, index) => {
            return (
              <div
                className='cursor-pointer'
                style={{
                  backgroundColor: selectedCourse?.id === course?.id ? "#f0f0f0" : "white",
                  padding: 10,
                }}
                onClick={() => {
                  setSelectedCourse(course)
                }}
              >
                <p>{course?.name}</p>
              </div>
            )
          })}
        </div>

        {/* Kanban */}
        <div style={{ padding: 10, width: "100%" }}>
          {selectedCourse?.id ? <>
            <button className="button row gap-4 items-center"
              onClick={() => {
                setShowTaskModal(true)
                setSelectedTask({})
              }}
            >
              <MdAdd />
              Create Task
            </button>
            <div className="row gap-20 items-start" style={{ padding: 10 }}>
              {/* <pre>{JSON.stringify(task, null, 2)}</pre> */}
              {buckets.map((bucket, index) => {
                return (
                  <div

                  >
                    <div className="card row justify-between items-center w-full" style={{ width: "300px", padding: 10, backgroundColor: bucket.backgroundColor }}>
                      <p className="card-title" style={{ fontSize: 12 }}>{bucket.name}</p>
                      <p className="card-title" style={{ fontSize: 12 }}>{
                        task?.filter((taskData) => taskData?.status == bucket.code && taskData?.course_id == selectedCourse?.id).length
                      }</p>
                    </div>
                    <div style={{ marginTop: 15, width: "100%", minHeight: 300, backgroundColor: isDragging ? "lightgrey" : "" }}
                      id={bucket.code}
                    >
                      {task?.length > 0 && task?.map((taskData, index) => {
                        if (taskData?.status == bucket.code && taskData?.course_id == selectedCourse?.id) {
                          return (
                            <div className="card cursor-pointer" style={{ padding: 10, marginBottom: 10, maxWidth: 300 }}
                              onClick={() => {
                                setSelectedTask(taskData)
                                setShowTaskModal(true)
                              }}
                              onDrag={(e) => {
                                setIsDragging(true)
                                let rect = e.target.getBoundingClientRect()
                                let x = e.clientX
                                let y = e.clientY

                              }}
                              draggable
                              onDragEnd={(e) => {
                                setIsDragging(false)
                                // find the bucket it is above
                                // let rect = e.target.getBoundingClientRect()
                                // get mouse

                                let x = e.clientX
                                let y = e.clientY
                                let targetBucket = null
                                for (let i = 0; i < buckets.length; i++) {
                                  let rect = document.getElementById(buckets[i].code).getBoundingClientRect()
                                  let bucket = buckets[i]
                                  // if the middle of the task is within the bucket
                                  if (x > rect.left && x < (rect.right + rect.width) && y > rect.top && y < (rect.bottom + rect.height)) {
                                    targetBucket = bucket.code
                                    break
                                  }
                                }
                                if (targetBucket) {
                                  editKanbanTask(taskData?.id, { ...taskData, status: targetBucket })
                                }
                              }}
                            >
                              {/* <pre>{JSON.stringify(taskData, null, 2)}</pre> */}
                              {taskData?.deadline && taskData?.status !== "completed" && <p className="card-subtitle" style={{
                                fontSize: 10, color: "white",
                                padding: "2px 5px",
                                width: "fit-content",
                                backgroundColor: new Date(taskData?.deadline) < new Date() && taskData?.status !== "completed" ? "red" : "var(--green)"
                              }}>{tidyDate(taskData?.deadline)}</p>}
                              <p className="card-title" style={{ whiteSpace: "pre-wrap" }}>{taskData?.task}</p>
                            </div>
                          )
                        }
                      })}
                    </div>
                  </div>
                )
              })}
            </div>
          </>
            :
            <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
              <MdViewKanban style={{ fontSize: 100, color: "lightgrey" }} />
              <p style={{ fontSize: 20, color: "lightgrey" }}>Select a course to view kanban</p>
            </div>
          }
        </div>

      </div>

    </UserLayout >
  )
}
