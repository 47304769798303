import React, { useState, useEffect } from 'react'
import Navbar from '../../components/Navbar'
import UserHeader from '../../components/UserHeader'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import { useParams } from 'react-router'
import AdminCourseNavbar from '../../components/AdminCourseNavbar'
import notify from '../../utils/IANotification'
import { IoAdd, IoTrash } from 'react-icons/io5'
import Line from '../../components/shapes/Line'
import { MdReorder } from 'react-icons/md'
import EditorJSComponent from '../../components/lesson/EditorJSComponent'
import TextComponent from '../../components/lesson/TextComponent'
import ImageComponent from '../../components/lesson/ImageComponent'
import Switch from 'react-switch'
import CodeComponent from '../../components/lesson/CodeComponent'
import VideoComponent from '../../components/lesson/VideoComponent'
import DocumentComponent from '../../components/lesson/DocumentComponent'
import AudioComponent from '../../components/lesson/AudioComponent'
import { PiDotsSixVerticalBold } from "react-icons/pi";
import { Draggable } from "react-drag-reorder";
import WebComponent from '../../components/lesson/WebComponent'
import IAPrompt from '../../utils/IAPrompt'
import CourseLessonFormPage from '../../subpages/CourseLessonFormPage'
import TeachersLayout from '../../layout/TeachersLayout'
import StaffCourseNavbar from '../../components/StaffCourseNavbar'

export default function StaffLessonFormPage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = useFetchUserData()

  const course_id = new URLSearchParams(window.location.search)?.get("course_id")
  const lesson_id = new URLSearchParams(window.location.search)?.get("lesson_id")
  
  const [lessonData, setLessonData] = useState({
    name: "",
    description: "",
  })

  const fetchLessonById = async () => {
    if (lesson_id === "new") return
    let endpoint = config.endpoint + "/lesson/id/" + lesson_id
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      setLessonData(data.data)
    } else {
      notify({ message: data.message, type: "error" })
    }
  }



  useEffect(() => {
    fetchLessonById()
  }, [])

  return (
    <TeachersLayout navSelected={"Classes"}>
      <p className='mb-4' style={{ fontSize: 30, fontWeight: "500" }}>{lesson_id === "new" ? "New Lesson" : lessonData?.name}</p>
      <StaffCourseNavbar selected={"Lessons"} course_id={course_id} />
      <CourseLessonFormPage type={"admin"}/>
    </TeachersLayout>
  )
}