import React, { useState, useEffect } from 'react'
import Navbar from '../../components/Navbar'
import UserHeader from '../../components/UserHeader'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import TeachersLayout from '../../layout/TeachersLayout'
import { tidyDate } from '../../utils/helper'
import AnnouncementCard from '../../components/AnnouncementCard'
import notify from '../../utils/IANotification'
import { FaChalkboardTeacher } from 'react-icons/fa'
import { MdCalendarMonth, MdChevronRight, MdFlag } from 'react-icons/md'
import { IoEaselOutline } from 'react-icons/io5'

export default function StafffHomePage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = useFetchUserData()

  const [showAnnouncement, setShowAnnouncement] = useState(false)
  const [selectedAnnouncement, setSelectedAnnouncement] = useState({})
  const [announcementData, setAnnouncementData] = useState([])
  const [classData, setClassData] = useState([])
  const [feedbackData, setFeedbackData] = useState([])

  const fetchAllClasses = async () => {
    let endpoint = config.endpoint + "/class/staff/id"
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      }
    })
    let response = await fetchResponse.json()
    if (response.status === 200) {
      setClassData(response.data)
    } else {
      notify({
        message: response.message,
        type: "error"
      })
    }
  }



  const fetchAnnouncement = async () => {
    let endpoint = config.endpoint + `/announcement/staff/id/`
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      setAnnouncementData(data.data)
    }
  }

  const fetchFeedback = async () => {
    let endpoint = config.endpoint + `/feedback/staff/id/`
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      setFeedbackData(data.data)
    }
  }


  useEffect(() => {
    fetchAnnouncement()
    fetchAllClasses()
    fetchFeedback()
    fetchFeedback()
  }, [])

  return (
    <TeachersLayout navSelected={"Home"}>
      <AnnouncementCard show={showAnnouncement} setShowModal={setShowAnnouncement} data={selectedAnnouncement} />
      <p className='mb-4' style={{ fontSize: 30, fontWeight: "500" }}>Hello {user_data?.first_name} {user_data?.last_name}</p>
      <div className="row gap-4 items-start w-full">
        <div style={{ width: "70%" }}>
          {/* Classes */}
          <div className="card shadow-md rounded-md mb-4">
            <div className="row gap-4 items-center justify-between">
              <p className="card-title">My Form Classes</p>
              <div className="row gap-2 items-center hover-underline cursor-pointer"
                onClick={() => {
                  navigate(`/staff/classes`)
                }}
              >
                <p className='card-subtitle text-slate-500 text-sm' >View all</p>
                <MdChevronRight size={20} color='gray' />
              </div>
            </div>
            {classData?.length > 0 ? classData.map((item, index) => {
              return (
                <div className='p-all row justify-between gap-20 items-center w-full mt-4' style={{ borderLeft: "4px solid var(--primary)", backgroundColor: "white", borderTopLeftRadius: "5px", borderBottomLeftRadius: "5px", borderRadius: "5px", paddingLeft: 10 }}>
                  <div className="" style={{ maxHeight: "50px", textOverflow: "ellipsis", overflow: "clip" }}>
                    <p className="card-subtitle" style={{ color: "gray" }}>{tidyDate(item?.start_datetime)}</p>
                    <h5 className="card-title">{item.name}</h5>
                  </div>
                  <button className="button"
                    onClick={() => {
                      navigate(`/staff/courses/classes/details?class_id=${item?.id}&course_id=${item?.course_id}`)
                    }}
                  >View</button>
                </div>
              )
            })
              :
              <div>
                <FaChalkboardTeacher style={{ fontSize: 50, color: "gray" }} />
                <p style={{ color: "gray", fontSize: 12 }}>You are not assigned to any classes at the moment.</p>
              </div>
            }
          </div>

          {/* Feedback and Review */}
          <div className="card shadow-md rounded-md ">
            <p className="card-title">Feedback & Review</p>
            {feedbackData?.length > 0 ? feedbackData.map((data, index) => {
              return (
                <div className='p-all row justify-between gap-20 items-center w-full mt-4' style={{ borderLeft: "4px solid var(--primary)", backgroundColor: "white", borderTopLeftRadius: "5px", borderBottomLeftRadius: "5px", borderRadius: "5px", paddingLeft: 10 }}>
                  <div className="column">
                    <p className="card-title">{data?.class_data?.name}</p>
                    <div className="row items-center gap-20">
                      <div className="row gap-4 items-center">
                        <IoEaselOutline size={15} />
                        <p style={{ color: "gray", fontSize: 12 }}>{data?.class_data?.course_data?.name}</p>
                      </div>
                      <div className="row gap-4 items-center">
                        <FaChalkboardTeacher size={15} />
                        <p style={{ color: "gray", fontSize: 12 }}>You</p>
                      </div>
                      <div className="row gap-4 items-center">
                        <MdCalendarMonth size={15} />
                        <p style={{ color: "gray", fontSize: 12 }}>by {tidyDate(data?.completion_deadline)}</p>
                      </div>
                    </div>
                    {/* <pre>{JSON.stringify(data?.user_data,null,2)}</pre> */}
                  </div>
                  <button className="button"
                    onClick={() => {
                      navigate(`/staff/feedback/review?request_id=${data?.id}`)
                    }}
                  >View</button>
                </div>
              )
            })
              :
              <div>
                <MdFlag style={{ fontSize: 50, color: "gray" }} />
                <p style={{ color: "gray", fontSize: 12 }}>You have no feedback or review request at the moment.</p>
              </div>
            }
          </div>
        </div>
        <div style={{ width: "30%" }}>

          {/* Announcements */}
          <div className="card shadow-md rounded-md p-4">
            <p className="card-title">Announcements</p>
            {announcementData?.length > 0 ? announcementData.map((item, index) => {
              if (index > 3) return
              return (
                <div className='p-all row justify-between gap-20 items-center w-full mt-4' style={{ borderLeft: "4px solid var(--primary)", backgroundColor: "white", borderTopLeftRadius: "5px", borderBottomLeftRadius: "5px", borderRadius: "5px", paddingLeft: 10 }}>
                  <div className="" style={{ maxHeight: "50px", textOverflow: "ellipsis", overflow: "clip" }}>
                    <p className="card-subtitle" style={{ color: "gray" }}>{tidyDate(item?.start_datetime)}</p>
                    <h5 className="card-title">{item.title}</h5>
                  </div>
                  <button className="button"
                    onClick={() => {
                      setSelectedAnnouncement(item)
                      setShowAnnouncement(true)
                    }}
                  >View</button>
                </div>
              )
            })
              : <p style={{ color: "gray", fontSize: 12 }}>There are no announcements at the moment.</p>
            }
          </div>
        </div>
      </div>
    </TeachersLayout>
  )
}
