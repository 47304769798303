import React, { useState, useEffect } from 'react'
import Navbar from '../components/Navbar'
import UserHeader from '../components/UserHeader'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../utils/AppFunctions'
import config from '../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import AdminLayout from '../layout/AdminLayout'
import { useParams } from 'react-router'
import AdminCourseNavbar from '../components/AdminCourseNavbar'
import notify from '../utils/IANotification'
import { IoAdd, IoTrash } from 'react-icons/io5'
import Line from '../components/shapes/Line'
import { MdReorder } from 'react-icons/md'
import EditorJSComponent from '../components/lesson/EditorJSComponent'
import TextComponent from '../components/lesson/TextComponent'
import ImageComponent from '../components/lesson/ImageComponent'
import Switch from 'react-switch'
import CodeComponent from '../components/lesson/CodeComponent'
import VideoComponent from '../components/lesson/VideoComponent'
import DocumentComponent from '../components/lesson/DocumentComponent'
import AudioComponent from '../components/lesson/AudioComponent'
import { PiDotsSixVerticalBold } from "react-icons/pi";
import { Draggable } from "react-drag-reorder";
import SingleSelectAssessmentComponent from '../components/assessment/SingleSelectAssessmentComponent'
import SingleTextAssessmentComponent from '../components/assessment/SingleTextAssessmentComponent'
import MultipleSelectAssessmentComponent from '../components/assessment/MultipleSelectAssessmentComponent'
import FileUploadAssessmentComponent from '../components/assessment/FileUploadAssessmentComponent'
import FileUploadField from '../components/FileUploadField'
import CodeEditorAssessmentComponent from '../components/assessment/CodeEditorAssessmentComponent'
import IAPrompt from '../utils/IAPrompt'
import { isMobile } from '../utils/helper'

export default function AssessmentForm({ type }) {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = useFetchUserData()

  const course_id = new URLSearchParams(window.location.search)?.get("course_id")
  const lesson_id = new URLSearchParams(window.location.search)?.get("lesson_id")
  const assessment_id = new URLSearchParams(window.location.search)?.get("assessment_id")

  const [showPreview, setShowPreview] = useState(false)
  const [isLoaded, setIsLoaded] = useState(true)
  const [width, setWidth] = useState(window.innerWidth)

  let modal = {
    question: "",
    answers: "",
    question_options: [], // [option1, option2, option3, option4]
    type: "single-text", // single-text, multi-text, single-select, multi-select, code, file,
    internal_notes: "",
    max_marks: 0,
    options: {},
    order: 0,
  }


  const [fileAttachments, setFileAttachments] = useState([])


  const [assessmentData, setAssessmentData] = useState({
    name: "",
    description: "",
    instructions: "",
    time_limit: 60, // in minutes
    is_unlimited_time: 0,
    attempts: 3,
    is_unlimited_attempts: 0,
    system_marking: 1,

    // Future
    // allow_backtracking: 0,
    // allow_skipping: 0,

  })
  const [componentData, setComponentData] = useState([
    { ...modal },
  ])

  const createAssessment = async () => {
    let endpoint = config.endpoint + "/assessment/"
    let fetchResponse = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        ...assessmentData,
        course_id
      })
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      let id = data.data.id
      navigate(`/${type}/courses/assessments/details?course_id=` + course_id + "&assessment_id=" + id)
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  const editAssessment = async () => {
    if (assessment_id === "new") return
    let endpoint = config.endpoint + "/assessment/id/" + assessment_id
    let fetchResponse = await fetch(endpoint, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        ...assessmentData,
        course_id
      })
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      let id = data.data.id
      notify({ message: data.message, type: "success" })
    } else {
      notify({ message: data.message, type: "error" })
    }
  }


  const saveComponent = async () => {
    // Check for any black questions, or option
    let blackQuestion = componentData.findIndex((item) => item.question === "")
    if (blackQuestion !== -1) {
      notify({ message: "Question cannot be empty", type: "error" })
      return
    }

    let blackOption = componentData.findIndex((item) => item.type !== "single-text" && item.type !== "code" && item.type !== "file" && item?.options?.options?.filter((item) => item === "")?.length > 0)
    if (blackOption !== -1) {
      notify({ message: "Options cannot be empty", type: "error" })
      return
    }
    let endpoint = config.endpoint + "/assessment/component/"
    let fetchResponse = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        assessment_id,
        course_id,
        component_data: componentData,
        file_attachments: fileAttachments
      })
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      fetchAssessmentById()
      fetchAssessmentComponentsById()
      notify({ message: data.message, type: "success" })
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  const fetchAssessmentById = async () => {
    if (assessment_id === "new") return
    let endpoint = config.endpoint + "/assessment/id/" + assessment_id
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      setAssessmentData(data.data)
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  const fetchAssessmentComponentsById = async () => {
    if (assessment_id === "new") return
    setIsLoaded(false)
    let endpoint = `${config.endpoint}/assessment/component/assessment/id/${assessment_id}`
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      setComponentData(data.data)
      setIsLoaded(true)
      notify({ message: data.message, type: "success" })
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  const deleteAssessment = async () => {
    if (assessment_id === "new") return
    let endpoint = `${config.endpoint}/assessment/id/${assessment_id}`
    let fetchResponse = await fetch(endpoint, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      navigate(-1)
      notify({ message: data.message, type: "success" })
    } else {
      notify({ message: data.message, type: "error" })
    }
  }


  useEffect(() => {
    fetchAssessmentById()
    fetchAssessmentComponentsById()
  }, [])

  return (
    <>
      <div className="card rounded-md shadow-md p-4 mt-4">
        <div className="input-container">
          <label htmlFor="" className="input-label">Name</label>
          <input type="text" className="text-input" value={assessmentData.name} onChange={(e) => {
            setAssessmentData({ ...assessmentData, name: e.target.value })
          }} />
        </div>
        <div className="input-container">
          <label htmlFor="" className="input-label">Description</label>
          <textarea type="text" className="textarea-input" value={assessmentData.description} onChange={(e) => {
            setAssessmentData({ ...assessmentData, description: e.target.value })
          }} />
        </div>

        <div className="input-container">
          <label htmlFor="" className="input-label">Assessment Instructions</label>
          {/* <textarea type="text" className="textarea-input" value={assessmentData.instructions} onChange={(e) => {
            setAssessmentData({ ...assessmentData, instructions: e.target.value })
          }} /> */}
          <TextComponent data={assessmentData?.instructions} onChange={(value) => {
            console.log({ value })
            setAssessmentData({ ...assessmentData, instructions: value })
          }} readOnly={false} />
          {/* <p style={{fontSize:10}}></p> */}
        </div>
        <div className={`${isMobile(width) ? "column" : "row"} gap-4 items-center w-full`} style={{ width: "100%" }}>
          <div className={`${isMobile(width) ? "column" : "row items-center"} gap-4 `} style={{ width: "100%" }}>
            <div className="input-container">
              <label htmlFor="" className="input-label">Time Limit</label>
              <input type="number" className="text-input" value={assessmentData.time_limit} onChange={(e) => {
                setAssessmentData({ ...assessmentData, time_limit: e.target.value })
              }} />
            </div>
            <div className="row gap-4 items-center">
              <Switch onChange={() => {
                setAssessmentData({ ...assessmentData, is_unlimited_time: assessmentData.is_unlimited_time == 1 ? 0 : 1 })
              }} checked={assessmentData.is_unlimited_time == 1 ? true : false} />
              <label htmlFor="" className="input-label">Unlimited Time</label>
            </div>
          </div>

          <div className={`${isMobile(width) ? "column" : "row items-center"} gap-4 `} style={{ width: "100%" }}>
            <div className="input-container">
              <label htmlFor="" className="input-label">Attempts</label>
              <input type="number" className="text-input" value={assessmentData.attempts} onChange={(e) => {
                setAssessmentData({ ...assessmentData, attempts: e.target.value })
              }} />
            </div>
            <div className="row gap-4 items-center">
              <Switch onChange={() => {
                setAssessmentData({ ...assessmentData, is_unlimited_attempts: assessmentData.is_unlimited_attempts == 1 ? 0 : 1 })
              }} checked={assessmentData.is_unlimited_attempts == 1 ? true : false} />
              <label htmlFor="" className="input-label">Unlimited Attempts</label>
            </div>
          </div>
        </div>

        <div className="input-container mt-4">
          <div className="row gap-4 items-center">
            <Switch onChange={() => {
              setAssessmentData({ ...assessmentData, system_marking: assessmentData.system_marking == 1 ? 0 : 1 })
            }} checked={assessmentData.system_marking == 1 ? true : false} />
            <label htmlFor="" className="input-label">System Marking</label>
          </div>
        </div>

        {assessment_id == "new" ?
          <div className="button-container">
            <button className="button" onClick={() => {
              createAssessment()
            }}>Create Assessment</button>
          </div>
          :

          <div className="button-container row gap-4 items-center">
            <button className="button-delete" onClick={() => {
              IAPrompt({
                title: "Delete Assessment",
                message: "Are you sure you want to delete this assessment?",
                buttons: [
                  {
                    label: "No",
                    onClick: () => { }
                  },
                  {
                    label: "Yes",
                    onClick: () => {
                      deleteAssessment()
                    }
                  },
                ]
              })
            }}>Delete Assessment</button>
            <button className="button" onClick={() => {
              editAssessment()
              saveComponent()
            }}>Save Changes</button>
          </div>
        }
      </div>

      {assessment_id !== "new" && <div className="card rounded-md shadow-md p-4 mt-4">
        <div className={`${isMobile(width) ? "column" : "row items-center"} justify-between  mb-4`}>
          <p className="card-title">Assessment Questions</p>
          <div className="row gap-20 items-center hide-scrollbars" style={{ overflowX: "scroll" }}>
            <div className="row gap-4 items-center">
              <Switch onChange={() => {
                setShowPreview(!showPreview)
              }} checked={showPreview} />
              <p>Preview</p>
            </div>

            <button className="backgroundLess row items-center gap-4" onClick={() => {

            }}>
              <MdReorder size={15} />
              Reorder</button>
            <button className="button row gap-4 items-center" onClick={() => {
              setComponentData([...componentData, { ...modal }])
            }}>
              <IoAdd size={15} />
              Question</button>
          </div>
        </div>
        {/* {isLoaded &&
          <Draggable
            onPosChange={(newPos, oldPos) => {
              let temp = [...componentData]
              let temp2 = temp[oldPos]
              temp[oldPos] = temp[newPos]
              temp[newPos] = temp2
              setComponentData(temp)
            }}
          > */}
        {componentData.map((data, index) => {
          return (
            <div className="mb-4" key={index}>
              <div className='row justify-between gap-20 items-center w-full' style={{ borderLeft: !showPreview && "4px solid var(--primary)", borderTopLeftRadius: "5px", borderBottomLeftRadius: "5px", borderRadius: "5px", paddingLeft: 10 }}>
                {!showPreview && <PiDotsSixVerticalBold size={30} color='black' style={{ cursor: "grab" }} />}
                <div className='w-97'>
                  {!showPreview && <>
                    <div className="row justify-between items-center">
                      <p className="card-title">Question {index + 1}</p>
                      <button className="backgroundLess" onClick={() => {
                        let temp = [...componentData]
                        temp.splice(index, 1)
                        setComponentData(temp)
                      }}>
                        <IoTrash size={20} />
                      </button>
                    </div>

                    <div className={`${isMobile(width) ? "column" : "row items-center"} gap-4 items-start`}>
                      <div className="input-container">
                        <label htmlFor="" className="input-label">Question</label>
                        <textarea type="text" className="textarea-input" value={data.question} style={{ minHeight: 100, }} onChange={(e) => {
                          let temp = [...componentData]
                          temp[index].question = e.target.value
                          setComponentData(temp)
                        }} />
                      </div>
                      <div className="input-container">
                        <p className="input-label">Upload Question Attachments</p>
                        <FileUploadField item_index={`attachment-${index}`} max_file_size={1} max_files={5} upload_url={config.endpoint + "/file/assessments/attempt"}
                          onChange={(value) => {
                            let temp = [...fileAttachments]
                            let indexFind = temp.findIndex((item) => item.item_index === `attachment-${index}`)
                            if (indexFind === -1) {
                              temp.push({ files: [...value], question_id: index })
                            } else {
                              temp[indexFind].files = [...value]
                            }
                            setFileAttachments(temp)
                          }} />
                      </div>
                    </div>
                    <div className={`${isMobile(width) ? "column" : "row items-center"} gap-4 items-center`}>
                      <div className="input-container">
                        <label htmlFor="" className="input-label">Type</label>
                        <select className="select-input" value={data.type} onChange={(e) => {
                          let temp = [...componentData]
                          temp[index].type = e.target.value
                          if (e.target.value === "single-text") {
                            temp[index].options = {}
                          } else if (e.target.value === "single-select") {
                            temp[index].options = {
                              correct_option: 0,
                              options: ["", "", "", ""]
                            }
                          } else if (e.target.value === "multi-select") {
                            temp[index].options = {
                              correct_options: [0],
                              options: ["", "", "", ""]
                            }
                          }
                          setComponentData(temp)
                        }}>
                          <option value="single-text">Text Response</option>
                          <option value="single-select">Multiple Choice</option>
                          <option value="multi-select">Multiple Response</option>
                          <option value="code">Code</option>
                          <option value="file">File Upload</option>
                        </select>
                      </div>
                      <div className="input-container">
                        <label htmlFor="" className="input-label">Max Marks</label>
                        <input type="number" className="text-input" value={data.max_marks} onChange={(e) => {
                          let temp = [...componentData]
                          temp[index].max_marks = e.target.value
                          setComponentData(temp)
                        }} />
                      </div>
                    </div>
                  </>}

                  {showPreview && <>
                    <div className="row justify-between items-center">
                      <p className="card-title">Question {index + 1}</p>
                    </div>
                    <div className="row gap-4 items-center">
                      <p className='w-full' style={{ fontSize: 13 }}>{data.question}</p>
                      <p style={{ fontSize: 12, whiteSpace: "nowrap" }}>{data.max_marks} marks</p>
                    </div>

                  </>}





                  {data.type === "single-select" && <SingleSelectAssessmentComponent item_index={index} state={showPreview ? "question" : "builder"} data={data} options={data.options}
                    onOptionChange={(value) => {
                      let temp = [...componentData]
                      temp[index].answer_options = value
                      setComponentData(temp)
                    }}

                    onChange={(value) => {
                      let temp = [...componentData]
                      temp[index] = value
                      setComponentData(temp)
                    }}
                  />
                  }

                  {data.type === "single-text" && <SingleTextAssessmentComponent state={showPreview ? "question" : "builder"} data={data} options={data.options} onChange={(value) => {
                    let temp = [...componentData]
                    temp[index] = value
                    setComponentData(temp)
                  }}

                    onOptionChange={(value) => {
                      let temp = [...componentData]
                      temp[index].options = value
                      setComponentData(temp)
                    }}
                  />
                  }

                  {data.type === "multi-select" && <MultipleSelectAssessmentComponent state={showPreview ? "question" : "builder"} data={data} options={data.options} onChange={(value) => {
                    let temp = [...componentData]
                    temp[index] = value
                    setComponentData(temp)
                  }}

                    onOptionChange={(value) => {
                      let temp = [...componentData]
                      temp[index].options = value
                      setComponentData(temp)
                    }}
                  />
                  }

                  {data.type === "file" && <FileUploadAssessmentComponent item_index={index} state={showPreview ? "question" : "builder"} data={data} options={data.options} onChange={(value) => {
                    let temp = [...componentData]
                    temp[index] = value
                    setComponentData(temp)
                  }}

                    onOptionChange={(value) => {
                      let temp = [...componentData]
                      temp[index].options = value
                      setComponentData(temp)
                    }}
                  />
                  }

                  {data.type === "code" && <CodeEditorAssessmentComponent item_index={index} state={showPreview ? "question" : "builder"} data={data} options={data.options}
                    onChange={(value) => {

                    }}

                    onOptionChange={(value) => {
                      let temp = [...componentData]
                      temp[index].options = value
                      setComponentData(temp)
                    }}
                  />
                  }

                </div>
              </div>
            </div>
          )
        })}
        {/* </Draggable>
        } */}
        <div style={{ height: 200 }} />
      </div>}
    </>
  )
}
