import React, { useState, useEffect } from 'react'
import Navbar from '../../components/Navbar'
import UserHeader from '../../components/UserHeader'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import TeachersLayout from '../../layout/TeachersLayout'
import { useParams } from 'react-router'
import AdminCourseNavbar from '../../components/AdminCourseNavbar'
import notify from '../../utils/IANotification'
import Switch from 'react-switch'
import ScheduleAssessment from '../../subpages/ScheduleAssessment'
import StaffCourseNavbar from '../../components/StaffCourseNavbar'

export default function StaffScheduleAssessmentPage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = useFetchUserData()

  const course_id = new URLSearchParams(window.location.search)?.get("course_id")
  const lesson_id = new URLSearchParams(window.location.search)?.get("lesson_id")
  const schedule_id = new URLSearchParams(window.location.search)?.get("schedule_id")

  const [assessmentData, setAssessmentData] = useState([])
  const [classData, setClassData] = useState([])
  const [scheduleData, setScheduleData] = useState({
    type: "assignments", // assignments or examinations -> to allow this to be defined by users
    assessment_id: "",
    title: "",
    description: "",
    start_date: "",
    end_date: "",
    no_end_date: 0,
    class_id: "",
    is_for_all_classes: 0,
    is_published: 0,
  })

  const getAssessments = async () => {
    if (!course_id) return notify({ message: "Unable to fetch lessons", type: "error" })
    let endpoint = config.endpoint + "/assessment/course/id/" + course_id
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      setAssessmentData(data.data)
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  const getClasses = async () => {
    if (!course_id) return notify({ message: "Unable to fetch classes", type: "error" })
    let endpoint = config.endpoint + "/class/course/id/" + course_id
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      setClassData(data.data)
    } else {
      notify({ message: data.message, type: "error" })
    }
  }


  const getScheduledAssessmentById = async () => {
    if (schedule_id === "new") return
    if (!schedule_id) return notify({ message: "Unable to fetch scheduled assessment", type: "error" })
    let endpoint = config.endpoint + "/assessment/schedule/id/" + schedule_id
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      setScheduleData(data.data)
    } else {
      notify({ message: data.message, type: "error" })
    }
  }


  useEffect(() => {
    getAssessments()
    getScheduledAssessmentById()
    getClasses()
  }, [])

  return (
    <TeachersLayout navSelected={"Classes"}>
      <p className='mb-4' style={{ fontSize: 30, fontWeight: "500" }}>{schedule_id === "new" || !scheduleData?.title ? "Schedule Assessment" : `Scheduling ${scheduleData.title}`}</p>
      {course_id != "new" && <StaffCourseNavbar selected={"Assessments"} course_id={course_id} />}
      <ScheduleAssessment type="staff" />
    </TeachersLayout>
  )
}
