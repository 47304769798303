import React from 'react'
import Editor from "@monaco-editor/react";
import { capitaliseFirstLetter } from '../../utils/helper';

export default function CodeComponent({ readOnly, data, onChange, onChangeOptions, options }) {
    // return (
    //     <>
    //         {
    //             readOnly ?
    //                 <>
    //                     <code style={{whiteSpace:"pre-wrap"}}>{data?.content}</code>
    //                 </>
    //                 :
    //                 <>
    //                     <code
    //                         style={{ whiteSpace: "pre-wrap" }}
    //                         value={data.content}
    //                         onChange={(e) => {
    //                             onChange(e.target.value)
    //                         }}
    //                     ></code>
    //                 </>
    //         }
    //     </>
    // )

    const LANGUAGES = [
        "javascript",
        "typescript",
        "python",
        "java",
        "c",
        "cpp",
        "csharp",
        "go",
        "ruby",
        "rust",
        "swift",
        "kotlin",
        "php",
        "sql",
        "plaintext",
    ]



    return (
        <>
            {!readOnly&&<>
                <div className="input-container">
                    <p className="input-label">Language</p>
                    <select className="select-input" value={options?.language} onChange={(e) => {
                        onChangeOptions({ ...options, language: e.target.value })
                    }}>
                        {LANGUAGES.map((language) => {
                            return (
                                <option value={language}>{capitaliseFirstLetter(language)}</option>
                            )
                        })}
                    </select>
                </div>
            </>}
            <Editor
                height="500px"
                language={options?.language || "python"}
                theme="vs-dark"
                value={data?.content}
                onChange={(value) => {
                    onChange(value)
                }}

                options={{
                    inlineSuggest: true,
                    fontSize: "16px",
                    formatOnType: true,
                    autoClosingBrackets: true,
                    minimap: { scale: 10 },
                    fontFamily: "monospace",
                    readOnly: readOnly,
                }}
            />
        </>
    )
}
