import React, { useState, useEffect } from 'react'
import Navbar from '../../components/Navbar'
import UserHeader from '../../components/UserHeader'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import ParentsLayout from '../../layout/ParentsLayout'
import { MdQuestionAnswer } from 'react-icons/md'
import TextFeedbackComponent from '../../components/feedback/TextFeedbackComponent'
import RadioFeedbackComponent from '../../components/feedback/RadioFeedbackComponent'
import CheckboxFeedbackComponent from '../../components/feedback/CheckboxFeedbackComponent'
import SliderFeedbackComponent from '../../components/feedback/SliderFeedbackComponent'
import notify from '../../utils/IANotification'
import FeedbackReviewFormPage from '../../subpages/FeedbackReviewFormPage'

export default function ParentsFeedbackReviewFormPage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = useFetchUserData()

  const student_id = new URLSearchParams(window.location.search).get("student_id")
  const form_id = new URLSearchParams(window.location.search).get("form_id")
  const request_id = new URLSearchParams(window.location.search).get("request_id")


  return (
    <ParentsLayout navSelected={"Feedback & Review"}>
      <FeedbackReviewFormPage type={"parents"}/>
    </ParentsLayout >
  )
}
