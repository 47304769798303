import React, { useEffect, useState } from 'react'
import Navbar from '../components/Navbar'
import UserHeader from '../components/UserHeader'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../utils/AppFunctions'
import config from '../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import AdminLayout from '../layout/AdminLayout'
import notify from '../utils/IANotification'

export default function TeacherList({type, filter}) {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = useFetchUserData()

  const [teacherData, setTeacherData] = useState([])

  const fetchAllTeachers = async () => {
    let endpoint = config.endpoint + "/teacher/"
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      }
    })
    let response = await fetchResponse.json()
    if (response.status === 200) {
      setTeacherData(response.data)
    } else {
      notify({
        message: response.message,
        type: "error"
      })
    }
  }

  useEffect(() => {
    fetchAllTeachers()
  }, [])

  return (
   <>
      {teacherData?.length > 0 &&
          <>
            {teacherData.map((data, index) => {
              if (filter&& !JSON.stringify(data).toLowerCase().includes(filter.toLowerCase())) return null
              return (
                <div className='row justify-between gap-20 items-center w-full mt-4 card cursor-pointer border-l-2 border-l-blue-500' 
                style={{ borderTopLeftRadius: "5px", borderBottomLeftRadius: "5px", borderRadius: "5px", paddingLeft: 10 }}
                  key={index}
                  onClick={() => {
                    if (type === "admin") navigate(`/${type}/teachers/details?teacher_id=${data?.id}`)
                    return
                    // navigate(`/${type}/teachers/details?teacher_id=${data?.id}`)
                  }}
                >
                  <div className="column">
                    <p className="card-title">{data?.first_name} {data?.last_name}</p>
                    <p className="card-text text-sm text-slate-500">{data?.email}</p>
                  </div>
                </div>
              )
            })}
          </>
        }
   </>
  )
}
