import React, { useState, useEffect } from 'react'
import Navbar from '../../components/Navbar'
import UserHeader from '../../components/UserHeader'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import TeachersLayout from '../../layout/TeachersLayout'
import { useParams } from 'react-router'
import notify from '../../utils/IANotification'
import { FaChevronDown, FaChevronRight } from 'react-icons/fa'
import { calculate_time_difference, tidyDate } from '../../utils/helper'
import AdminCourseNavbar from '../../components/AdminCourseNavbar'
import AssessmentSubmissionAttemptPage from '../../subpages/AssessmentSubmissionAttemptPage'
import StaffCourseNavbar from '../../components/StaffCourseNavbar'

export default function StaffAssessmentSubmissionAttemptPage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = useFetchUserData()
  const assessment_id = new URLSearchParams(window.location.search)?.get("assessment_id")
  const schedule_id = new URLSearchParams(window.location.search)?.get("schedule_id")
  const attempt_id = new URLSearchParams(window.location.search)?.get("attempt_id")
  const { course_id } = useParams()
  const [submissionData, setSubmissionData] = useState([])



  const fetchAssessmentSubmissions = async () => {
    if (attempt_id === "new") return
    let endpoint = config.endpoint + "/assessment/attempt/id/" + attempt_id
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      setSubmissionData(data.data)
    } else {
      notify({ message: data.message, type: "error" })
    }
  }


  useEffect(() => {
    fetchAssessmentSubmissions()
  }, [])


  return (
    <TeachersLayout navSelected={"Classes"}>
      <p className='mb-4' style={{ fontSize: 30, fontWeight: "500" }}>Attempt</p>
      {course_id != "new" && <StaffCourseNavbar selected={"Assessments"} course_id={course_id} />}
      <p style={{ fontSize: 20, fontWeight: "500" }}>{submissionData?.student_data?.first_name} {submissionData?.student_data?.last_name} {calculate_time_difference(submissionData?.attempt_data?.start_datetime, submissionData?.attempt_data?.end_datetime)?.hours}h {calculate_time_difference(submissionData?.attempt_data?.start_datetime, submissionData?.attempt_data?.end_datetime)?.minutes}m {calculate_time_difference(submissionData?.attempt_data?.start_datetime, submissionData?.attempt_data?.end_datetime)?.seconds}s</p>
      <AssessmentSubmissionAttemptPage type={"admin"} />
    </TeachersLayout>
  )
}