import React, { useEffect, useState } from 'react'
import Navbar from '../../components/Navbar'
import UserHeader from '../../components/UserHeader'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import TeachersLayout from '../../layout/TeachersLayout'
import notify from '../../utils/IANotification'
import TeacherForm from '../../subpages/TeacherForm'

export default function StaffTeacherFormPage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = useFetchUserData()
  let teacher_id = new URLSearchParams(window.location.search)?.get("teacher_id")



  return (
    <TeachersLayout navSelected={"Teachers"}>
      <TeacherForm/>
    </TeachersLayout>
  )
}
