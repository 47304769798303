import React, { useEffect, useState } from 'react'
import Navbar from '../../components/Navbar'
import UserHeader from '../../components/UserHeader'
import { useNavigate, useParams } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import UserLayout from '../../layout/UserLayout'
import notify from '../../utils/IANotification'
import StudentCourseNavbar from '../../components/StudentCourseNavbar'
import { tidyDate } from '../../utils/helper'
import { MdChevronRight, MdEditDocument, MdPlayLesson } from 'react-icons/md'

export default function CourseHomePage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = useFetchUserData()

  const [typesOfAssessments, setTypesOfAssessments] = useState(["Assignments", "Examinations"])

  let { course_id } = useParams()

  const [courseData, setCourseData] = useState({})
  const [selected, setSelected] = useState("Lessons")
  const [completionData, setCompletionData] = useState({})
  const [assessmentData, setAssessmentData] = useState({})
  const [materialData, setMaterialData] = useState({})

  const fetchCourseData = async () => {
    if (!course_id) return
    let endpoint = config.endpoint + "/course/student/home/" + course_id
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      setCourseData(data.data)
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  const fetchCourseMaterials = async () => {
    if (!course_id) return
    let endpoint = config.endpoint + "/materials/student/course/id/" + course_id
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      setMaterialData(data.data)
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  const fetchCompletionStatus = async () => {
    if (!course_id) return
    let endpoint = config.endpoint + "/completion/student/course/id/" + course_id
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      setCompletionData(data.data)
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  const fetchAssessmentData = async (selectedType) => {
    if (!course_id) return
    if (!selectedType) return
    let endpoint = config.endpoint + "/assessment/student/type/" + selectedType + "/course/id/" + course_id
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      let temp = { ...assessmentData }
      temp[selectedType] = data.data
      setAssessmentData(temp)
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  useState(() => {
    fetchCourseData()
    fetchCompletionStatus()
    fetchCourseMaterials()
  }, [])

  useEffect(() => {
    if (typesOfAssessments.length > 0) {
      typesOfAssessments.map(async (type, index) => {
        await fetchAssessmentData(type)
      })
    }
  }, [typesOfAssessments, selected])

  useEffect(() => {
    fetchAssessmentData(selected)
  }, [selected])

  return (
    <UserLayout navSelected={"Courses"}>
      <div className="row gap-4">
        {/* Header */}
        <div className="card w-60 rounded-md shadow-lg" style={{ padding: 0, width: "100%" }}>
          <img src={courseData?.course_data?.file_data?.ext_file_path ? `${config.endpoint}${courseData?.course_data?.file_data?.ext_file_path}` : "/default-course.png"} alt="" style={{ height: 300, width: "100%", objectFit: "cover" }} 
            className="rounded-t-md"
          />
          <div className="p-all">
            <h1 className="font-semibold text-md">{courseData?.course_data?.name}</h1>
            <h1 className="text-base">{courseData?.course_data?.description}</h1>
          </div>
        </div>

        {/* Materials */}
        <div className="card w-40 rounded-md shadow-lg" style={{ padding: 0, width: "40%" }}>
          <div className="p-all">
            <h1 style={{ fontWeight: 500, fontSize: 12 }}>Course Materials</h1>
            <div className="row gap-4">
              {materialData?.length > 0 ? materialData?.map((material) => {
                return (
                  <div className="p-all row gap items-center cursor-pointer"
                    href={`${config.endpoint}${material?.file_data?.ext_file_path}`}
                    onClick={() => {
                      window.open(`${config.endpoint}${material?.file_data?.ext_file_path}`, "_blank")
                    }}
                  >
                    <MdChevronRight style={{ fontSize: 20 }} />
                    <h1 style={{ fontWeight: 500, fontSize: 15, textDecoration: "underline" }}>{material?.name}</h1>
                    {/* <pre>{JSON.stringify(material,null,2)}</pre> */}
                  </div>
                )
              })
                :
                <div className="p-all row gap items-center">
                  <MdEditDocument style={{ fontSize: 20 }} />
                  <h1 style={{ fontWeight: 500, fontSize: 15 }}>Materials will be uploaded here</h1>
                </div>}
            </div>
          </div>
        </div>

      </div>
      {/* Navbar */}
      <StudentCourseNavbar selected={selected} setSelected={setSelected} />
      <div className="card rounded-b-md rounded-tr-md shadow-lg">
        {selected === "Lessons" &&
          <div className='mt-4'>
            {Object.keys(completionData).length > 0 ? courseData?.lesson_data?.map((lesson) => {
              return (
                <div className="row items-center justify-between">
                  <div className="row gap-4 mb-2 items-center">
                    <img src="/default-header.png" alt="" style={{ width: 200, height: 70, objectFit: "cover" }} className="rounded-md" />
                    <div>
                      <p className="text-md font-semibold">{lesson.name}</p>
                      <p className="text-xs text-slate-500">{lesson.name}</p>
                    </div>
                  </div>
                  <div className="row gap-30 items-center">

                    <div>
                      <progress 
                      className="bg-slate-500"
                      value={completionData?.find((completion) => {
                        return completion.lesson_id == lesson.id
                      })?.completed} max={completionData?.find((completion) => {
                        return completion.lesson_id == lesson.id
                      })?.components} style={{ maxWidth: 200 }} />
                      <p style={{ fontSize: 12}} className="text-slate-500">{(completionData?.find((completion) => {
                        return completion.lesson_id == lesson.id
                      })?.completed / completionData?.find((completion) => {
                        return completion.lesson_id == lesson.id
                      })?.components * 100).toFixed(1)}% completed</p>
                    </div>
                    <div style={{ width: 120, display: "column", alignItems: "flex-end", justifyContent: "right", alignContent: "flex-end" }}>
                      <button className="p-2 button text-white rounded-md font-['Poppins'] hover:bg-blue-600 transition-colors" style={{ width: "100%", fontSize: 12 }} onClick={() => {
                        navigate(`/lesson/${lesson.id}?course_id=${course_id}`)
                      }}>{completionData?.find((completion) => {
                        return completion.lesson_id == lesson.id
                      })?.completed > 0 ? "CONTINUE" : "START"}</button>
                    </div>
                  </div>
                </div>
              )
            })
              :
              <div className="row items-center justifyCenter" style={{ height: 200 }}>
                <h1 style={{ fontWeight: 500, fontSize: 15 }}>No lessons available</h1>
              </div>}
          </div>
        }
        {typesOfAssessments.includes(selected) &&
          <div className='mt-4'>
            {/* <pre>{JSON.stringify(assessmentData, null, 2)}</pre> */}
            {/* <p>{selected}</p> */}
            {assessmentData[selected]?.length > 0 ? assessmentData[selected]?.map((assessment) => {
              return (
                <div className="row items-center justify-between">
                  <div className="row gap-4 mb-2 items-center">
                    <img src="/default-header.png" alt="" style={{ width: 200, height: 70, objectFit: "cover" }} className="rounded-md"/>
                    <div>
                      <p className="text-md font-semibold">{assessment.title}</p>
                      <p className="text-xs text-slate-500">{assessment?.can_start == 0 && "Starts on "}{tidyDate(assessment.start_date)}{assessment?.no_end_date == 1 ? "" : ` - ${tidyDate(assessment.end_date)}`}</p>
                    </div>
                  </div>
                  <div className="row gap-30 items-center">
                    <div style={{ width: 120, display: "column", alignItems: "flex-end", justifyContent: "right", alignContent: "flex-end" }}>
                      <button className="p-2 button text-white rounded-md font-['Poppins'] hover:bg-blue-600 transition-colors" style={{ width: "100%", fontSize: 12 }} onClick={() => {
                        if (assessment?.can_start == 0) return notify({ message: "Assessment is not available yet", type: "error" })
                        navigate(`/assessment/${assessment.assessment_id}?course_id=${course_id}`)
                      }}>{assessment?.can_start == 0 ? "LOCKED" : "START"}</button>
                    </div>
                  </div>
                </div>
              )
            })
              :
              <div className="row items-center justifyCenter" style={{ height: 200 }}>
                <MdPlayLesson style={{ fontSize: 50 }} />
                <h1 style={{ fontWeight: 500, fontSize: 15 }}>No {selected} available, please refresh this page. If the issue persist, please inform your tutor</h1>
              </div>}
          </div>
        }
      </div>
    </UserLayout>
  )
}
