import React, { useState, useEffect } from 'react'
import Navbar from '../components/Navbar'
import UserHeader from '../components/UserHeader'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../utils/AppFunctions'
import config from '../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import TeachersLayout from '../layout/TeachersLayout'
import { MdQuestionAnswer } from 'react-icons/md'
import TextFeedbackComponent from '../components/feedback/TextFeedbackComponent'
import RadioFeedbackComponent from '../components/feedback/RadioFeedbackComponent'
import CheckboxFeedbackComponent from '../components/feedback/CheckboxFeedbackComponent'
import SliderFeedbackComponent from '../components/feedback/SliderFeedbackComponent'
import notify from '../utils/IANotification'
import { isMobile } from '../utils/helper'

export default function FeedbackReviewFormPage({ type }) {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = useFetchUserData()

  const student_id = new URLSearchParams(window.location.search).get("student_id")
  const form_id = new URLSearchParams(window.location.search).get("form_id")
  const request_id = new URLSearchParams(window.location.search).get("request_id")

  const [studentData, setStudentData] = useState({})
  const [formData, setFormData] = useState({})
  const [requestData, setRequestData] = useState({})
  const [questionAttemptData, setQuestionAttemptData] = useState([])
  const [width, setWidth] = useState(window.innerWidth)

  const fetchFormData = async () => {
    if (!form_id) return
    let endpoint = config.endpoint + `/feedback/form/id/${form_id}`
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      setFormData(data.data)
    }
  }

  const fetchStudentData = async () => {
    if (!student_id) return
    let endpoint = config.endpoint + `/students/id/${student_id}`
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      setStudentData(data.data)
    }
  }

  const fetchQuestionAttemptData = async () => {
    if (!student_id || !form_id || !request_id) return
    let endpoint = config.endpoint + `/feedback/attempt?student_id=${student_id}&form_id=${form_id}&request_id=${request_id}`
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      setQuestionAttemptData(data.data)
    }
  }

  const saveProgress = async () => {
    if (!student_id) return
    let endpoint = config.endpoint + `/feedback/attempt`
    let fetchResponse = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({ student_id, form_id, data: questionAttemptData, request_id })
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      notify({ message: "Progress saved", type: "success" })
      navigate(-1)
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  const fetchRequestData = async () => {
    if (!request_id) return
    let endpoint = config.endpoint + `/feedback/schedule/id/${request_id}`
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      setRequestData(data.data)
    }

  }

  useEffect(() => {
    fetchFormData()
    fetchStudentData()
    fetchQuestionAttemptData()
    fetchRequestData()
  }, [])


  return (
    <>
      {/* individual Student grading */}
      <p className='' style={{ fontSize: 30, fontWeight: "500" }}>{studentData?.first_name} {studentData?.last_name}</p>
      <p className="card-title" style={{ color: "gray" }}>{formData?.form?.title}</p>

      {/* Form */}
      {formData?.questions?.length > 0 ? formData?.questions.map((question, index) => {
        return (
          <div className="card mt-4 " style={{ backgroundColor: "white", borderRadius: "5px" }}>
            <p className="card-title" style={{ color: "gray" }}>Question {index + 1}.</p>
            <div className={`${isMobile(width) ? "column" : "row"}  gap-4 items-start`}>
              <div className='mt-4' style={{ minWidth: "500px", maxWidth: 500 }}>
                <p>{question.question}</p>
                <p style={{ whiteSpace: "pre-wrap", color: "gray", fontSize: 14 }}>{question.rubrics}</p>
              </div>
              {question.type === "text" && <TextFeedbackComponent state={"answer"} data={question.options}
                readonly={type === "parents"}
                userAnswer={questionAttemptData?.find(x => x.question_id == question.id)?.answer}
                onAnswer={(value) => {
                  let search = questionAttemptData?.find(x => x.question_id == question.id)
                  if (search) {
                    let index = questionAttemptData.findIndex(x => x.question_id == question.id)
                    let newQuestionAttempt = [...questionAttemptData]
                    newQuestionAttempt[index].answer = value
                    setQuestionAttemptData(newQuestionAttempt)
                  } else {
                    let newQuestionAttempt = [...questionAttemptData]
                    newQuestionAttempt.push({ question_id: question.id, answer: value })
                    setQuestionAttemptData(newQuestionAttempt)
                  }
                }} index={index} />}
              {question.type === "radio" && <RadioFeedbackComponent state={"answer"} data={question} index={index}
                readonly={type === "parents"}
                item_index={index}
                onAnswer={(value) => {
                  let search = questionAttemptData?.find(x => x.question_id == question.id)
                  if (search) {
                    let index = questionAttemptData.findIndex(x => x.question_id == question.id)
                    let newQuestionAttempt = [...questionAttemptData]
                    newQuestionAttempt[index].answer = value
                    setQuestionAttemptData(newQuestionAttempt)
                  } else {
                    let newQuestionAttempt = [...questionAttemptData]
                    newQuestionAttempt.push({ question_id: question.id, answer: value })
                    setQuestionAttemptData(newQuestionAttempt)
                  }
                }}
                userAnswer={questionAttemptData?.find(x => x.question_id == question.id)?.answer}
                onChange={(value) => { }}
              />}
              {question.type === "checkbox" && <CheckboxFeedbackComponent state={"answer"} data={question} index={index}
                readonly={type === "parents"}
                item_index={index}
                onAnswer={(value) => {
                  console.log(value)
                  let search = questionAttemptData?.find(x => x.question_id == question.id)
                  if (search) {
                    let index = questionAttemptData.findIndex(x => x.question_id == question.id)
                    let newQuestionAttempt = [...questionAttemptData]
                    newQuestionAttempt[index].answer = value
                    setQuestionAttemptData(newQuestionAttempt)
                  } else {
                    let newQuestionAttempt = [...questionAttemptData]
                    newQuestionAttempt.push({ question_id: question.id, answer: value })
                    setQuestionAttemptData(newQuestionAttempt)
                  }
                }}
                userAnswer={questionAttemptData?.find(x => x.question_id == question.id)?.answer || ""}
                onChange={(value) => { }}
              />}
              {question.type === "slider" && <SliderFeedbackComponent state={"answer"} data={question} index={index}
                readonly={type === "parents"}
                item_index={index}
                onAnswer={(value) => {
                  let search = questionAttemptData?.find(x => x.question_id == question.id)
                  if (search) {
                    let index = questionAttemptData.findIndex(x => x.question_id == question.id)
                    let newQuestionAttempt = [...questionAttemptData]
                    newQuestionAttempt[index].answer = value
                    setQuestionAttemptData(newQuestionAttempt)
                  } else {
                    let newQuestionAttempt = [...questionAttemptData]
                    newQuestionAttempt.push({ question_id: question.id, answer: value })
                    setQuestionAttemptData(newQuestionAttempt)
                  }
                }}
                userAnswer={questionAttemptData?.find(x => x.question_id == question.id)?.answer}
                onChange={(value) => { }}
              />}
            </div>
          </div>
        )
      })
        :
        <div className='card'>
          <MdQuestionAnswer style={{ fontSize: 50, color: "gray" }} />
          <p style={{ color: "gray" }}>No form questions found</p>
        </div>
      }
      <div className="column right alignEnd">
        <button className="button mt-4"
          disabled={requestData?.is_released == "1"}
          onClick={() => {
            saveProgress()
          }}
        >Save Progress</button>
      </div>
      {/* <pre>{JSON.stringify(questionAttemptData, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(studentData, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(requestData, null, 2)}</pre> */}
    </>
  )
}
