const tidyDate = (date) => {

    if (!isNaN(date)) {
        let seconds = date
        date = new Date(date * 1000)
    }
    // if (!date?.includes("Z")) {
    //     date = date + "Z"
    // }
    let day = new Date(date).getDate()
    let month = new Date(date).getMonth() + 1
    let year = new Date(date).getFullYear()
    let hour = new Date(date).getHours()
    let minute = new Date(date).getMinutes()

    if (day < 10) {
        day = "0" + day
    }

    if (month < 10) {
        month = "0" + month
    }

    if (hour < 10) {
        hour = "0" + hour
    }

    if (minute < 10) {
        minute = "0" + minute
    }

    const MONTHS = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oc", "Nov", "Dec"]

    return `${day} ${MONTHS[month - 1]} ${year} ${hour}:${minute}`
}

const tidyDateRaw = (date) => {
    if (!isNaN(date)) {
        let seconds = date
        // date = new Date(date * 1000)
    }
    // if (!date?.includes("Z")) {
    //     date = date + "Z"
    // }
    let day = new Date(date).getDate()
    let month = new Date(date).getMonth() + 1
    let year = new Date(date).getFullYear()
    let hour = new Date(date).getHours()
    let minute = new Date(date).getMinutes()
    let seconds = new Date(date).getSeconds()

    const MONTHS = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

    if (day < 10) {
        day = "0" + day
    }

    if (month < 10) {
        month = "0" + month
    }

    if (hour < 10) {
        hour = "0" + hour
    }

    if (minute < 10) {
        minute = "0" + minute
    }

    if (seconds < 10) {
        seconds = "0" + seconds
    }

    return {
        day,
        month: MONTHS[month - 1],
        year,
        hour,
        minute,
        seconds
    }

}


const currency = (amount) => {
    amount = Math.round((amount) * 100) / 100

    if (amount.toString().includes(".")) {
        amount = amount.toFixed(2)
    }

    // add commas
    amount = amount.toLocaleString()

    return amount
}

const isMobile = (width) => {
    if (width < 700) {
        return true
    } else {
        return false
    }
}

const capitaliseFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}


const calculate_time_difference = (start_time, end_time) => {
    if (!start_time || !end_time) return
    start_time = new Date(start_time).getTime() / 1000
    end_time = new Date(end_time).getTime() / 1000
    // console.log({start_time, end_time})
    let difference = end_time - start_time
    let hours = Math.floor(difference / 3600)
    let minutes = Math.floor((difference - (hours * 3600)) / 60)
    let seconds = Math.floor(difference - (hours * 3600) - (minutes * 60))

    return {
        hours,
        minutes,
        seconds
    }
}


const calculate_time_difference_tidy = (start_time, end_time) => {
    if (!start_time || !end_time) return
    start_time = new Date(start_time).getTime() / 1000
    end_time = new Date(end_time).getTime() / 1000
    // console.log({start_time, end_time})
    let difference = end_time - start_time
    let years = Math.floor(difference / 31536000)
    let months = Math.floor(difference / 2628000)
    let weeks = Math.floor(difference / 604800)
    let days = Math.floor(difference / 86400) 
    let hours = Math.floor(difference / 3600)
    let minutes = Math.floor((difference - (hours * 3600)) / 60)
    let seconds = Math.floor(difference - (hours * 3600) - (minutes * 60))
    
    if (years > 0) {
        return `${years} years ago`
    }
    if (months > 0) {
        return `${months} months ago`
    }
    if (weeks > 0) {
        return `${weeks} weeks ago`
    }
    if (days > 0) {
        return `${days} days ago`
    }
    if (hours > 0) {
        return `${hours} hours ${minutes} minutes ago`
    }else if (minutes > 0) {
        return `${minutes} minutes ago`
    }
    return `${seconds} seconds ago`
}

module.exports = {
    tidyDate,
    currency,
    isMobile,
    capitaliseFirstLetter,
    calculate_time_difference,
    tidyDateRaw,
    calculate_time_difference_tidy,
}