import React, { useEffect, useState } from 'react'
import Navbar from '../../components/Navbar'
import UserHeader from '../../components/UserHeader'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import notify from '../../utils/IANotification'
import TeacherList from '../../subpages/TeacherList'
import TeachersLayout from '../../layout/TeachersLayout'

export default function StaffTeacherPage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = useFetchUserData()

  return (
    <TeachersLayout navSelected={"Teachers"}>
      <p className='mb-4' style={{ fontSize: 30, fontWeight: "500" }}>Teachers</p>
      <button className="button rounded-md shadow-md px-4 py-2 text-white text-sm font-['Poppins']"
        onClick={() => {
          navigate("/admin/teachers/details?teacher_id=new")
        }}
      >Add Teacher</button>
      <TeacherList type={"staff"}/>
    </TeachersLayout>
  )
}
