import React, { useState, useEffect } from 'react'
import Navbar from '../../components/Navbar'
import UserHeader from '../../components/UserHeader'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import AdminLayout from '../../layout/AdminLayout'
import { useParams } from 'react-router'
import notify from '../../utils/IANotification'
import AdminFeedbackNavbar from '../../components/AdminFeedbackNavbar'
import { IoEaselOutline } from 'react-icons/io5'
import { FaChalkboardTeacher } from 'react-icons/fa'
import { MdFeedback } from 'react-icons/md'

export default function AdminStudentFeedbackRequestPage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("staff")
  let user_data = useFetchUserData()

  const [feedbackRequestData, setFeedbackRequestData] = useState([])
  const [filter, setFilter] = useState("")

  const fetchFeedbackRequestData = async () => {
    let endpoint = config.endpoint + "/feedback/schedule"
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      }
    })
    let response = await fetchResponse.json()
    if (response.status === 200) {
      setFeedbackRequestData(response.data)
    } else {
      notify({
        message: response.message,
        type: "error"
      })
    }
  }

  useEffect(() => {
    fetchFeedbackRequestData()
  }, [])

  return (
    <AdminLayout navSelected={"Feedback & Review"}>
      <p className='mb-4' style={{ fontSize: 30, fontWeight: "500" }}>Feedback & Review</p>
      <AdminFeedbackNavbar selected={"Request"} />
      <div className="mt-4">
        {feedbackRequestData?.length > 0 ?
          <>
            {feedbackRequestData.map((data, index) => {
              if (filter && !JSON.stringify(data).toLowerCase().includes(filter.toLowerCase())) return null
              return (
                <div className='row justify-between gap-20 items-center w-full mt-4 card cursor-pointer' style={{ borderLeft: "4px solid var(--primary)", borderTopLeftRadius: "5px", borderBottomLeftRadius: "5px", borderRadius: "5px", paddingLeft: 10 }}
                  key={index}
                  onClick={() => {
                    navigate(`/admin/feedback/request/form?request_id=${data?.id}`)
                  }}
                >
                  <div className="column">
                    <p className="card-title">{data?.class_data?.name}</p>
                    <div className="row items-center gap-20">
                      <div className="row gap-4 items-center">
                        <IoEaselOutline size={15} />
                        <p style={{ color: "gray", fontSize: 12 }}>{data?.class_data?.course_data?.name}</p>
                      </div>
                      <div className="row gap-4 items-center">
                        <FaChalkboardTeacher size={15} />
                        <p style={{ color: "gray", fontSize: 12 }}>{data?.user_data?.first_name} {data?.user_data?.last_name}</p>
                      </div>
                    </div>
                    {/* <pre>{JSON.stringify(data?.user_data,null,2)}</pre> */}
                  </div>
                  {/* <pre>{JSON.stringify(data,null,2)}</pre> */}
                </div>
              )
            })}
          </>
          :
          <div className='card rounded-md shadow-md p-4'>
            <MdFeedback size={50} style={{ color: "gray" }} />
            <p style={{ color: "gray", fontSize: 12 }}>No feedback request found</p>
          </div>
        }
      </div>
    </AdminLayout>
  )
}
