import React, { useEffect, useState } from 'react'
import Navbar from '../../components/Navbar'
import UserHeader from '../../components/UserHeader'
import { useNavigate, useParams } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import UserLayout from '../../layout/UserLayout'
import notify from '../../utils/IANotification'
import AssessmentLayout from '../../layout/AssessmentLayout'
import SingleSelectAssessmentComponent from '../../components/assessment/SingleSelectAssessmentComponent'
import SingleTextAssessmentComponent from '../../components/assessment/SingleTextAssessmentComponent'
import MultipleSelectAssessmentComponent from '../../components/assessment/MultipleSelectAssessmentComponent'
import FileUploadAssessmentComponent from '../../components/assessment/FileUploadAssessmentComponent'
import IAPrompt from '../../utils/IAPrompt'
import { calculate_time_difference, isMobile } from '../../utils/helper'
import CodeEditorAssessmentComponent from '../../components/assessment/CodeEditorAssessmentComponent'

export default function AssessmentAttemptPage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = useFetchUserData()
  const [width, setWidth] = useState(window.innerWidth)
  let { assessment_id } = useParams()
  let course_id = new URLSearchParams(window.location.search).get("course_id")
  let attempt_id = new URLSearchParams(window.location.search).get("attempt_id")

  const [assessmentData, setAssessmentData] = useState({})
  const [attemptData, setAttemptData] = useState({})
  const [attemptData1, setAttemptData1] = useState({})
  const [isLoading, setIsLoading] = useState(true)

  const [timeLeft, setTimeLeft] = useState(0)

  const fetchAttemptData = async () => {
    if (!attempt_id) return console.log("no attempt id")
    let endpoint = config.endpoint + "/assessment/student/attempt/id/" + attempt_id
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      console.log(data.data)
      setAttemptData1(data.data) //would not be changed
      setAttemptData(data.data)
      setIsLoading(false)
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  const submitAttempt = async () => {
    if (!attempt_id) return
    await submitAutoSave()
    let endpoint = config.endpoint + "/assessment/student/attempt/submit/" + attempt_id
    let fetchResponse = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        assessment_id: assessment_id,
        schedule_assessment_id: assessmentData?.schedule_assessment_data?.id,
      })
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      navigate(-1)
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  const submitAutoSave = async () => {
    if (isLoading) return
    if (!attempt_id) return
    let endpoint = config.endpoint + "/assessment/student/attempt/id/" + attempt_id
    let fetchResponse = await fetch(endpoint, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        attempt_id: attempt_id,
        attempt_data: attemptData
      })
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {

    } else {
      notify({ message: data.message, type: "error" })
    }
  }


  useEffect(() => {
    fetchAttemptData()
  }, [])

  useEffect(() => {
    submitAutoSave()
  }, [])

  // const [change, setChange] = useState(`${calculate_time_difference(attemptData?.start_datetime, new Date().toISOString())?.hours} ${calculate_time_difference(attemptData?.start_datetime, new Date().toISOString())?.minutes} ${calculate_time_difference(attemptData?.start_datetime, new Date().toISOString())?.seconds}`)

  useEffect(() => {
    if (!attemptData1?.start_datetime || !attemptData1?.assessment_data?.time_limit) return
    if (attemptData?.assessment_data?.is_unlimited_time == 1) {
      setTimeLeft("")
      return
    }
    let interval = setInterval(async () => {
      let start = attemptData1?.start_datetime
      // console.log({...attemptData1})
      if (!attemptData1?.start_datetime || !attemptData1?.assessment_data?.time_limit) {
        await fetchAttemptData()
        return
      }
      let end = (new Date(start).getTime()) + attemptData1?.assessment_data?.time_limit * 60 * 1000
      let time_left = calculate_time_difference(new Date().toISOString(), new Date(end || new Date(start).getTime()).toISOString())




      if (time_left?.hours <= 0 && time_left?.minutes <= 0 && time_left?.seconds <= 0 && attemptData?.assessment_data?.time_limit > 0 && attemptData?.assessment_data?.is_unlimited_time == 0) {
        submitAttempt()
      } else {
        setTimeLeft(`${time_left?.hours}h ${time_left?.minutes}m ${time_left?.seconds}s`)
        // document.getElementById("timer").innerText = timeLeft
      }


    }, 900)
    return () => clearInterval(interval)
  }, [attemptData])

  return (
    <AssessmentLayout
      time_left={timeLeft}
    >
      {attemptData?.assessment_questions?.length > 0 &&
        attemptData?.assessment_questions.map((data, index) => {
          return (
            <>
              <div className="row justify-between items-center mt-40">
                <p className="card-title">Question {index + 1}</p>
              </div>
              <div className="row gap-20 items-start">
                <p className='w-full' style={{ fontSize: 13, whiteSpace: "pre-wrap" }}>{data.question}</p>
                <p style={{ fontSize: 12, whiteSpace: "nowrap" }}>{data.max_marks} marks</p>
              </div>
              {data.type === "single-select" && <SingleSelectAssessmentComponent item_index={index} state={"question"} data={data} options={data.options}
                // onOptionChange={(value) => {
                //   let temp = [...componentData]
                //   temp[index].answer_options = value
                //   setComponentData(temp)
                // }}

                // onChange={(value) => {
                //   let temp = [...componentData]
                //   temp[index] = value
                //   setComponentData(temp)
                // }}
                userAnswer={data?.question_attempt_data?.user_answer}
                onAnswered={(value) => {
                  let temp = [...attemptData?.assessment_questions]
                  temp[index].question_attempt_data.user_answer = value
                  setAttemptData({ ...attemptData, assessment_questions: temp })
                }}
              />
              }

              {data.type === "single-text" && <SingleTextAssessmentComponent state={"question"} data={data} options={data.options}
                // onChange={(value) => {
                //   let temp = [...componentData]
                //   temp[index] = value
                //   setComponentData(temp)
                // }}

                //   onOptionChange={(value) => {
                //     let temp = [...componentData]
                //     temp[index].options = value
                //     setComponentData(temp)
                //   }}
                userAnswer={data?.question_attempt_data?.user_answer}
                onAnswered={(value) => {
                  let temp = [...attemptData?.assessment_questions]
                  temp[index].question_attempt_data.user_answer = value
                  setAttemptData({ ...attemptData, assessment_questions: temp })
                }}
              />
              }

              {data.type === "multi-select" && <MultipleSelectAssessmentComponent state={"question"} data={data} options={data.options}
                // onChange={(value) => {
                //   let temp = [...componentData]
                //   temp[index] = value
                //   setComponentData(temp)
                // }}

                //   onOptionChange={(value) => {
                //     let temp = [...componentData]
                //     temp[index].options = value
                //     setComponentData(temp)
                //   }}
                userAnswer={data?.question_attempt_data?.user_answer}
                onAnswered={(value) => {
                  let temp = [...attemptData?.assessment_questions]
                  temp[index].question_attempt_data.user_answer = value
                  setAttemptData({ ...attemptData, assessment_questions: temp })
                }}
              />
              }

              {data.type === "file" && <FileUploadAssessmentComponent item_index={index} state={"question"} data={data} options={data.options}
                // onChange={(value) => {
                //   let temp = [...componentData]
                //   temp[index] = value
                //   setComponentData(temp)
                // }}

                //   onOptionChange={(value) => {
                //     let temp = [...componentData]
                //     temp[index].options = value
                //     setComponentData(temp)
                //   }}
                userAnswer={data?.question_attempt_data?.user_answer}
                onAnswered={(value) => {
                  let temp = [...attemptData?.assessment_questions]
                  temp[index].question_attempt_data.user_answer = value
                  setAttemptData({ ...attemptData, assessment_questions: temp })
                }}
              />
              }

              {data.type === "code" && <CodeEditorAssessmentComponent item_index={index} state={"question"} data={data} options={data.options}
                // onChange={(value) => {
                //   let temp = [...componentData]
                //   temp[index] = value
                //   setComponentData(temp)
                // }}

                //   onOptionChange={(value) => {
                //     let temp = [...componentData]
                //     temp[index].options = value
                //     setComponentData(temp)
                //   }}
                userAnswer={data?.question_attempt_data?.user_answer}
                onAnswered={(value) => {
                  let temp = [...attemptData?.assessment_questions]
                  temp[index].question_attempt_data.user_answer = value
                  setAttemptData({ ...attemptData, assessment_questions: temp })
                }}
              />
              }
              {/* <p>{JSON.stringify(data?.question_attempt_data, null, 2)}</p> */}
            </>
          )
        })}

      <div className="row justifyCenter items-center mt-40">
        <button className="button"
          onClick={() => {
            IAPrompt({
              title: "Submit Assessment",
              message: "Are you sure you want to submit the assessment?",
              buttons: [
                {
                  label: "Cancel",
                  onClick: () => { }
                },
                {
                  label: "Submit",
                  onClick: () => {
                    submitAttempt()
                  }
                }
              ]
            })
          }}
        >Submit Assessment</button>
      </div>
    </AssessmentLayout>
  )
}
