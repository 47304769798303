import React from 'react'

export default function TextFeedbackComponent({ state, data, onChange,userAnswer, index, readonly, onAnswer }) {
    return (
        <>
            {state === "answer" && <>
                <div className="input-container">
                    <textarea
                        className="textarea-input"
                        value={userAnswer}
                        onChange={(e) => {
                            onAnswer(e.target.value)
                        }}
                        placeholder='Type feedback here...'
                        disabled={readonly}
                    />
                </div>
            </>}
        </>
    )
}
