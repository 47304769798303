import React, { useEffect, useState } from 'react'
import Navbar from '../components/Navbar'
import UserHeader from '../components/UserHeader'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../utils/AppFunctions'
import config from '../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import AdminLayout from '../layout/AdminLayout'
import notify from '../utils/IANotification'

export default function FamilyList({ type, filter }) {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = useFetchUserData()


  const [familyData, setFamilyData] = useState([])

  const fetchAllFamily = async () => {
    let endpoint = config.endpoint + "/family/"
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      }
    })
    let response = await fetchResponse.json()
    if (response.status === 200) {
      setFamilyData(response.data)
    } else {
      notify({
        message: response.message,
        type: "error"
      })
    }
  }

  useEffect(() => {
    fetchAllFamily()
  }, [])

  return (
    <>
      {familyData?.length > 0 &&
        <>
          {familyData.map((data, index) => {
            if (filter && !JSON.stringify(data).toLowerCase().includes(filter.toLowerCase())) return null
            return (
              <div className='row justify-between gap-20 items-center w-full mt-4 card cursor-pointer' style={{ borderLeft: "4px solid var(--primary)", borderTopLeftRadius: "5px", borderBottomLeftRadius: "5px", borderRadius: "5px", paddingLeft: 10 }}
                key={index}
                onClick={() => {
                  navigate(`/${type}/family/details?family_id=${data?.id}`)
                }}
              >
                <div className="column">
                  <p className="card-title">{data?.family_name}</p>
                </div>
              </div>
            )
          })}
        </>
      }
    </>
  )
}
