import React, { useState, useEffect } from 'react'
import Navbar from '../../components/Navbar'
import UserHeader from '../../components/UserHeader'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import TeachersLayout from '../../layout/TeachersLayout'
import notify from '../../utils/IANotification'
import { MdFlag } from 'react-icons/md'
import IAPrompt from '../../utils/IAPrompt'
import FeedbackReviewPage from '../../subpages/FeedbackReviewPage'

export default function StaffFeedbackReviewPage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = useFetchUserData()
  const [feedbackData, setFeedbackData] = useState([])
  let request_id = new URLSearchParams(window.location.search).get("request_id")
  const [classID, setClassID] = useState("")
  const [studentData, setStudentData] = useState([])
  const [completionData, setCompletionData] = useState([])
  const [filter, setFilter] = useState("")


  const fetchFeedbackById = async () => {
    if (!request_id) return notify({ message: "Unable to fetch review request", type: "error" })
    let endpoint = config.endpoint + `/feedback/schedule/id/${request_id}`
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      setFeedbackData(data.data)
      setClassID(data.data?.class_data?.id)
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  const getStudentsByClassId = async () => {
    if (classID === "new") return
    if (!classID) return
    let endpoint = config.endpoint + "/class/student/class/id/" + classID
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      setStudentData(data.data)
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  const fetchFeedbackCompletion = async () => {
    if (!request_id) return
    let endpoint = config.endpoint + `/feedback/completion/request/id/${request_id}`
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      setCompletionData(data.data)
    }
  }

  const submitFeedback = async () => {
    if (!request_id) return notify({ message: "Unable to mark as complete", type: "error" })
    let endpoint = config.endpoint + `/feedback/submit`
    let fetchResponse = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({ request_id })
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      notify({ message: "Marked as complete", type: "success" })
      navigate(-1)
    } else {
      notify({ message: data.message, type: "error" })
    }

  }

  useEffect(() => {
    fetchFeedbackById()
    fetchFeedbackCompletion()

  }, [])

  useEffect(() => {
    getStudentsByClassId()
  }, [classID])

  return (
    <TeachersLayout navSelected={"Feedback & Review"}>
      <FeedbackReviewPage type={"staff"}/>
    </TeachersLayout>
  )
}
