import React, { useEffect, useState } from 'react'
import Navbar from '../../components/Navbar'
import UserHeader from '../../components/UserHeader'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import AdminLayout from '../../layout/AdminLayout'
import notify from '../../utils/IANotification'
import EnrolmentList from '../../subpages/EnrolmentList'

export default function AdminEnrolmentPage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("staff")
  let user_data = useFetchUserData()

  const [studentsData, setStudentsData] = useState([])
  const [searchQuery, setSearchQuery] = useState("")

  const fetchAllStudents = async () => {
    let endpoint = config.endpoint + "/students/"
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      }
    })
    let response = await fetchResponse.json()
    if (response.status === 200) {
      setStudentsData(response.data)
    } else {
      notify({
        message: response.message,
        type: "error"
      })
    }
  }

  useEffect(() => {
    fetchAllStudents()
  }, [])

  return (
    <AdminLayout navSelected={"Enrolments"}>
      <p className='mb-4' style={{ fontSize: 30, fontWeight: "500" }}>Enrolments</p>

        {/* <button className="button"
          onClick={() => {
            navigate("/admin/family/details?family_id=new")
          }}
        >Add Family</button> */}
        <div className="input-container">
          <input type="text" className="text-input" placeholder="Search courses..." value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
        </div>
        <button className="button"
          onClick={() => {
            navigate("/admin/students/details?student_id=new")
          }}
        >Add Student</button>
      <EnrolmentList type={"admin"} filter={searchQuery}/>
    </AdminLayout>
  )
}
