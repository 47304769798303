import React, { useState, useEffect } from 'react'
import Navbar from '../../components/Navbar'
import UserHeader from '../../components/UserHeader'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import AdminLayout from '../../layout/AdminLayout'
import notify from '../../utils/IANotification'
import { MdFlag } from 'react-icons/md'
import IAPrompt from '../../utils/IAPrompt'
import FeedbackReviewPage from '../../subpages/FeedbackReviewPage'

export default function AdminFeedbackReviewPage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("staff")
  let user_data = useFetchUserData()
  const [feedbackData, setFeedbackData] = useState([])
  let request_id = new URLSearchParams(window.location.search).get("request_id")
  const [classID, setClassID] = useState("")
  const [studentData, setStudentData] = useState([])
  const [completionData, setCompletionData] = useState([])
  const [filter, setFilter] = useState("")
  const [classData, setClassData] = useState({})
  const [submissionData, setSubmissionData] = useState({})

  const fetchFeedbackById = async () => {
    if (!request_id) return notify({ message: "Unable to fetch review request", type: "error" })
    let endpoint = config.endpoint + `/feedback/attempt/completed/request/id/${request_id}`
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      setFeedbackData(data.data?.feedback_data)
      setClassID(data.data?.class_data?.id)
      setClassData(data.data)
      setSubmissionData(data.data)
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  const getStudentsByClassId = async () => {
    if (classID === "new") return
    if (!classID) return
    let endpoint = config.endpoint + "/class/student/class/id/" + classID
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      setStudentData(data.data)
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  const fetchFeedbackCompletion = async () => {
    if (!request_id) return
    let endpoint = config.endpoint + `/feedback/completion/request/id/${request_id}`
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      setCompletionData(data.data)
    }
  }
  useEffect(() => {
    fetchFeedbackById()
    fetchFeedbackCompletion()

  }, [])

  useEffect(() => {
    getStudentsByClassId()
  }, [classID])


  const releaseFeedback = async () => {
    if (!request_id) return notify({ message: "Unable to release feedback", type: "error" })
    let endpoint = config.endpoint + `/feedback/release`
    let fetchResponse = await fetch(endpoint, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        request_id,
      })
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      notify({ message: data.message, type: "success" })
      fetchFeedbackById()
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  return (
    <AdminLayout navSelected={"Feedback & Review"}>
      <div className="row justify-between w-full items-center">
        <div>
          <p className='' style={{ fontSize: 30, fontWeight: "500" }}>{classData?.class_data?.name}</p>
          <p className="card-title" style={{ color: "gray", fontWeight: "900" }}>Submitted Feedback & Review</p>
        </div>
        <button className="button"
          disabled={submissionData?.request_data?.is_released == "1"}
          onClick={() => {
            IAPrompt({
              title: "Release Feedback",
              message: "Are you sure you want to release feedback?",
              buttons: [
                {
                  label: "No",
                  onClick: () => {

                  }
                },
                {
                  label: "Yes",
                  onClick: () => {
                    releaseFeedback()
                  }
                },
              ]
            })
          }}
        >{submissionData?.request_data?.is_released == "0" ? "Release Feedback" : "Feedback Released"}</button>
      </div>
      <div className="mt-4 input-container">
        <input type="text" className="text-input" placeholder="Search for students..." value={filter} onChange={(e) => {
          setFilter(e.target.value)
        }} />
      </div>
      {feedbackData?.length > 0 ? feedbackData.map((data, index) => {
        if (filter && !JSON.stringify(data)?.toLowerCase().includes(filter.toLowerCase())) return null
        let completed = completionData?.students?.find((item) => item?.user_id == data?.user_data?.id)?.attempt
        let total = completionData?.total_questions
        let percentage = isNaN((parseInt(completed) / parseInt(total)) * 100) ? 0 : (completed / total) * 100
        return (
          <div className='p-all row justify-between gap-20 items-center w-full mt-4 cursor-pointer'
            onClick={() => {
              navigate(`/admin/feedback/review/form?request_id=${request_id}&student_id=${data?.user_data?.id}&form_id=${data?.form_id}`)
            }}
            style={{ borderLeft: "4px solid var(--primary)", backgroundColor: "white", borderTopLeftRadius: "5px", borderBottomLeftRadius: "5px", borderRadius: "5px", paddingLeft: 10 }}>
            <div className="column">
              <p className="card-title">{data?.user_data?.first_name} {data?.user_data?.last_name}</p>
              <div className="row items-center gap-20">
                <div className="row gap-4 items-center">
                  <p style={{ color: "gray", fontSize: 12 }}>{data?.user_data?.email}</p>
                </div>
              </div>
            </div>
            {/* <button className="button"
              onClick={() => {
                navigate(`/staff/feedback/review/form?request_id=${request_id}&student_id=${data?.user_data?.id}`)
              }}
            >View</button> */}
            <div className="row gap-4 items-center">
              <div>
                <progress value={completed} max={total}></progress>
                <p style={{ fontWeight: "900", color: "gray", fontSize: 12 }}>{percentage?.toFixed(2)}%</p>
              </div>
            </div>
          </div>
        )
      })
        :
        <div className='card'>
          <MdFlag style={{ fontSize: 50, color: "gray" }} />
          <p style={{ color: "gray", fontSize: 12 }}>You have no student to review at the moment.</p>
        </div>
      }

      {/* <pre>{JSON.stringify(completionData, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(studentData, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(submissionData, null, 2)}</pre> */}
    </AdminLayout>
  )
}
