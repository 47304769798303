import React, { useState, useEffect } from 'react'
import Navbar from '../../components/Navbar'
import UserHeader from '../../components/UserHeader'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import AdminLayout from '../../layout/AdminLayout'
import { useParams } from 'react-router'
import notify from '../../utils/IANotification'
import { FaChevronDown, FaChevronRight } from 'react-icons/fa'
import { tidyDate } from '../../utils/helper'
import AdminCourseNavbar from '../../components/AdminCourseNavbar'
import AssessmentSubmissionPage from '../../subpages/AssessmentSubmissionPage'

export default function AdminAssessmentSubmissionPage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("staff")
  let user_data = useFetchUserData()
  const assessment_id = new URLSearchParams(window.location.search)?.get("assessment_id")
  const schedule_id = new URLSearchParams(window.location.search)?.get("schedule_id")
  const course_id = new URLSearchParams(window.location.search)?.get("course_id")
  const [assessmentData, setAssessmentData] = useState([])
  const [submissionData, setSubmissionData] = useState([])

  const fetchAssessmentById = async () => {
    if (assessment_id === "new") return
    let endpoint = config.endpoint + "/assessment/id/" + assessment_id
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      setAssessmentData(data.data)
    } else {
      notify({ message: data.message, type: "error" })
    }
  }


  const fetchAssessmentSubmissions = async () => {
    if (schedule_id === "new") return
    let endpoint = config.endpoint + "/assessment/submission/schedule/id/" + schedule_id
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      setSubmissionData(data.data)
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  useEffect(() => {
    fetchAssessmentById()
    fetchAssessmentSubmissions()
  }, [])


  return (
    <AdminLayout navSelected={"Courses"}>
      <p className='mb-4' style={{ fontSize: 30, fontWeight: "500" }}>{assessmentData?.name} Submissions</p>
      {course_id != "new" && <AdminCourseNavbar selected={"Assessments"} course_id={course_id} />}
      {/* <pre>{JSON.stringify(submissionData, null, 2)}</pre> */}
      <AssessmentSubmissionPage type={"admin"} />
    </AdminLayout>
  )
}