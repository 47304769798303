import React, { useLayoutEffect, useState } from 'react'
import { MdAppBlocking, MdClass, MdGroup, MdHome, MdSettings } from 'react-icons/md'
import { useParams } from 'react-router-dom'
import { TbReport } from "react-icons/tb";
import { IoDocumentsOutline, IoEaselOutline, IoHomeOutline, IoJournalOutline, IoLibraryOutline, IoMegaphone, IoMegaphoneOutline, IoNewspaperOutline, IoPeopleOutline, IoSettingsOutline } from "react-icons/io5";

export default function StaffCourseNavbar({ selected }) {

    const course_id = new URLSearchParams(window.location.search)?.get("course_id")
    const class_id = new URLSearchParams(window.location.search)?.get("class_id")
    const lesson_id = new URLSearchParams(window.location.search)?.get("lesson_id")

    const [headerHeight, setHeaderHeight] = useState(document?.getElementById("admin-header")?.clientHeight + 1)

    useLayoutEffect(() => {
        setHeaderHeight(document?.getElementById("admin-header")?.clientHeight + 1)
    }, [])

    const ICON_SIZE = 20

    const ITEMS = [
        {
            "name": "Class",
            "link": `/staff/courses/classes/details?course_id=${course_id}` + (class_id ? `&class_id=${class_id}` : ""),
            "icon": <IoEaselOutline size={ICON_SIZE} />,
        },
        {
            "name": "Lessons",
            "link": `/staff/courses/lessons?course_id=${course_id}` + (class_id ? `&class_id=${class_id}` : ""),
            "icon": <IoLibraryOutline size={ICON_SIZE} />,
        },
        {
            "name": "Assessments",
            "link": `/staff/courses/assessments?course_id=${course_id}` + (class_id ? `&class_id=${class_id}` : ""),
            "icon": <IoNewspaperOutline size={ICON_SIZE} />,
        },
        {
            "name": "Assignments",
            "link": `/staff/courses/assessments/type?course_id=${course_id}&type=assignments` + (class_id ? `&class_id=${class_id}` : ""),
            "icon": <IoJournalOutline size={ICON_SIZE} />,
        },
        {
            "name": "Examinations",
            "link": `/staff/courses/assessments/type?course_id=${course_id}&type=examinations` + (class_id ? `&class_id=${class_id}` : ""),
            "icon": <IoJournalOutline size={ICON_SIZE} />,
        },
    ]
    return (
        <div id='course-nav' className='row mb-6 items-center hide-scrollbars bg-slate-100 rounded-md shadow-sm w-fit' style={{
            borderBottom: "1px solid #E5E5E5",
            position: "sticky",
            top: headerHeight,
            zIndex: 5,
            backgroundColor: "white",
            overflowY: "auto",
        }}>
            {ITEMS.map((item, index) => {
                return (
                    <div className={`row gap-4 items-center px-4 py-2 cursor-pointer`} style={{ borderBottom: selected === item.name ? "2px solid var(--primary)" : "", }} onClick={() => {
                        if (!class_id) {
                            window.location.href = "/staff/classes"
                        } else {
                            window.location.href = item.link
                        }
                    }}>
                        {item.icon}
                        <p style={{ fontSize: 12, fontWeight: 500 }}>{item.name}</p>
                    </div>
                )
            })}
        </div>
    )
}
