import React, { useEffect, useState } from 'react'
import Navbar from '../components/Navbar'
import UserHeader from '../components/UserHeader'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../utils/AppFunctions'
import config from '../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import AdminLayout from '../layout/AdminLayout'
import { useParams } from 'react-router'
import AdminCourseNavbar from '../components/AdminCourseNavbar'
import notify from '../utils/IANotification'
import Switch from "react-switch";
import { isMobile, tidyDate, tidyDateRaw } from '../utils/helper'

export default function AttendanceForm({ type }) {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = useFetchUserData()

  const course_id = new URLSearchParams(window.location.search)?.get("course_id")
  const class_id = new URLSearchParams(window.location.search)?.get("class_id")
  const session_id = new URLSearchParams(window.location.search)?.get("session_id")


  const [attendanceData, setAttendanceData] = useState({})
  const [studentData, setStudentData] = useState([])
  const [startDateTime, setStartDateTime] = useState(new Date())
  const [endDateTime, setEndDateTime] = useState(new Date())
  const [remarks, setRemarks] = useState("")
  const [width, setWidth] = useState(window.innerWidth);


  const getStudentsByClassId = async () => {
    if (class_id === "new") return
    if (!class_id) return
    let endpoint = config.endpoint + "/class/student/class/id/" + class_id
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      let students = data.data
      if (session_id === "new") {
        let attendance = students.map((student) => {
          return {
            user_id: student.user_id,
            attendance: "present", // default to present - present, absent, late
            class_id,
          }
        })

        setAttendanceData(attendance)
      }
      setStudentData(students)
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  const getAttendanceSessionById = async () => {
    if (class_id === "new") return
    if (!class_id) return
    if (session_id === "new") return
    let endpoint = config.endpoint + "/attendance/mark/session/id/" + session_id
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      setEndDateTime(data.data?.class_session_data?.end_datetime)
      setStartDateTime(data.data?.class_session_data?.start_datetime)
      setRemarks(data.data?.class_session_data?.remarks)
      setAttendanceData(data.data?.attendance_records)
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  const submitAttendance = async () => {
    if (class_id === "new") return
    if (!class_id) return
    let endpoint = config.endpoint + "/attendance/mark/class/id/"
    let fetchResponse = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        class_id,
        students: attendanceData,
        start_datetime: startDateTime,
        end_datetime: endDateTime,
        remarks,
      })
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      getAttendanceSessionById()
      getStudentsByClassId()
      notify({ message: data.message, type: "success" })
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  useEffect(() => {
    getAttendanceSessionById()
    getStudentsByClassId()
  }, [])

  return (
    <div className='column center items-center card'>
      <div className={`${isMobile(width) ? "w-full" : "w-50"} column gap-20`}>
        <p className='mb-4' style={{ fontSize: 20, fontWeight: "500" }}>Class - {tidyDateRaw(new Date().toISOString())?.day + " " + tidyDateRaw(new Date().toISOString())?.month + " " + tidyDateRaw(new Date().toISOString())?.year}</p>
        <div className="row items-center justify-between w-full">
          <p>Class Start Time</p>
          <input type="datetime-local" className='text-input' value={startDateTime} onChange={(e) => {
            setStartDateTime(e.target.value)
          }} />
        </div>
        <div className="row items-center justify-between w-full">
          <p>Class End Time</p>
          <input type="datetime-local" className='text-input' value={endDateTime} onChange={(e) => {
            setEndDateTime(e.target.value)
          }} />
        </div>
        <div className="row items-center justify-between w-full">
          <p>Class Duration</p>
          <p>{(new Date(endDateTime) - new Date(startDateTime)) / 1000 / 60} minutes</p>
        </div>
        <div className="input-container">
          <p>Remarks</p>
          <textarea type="text" className='textarea-input' value={remarks} onChange={(e) => {
            setRemarks(e.target.value)
          }} />
        </div>
      </div>
      <p className='mb-4' style={{ fontSize: 20, fontWeight: "500", marginTop: 30 }}>Attendance</p>
      <div className={`${isMobile(width) ? "w-full" : "w-50"}`} style={{ overflowX: "scroll" }}>
        <div className={`row items-center justify-between`}>
          <p className='w-50' style={{ fontWeight: "bold", minWidth: 200 }}>Name</p>
          <div className="row items-center gap-4">
            <p style={{ fontWeight: "bold", width: 100 }}>Present</p>
            <p style={{ fontWeight: "bold", width: 100 }}>Absent</p>
            <p style={{ fontWeight: "bold", width: 100 }}>Late</p>
          </div>
        </div>
        {studentData.length > 0 && attendanceData.length > 0 && studentData.length === attendanceData.length && studentData.map((student) => {
          return (
            <div className="row items-center justify-between mt-4">
              <p className='w-50' style={{ minWidth: 200, whiteSpace: "nowrap" }}>{student?.first_name} {student?.last_name}</p>
              <div className="row items-center gap-4">
                <div style={{ width: 100 }}>
                  <Switch
                    checked={attendanceData[attendanceData?.findIndex((attendance) => attendance?.user_id == student?.user_id)]?.attendance === "present"}
                    onChange={(checked) => {
                      let attendance = [...attendanceData]
                      attendance[attendanceData?.findIndex((attendance) => attendance?.user_id == student?.user_id)].attendance = "present"
                      setAttendanceData(attendance)
                    }} />
                </div>
                <div style={{ width: 100 }}>
                  <Switch
                    checked={attendanceData[attendanceData?.findIndex((attendance) => attendance?.user_id == student?.user_id)]?.attendance === "absent"}
                    onChange={(checked) => {
                      let attendance = [...attendanceData]
                      attendance[attendanceData?.findIndex((attendance) => attendance?.user_id == student?.user_id)].attendance = "absent"
                      setAttendanceData(attendance)
                    }} />
                </div>
                <div style={{ width: 100 }}>
                  <Switch
                    checked={attendanceData[attendanceData?.findIndex((attendance) => attendance?.user_id == student?.user_id)]?.attendance === "late"}
                    onChange={(checked) => {
                      let attendance = [...attendanceData]
                      attendance[attendanceData?.findIndex((attendance) => attendance?.user_id == student?.user_id)].attendance = "late"
                      setAttendanceData(attendance)
                    }} />
                </div>
              </div>
            </div>
          )
        })}
      </div>
      <div className="column right alignEnd mt-4">
        <button className="button"
          onClick={() => {
            submitAttendance()
          }}
        >Submit Attendance</button>
      </div>
    </div>
  )
}
