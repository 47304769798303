import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import Navbar from '../../../components/Navbar'
import UserHeader from '../../../components/UserHeader'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../../utils/AppFunctions'
import config from '../../../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import UserLayout from '../../../layout/UserLayout'
import { MdAdd } from 'react-icons/md'
import { PiDotsSixVerticalBold } from 'react-icons/pi'
import JoditEditor from 'jodit-react';
import { createReactEditorJS } from 'react-editor-js'
import { EDITOR_JS_TOOLS } from '../../../utils/editorjs'
import Editor from '@stfy/react-editor.js'
import Switch from "react-switch";
import Blocks from 'editorjs-blocks-react-renderer';
import edjsHTML from 'editorjs-html';
import notify from '../../../utils/IANotification'
import { tidyDate } from '../../../utils/helper'

const edjsParser = edjsHTML();

const ReactEditorJS = createReactEditorJS()




export default function NotebookPage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = useFetchUserData()
  let notebook_id = new URLSearchParams(window.location.search).get("notebook_id")
  const [headerHeight, setHeaderHeight] = useState(document?.getElementById("user-header")?.clientHeight)

  useLayoutEffect(() => {
    setHeaderHeight(document?.getElementById("user-header")?.clientHeight)
  }, [document?.getElementById("user-header")?.clientHeight])

  const [editorJSBlocks, setEditorJSBlocks] = useState({
    time: new Date().getTime(),
    blocks: [

    ]
  })
  const [notebookData, setNotebookData] = useState({})
  const [userNotebooks, setUserNotebooks] = useState([])

  const [preview, setPreview] = useState(false)
  const [previewHtml, setPreviewHtml] = useState("")
  const [editorReady, setEditorReady] = useState(false)

  useEffect(() => {
    if (editorReady) {
      clearDuplicate()
    }
  }, [editorReady, document.querySelector(".codex-editor")])

  const clearDuplicate = () => {
    let editor = document.querySelectorAll(".codex-editor")
    if (editor.length > 0) {
      // Delete
      editor[0].querySelectorAll(".codex-editor__redactor").forEach((e) => {
        e.remove()
      })
    }
  }

  const fetchNotebook = async () => {
    let endpoint = config.endpoint + "/notebook/"
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      credentials: "include",
    })
    let response = await fetchResponse.json()
    if (response.status === 200) {
      setUserNotebooks(response.data)
    } else {
      notify({ message: "Failed to fetch notebook", type: "error" })
    }
  }
  const saveNotes = async (trigger) => {
    let endpoint = config.endpoint + "/notebook/"
    let fetchResponse = await fetch(endpoint, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        ...notebookData,
        notebook_blocks: editorJSBlocks
      })
    })
    let response = await fetchResponse.json()
    if (response.status === 200) {
      fetchNotebook()
      if (trigger === "user") {
        notify({ message: "Notebook saved successfully", type: "success" })
      }
    } else {
      notify({ message: "Failed to save notebook", type: "error" })
    }
  }

  const fetchNotebookById = async () => {
    if (!notebook_id) return
    if (notebook_id === "new") return
    let endpoint = config.endpoint + "/notebook/id/" + notebook_id
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      credentials: "include",
    })
    let response = await fetchResponse.json()
    if (response.status === 200) {
      setNotebookData(response?.data)
      setEditorJSBlocks(response?.data?.content)
    } else {
      notify({ message: "Failed to fetch notebook", type: "error" })
    }
  }

  const obtainTitle = (data) => {
    let text = ""
    for (let i = 0; i < data.blocks.length; i++) {
      text = data.blocks[i].data.text
      if (text) {
        break
      }
    }
    return text
  }

  useEffect(() => {
    fetchNotebook()
    fetchNotebookById()
  }, [])

  return (
    <UserLayout navSelected={"Home"} noParentPadding={true} defHeight={`calc(100vh - ${headerHeight}px)`}>
      <div className="row gap-4" style={{ height: "100%", overflow: "scroll" }}>
        {/* Navigation */}
        <div className="card w-20" style={{ width: "30%", position: "sticky", top: `0px` }}>
          <button className="button column gap-4 items-center w-full center"
            onClick={() => {
              clearDuplicate()
              window.location.href = config.local_url + "/tools/notebook?notebook_id=new"
            }}
          >
            New Notebook
          </button>
          <div className="column mt-4" style={{ overflowY: "scroll", maxHeight: "calc(100vh - 200px)" }}>
            {userNotebooks?.length > 0 ?
              userNotebooks?.map((notebook, index) => {
                return (
                  <button className="backgroundLess" key={index} style={{ width: "100%", padding: "5px 10px", fontSize: 13, textAlign: "left", backgroundColor: notebook_id == notebook.id ? "#e62d74" : "#e6e6e6", color: notebook_id == notebook.id ? "white" : "black", borderBottom: "0.5px solid black" }}
                    onClick={() => {
                      window.location.href = config.local_url + "/tools/notebook?notebook_id=" + notebook.id
                    }}
                  >
                    <p style={{ textAlign: 'right' }}>{tidyDate(notebook?.updatedAt)}</p>
                    <p>{obtainTitle(notebook?.content)}</p>
                  </button>
                )
              })
              : <p>No notebooks found</p>}
          </div>
        </div>

        {/* Notebook */}
        <div className='p-all' style={{ width: "60%" }}>
          <div className="card w-full">
            {/* <pre>{JSON.stringify(editorJSBlocks, null, 2)}</pre> */}

            {/* <div className="row gap-4 items-center">
              <Switch onChange={() => {
                  setPreview(!preview)
                  if (!preview) {
                    setPreviewHtml(edjsParser.parse(editorJSBlocks))
                  }
              }} checked={preview} />
              <p>Preview</p>
            </div> */}
            {preview ?
              <div dangerouslySetInnerHTML={{ __html: previewHtml }}></div>
              :
              <>
                {(editorJSBlocks?.blocks?.length > 0 || notebook_id === "new") && <Editor
                  autofocus={true}
                  tools={EDITOR_JS_TOOLS}
                  data={editorJSBlocks}
                  onData={(e) => { setEditorJSBlocks(e) }}
                  onReady={() => { setEditorReady(true) }}
                  placeholder="Start typing..."
                />}
              </>
            }
            <div className="column right alignEnd">
              <button className="button"
                onClick={() => {
                  saveNotes("user")
                }}
              >Save</button>
            </div>
          </div>
        </div>
      </div>
    </UserLayout>
  )
}
