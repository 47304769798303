import React, { useEffect, useLayoutEffect, useState } from 'react'
import Navbar from '../../components/Navbar'
import UserHeader from '../../components/UserHeader'
import { useNavigate, useParams } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import UserLayout from '../../layout/UserLayout'
import notify from '../../utils/IANotification'
import StudentCourseNavbar from '../../components/StudentCourseNavbar'
import TextComponent from '../../components/lesson/TextComponent'
import VideoComponent from '../../components/lesson/VideoComponent'
import ImageComponent from '../../components/lesson/ImageComponent'
import AudioComponent from '../../components/lesson/AudioComponent'
import DocumentComponent from '../../components/lesson/DocumentComponent'
import CodeComponent from '../../components/lesson/CodeComponent'
import WebComponent from '../../components/lesson/WebComponent'
import { IoCheckmarkDoneCircleOutline, IoCheckmarkDoneCircleSharp } from 'react-icons/io5'

export default function LessonPage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = useFetchUserData()
  const [adminHeader, setAdminHeader] = useState(document?.getElementById("user-header")?.clientHeight + 10)

  let { lesson_id } = useParams()
  const course_id = new URLSearchParams(window.location.search)?.get("course_id")

  const [lessonData, setLessonData] = useState({})
  const [selected, setSelected] = useState("Lessons")
  const [completionData, setCompletionData] = useState()
  const [totalCompletion, setTotalCompletion] = useState(0) // completed/total -> round to 1dp

  const fetchLessonData = async () => {
    if (!course_id) return
    let endpoint = config.endpoint + "/lesson/student/lesson/id/" + lesson_id
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      setLessonData(data.data)
    } else {
      notify({ message: data.message, type: "error" })
    }
  }


  const updateCompletion = async (component_id, is_completed) => {
    let endpoint = config.endpoint + "/completion/update"
    let fetchResponse = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        lesson_id: lesson_id,
        course_id: course_id,
        component_id: component_id,
        is_completed: is_completed,
      })
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      // notify({ message: data.message, type: "success" })
      getCompletion()
    } else {
      // notify({ message: data.message, type: "error" })
    }
  }

  const getCompletion = async () => {
    if (!course_id) return
    let endpoint = config.endpoint + "/completion/lesson/id/" + lesson_id
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      let temp = data
      let completed = temp.data?.completion.filter((completion) => completion.is_completed == 1)
      let total = temp.data?.total_components
      let percentage = ((completed.length / total) * 100).toFixed(1)
      if (isNaN(percentage)) percentage = 0
      if (percentage > 100) percentage = 100
      setTotalCompletion(percentage)

      setCompletionData(data.data?.completion)
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  const pingLesson = async () => {
    let endpoint = config.endpoint + "/ping/lesson"
    let fetchResponse = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        lesson_id: lesson_id,
        course_id: course_id,
      })
    })
    let data = await fetchResponse.json()
  }

  const isCompleted = (component_id) => {
    if (!completionData) return false
    let completion = completionData.find((completion) => completion.component_id == component_id)
    if (completion) {
      return completion.is_completed == 1
    } else {
      return false
    }
  }

  useState(() => {
    getCompletion()
    fetchLessonData()
  }, [])


  useEffect(() => {
    let interval = setInterval(() => {
      pingLesson()
    }, 10000)

    return () => {
      clearInterval(interval)
    }
  }, [])


  // window.addEventListener("scroll", () => {
  //   // Check if the user have past the components. All the lessons component are in view. If they are, update the completion
  //   let components = document.getElementsByClassName("column")
  //   let lessonComponents = []
  //   for (let i = 0; i < components.length; i++) {
  //     let component = components[i]
  //     let rect = component.getBoundingClientRect()
  //   }

  //   setAdminHeader(document?.getElementById("user-header")?.clientHeight + 10)
  // })

  useLayoutEffect(() => {
    setAdminHeader(document?.getElementById("user-header")?.clientHeight + 10)
  }, [])

  // window.addEventListener("scroll", () => {
  //   let components = document.getElementsByClassName("column")
  //   let lessonComponents = []
  //   for (let i = 0; i < components.length; i++) {
  //     let component = components[i]
  //     let rect = component.getBoundingClientRect()
  //     if (rect.top < window.innerHeight && rect.bottom > 0) {
  //       lessonComponents.push(component)
  //     }
  //   }

  //   lessonComponents?.forEach((lesson) => {
  //     updateCompletion(lesson.id.replace("lesson-component-", ""), 1)
  //   })

  // })



  return (
    <UserLayout navSelected={"Courses"}>
      <h1 style={{ fontWeight: 500, fontSize: 20 }}>{lessonData?.lesson_data?.name}</h1>
      <div className="row gap-4 items-start w-full">
        <div className="card w-70" style={{ width: "70%" }}>
          {lessonData?.components?.length > 0 &&
            lessonData?.components?.map((lesson, index) => {
              return (
                <div className="column" style={{ width: "100%" }} id={`lesson-component-${lesson.id}`}>
                  <div style={{ marginBottom: 20, }}>
                    {/* <p style={{ fontSize: 20, fontWeight: 500 }}>{index + 1}. {lesson.id}</p> */}
                    {lesson.type === "text" &&
                      <TextComponent data={lesson?.content} readOnly={true} />
                    }
                    {lesson.type === "video" &&
                      <VideoComponent data={lesson} readOnly={true} />
                    }
                    {lesson.type === "image" &&
                      <ImageComponent data={lesson} readOnly={true} />
                    }
                    {lesson.type === "audio" &&
                      <AudioComponent data={lesson} readOnly={true} />
                    }
                    {lesson.type === "file" &&
                      <DocumentComponent data={lesson} readOnly={true} />
                    }
                    {lesson.type === "code" &&
                      <CodeComponent data={lesson} readOnly={true} />
                    }
                    {lesson.type === "web" &&
                      <WebComponent data={lesson} readOnly={true} />
                    }
                  </div>
                </div>
              )
            })
          }
        </div>
        <div className="card w-30" style={{ width: "30%", position: "sticky", top: adminHeader }}>
          <p className='card-title'>Content</p>
          <p style={{ fontSize: 30 }}>{totalCompletion}%</p>
          <progress className="progress" value={totalCompletion} max="100" style={{ width: "100%", marginBottom: 30 }}></progress>
          {/* <pre>{JSON.stringify(completionData,null,2)}</pre> */}
          {lessonData?.components?.length > 0 &&
            lessonData?.components?.map((lesson, index) => {
              return (
                <div className='cursor-pointer w-full row justify-between' onClick={() => {
                  // Scroll to the component
                  let element = document.getElementById(`lesson-component-${lesson.id}`)
                  element.scrollIntoView({ behavior: "smooth", block: "center" })
                  updateCompletion(lesson.id, 1)
                }}>
                  <p style={{ textDecoration: isCompleted(lesson.id) ? "line-through" : "normal" }}>{index + 1}. {lesson.name}</p>
                  {isCompleted(lesson.id) ?
                    <IoCheckmarkDoneCircleSharp size={20} color={"var(--green)"} /> :
                    <IoCheckmarkDoneCircleOutline size={20} color={"grey"} />
                  }

                </div>
              )
            })
          }
        </div>
      </div>
    </UserLayout>
  )
}
