import React, { useEffect, useState } from 'react'
import '../globals.css'
import config from '../app-config'
import { isMobile } from '../utils/helper'
import { MdMenu } from 'react-icons/md'

export default function Header() {
    const [announcementBanner, setAnnouncementBanner] = useState("")
    const [showNavFull, setShowNavFull] = useState(false)
    const [width, setWidth] = useState(window.innerWidth)

    const windowResize = () => {
        setWidth(window.innerWidth)
    }

    window.addEventListener('resize', windowResize)

    const fetchAnnouncementBanner = async () => {
        let endpoint = config.endpoint + "/config/announcement"
        let response = await fetch(endpoint, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        })
        let data = await response.json()
        if (data.status == 200) {
            setAnnouncementBanner(data.data)
            window.sessionStorage.setItem("announcementBanner", data.data)
        } else {
            setAnnouncementBanner("")
        }
    }

    useEffect(() => {
        let announcement = window.sessionStorage.getItem("announcementBanner")
        if (announcement) {
            setAnnouncementBanner(announcement)
        }
        fetchAnnouncementBanner()
    }, [])

    return (
        <>
            {announcementBanner && announcementBanner.length > 0 && <div style={{ backgroundColor: "#F2F2F2", padding: "10px 0", textAlign: "center" }}>
                <p style={{ margin: 0, fontSize: 14 }}>{announcementBanner}</p>
            </div>}
            {!isMobile(window.innerWidth) ?
                <div style={{ borderBottom: "1px solid gray", width: "100%", backgroundColor: "white", display: "flex", }} className='justifyCenter items-center'>
                    <div className="header row items-center justify-between" style={{ padding: 10, maxWidth: 1500 }}>
                        <div className="row gap-20">
                            <img src={config.images.logo} alt="logo" style={{ width: "150px", height: "50px", objectFit: "contain" }} />
                            <div className="row gap-6 items-center">
                                <a className='a-link' href={`${config.client_url}`}>{config.client_title}</a>
                                <a className='a-link' href={`${config.privacy}`}>Privacy</a>
                                {/* <a className='a-link' href="/#features">Features</a> */}
                            </div>
                        </div>
                        <div className="row gap-20 items-center">
                            {/* <a className='a-link' href="/#pricing">Pricing</a>
                        <a className='a-link' href="/#sales">Contact Sales</a> */}
                            <button className='a-link' onClick={() => {
                                let message = "Hello, I am using the Empire Code LMS and I would like some help with the following: \n"
                                let subject = "Empire Code LMS Support"
                                let email = config.support_email
                                let cc_email = config.developer_email
                                window.location.href = `mailto:${email}?subject=${subject}&body=${message}&cc=${cc_email}`
                            }} >Contact Support</button>
                            <button
                                style={{
                                    backgroundColor: "#2F80ED",
                                    color: "white",
                                    borderRadius: "50px",
                                    border: "none",
                                    outline: "none",
                                    fontSize: "14px",
                                    padding: "10px 20px",
                                }}
                                onClick={() => { window.location.href = "/auth/login" }}
                            >Sign In</button>
                        </div>
                    </div>
                </div>
                :
                <div className='border-b-[1px] border-gray-300 bg-white sticky top-0'>
                    <div className='flex flex-row justify-between items-center p-4 w-full'>
                        <img src={config.images.logo} alt="logo" style={{ width: "150px", height: "50px", objectFit: "contain" }} />
                        <div className='border-[1px] border-gray-300 p-2 rounded-md cursor-pointer'
                            onClick={() => {    
                                setShowNavFull(!showNavFull)
                            }}
                        >
                            <MdMenu size={30} color={"gray"} />
                        </div>
                    </div>
                    {showNavFull && <div className='flex flex-col gap-4 items-center justify-center pb-4'>
                        <a className='a-link' href={`${config.client_url}`}>{config.client_title}</a>
                        <a className='a-link' href={`${config.privacy}`}>Privacy</a>
                        <button className='a-link' onClick={() => {
                            let message = "Hello, I am using the Empire Code LMS and I would like some help with the following: \n"
                            let subject = "Empire Code LMS Support"
                            let email = config.support_email
                            let cc_email = config.developer_email
                            window.location.href = `mailto:${email}?subject=${subject}&body=${message}&cc=${cc_email}`
                        }} >Contact Support</button>
                        <button
                            style={{
                                backgroundColor: "#2F80ED",
                                color: "white",
                                borderRadius: "50px",
                                border: "none",
                                outline: "none",
                                fontSize: "14px",
                                padding: "10px 20px",
                            }}
                            onClick={() => { window.location.href = "/auth/login" }}
                        >Sign In</button>
                    </div>}
                </div>
            }
        </>
    )
}
