import React from 'react'
import Navbar from '../components/Navbar'
import UserHeader from '../components/UserHeader'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../utils/AppFunctions'
import config from '../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';

export default function UserLayout({ children, navSelected, noParentPadding, defHeight, defWidth, hideNav }) {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = useFetchUserData()

  return (
    <div style={{ width: "100vw", }}>
      <ToastContainer />
      <div className='row' style={{ width: "100%" }}>
        {!hideNav && <Navbar selected={navSelected} />}
        <div style={{ width: "100%", maxHeight: "100vh", overflowY: "scroll" }}>
          <UserHeader type={"student"} />
          <div className="container" style={{ padding: noParentPadding && 0, height: defHeight && defHeight, width: defWidth && defWidth, maxWidth: "100vw" }}>
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}
