import React, { useState, useEffect } from 'react'
import Navbar from '../../components/Navbar'
import UserHeader from '../../components/UserHeader'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import AdminLayout from '../../layout/AdminLayout'
import { useParams } from 'react-router'
import AdminCourseNavbar from '../../components/AdminCourseNavbar'
import notify from '../../utils/IANotification'
import Switch from 'react-switch'

export default function AdminCourseStudentPage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("staff")
  let user_data = useFetchUserData()

  const course_id = new URLSearchParams(window.location.search)?.get("course_id")
  const lesson_id = new URLSearchParams(window.location.search)?.get("lesson_id")
  const class_id = new URLSearchParams(window.location.search)?.get("class_id")

  const [studentData, setStudentData] = useState([])
  const [searchQuery, setSearchQuery] = useState("")
  const [ongoingStudentData, setOngoingStudentData] = useState([])
  const [completedStudentData, setCompletedStudentData] = useState([])
  const [totalStudentData, setTotalStudentData] = useState([])
  const [currentStudentData, setCurrentStudentData] = useState([])
  const [showAllStudents, setShowAllStudents] = useState(false)


  const getStudents = async () => {
    if (!course_id) return notify({ message: "Unable to fetch lessons", type: "error" })
    let endpoint = config.endpoint + "/enrolment/class/id/" + course_id
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      setStudentData(data.data)
      setTotalStudentData([...data.data?.ongoing].concat(...data.data?.completed))
      setOngoingStudentData(data.data?.ongoing)
      setCurrentStudentData(data.data?.ongoing)
      setCompletedStudentData(data.data?.completed)
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  useEffect(() => {
    getStudents()
  }, [])

  return (
    <AdminLayout navSelected={"Courses"}>
      <p className='mb-4' style={{ fontSize: 30, fontWeight: "500" }}>Students</p>
      {course_id != "new" && <AdminCourseNavbar selected={"Students"} course_id={course_id} />}
      {/* <button className="button"
        onClick={() => {
          navigate(`/admin/courses/students/details?student_id=new&course_id=${course_id}`)
        }}
      >New Class</button> */}
      <div className="row right items-center gap-4 mt-40 mb-4">
        <div className="row gap-4 items-center">
          <Switch checked={showAllStudents} onChange={(e) => {
            setShowAllStudents(e)
            if (e) {
              setCurrentStudentData(totalStudentData)
            } else {
              setCurrentStudentData(ongoingStudentData)
            }
          }} />
          <p>Show Graduated Students</p>
        </div>
      </div>
      <div className="input-container mb-30">
        <input type="text" className="text-input" placeholder="Search students..." value={searchQuery} onChange={(e) => {
          setSearchQuery(e.target.value)
        }} />
      </div>
      {currentStudentData.length > 0 ?
        currentStudentData.map((data, index) => {
          if (JSON.stringify(data)?.toLowerCase().includes(searchQuery.toLowerCase())) {
            return (
              <div className='p-all row justify-between gap-20 items-center w-full mt-4' style={{ borderLeft: "4px solid var(--primary)", backgroundColor: "white", borderTopLeftRadius: "5px", borderBottomLeftRadius: "5px", borderRadius: "5px", paddingLeft: 10 }}>
                <div className="" style={{ maxWidth: 200 }}>
                  <h5 className="card-title" style={{
                    overflow: "hidden", textOverflow: "ellipsis"
                  }}>{data.first_name} {data.last_name}</h5>
                  <p className="card-text" style={{
                    overflow: "hidden", textOverflow: "ellipsis"
                  }}>{data?.email}</p>
                </div>
                <button className="button"
                  onClick={() => {
                    navigate(`/admin/courses/students/details?course_id=${course_id}&student_id=${data.id}`)
                  }}
                >Edit</button>
              </div>
            )
          }
        })
        :
        <div className="card rounded-md shadow-md p-4 mt-4">
          <h5 className="card-title">No students found</h5>
          <p className="card-text">There are no students enrolled for this course.</p>
        </div>}
    </AdminLayout>
  )
}
