import React, { useState } from 'react'
import { IoAdd, IoClose, IoTrash } from 'react-icons/io5'
import { MdBolt, MdEdit } from 'react-icons/md'
import Switch from 'react-switch'
import { isMobile } from '../../utils/helper'

export default function MultipleSelectAssessmentComponent({ data, options, onOptionChange, onChange, item_index, onAnswered, userAnswer, state, revealAnswer }) {
    const [width, setWidth] = useState(window.innerWidth)
    return (
        <>
            {state === "builder" && <>
                {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
                <p>Select more than 1 option</p>
                <div className="w-full row gap-30 right alignEnd">
                    <button className="backgroundLess row gap-4 items-center"
                        onClick={() => {
                            onChange({ ...data, question_options: [...data.question_options, { text: "", is_answer: 0 }] })
                        }}
                    >
                        <IoAdd size={20} />
                        Add Option
                    </button>

                    <button className="backgroundLess row gap-4 items-center"
                        onClick={() => {
                            onChange({ ...data, system_graded: (data?.system_graded == 1 ? 0 : 1) })
                        }}
                    >
                        {<>
                            {data?.system_graded == 1 ? <MdBolt size={20} /> : <MdEdit size={20} />}
                            {data?.system_graded == 1 ? "System Graded" : "Manual Grading"}
                        </>}
                    </button>
                </div>
                <div className='column gap-4'>
                    {data?.question_options?.length > 0 && data?.question_options.map((option, index) => {
                        return (
                            <div key={index} className={`${isMobile(width) ? "column" : "row items-center"} w-full gap-4`}>
                                <p style={{ width: 50 }}>{index + 1}.</p>
                                <input type="text" className="text-input" required value={option.text} onChange={(e) => {
                                    let temp = [...data.question_options]
                                    temp[index].text = e.target.value
                                    onChange({ ...data, question_options: temp })
                                }} />
                                <Switch onChange={(e) => {
                                    let temp = [...data.question_options]
                                    temp[index].is_answer = (e ? 1 : 0)
                                    onChange({ ...data, question_options: temp })
                                }} checked={option.is_answer == 1} />

                                <button className="ml-20 backgroundLess row gap-4 items-center"
                                    onClick={() => {
                                        let temp = [...data.question_options]
                                        temp.splice(index, 1)
                                        onChange({ ...data, question_options: temp })
                                    }}
                                >
                                    <IoTrash size={30} />
                                </button>
                            </div>
                        )
                    })}
                </div>
            </>}
            {state === "question" && <>
                <div className='column gap-4 mb-4'>
                    <div className="input-container mt-4">
                        {data?.question_options?.length > 0 && data?.question_options.map((option, index) => {
                            let radioGroup = item_index
                            return (
                                <div key={index} className="w-full row gap-4 items-center"
                                    style={{
                                        border: revealAnswer && data?.question_options[index]?.is_answer == 1 ? "1px solid green" : "",
                                        borderRadius: 5,
                                        padding: 10
                                    }}
                                >
                                    <input type="checkbox" className="text-input" name={radioGroup} required checked={userAnswer?.split("/c/")?.includes(option.text)} onChange={(e) => {
                                        let temp = [...userAnswer?.split("/c/") || []]
                                        if (e.target.checked) {
                                            temp.push(option.text)
                                        } else {
                                            temp.splice(temp.indexOf(option.text), 1)
                                        }
                                        onAnswered(temp.join("/c/"))
                                    }} />
                                    <p>{option.text}</p>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </>
            }
        </>
    )
}
