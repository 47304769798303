import React, { useEffect, useState } from 'react'
import Navbar from '../../components/Navbar'
import UserHeader from '../../components/UserHeader'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import ParentsLayout from '../../layout/ParentsLayout'
import notify from '../../utils/IANotification'
import Select from 'react-select'
import { MdCalendarMonth, MdLock } from 'react-icons/md'
import { tidyDate } from '../../utils/helper'
import { FiAward } from 'react-icons/fi'
import { FaCertificate } from 'react-icons/fa'

export default function ParentsCertificatePage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = useFetchUserData()
  const [certificateData, setCertificateData] = useState([])
  const [childData, setChildData] = useState([])
  const [selectedChild, setSelectedChild] = useState({})

  const fetchCertificateData = async () => {
    setCertificateData([])
    if (!selectedChild?.user_id) return
    let endpoint = config.endpoint + "/certificate/user/id/" + selectedChild?.user_id
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      setCertificateData(data.data)
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  const fetchFamilyData = async () => {
    let endpoint = config.endpoint + `/family/parent/id/`
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      let temp = data.data?.members?.filter((item) => {
        return item.role === "student"
      })
      setChildData(temp)
      setSelectedChild(temp?.[0])
    }
  }



  useEffect(() => {

    fetchFamilyData()
  }, [])

  useEffect(() => {
    fetchCertificateData()
  }, [selectedChild])

  return (
    <ParentsLayout navSelected={"Certificates"}>
      <div className="row gap-4 items-start justify-between">
        <p style={{ fontWeight: "normal", fontSize: 25, marginBottom: 10 }}>{selectedChild?.user_data?.first_name}'s Certificates</p>
        {childData?.length > 0 && <Select
          options={childData?.map((item) => {
            return {
              value: item,
              label: `${item?.user_data?.first_name} ${item?.user_data?.last_name}`
            }
          })}
          onChange={(option) => {
            setSelectedChild(option?.value)
          }}
          value={childData?.map((item) => {
            return {
              value: item,
              label: `${item?.user_data?.first_name} ${item?.user_data?.last_name}`
            }
          })?.find((item) => item.value.id === selectedChild.id)}
        />}
      </div>

      {certificateData?.length > 0 ? certificateData?.map((item, index) => {
        return (
          <div className='card cursor-pointer'
            onClick={() => {
              navigate(`/parents/certificates/view?certificate_id=${item.id}&user_id=${selectedChild?.user_id}&course_id=${item?.course_id}`)
            }}
            style={{ padding: 15, marginBottom: 20, borderRadius: 10, border: "1px solid #E5E5E5" }}
          >
              <p style={{ fontWeight: "800", fontSize: 15,}}>{item?.course_data?.name}</p>
              <div className="row gap-4 items-center">
                <MdCalendarMonth style={{ fontSize: 20, color: "gray" }} />
                <p style={{ fontWeight: "normal", fontSize: 12, }}>Issued on {tidyDate(item?.createdAt)}</p>
              </div>
          </div>
        )
      }) :
        <div className="card rounded-md shadow-md p-4 mt-4">
          <FiAward style={{ fontSize: 50, color: "gray" }} />
          <p style={{ fontWeight: "normal", fontSize: 15, marginBottom: 10 }}>No Certificates found</p>
        </div>
      }

      {/* <pre>{JSON.stringify(certificateData, null, 2)}</pre> */}
    </ParentsLayout>
  )
}
