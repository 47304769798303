import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import ModalLayout from '../layout/ModalLayout';

const config = {
    closeOnEscape: true,
    closeOnClickOutside: true,
    keyCodeForClose: [8, 32],
    willUnmount: () => { },
    afterClose: () => { },
    onClickOutside: () => { },
    onKeypress: () => { },
    onKeypressEscape: () => { },
};

const IAPrompt = ({ title, message, buttons }) => {
    confirmAlert({
        ...config,
        title: title,
        message: message,
        buttons: buttons,
        customUI: ({ onClose }) => {
            return (
                <ModalLayout modalTitle={title} isVisible={true} setIsVisible={onClose}>
                    <div className="items-start mb-20 w-full" style={{zIndex:1500}}>
                        {message}
                    </div>
                    <div className="row items-center gap-4 alignEnd w-full right">
                        {buttons.map((button, index) => {
                            return (
                                <button key={index} className={index === buttons.length - 1 ? "button" : "button-cta"} onClick={() => {
                                    button.onClick()
                                    onClose()
                                }}>{button.label}</button>
                            )
                        })}
                    </div>
                </ModalLayout>
            );
        }
    });
}

export default IAPrompt