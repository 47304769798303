import React, { useState, useEffect, useRef } from 'react'
import Navbar from '../../components/Navbar'
import UserHeader from '../../components/UserHeader'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import AdminLayout from '../../layout/AdminLayout'
import { useParams } from 'react-router'
import AdminCourseNavbar from '../../components/AdminCourseNavbar'
import notify from '../../utils/IANotification'
import { IoAdd, IoCloudDownload, IoCloudUpload, IoPersonAdd, IoSchool, IoTrash } from 'react-icons/io5'
import { MdImportExport, MdTableView } from 'react-icons/md'
import { FaFileExport, FaFileImport } from 'react-icons/fa'
import ModalLayout from '../../layout/ModalLayout'
import IAPrompt from '../../utils/IAPrompt'
import Select from 'react-select'
import ClassFormPage from '../../subpages/ClassFormPage'

export default function AdminCourseClassFormPage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("staff")
  let user_data = useFetchUserData()

  const course_id = new URLSearchParams(window.location.search)?.get("course_id")
  const lesson_id = new URLSearchParams(window.location.search)?.get("lesson_id")
  const class_id = new URLSearchParams(window.location.search)?.get("class_id")

  const [studentData, setStudentData] = useState()
  const [allStudentsData, setAllStudentsData] = useState([])


  const [classData, setClassData] = useState({
    name: "",
    description: "",
    course_id: "",
    start_datetime: "",
    end_datetime: "",
    is_forever: "0",
    instructions: ""
  })



  const fetchClassById = async () => {
    if (class_id === "new") return
    let endpoint = config.endpoint + "/class/id/" + class_id
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      setClassData(data.data)
    } else {
      notify({ message: data.message, type: "error" })
    }
  }



  useEffect(() => {
    fetchClassById()
  }, [])



  // const AddStudentModal = () => {
  //   return (
  //     <></>
  //   )
  // }

  return (
    <AdminLayout navSelected={"Courses"}>
      <p className='mb-4' style={{ fontSize: 30, fontWeight: "500" }}>{class_id === "new" ? "New Class" : classData?.name}</p>
      <AdminCourseNavbar selected={"Classes"} course_id={course_id} />
      <ClassFormPage type={"admin"}/>
      </AdminLayout>
  )
}