import React, { useState, useEffect } from 'react'
import Navbar from '../components/Navbar'
import UserHeader from '../components/UserHeader'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../utils/AppFunctions'
import config from '../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import AdminLayout from '../layout/AdminLayout'
import { useParams } from 'react-router'
import AdminCourseNavbar from '../components/AdminCourseNavbar'
import notify from '../utils/IANotification'
import Switch from 'react-switch'
import { isMobile } from '../utils/helper'

export default function ScheduleAssessment({ type }) {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = useFetchUserData()

  const course_id = new URLSearchParams(window.location.search)?.get("course_id")
  const lesson_id = new URLSearchParams(window.location.search)?.get("lesson_id")
  const schedule_id = new URLSearchParams(window.location.search)?.get("schedule_id")
  const [width, setWidth] = useState(window.innerWidth)
  const [assessmentData, setAssessmentData] = useState([])
  const [classData, setClassData] = useState([])
  const [scheduleData, setScheduleData] = useState({
    type: "assignments", // assignments or examinations -> to allow this to be defined by users
    assessment_id: "",
    title: "",
    description: "",
    start_date: "",
    end_date: "",
    no_end_date: 0,
    class_id: "",
    is_for_all_classes: 0,
    is_published: 0,
  })

  const getAssessments = async () => {
    if (!course_id) return notify({ message: "Unable to fetch lessons", type: "error" })
    let endpoint = config.endpoint + "/assessment/course/id/" + course_id
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      setAssessmentData(data.data)
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  const getClasses = async () => {
    if (!course_id) return notify({ message: "Unable to fetch classes", type: "error" })
    let endpoint = config.endpoint + "/class/course/id/" + course_id
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      setClassData(data.data)
    } else {
      notify({ message: data.message, type: "error" })
    }
  }


  const getScheduledAssessmentById = async () => {
    if (schedule_id === "new") return
    if (!schedule_id) return notify({ message: "Unable to fetch scheduled assessment", type: "error" })
    let endpoint = config.endpoint + "/assessment/schedule/id/" + schedule_id
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      setScheduleData(data.data)
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  const editScheduledAssessment = async () => {
    if (!schedule_id) return notify({ message: "Unable to edit scheduled assessment", type: "error" })
    let endpoint = config.endpoint + "/assessment/schedule/id/" + schedule_id
    let fetchResponse = await fetch(endpoint, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        ...scheduleData,
        course_id: course_id,
      })
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      notify({ message: data.message, type: "success" })
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  const createScheduledAssessment = async () => {
    if (!course_id) return notify({ message: "Unable to create scheduled assessment", type: "error" })
    let endpoint = config.endpoint + "/assessment/schedule"
    let fetchResponse = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        ...scheduleData,
        course_id: course_id,
      })
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      notify({ message: data.message, type: "success" })
      navigate(`/admin/courses/assessments/schedule?course_id=${course_id}&schedule_id=${data.data.id}`)
    } else {
      notify({ message: data.message, type: "error" })
    }
  }




  useEffect(() => {
    getAssessments()
    getScheduledAssessmentById()
    getClasses()
  }, [])

  return (
    <>
      <div className="card rounded-md shadow-md p-4 mt-4">
        <div className={`${isMobile(width) ? "column" : "row items-center"}  gap-4`}>
          <div className="input-container">
            <p className="input-label">Assessment</p>
            <select className="select-input" required value={scheduleData.assessment_id} onChange={(e) => {
              setScheduleData({ ...scheduleData, assessment_id: e.target.value })
            }}>
              <option value="" disabled>Select Assessment</option>
              {assessmentData.length > 0 && assessmentData.map((assessment, index) => {
                return (
                  <option key={index} value={assessment.id}>{assessment.name}</option>
                )
              })}
            </select>
          </div>

          <div className="input-container">
            <p className="input-label">Type</p>
            <select className="select-input" required value={scheduleData.type} onChange={(e) => {
              setScheduleData({ ...scheduleData, type: e.target.value })
            }}>
              <option value="" disabled>Select Type</option>
              <option value="assignments">Assignments</option>
              <option value="examinations">Examinations</option>
            </select>
          </div>

          <div className="row gap-4 items-center">
            <p className='input-label' style={{ fontWeight: scheduleData?.is_published == 0 ? "bold" : "normal" }}>Draft</p>
            <Switch onChange={(e) => {
              setScheduleData({ ...scheduleData, is_published: e ? 1 : 0 })
            }} checked={scheduleData.is_published == 1} />
            <p className="input-label" style={{ fontWeight: scheduleData?.is_published == 1 ? "bold" : "normal" }}>Published</p>
          </div>
        </div>
        <div className="input-container mt-4">
          <p className="input-label">Title</p>
          <input type="text" className="text-input" required value={scheduleData.title} onChange={(e) => {
            setScheduleData({ ...scheduleData, title: e.target.value })
          }} />
        </div>

        <div className="input-container">
          <p className="input-label">Description</p>
          <textarea className="textarea-input" required value={scheduleData.description} onChange={(e) => {
            setScheduleData({ ...scheduleData, description: e.target.value })
          }} />
        </div>
        <div className={`${isMobile(width) ? "column" : "row items-center"}  gap-4`}>
          <div className="input-container">
            <p className="input-label">Start Date</p>
            <input type="datetime-local" className="text-input" required value={scheduleData.start_date} onChange={(e) => {
              setScheduleData({ ...scheduleData, start_date: e.target.value })
            }} />
          </div>
          <div className="input-container">
            <p className="input-label">End Date</p>
            <input type="datetime-local" className="text-input" required value={scheduleData.end_date} onChange={(e) => {
              setScheduleData({ ...scheduleData, end_date: e.target.value })
            }} />
          </div>
          <div className="row gap-4 items-center center">
            <input type="checkbox" className="text-input" required checked={scheduleData.no_end_date == 1} onChange={(e) => {
              setScheduleData({ ...scheduleData, no_end_date: e.target.checked ? 1 : 0 })
            }} />
            <p className="input-label" style={{ whiteSpace: "nowrap" }}>No End Date</p>
          </div>
        </div>
        <div className={`${isMobile(width) ? "column" : "row items-center"}  gap-4 mt-4`}>
          <div className="input-container">
            <p className="input-label">Class</p>
            <select className="select-input" required value={scheduleData.class_id} disabled={scheduleData?.is_for_all_classes == 1}
              onChange={(e) => {
                setScheduleData({ ...scheduleData, class_id: e.target.value })
              }}>
              <option value="" disabled>Select Class</option>
              {classData.length > 0 && classData.map((classItem, index) => {
                return (
                  <option key={index} value={classItem.id}>{classItem.name}</option>
                )
              })}
            </select>
          </div>

          <div className="row gap-4 items-center center">
            <input type="checkbox" className="text-input" required checked={scheduleData.is_for_all_classes == 1} onChange={(e) => {
              setScheduleData({ ...scheduleData, is_for_all_classes: e.target.checked ? 1 : 0 })
            }} />
            <p className="input-label" style={{ whiteSpace: "nowrap" }}>For All Classes</p>
          </div>
        </div>
        <div className="row w-full right">
          <button className="button mt-4 row"
            onClick={() => {
              if (schedule_id === "new") {
                createScheduledAssessment()
              } else {
                editScheduledAssessment()
              }
            }}
          >Save</button>
        </div>
        {/* <pre>{JSON.stringify(scheduleData,null,2)}</pre> */}
      </div>
    </>
  )
}
