import React, { useEffect, useState } from 'react'
import Navbar from '../components/Navbar'
import UserHeader from '../components/UserHeader'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../utils/AppFunctions'
import config from '../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import AdminLayout from '../layout/AdminLayout'
import notify from '../utils/IANotification'

export default function ClassesList({type, filter}) {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = useFetchUserData()

  const [classData, setClassData] = useState([])

  const fetchAllClasses = async () => {
    let endpoint = config.endpoint + "/class/"
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      }
    })
    let response = await fetchResponse.json()
    if (response.status === 200) {
      setClassData(response.data)
    } else {
      notify({
        message: response.message,
        type: "error"
      })
    }
  }

  useEffect(() => {
    fetchAllClasses()
  }, [])

  return (
    <>
      {classData?.length > 0 &&
          <>
            {classData.map((data, index) => {
              if (filter&& !JSON.stringify(data).toLowerCase().includes(filter.toLowerCase())) return null
              return (
                <div className='row justify-between gap-20 items-center w-full mt-4 card cursor-pointer border-l-2 border-l-blue-500'
                style={{borderTopLeftRadius: "5px", borderBottomLeftRadius: "5px", borderRadius: "5px", paddingLeft: 10 }}
                  key={index}
                  onClick={() => {
                    navigate(`/${type}/courses/classes/details?class_id=${data?.id}&course_id=${data?.course_id}`)
                  }}
                >
                  <div className="column">
                    <p className="card-title">{data?.name}</p>
                    <p className="card-text">{data?.course_data?.name}</p>
                  </div>
                </div>
              )
            })}
          </>
        }
    </>
  )
}
