import React, { useState, useEffect } from 'react'
import Navbar from '../components/Navbar'
import UserHeader from '../components/UserHeader'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../utils/AppFunctions'
import config from '../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import AdminLayout from '../layout/AdminLayout'
import { useParams } from 'react-router'
import notify from '../utils/IANotification'
import { FaChevronDown, FaChevronRight } from 'react-icons/fa'
import { tidyDate } from '../utils/helper'
import AdminCourseNavbar from '../components/AdminCourseNavbar'

export default function AssessmentSubmissionPage({ type }) {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = useFetchUserData()
  const assessment_id = new URLSearchParams(window.location.search)?.get("assessment_id")
  const schedule_id = new URLSearchParams(window.location.search)?.get("schedule_id")
  const course_id = new URLSearchParams(window.location.search)?.get("course_id")
  const [assessmentData, setAssessmentData] = useState([])
  const [submissionData, setSubmissionData] = useState([])

  const fetchAssessmentById = async () => {
    if (assessment_id === "new") return
    let endpoint = config.endpoint + "/assessment/id/" + assessment_id
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      setAssessmentData(data.data)
    } else {
      notify({ message: data.message, type: "error" })
    }
  }


  const fetchAssessmentSubmissions = async () => {
    if (schedule_id === "new") return
    let endpoint = config.endpoint + "/assessment/submission/schedule/id/" + schedule_id
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      setSubmissionData(data.data)
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  useEffect(() => {
    fetchAssessmentById()
    fetchAssessmentSubmissions()
  }, [])


  return (
    <>
      {submissionData?.length > 0 ?
        submissionData?.map((submission, index) => {
          return (
            <div className='card mb-4 cursor-pointer'
              onClick={() => {
                submission.show_attempts = !submission.show_attempts
                setSubmissionData([...submissionData])
              }}
            >
              <div className='row items-start justify-between'>
                <p className='card-title'>{submission?.student_data?.first_name} {submission?.student_data?.last_name}</p>
                {submission?.show_attempts ?
                  <FaChevronDown className='ml-auto' style={{ cursor: "pointer" }} onClick={() => {
                    submission.show_attempts = !submission.show_attempts
                    setSubmissionData([...submissionData])
                  }} />
                  :
                  <FaChevronRight className='ml-auto' style={{ cursor: "pointer" }} onClick={() => {
                    submission.show_attempts = !submission.show_attempts
                    setSubmissionData([...submissionData])
                  }} />
                }
              </div>

              {submission?.show_attempts ?
                <div className='mt-30'>
                  {submission?.attempts?.length > 0 ?
                    submission?.attempts?.map((attempt, index) => {
                      let status, color, bgColor
                      if (attempt?.final_grade) {
                        status = "Graded"
                        color = "white"
                        bgColor = "var(--green)"
                      } else if (attempt?.ended_by) {
                        status = "Submitted"
                        color = "white"
                        bgColor = "var(--green)"
                      } else {
                        status = "Attempting"
                        color = "black"
                        bgColor = "yellow"
                      }
                      return (
                        <div className="row items-center justify-between mb-20"
                          onClick={() => {
                            navigate(`/${type}/courses/assessments/submissions/attempt?attempt_id=${attempt?.id}&assessment_id=${assessment_id}&schedule_id=${schedule_id}&course_id=${course_id}`)
                          }}
                        >
                          <div>
                            <p className="card-title" style={{ fontWeight: "normal" }}>Attempt {index + 1}</p>
                            <p className="card-title" style={{ color: "gray" }}>{status === "Graded" ? `Grade: ${attempt?.final_grade}` : status === "Submitted" ? `Submitted at ${tidyDate(attempt?.end_datetime)}` : `Started at ${tidyDate(attempt?.start_datetime)}`}</p>
                          </div>
                          <p style={{ backgroundColor: bgColor, color: color, padding: "5px 15px", fontSize: 12, borderRadius: 20, fontWeight: "900" }}>{status}</p>
                        </div>
                      )
                    })
                    :
                    <p>No attempts found</p>
                  }
                </div>
                : null}
            </div>
          )
        })
        : null}
    </>
  )
}
