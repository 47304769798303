import React, { useState, useEffect, useLayoutEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { authorisationConverter, useFetchAuthorisation } from '../utils/AppFunctions'
import { FaAngleRight } from 'react-icons/fa'
import { AiOutlineDashboard } from 'react-icons/ai'
import { MdAccountBalance, MdCalendarToday, MdEditDocument, MdOutlineFlag, MdForum, MdGroup, MdOutlineAccountBalance, MdOutlineAirplanemodeActive, MdOutlineEdit, MdOutlineEventNote, MdOutlineForum } from 'react-icons/md'
import { FiAward, FiHome } from 'react-icons/fi'
import ProfileSwitcher from './ProfileSwitcher'
import config from '../app-config'

export default function Navbar({ selected }) {
    let { isAuthorised, userRole } = useFetchAuthorisation("user")

    const size = 25
    const color = "gray"
    const navigate = useNavigate()
    const [headerHeight, setHeaderHeight] = useState(document?.getElementById("user-header")?.clientHeight)

    useLayoutEffect(() => {
        setHeaderHeight(document?.getElementById("user-header")?.clientHeight)
    }, [])



    let items = [
        {
            name: "Home",
            icon: <FiHome size={size} className="text-slate-500" />,
            link: "/home",
            authorisation: "user",
            children: []
        },
        {
            name: "Courses",
            icon: <FiAward size={size} className="text-slate-500" />,
            link: "/courses",
            authorisation: "user",
        },
        {
            name: "Grades",
            icon: <MdOutlineEdit size={size} className="text-slate-500" />,
            authorisation: "user",
            link: "/grades",
        },
        // {
        //     name: "Calendar",
        //     icon: <MdCalendarToday size={size} className="text-slate-500" />,
        //     authorisation: "user",
        //     link: "/accounting",
        // },
        {
            name: "Forums",
            icon: <MdOutlineForum size={size} className="text-slate-500" />,
            authorisation: "user",
            link: "/forums",
        },
        // {
        //     name: "Dev Playground",
        //     icon: <MdOutlineFlag size={size} className="text-slate-500" />,
        //     authorisation: "user",
        //     link: "/playground",
        // }
    ]
    return (
        <div className='navbar shadow-md' id='navbar' style={{ height: "100dvh", backgroundColor: "white"
        // "#E5F3FE"
         }}>
            <div className='column justify-between items-start' style={{ height: "100%", width:"100%" }}>
                <div style={{width:"100%"}}>
                    <div className="row gap-4 pr-20" style={{ padding: 20 }}>
                        <img src={config.images.logo_wo_text} style={{ height: 50, width: 50, objectFit: "contain", }} alt="" />
                        <div>
                            <p style={{ fontWeight: "800", fontSize: 20,color:"var(--primary)", }}>{config.client_title}</p>
                            <p style={{ fontWeight: "600", fontSize: 12, color: "gray", letterSpacing: 2 }}>{config.client_subtitle}</p>
                        </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", padding: 10, width: "100%" }}>
                    {items.map((item, index) => {
                            if (!authorisationConverter({ requirement: item?.authorisation, current_role: userRole })) return null
                            return (
                                <div className={`navbarItem rounded-md w-full 
                                ${selected === item.name ? "bg-indigo-100" : ""}
                                `}
                                    key={index} id={`navitem-${index}`}>
                                    <div className='navbarItemIcon'
                                        onClick={() => {
                                            navigate(item.link)
                                        }}
                                    >
                                        {item.icon}
                                        <p
                                            className={`text-sm py-1 ${selected === item.name ? "text-indigo-500" : "text-slate-500"}`}
                                        >{item.name}</p>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div style={{width:"100%"}} className='mb-4' id='profile-button'>
                    {authorisationConverter({ requirement: "parents", current_role: userRole }) && 
                        <ProfileSwitcher />
                    }
                    <p className="text-slate-500 text-xs text-center mt-4">Built by <a href='www.ourcodelab.com' className='coy-website' style={{ textDecoration: "none" }}> OurCodeLab LLP</a></p>
                </div>
            </div>
        </div>
    )
}
