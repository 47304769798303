import React, { useEffect, useState } from 'react'
import Navbar from '../../components/Navbar'
import UserHeader from '../../components/UserHeader'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import UserLayout from '../../layout/UserLayout'
import notify from '../../utils/IANotification'
import { tidyDate } from '../../utils/helper'
import { MdLock } from 'react-icons/md'

export default function GradesPage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = useFetchUserData()
  const [gradesData, setGradesData] = useState({})

  const fetchAllGrades = async () => {
    let endpoint = config.endpoint + "/grades/student"
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      setGradesData(data.data.attempts)
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  useEffect(() => {
    fetchAllGrades()
  }, [])

  return (
    <UserLayout navSelected={"Grades"}>
      <p className="text-2xl mb-4">Grades Centre</p>
      {/* <pre>{JSON.stringify(gradesData,null,2)}</pre> */}
      {Object.keys(gradesData)?.length > 0 ? Object.keys(gradesData).map((key, index) => {
        return (
          <div className='card shadow-md rounded-md'>
            <p>{key}</p>
            {gradesData[key]?.map((data, index) => {
              let grade = parseFloat(data?.final_grade) / parseFloat(data?.max_grade)
              let gradePercentage = grade * 100
              let color = ""
              let bgColor = ""
              if (gradePercentage >= 80) {
                color = "white"
                bgColor = "var(--green)"
              } else if (gradePercentage >= 60) {
                color = "white"
                bgColor = "orange"
              } else if (gradePercentage >= 50) {
                color = "white"
                bgColor = "yellow"
              } else {
                color = "white"
                bgColor = "var(--red)"
              }

              if (isNaN(gradePercentage)) gradePercentage = 0

              return (
                <div className='mt-4 p-all row items-center justify-between' style={{ borderBottom: "2px solid gray" }}>
                  <div>
                    <p className="text-md font-semibold">{data?.assessment_data?.title}</p>
                    <p className="text-sm text-slate-500">{tidyDate(data?.assessment_data?.createdAt)}</p>
                  </div>
                  <p style={{
                    color: color,
                    backgroundColor: bgColor,
                    padding: "5px 20px",
                    borderRadius: 20,
                    fontWeight: "900",
                  }}>{gradePercentage}%</p>
                </div>
              )
            })}
          </div>
        )
      }) :
        <div className="card rounded-md shadow-md p-4 mt-4">
          <MdLock style={{ fontSize: 50, color: "gray" }} />
          <p style={{ fontWeight: "normal", fontSize: 15, marginBottom: 10 }}>Grades have yet to be released, please check back later!</p>
        </div>
      }
    </UserLayout >
  )
}
