import React, { useState, useEffect } from 'react'
import Navbar from '../../components/Navbar'
import UserHeader from '../../components/UserHeader'
import { useNavigate, useParams } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import AdminLayout from '../../layout/AdminLayout'
import notify from '../../utils/IANotification'
import AdminCourseNavbar from '../../components/AdminCourseNavbar'
import IAPrompt from '../../utils/IAPrompt'
import FileUploadField from '../../components/FileUploadField'
import { isMobile } from '../../utils/helper'

export default function AdminCourseFormGeneralPage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("staff")
  let user_data = useFetchUserData()

  const course_id = new URLSearchParams(window.location.search)?.get("course_id")
  const lesson_id = new URLSearchParams(window.location.search)?.get("lesson_id")



  const [courseData, setCourseData] = useState({
    name: "",
    description: "",
  })

  const createCourse = async () => {
    let endpoint = config.endpoint + "/course/"
    let fetchResponse = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(courseData)
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      let id = data.data.id
      navigate("/admin/courses/details?course_id=" + id)
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  const editCourse = async () => {
    if (course_id === "new") return
    let endpoint = config.endpoint + "/course/general/id/" + course_id
    let fetchResponse = await fetch(endpoint, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(courseData)
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      let id = data.data.id
      notify({ message: data.message, type: "success" })
    } else {
      notify({ message: data.message, type: "error" })
    }
  }


  const deleteCourse = async () => {
    if (course_id === "new") return
    let endpoint = config.endpoint + "/course/id/" + course_id
    let fetchResponse = await fetch(endpoint, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      navigate("/admin/courses")
    } else {
      notify({ message: data.message, type: "error" })
    }
  }


  const fetchCourseById = async () => {
    if (course_id === "new") return
    let endpoint = config.endpoint + "/course/id/" + course_id
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      setCourseData(data.data)
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  useEffect(() => {
    fetchCourseById()
  }, [])

  // useEffect(() => {
  //   if (courseData?.file_id) {
  //     editCourse()
  //   }
  // }, [courseData?.file_id])
  const [width, setWidth] = useState(window.innerWidth);

  return (
    <AdminLayout navSelected={"Courses"}>
      <p className='mb-4' style={{ fontSize: 30, fontWeight: "500" }}>{course_id === "new" ? "New Course" : courseData?.name}</p>

      {/* Course Nav Bar */}
      {course_id != "new" && <AdminCourseNavbar selected={"General"} course_id={course_id} />}
      <div className="card rounded-md shadow-md p-4 mt-4">
        <div className={`${isMobile(width) ? "column" : "row"} gap-4 items-start`}>
          <div style={{ width: "100%" }}>
            <div className="input-container">
              <label htmlFor="" className="input-label">Course Name</label>
              <input type="text" className="text-input" value={courseData.name} onChange={(e) => {
                setCourseData({ ...courseData, name: e.target.value })
              }} />
            </div>
            <div className="input-container">
              <label htmlFor="" className="input-label">Course Description</label>
              <textarea type="text" className="textarea-input" value={courseData.description} onChange={(e) => {
                setCourseData({ ...courseData, description: e.target.value })
              }} />
            </div>
          </div>
          <div style={{ width: isMobile(width) ? "100%" : "50%" }}>
            <label htmlFor="" className="input-label mb-20">Course Image</label>
            <FileUploadField upload_url={config.endpoint + "/file/course/id/" + course_id}
              item_index={0}
              max_files={1}
              max_file_size={1}
              text={"Upload Course Image"}
              subtext={"Drag and drop your image here or click to browse"}
              onChange={(files) => {
                setCourseData({ ...courseData, file_id: files[0].id })
              }} />
          </div>
        </div>

        {course_id == "new" ?
          <div className="button-container">
            <button className="button" onClick={() => {
              navigate("/admin/courses")
            }}>Cancel</button>
            <button className="button" onClick={() => {
              createCourse()
            }}>Create Course</button>
          </div>
          :
          <div className="button-container">
            {authorisationConverter({ requirement: "admin", current_role: user_data?.account_type }) &&
              course_id != "new" &&
              <button className="button-delete" onClick={() => {
                IAPrompt({
                  title: "Delete Course",
                  message: "Are you sure you want to delete this course? This action cannot be undone.",
                  buttons: [
                    {
                      label: "Cancel",
                      onClick: () => { }
                    },
                    {
                      label: "Delete",
                      onClick: () => {
                        deleteCourse()
                      }
                    }
                  ]
                })
              }}>Delete</button>}
            <button className="button" onClick={() => {
              editCourse()
            }}>Save Changes</button>
          </div>
        }
      </div>
    </AdminLayout >
  )
}
