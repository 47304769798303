import React, { useState, useEffect } from 'react'
import Navbar from '../../components/Navbar'
import UserHeader from '../../components/UserHeader'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import AdminLayout from '../../layout/AdminLayout'
import { useParams } from 'react-router'
import notify from '../../utils/IANotification'
import Switch from 'react-switch'
import Select from 'react-select'

export default function AdminStudentFeedbackRequestFormPage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("staff")
  let user_data = useFetchUserData()

  const { course_id } = useParams()
  const request_id = new URLSearchParams(window.location.search)?.get("request_id")


  const [classData, setClassData] = useState([])
  const [teacherData, setTeacherData] = useState([])
  const [feedbackFormData, setFeedbackFormData] = useState([])

  const [completion_deadline, setCompletion_deadline] = useState("")
  const [requestData, setRequestData] = useState([])

  const fetchFormData = async () => {
    let endpoint = config.endpoint + "/feedback/form"
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      }
    })
    let response = await fetchResponse.json()
    if (response.status === 200) {
      setFeedbackFormData(response.data)
    } else {
      notify({
        message: response.message,
        type: "error"
      })
    }
  }

  const fetchAllClasses = async () => {
    let endpoint = config.endpoint + "/class/"
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      }
    })
    let response = await fetchResponse.json()
    if (response.status === 200) {
      setClassData(response.data)
    } else {
      notify({
        message: response.message,
        type: "error"
      })
    }
  }

  const fetchAllTeachers = async () => {
    let endpoint = config.endpoint + "/teacher/"
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      }
    })
    let response = await fetchResponse.json()
    if (response.status === 200) {
      setTeacherData(response.data)
    } else {
      notify({
        message: response.message,
        type: "error"
      })
    }
  }

  const fetchRequestById = async () => {
    if (!request_id) return
    if (classData?.length <= 0) return
    let endpoint = config.endpoint + "/feedback/schedule/id/" + request_id
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      }
    })
    let response = await fetchResponse.json()
    if (response.status === 200) {
      setRequestData([
        {
          ...response.data,
          class: classData?.find((classItem) => classItem.id == response.data.class_id),
          is_requesting: 1,
        }
      ])
      setCompletion_deadline(response.data.completion_deadline)
    } else {
      notify({
        message: response.message,
        type: "error"
      })
    }
  }

  const requestFeedback = async () => {
    let endpoint = config.endpoint + "/feedback/schedule"
    let fetchResponse = await fetch(endpoint, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        data: requestData,
        completion_deadline: completion_deadline
      })
    })
    let response = await fetchResponse.json()
    if (response.status === 200) {
      notify({
        message: "Feedback Requested",
        type: "success"
      })
      navigate(-1)
    } else {
      notify({
        message: response.message,
        type: "error"
      })
    }
  }

  const collateRequestData = () => {
    if (request_id) return
    if (!teacherData || !classData || !feedbackFormData) return
    let data = []
    let teachers = [...teacherData]
    let classes = [...classData]
    let forms = [...feedbackFormData]

    classes.forEach((classItem) => {
      data.push({
        class: classItem,
        is_requesting: 1,
        staff_id: teachers?.[0]?.id,
        form_id: forms?.[0]?.id,
        class_id: classItem.id
      })
    })
    setRequestData(data)
  }

  useEffect(() => {
    collateRequestData()
    fetchRequestById()
  }, [classData, teacherData, feedbackFormData])

  useEffect(() => {
    // fetchRequestById()
    fetchAllTeachers()
    fetchAllClasses()
    fetchFormData()
  }, [])

  return (
    <AdminLayout navSelected={"Feedback & Review"}>
      <p className='mb-4' style={{ fontSize: 30, fontWeight: "500" }}>Request Feedback</p>
      <div className="card rounded-md shadow-md p-4 mt-4">
        <div className="input-container">
          <label htmlFor="" className='input-label'>Deadline</label>
          <input type="datetime-local" className='text-input' value={completion_deadline} onChange={(e) => setCompletion_deadline(e.target.value)} />
        </div>
        {/* <pre>{JSON.stringify(teacherData, null, 2)}</pre> */}
        {/* <pre>{JSON.stringify(requestData, null, 2)}</pre> */}
        {requestData?.length > 0 &&
          requestData?.map((data, index) => {
            return (
              <div className='row justify-between gap-20 items-center w-full mt-4'
                key={index}
                onClick={() => {
                  // navigate(`/admin/feedback/request/class?class_id=${data?.id}`)
                }}
              >
                <div className="">
                  <p className="card-title">{data?.class?.name}</p>
                  <p className="card-text">{data?.class?.course_data?.name}</p>
                </div>
                <div className="row gap-4 items-center">
                  {/* Form */}
                  <div className="input-container">
                    <label htmlFor="" className="input-label">Form</label>
                    {/* <p>{feedbackFormData.find((form) => form.id == data?.form_id)?.title}</p> */}
                    <Select
                      options={feedbackFormData.map((form) => {
                        return {
                          value: form?.id,
                          label: form.title
                        }
                      })}
                      value={feedbackFormData?.map((form) => {
                        return {
                          value: form?.id,
                          label: form.title
                        }
                      })?.find((form) => form.value == data?.form_id)}
                      onChange={(selected) => {
                        let temp = [...requestData]
                        temp[index].form_id = selected.value
                        setRequestData(temp)
                      }}
                      styles={{
                        control: (styles) => ({
                          ...styles,
                          width: 200
                        })
                      }}
                    />
                  </div>

                  {/* Teacher */}
                  <div className="input-container">
                    <label htmlFor="" className="input-label">Teacher</label>
                    <Select
                      options={teacherData.map((teacher) => {
                        return {
                          value: teacher,
                          label: teacher.first_name + " " + teacher.last_name
                        }
                      })}
                      value={teacherData?.map((teacher) => {
                        return {
                          value: teacher?.id,
                          label: teacher.first_name + " " + teacher.last_name
                        }
                      })?.find((teacher) => teacher.value == data?.staff_id)}
                      onChange={(selected) => {
                        let temp = [...requestData]
                        temp[index].staff_id = selected.value?.id
                        setRequestData(temp)
                      }}
                      styles={{
                        control: (styles) => ({
                          ...styles,
                          width: 200
                        })
                      }}
                    />
                  </div>

                  {/* Whether requesting or not */}
                  <div className="input-container">
                    <label htmlFor="" className="input-label">Request?</label>
                    <Switch
                      checked={data?.is_requesting == 1}
                      onChange={(checked) => {
                        let temp = [...requestData]
                        temp[index].is_requesting = checked ? 1 : 0
                        setRequestData(temp)
                      }}
                    />
                  </div>
                </div>
              </div>
            )
          })}
        {/* <pre>{JSON.stringify(teacherData, null, 2)}</pre>
        <pre>{JSON.stringify(requestData, null, 2)}</pre> */}
        <div className="column right alignEnd mt-30">
          <div className="row gap-4 items-center">
            <button className="button"
              onClick={() => {
                requestFeedback()
              }}
            >
              Send Request
            </button>
          </div>
        </div>
      </div>
    </AdminLayout>
  )
}
