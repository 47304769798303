import React, { useEffect, useState } from 'react'
import Navbar from '../../components/Navbar'
import UserHeader from '../../components/UserHeader'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import AdminLayout from '../../layout/AdminLayout'
import notify from '../../utils/IANotification'
import EnrolmentList from '../../subpages/EnrolmentList'

export default function AdminStudentFormPage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("staff")
  let user_data = useFetchUserData()

  const [studentFormData, setStudentFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
  })

  let student_id = new URLSearchParams(window.location.search).get("student_id")


  const createStudent = async () => {
    if (!studentFormData.first_name || !studentFormData.last_name || !studentFormData.email) {
      notify({ message: "Please fill in all fields", type: "error" })
      return
    }
    let endpoint = config.endpoint + "/students/"
    let fetchResponse = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        ...studentFormData,
      })
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      notify({ message: data.message, type: "success" })
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  const editStudent = async () => {
    if (!student_id) return notify({ message: "Unable to  edit student", type: "error" })
    if (student_id === "new") return notify({ message: "Unable to  edit student", type: "error" })
    if (!studentFormData.first_name || !studentFormData.last_name || !studentFormData.email) {
      notify({ message: "Please fill in all fields", type: "error" })
      return
    }
    let endpoint = config.endpoint + "/students/"
    let fetchResponse = await fetch(endpoint, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        ...studentFormData,
        student_id,
      })
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      notify({ message: data.message, type: "success" })
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  const fetchStudentById = async () => {
    if (!student_id) return
    if (student_id === "new") return
    let endpoint = config.endpoint + "/students/id/" + student_id
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      setStudentFormData(data.data)
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  useEffect(() => {
    fetchStudentById()
  } , [])

  return (
    <AdminLayout navSelected={"Enrolments"}>
      <p className='mb-4' style={{ fontSize: 30, fontWeight: "500" }}>{student_id !== "new" ? "Edit" : "Add"} Student</p>
      <div className="card rounded-md shadow-md p-4 mt-4">
        <p className="card-title">Student's Details</p>
        <div className="input-container">
          <label className='input-label'>First Name</label>
          <input
            type="text"
            className='text-input'
            value={studentFormData.first_name}
            onChange={(e) => {
              setStudentFormData({ ...studentFormData, first_name: e.target.value })
            }}
          />
        </div>
        <div className="input-container">
          <label className='input-label'>Last Name</label>
          <input
            type="text"
            className='text-input'
            value={studentFormData.last_name}
            onChange={(e) => {
              setStudentFormData({ ...studentFormData, last_name: e.target.value })
            }}
          />
        </div>
        <div className="input-container">
          <label className='input-label'>Email</label>
          <input
            type="text"
            className='text-input'
            value={studentFormData.email}
            onChange={(e) => {
              setStudentFormData({ ...studentFormData, email: e.target.value })
            }}
          />
        </div>

        <div className="column gap-4 right alignEnd w-full">
          <div className="row items-center gap-4">
            <button className="button"
              onClick={() => {
                navigate("/admin/enrolment")
              }}
            >Cancel</button>
            <button className="button"
              onClick={() => {
                if (student_id !== "new") {
                  editStudent()
                } else {
                  createStudent()
                }
              }}
            >{student_id !== "new" ? "Edit" : "Add"} Student</button>
          </div>
        </div>
      </div>
    </AdminLayout>
  )
}
