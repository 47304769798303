import React, { useEffect, useState } from 'react'
import { calculate_time_difference } from '../utils/helper'
import config from '../app-config'

export default function AssessmentHeader({ time_left }) {
    return (
        <div style={{ borderBottom: "1px solid gray", width: "100%", backgroundColor: "white", display: "flex", position: "sticky", top: 0, zIndex:10001, }} className='justifyCenter items-center'>
            <div className="header row items-center justify-between" style={{ padding: 10, maxWidth: 1500 }}>
                <div className="row gap-20">
                    <img src={config.images.logo_wo_text} alt="logo" style={{ width: "50px", height: "50px", objectFit: "contain" }} />
                    <div>
                        <p style={{ fontWeight: "900", fontSize: 20, color: "var(--primary)" }}>{config.client_title}</p>
                        <p style={{ fontWeight: "600", fontSize: 12, color: "gray", letterSpacing: 2 }}>{config.client_subtitle}</p>
                    </div>
                </div>
                <p id='timer'>{time_left ? time_left : ""}</p>
            </div>
        </div>
    )
}
