import React, { useEffect, useState, useRef } from 'react'
import Navbar from '../../components/Navbar'
import UserHeader from '../../components/UserHeader'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import UserLayout from '../../layout/UserLayout'
import { MdAdd } from 'react-icons/md'
import ModalLayout from '../../layout/ModalLayout'
import EmojiPicker from 'emoji-picker-react';
import notify from '../../utils/IANotification'

export default function ForumsPage() {
  const modalRef = useRef(null)
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = useFetchUserData()
  const [searchQuery, setSearchQuery] = useState("")
  const [forumCategory, setForumCategory] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [emojiModal, setEmojiModal] = useState(false)
  const [categoryForm, setCategoryForm] = useState({
    category_name: "",
    category_description: "",
    emoji: "🌏"
  })

  useEffect(() => {
    fetchCategory()
  }, [])

  const fetchCategory = async () => {
    let endpoint = config.endpoint + "/forums/category"
    let response = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await response.json()
    if (data.status === 200) {
      setForumCategory(data.data)
    }
  }

  const createCategory = async () => {
    let endpoint = config.endpoint + "/forums/category"
    let response = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(categoryForm)
    })
    let data = await response.json()
    if (data.status === 200) {
      setShowModal(false)
      fetchCategory()
      notify({ message: "Category Created", type: "success" })
    } else {
      notify({ message: "Unable to create category", type: "error" })
    }
  }


  return (
    <UserLayout navSelected={"Forums"}>

      {/* Category */}
      <ModalLayout modalTitle="Create Category" isVisible={showModal} setIsVisible={setShowModal}
        modalRef={modalRef}
      >
        <div className="column left items-start w-full mb-20 mt-30">
          <div
            id='emoji-picker'
            className='cursor-pointer'
            onClick={() => setEmojiModal(!emojiModal)}
            style={{ width: 100, height: 100, display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, 0.5)`, borderRadius: 15 }}
          >
            <p style={{ fontSize: 50 }}>{categoryForm.emoji}</p>
          </div>
        </div>
        {/* <p>{JSON.stringify(categoryForm, null, 2)}</p> */}

        <EmojiPicker
          style={{ position: "absolute", top: document.getElementById('emoji-picker')?.offsetTop + 100, left: document.getElementById('emoji-picker')?.offsetLeft }}
          open={emojiModal}
          onEmojiClick={(e, emojiObject) => {
            setCategoryForm({ ...categoryForm, emoji: e.emoji })
            setEmojiModal(false)

          }}
        />
        <div className="input-container">
          <label htmlFor="" className="input-label">Name</label>
          <input type="text" className="text-input" value={categoryForm.category_name} onChange={(e) => setCategoryForm({ ...categoryForm, category_name: e.target.value })} />
        </div>
        <div className="input-container">
          <label htmlFor="" className="input-label">Description</label>
          <textarea type="text" className="textarea-input" value={categoryForm.category_description} onChange={(e) => setCategoryForm({ ...categoryForm, category_description: e.target.value })} />
        </div>

        <button className="button rounded-md shadow-md px-4 py-2 text-white text-sm w-full mt-4"
          onClick={() => {
            createCategory()
          }}>
          Create
        </button>
      </ModalLayout>



      <div className="row items-center justify-between w-full mb-20">
        <p style={{ fontWeight: "normal", fontSize: 25, }}>Forums</p>
        {authorisationConverter({ requirement: "staff", current_role: user_data?.account_type }) && 
        <button className="rounded-md button shadow-md px-4 py-2 text-white text-md row gap-4 items-center"
          onClick={() => setShowModal(true)}
        >
          <MdAdd size={12} color='white' />
          <p style={{ fontWeight: "normal", fontSize: 12 }}>Category</p>
        </button>}
      </div>
      {forumCategory?.length > 0 &&
        forumCategory?.map((category, index) => {
          return (
            <div className="card w-full row gap-20 items-center cursor-pointer mb-4  rounded-md shadow-md" key={index}
              onClick={()=>{
                navigate(`/forums/category/${category.id}`)
              }}
            >
              <div
                id='emoji-picker'
                className='cursor-pointer'
                style={{ width: 50, height: 50, display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, 0.5)`, borderRadius: 15 }}
              >
                <p style={{ fontWeight: "bold", fontSize: 35 }}>{category.emoji}</p>
              </div>
              <div>
                <p className="text-md">{category.category_name}</p>
                <p  className="text-slate-500 text-sm">{category.category_description}</p>
              </div>
            </div>
          )
        })
      }
      {/* <pre>{JSON.stringify(forumCategory, null, 2)}</pre> */}
    </UserLayout>
  )
}
