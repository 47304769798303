import React, { useState, useEffect, useLayoutEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { authorisationConverter, logout, useFetchAuthorisation } from '../utils/AppFunctions'
import { FaAngleRight, FaSchool } from 'react-icons/fa'
import { AiFillDashboard, AiOutlineDashboard } from 'react-icons/ai'
import { MdAccountBalance, MdBusiness, MdCalendarToday, MdClass, MdEditDocument, MdExitToApp, MdFlag, MdForum, MdGroup, MdLeaderboard, MdOutlineAccountBalance, MdOutlineAirplanemodeActive, MdOutlineClass, MdOutlineEventNote, MdOutlineFlag, MdOutlineGroup, MdOutlineSchool, MdOutlineSettings, MdPeople, MdPerson2, MdSchool, MdSettings } from 'react-icons/md'
import { FiAward, FiHome } from 'react-icons/fi'
import "../hover.css"
import ProfileSwitcher from './ProfileSwitcher'
import { IoSchoolOutline } from 'react-icons/io5'
import config from '../app-config'

export default function TeachersNavbar({ selected }) {
    let { isAuthorised, userRole } = useFetchAuthorisation("user")

    const size = 25
    const color = "gray"
    const navigate = useNavigate()
    const [headerHeight, setHeaderHeight] = useState(document?.getElementById("admin-header")?.clientHeight + 10)



    useLayoutEffect(() => {
        setHeaderHeight(document?.getElementById("admin-header")?.clientHeight)
    }, [document?.getElementById("admin-header")?.clientHeight])



    let items = [
        {
            name: "Home",
            icon: <FaSchool size={size} className="text-slate-500" />,
            link: "/staff",
            authorisation: "staff",
            children: []
        },
        {
            name: "Classes",
            icon: <MdOutlineClass size={size} className="text-slate-500" />,
            authorisation: "staff",
            link: "/staff/classes",
        },
        {
            name: "Feedback & Review",
            icon: <MdOutlineFlag size={size} className="text-slate-500" />,
            authorisation: "staff",
            link: "/staff/feedback",
        },
        {
            name: "Teachers",
            icon: <MdOutlineGroup size={size} className="text-slate-500" />,
            authorisation: "staff",
            link: "/staff/teachers",
        },
        {
            name: "Enrolments",
            icon: <MdOutlineSchool size={size} className="text-slate-500" />,
            authorisation: "staff",
            link: "/staff/enrolment",
        },

    ]

    return (
        <div className='navbar' id='navbar' style={{
            height: "100dvh",
            backgroundColor: "white",
        }}>
            <div className='column justify-between items-start' style={{ height: "100%", width: "100%" }}>
                <div style={{ width: "100%" }}>
                    <div className="row gap-4 pr-20" style={{ padding: 20 }}>
                        <img src={config.images.logo_wo_text} style={{ height: 50, width: 50, objectFit: "contain", }} alt="" />
                        <div>
                            <p style={{ fontWeight: "900", fontSize: 20, color: "var(--primary)" }}>{config.client_title}</p>
                            <p style={{ fontWeight: "600", fontSize: 12, color: "gray", letterSpacing: 2 }}>{config.client_subtitle}</p>
                        </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", padding: 10, width: "100%" }}>
                        {items.map((item, index) => {
                            if (!authorisationConverter({ requirement: item?.authorisation, current_role: userRole })) return null
                            return (
                                <div className={`navbarItem rounded-md w-full 
                                ${selected === item.name ? "bg-indigo-100" : ""}
                                `}
                                    key={index} id={`navitem-${index}`}>
                                    <div className='navbarItemIcon'
                                        onClick={() => {
                                            navigate(item.link)
                                        }}
                                    >
                                        {item.icon}
                                        <p
                                            className={`text-sm py-1 ${selected === item.name ? "text-indigo-500" : "text-slate-500"}`}
                                        >{item.name}</p>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div style={{ width: "100%", justifyContent: "left", display: "flex", flexDirection: "column", alignItems: "flex-start", borderTop: "1px solid #E9E3F3" }} id='profile-button'>
                    {authorisationConverter({ requirement: "admin", current_role: userRole }) &&
                        <ProfileSwitcher />
                    }
                    <div className="px-2">
                        <div className='backgroundLess items-center gap-2 row p-2'
                            style={{ width: "100%", textAlign: "left", justifyContent: "left", display: "flex" }}
                            onClick={() => {
                                logout()
                                navigate("/auth/login")
                            }}
                        >
                            <MdExitToApp size={20} className="text-rose-500" />
                            <p className="text-sm text-rose-500">Logout</p>
                        </div>
                        <p className="text-slate-500 text-xs text-center p-2">Built by <a href='www.ourcodelab.com' className='coy-website' style={{ textDecoration: "none" }}> OurCodeLab LLP</a></p>
                    </div>
                </div>
            </div>
        </div>
    )
}
