import React, { useEffect, useState } from 'react'
import Navbar from '../components/Navbar'
import UserHeader from '../components/UserHeader'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../utils/AppFunctions'
import config from '../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import AdminLayout from '../layout/AdminLayout'
import notify from '../utils/IANotification'

export default function EnrolmentList({ type, filter }) {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = useFetchUserData()

  const [studentsData, setStudentsData] = useState([])
  const [familyData, setFamilyData] = useState([])
  const [selected, setSelected] = useState("family") // family or student

  const fetchAllStudents = async () => {
    let endpoint = config.endpoint + "/students/"
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      }
    })
    let response = await fetchResponse.json()
    if (response.status === 200) {
      setStudentsData(response.data)
    } else {
      notify({
        message: response.message,
        type: "error"
      })
    }
  }

  const fetchAllFamily = async () => {
    let endpoint = config.endpoint + "/family/"
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      }
    })
    let response = await fetchResponse.json()
    if (response.status === 200) {
      setFamilyData(response.data)
    } else {
      notify({
        message: response.message,
        type: "error"
      })
    }
  }

  useEffect(() => {
    fetchAllStudents()
    fetchAllFamily()
  }, [])

  return (
    <>
      {studentsData?.length > 0 &&
        <>
          {studentsData.map((data, index) => {
            if (filter && !JSON.stringify(data).toLowerCase().includes(filter.toLowerCase())) return null
            return (
              <div className='row justify-between gap-20 items-center w-full mt-4 card cursor-pointer border-l-2 border-l-blue-500'
              style={{ borderTopLeftRadius: "5px", borderBottomLeftRadius: "5px", borderRadius: "5px", paddingLeft: 10 }}
                key={index}
                onClick={() => {
                  if (type === "staff") return
                  navigate(`/${type}/students/details?student_id=${data?.id}`)
                }}
              >
                <div className="column">
                  <p className="card-title">{data?.first_name} {data?.last_name}</p>
                  <p className="card-text text-sm text-slate-500">{data?.email}</p>
                </div>
                {/* <button className="backgroundLess row gap-4 items-center"
                    onClick={() => {
                      IAPrompt({
                        title: "Remove Student",
                        message: "Are you sure you want to remove this student?",
                        buttons: [
                          {
                            label: "Cancel",
                            onClick: () => {
                              console.log("cancelled")
                            }
                          },
                          {
                            label: "Remove",
                            onClick: () => {
                              removeStudentFromClass(data?.membership_id)
                            }
                          },
                        ]
                      })
                    }}
                  >
                    <IoTrash size={20} />
                    Remove
                  </button> */}
              </div>
            )
          })}
        </>
      }
    </>
  )
}
