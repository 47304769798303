import React, { useEffect, useState } from 'react'
import Navbar from '../../components/Navbar'
import UserHeader from '../../components/UserHeader'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import ParentsLayout from '../../layout/ParentsLayout'
import notify from '../../utils/IANotification'
import Select from 'react-select'
import { MdLock } from 'react-icons/md'
import { tidyDate } from '../../utils/helper'

export default function ParentsGradesPage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = useFetchUserData()
  const [gradesData, setGradesData] = useState({})
  const [childData, setChildData] = useState([])
  const [selectedChild, setSelectedChild] = useState({})

  const fetchChildGrades = async () => {
    setGradesData({})
    if (!selectedChild?.user_id) return
    let endpoint = config.endpoint + "/grades/parents/child/id/" + selectedChild?.user_id
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      setGradesData(data.data.attempts)
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  const fetchFamilyData = async () => {
    let endpoint = config.endpoint + `/family/parent/id/`
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      let temp = data.data?.members?.filter((item) => {
        return item.role === "student"
      })
      setChildData(temp)
      setSelectedChild(temp?.[0])
    }
  }

  useEffect(() => {

    fetchFamilyData()
  }, [])

  useEffect(() => {
    fetchChildGrades()
  }, [selectedChild])

  return (
    <ParentsLayout navSelected={"Grades"}>
      <div className="row gap-4 items-start justify-between">
        <p style={{ fontWeight: "normal", fontSize: 25, marginBottom: 10 }}>{selectedChild?.user_data?.first_name}'s Grades</p>
        {childData?.length > 0 && <Select
          options={childData?.map((item) => {
            return {
              value: item,
              label: `${item?.user_data?.first_name} ${item?.user_data?.last_name}`
            }
          })}
          onChange={(option) => {
            setSelectedChild(option?.value)
          }}
          value={childData?.map((item) => {
            return {
              value: item,
              label: `${item?.user_data?.first_name} ${item?.user_data?.last_name}`
            }
          })?.find((item) => item.value.id === selectedChild.id)}
        />}
      </div>
      {/* <pre>{JSON.stringify(selectedChild, null, 2)}</pre>
        <pre>{JSON.stringify(gradesData, null, 2)}</pre> */}
      {Object.keys(gradesData)?.length > 0 ? Object.keys(gradesData).map((key, index) => {
        return (
          <div className='card'>
            <p>{key}</p>
            {gradesData[key]?.map((data, index) => {
              let grade = parseFloat(data?.final_grade) / parseFloat(data?.max_grade)
              let gradePercentage = grade * 100
              let color = ""
              let bgColor = ""
              if (gradePercentage >= 80) {
                color = "white"
                bgColor = "var(--green)"
              } else if (gradePercentage >= 60) {
                color = "white"
                bgColor = "orange"
              } else if (gradePercentage >= 50) {
                color = "white"
                bgColor = "yellow"
              } else {
                color = "white"
                bgColor = "red"
              }
              if (isNaN(gradePercentage)) gradePercentage = 0

              return (
                <div className='mt-4 p-all row items-center justify-between' style={{ borderBottom: "2px solid gray" }}>
                  <div>
                    <p>{data?.assessment_data?.title}</p>
                    <p>{tidyDate(data?.assessment_data?.createdAt)}</p>
                  </div>
                  <p style={{
                    color: color,
                    backgroundColor: bgColor,
                    padding: "5px 20px",
                    borderRadius: 20,
                    fontWeight: "900",
                  }}>{gradePercentage}%</p>
                </div>
              )
            })}
          </div>
        )
      }) :
        <div className="card rounded-md shadow-md p-4 mt-4">
          <MdLock style={{ fontSize: 50, color: "gray" }} />
          <p style={{ fontWeight: "normal", fontSize: 15, marginBottom: 10 }}>Grades have yet to be released, please check back later!</p>
        </div>
      }
    </ParentsLayout>
  )
}
