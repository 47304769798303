import React, { useState, useEffect } from 'react'
import Navbar from '../components/Navbar'
import UserHeader from '../components/UserHeader'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../utils/AppFunctions'
import config from '../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import { useParams } from 'react-router'
import notify from '../utils/IANotification'
import { calculate_time_difference, calculate_time_difference_tidy, capitaliseFirstLetter, isMobile, tidyDate } from '../utils/helper'
import { MdDataArray, MdDataUsage, MdEdit, MdLock, MdPerson } from 'react-icons/md'
import Select from 'react-select'


export default function UserProfilePage({ type }) {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("staff")
  let user_data = useFetchUserData()
  let user_id = new URLSearchParams(window.location.search).get("user_id")
  const { course_id } = useParams()
  const [width, setWidth] = useState(window.innerWidth)

  const [userData, setUserData] = useState({})

  const [showPasswordReset, setShowPasswordReset] = useState(false)
  const [password, setPassword] = useState("")


  const resetPassword = async () => {
    if (!user_id) {
      return notify({ type: "error", message: "Unable to reset password" })
    }
    let endpoint = config.endpoint + "/auth/password/reset"
    let response = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        user_id: user_id,
        password: password
      })
    })
    let data = await response.json()
    if (data.status === 200) {
      notify({ type: "success", message: "Password reset" })
    } else {
      notify({ type: "error", message: "Unable to reset password" })
    }
  }


  const fetchUserFullProfile = async () => {
    setUserData({})
    if (!user_id) {
      return notify({ type: "error", message: "Unable to fetch user profile" })
    }
    let endpoint = config.endpoint + "/auth/user/id/" + user_id
    let response = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await response.json()
    if (data.status === 200) {
      setUserData(data.data)
    } else {
      notify({ type: "error", message: "Unable to fetch user profile" })
    }
  }

  const unlockAccount = async () => {
    let endpoint = config.endpoint + "/auth/user/unlock"
    let response = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        user_id: user_id
      })
    })
    let data = await response.json()
    if (data.status === 200) {
      notify({ type: "success", message: "Account unlocked" })
      fetchUserFullProfile()
    } else {
      notify({ type: "error", message: "Unable to unlock account" })
    }
  }

  const saveUserDetails = async () => {
    if (!user_id) {
      return notify({ type: "error", message: "Unable to save user details" })
    }
    let endpoint = config.endpoint + "/auth/user/id/" + user_id
    let response = await fetch(endpoint, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(userData?.user_data)
    })
    let data = await response.json()
    if (data.status === 200) {
      notify({ type: "success", message: "User details saved" })
      fetchUserFullProfile()
    } else {
      notify({ type: "error", message: "Unable to save user details" })
    }
  }

  const ROLES = [
    { value: "student", label: "Student" },
    { value: "parent", label: "Parents" },
    { value: "staff", label: "Staff" },
    { value: "admin", label: "Admin" },
    { value: "superadmin", label: "Super Admin" },
  ]

  useEffect(() => {
    fetchUserFullProfile()
  }, [user_id])

  return (
    <div>
      {Object.keys(userData).length > 0 ? <>
        <p className='mb-4' style={{ fontSize: 30, fontWeight: "500" }}>{userData?.user_data?.first_name} {userData?.user_data?.last_name}</p>
        <div className={`${isMobile(width) ? "column" : "row"} gap-4 mt-4 items-start`}>
          <div style={{ width: isMobile(width) ? "100%" : "70%" }}>
            <div className="card rounded-md shadow-md p-4 mt-4">
              <div className="row justify-between items-center">
                <p className="card-title">User Details</p>
                <button className="backgroundLess row gap-4 items-center"
                  onClick={() => {
                    navigate(`/${type}/students/details?student_id=${user_id}`)
                  }}
                >
                  <MdEdit size={15} />
                  <p style={{ fontSize: 15 }}>Edit</p>
                </button>
              </div>
              <div className={`${isMobile(width) ? "column" : "row"} gap-4 items-start justify-between`}>
                <div className="input-container">
                  <label htmlFor="" className="input-label">First Name</label>
                  <input type="text" className="text-input" value={userData?.user_data?.first_name} disabled={type === "staff"} onChange={(e) => {
                    setUserData({ ...userData, user_data: { ...userData?.user_data, first_name: e.target.value } })
                  }} />
                </div>
                <div className="input-container">
                  <label htmlFor="" className="input-label">Last Name</label>
                  <input type="text" className="text-input" value={userData?.user_data?.last_name} disabled={type === "staff"} onChange={(e) => {
                    setUserData({ ...userData, user_data: { ...userData?.user_data, last_name: e.target.value } })
                  }} />
                </div>
              </div>

              <div className={`${isMobile(width) ? "column" : "row"} gap-4 items-start justify-between`}>
                <div className="input-container">
                  <label htmlFor="" className="input-label">Email</label>
                  <input type="email" className="text-input" value={userData?.user_data?.email} disabled={type === "staff"} onChange={(e) => {
                    setUserData({ ...userData, user_data: { ...userData?.user_data, email: e.target.value } })
                  }} />
                </div>
                <div className="input-container">
                  <label htmlFor="" className="input-label">Phone</label>
                  <input type="tel" className="text-input" value={userData?.user_data?.contact_number} disabled={type === "staff"} onChange={(e) => {
                    setUserData({ ...userData, user_data: { ...userData?.user_data, contact_number: e.target.value } })
                  }} />
                </div>
              </div>

              <div className="input-container">
                <label htmlFor="" className="input-label">Remarks</label>
                <textarea type="text" className="textarea-input" value={userData?.user_data?.account_remarks} disabled={type === "staff"} onChange={(e) => {
                  setUserData({ ...userData, user_data: { ...userData?.user_data, account_remarks: e.target.value } })
                }} />
              </div>
            </div>

            <div className="card rounded-md shadow-md p-4 mt-4">
              <div className={`${isMobile(width) ? "column" : "row"} justify-between items-center`}>
                <p className="card-title">Account Details</p>
                <button className="backgroundLess row gap-4 items-center"
                  style={userData?.user_data?.account_lock_till ?
                    { color: "red", cursor: "pointer" } :
                    { color: "var(--green)", cursor: "not-allowed" }
                  }
                  disabled={
                    userData?.user_data?.account_lock_till ?
                      false :
                      true
                  }
                  onClick={() => {
                    unlockAccount()
                  }}
                >
                  <MdLock size={15} />
                  <p style={{ fontSize: 15 }}>{userData?.user_data?.account_lock_till ? "Unlock Account" : "Account is not locked"}</p>
                </button>
              </div>
              <div className="input-container">
                <label htmlFor="" className="input-label">Privilege</label>
                <Select
                  isDisabled={type === "staff"}
                  options={ROLES}
                  value={ROLES.filter((role) => role.value === userData?.user_data?.account_type)}
                  onChange={(e) => {
                    setUserData({ ...userData, user_data: { ...userData?.user_data, account_type: e.value } })
                  }}
                />
              </div>
              {userData?.user_data?.last_login ? <p style={{ color: "gray", fontSize: 12 }}>Last login: {tidyDate(userData?.user_data?.last_login)} ({calculate_time_difference_tidy(new Date(), userData?.user_data?.last_login)})</p> : <p style={{ color: "gray", fontSize: 12 }}>No login history</p>}
              <div className="input-container">
                <div className="flex flex-row items-center gap-2">
                  <label htmlFor="" className="input-label">Password</label>
                  <button className="button backgroundLess" style={{ padding: "5px 10px", color: "gray", fontSize:12, }}
                    onClick={() => {
                      setShowPasswordReset(!showPasswordReset)
                    }}
                  >Reset</button>
                </div>
                {showPasswordReset &&
                  <div className="row gap-4 items-center">
                    <input type="password" className="text-input" value={password} onChange={(e) => {
                      setPassword(e.target.value)
                    }} />
                    <button className="button"
                      onClick={() => {
                        resetPassword()
                      }}
                    >Save</button>

                  </div>
                }
              </div>
              <div className="column mt-4 alignEnd">
                <button className="button"
                  onClick={() => {
                    saveUserDetails()
                  }}
                >Save</button>
              </div>
            </div>

            {/* <pre>{JSON.stringify(userData, null, 2)}</pre> */}


            <div className="card rounded-md shadow-md p-4 mt-4">
              <div className="row justify-between items-center">
                <p className="card-title">Family</p>
                <button className="backgroundLess row gap-4 items-center"
                  onClick={() => {
                    navigate(`/${type}/family/details?family_id=${userData?.family_data?.id}`)
                  }}
                >
                  <MdEdit size={15} />
                  <p style={{ fontSize: 15 }}>Edit</p>
                </button>
              </div>

              <div className={`${isMobile(width) ? "column" : "row"} gap-4 items-start justify-between`}>
                <div className="input-container">
                  <label htmlFor="" className="input-label">Parent</label>
                  {/* <p>{userData?.family_data?.members?.filter((member) => member?.role === "parent")?.[0]?.first_name} {userData?.family_data?.members?.filter((member) => member?.role === "parent")?.[0]?.last_name}</p> */}
                  <input type="text" className="text-input" value={capitaliseFirstLetter(userData?.family_data?.members?.filter((member) => member?.role === "parent")?.[0]?.first_name || " ") + " " + capitaliseFirstLetter(userData?.family_data?.members?.filter((member) => member?.role === "parent")?.[0]?.last_name || " ")} disabled={true} />
                </div>
                <div className="input-container">
                  <label htmlFor="" className="input-label">Role</label>
                  <input type="text" className="text-input" value={capitaliseFirstLetter(userData?.family_data?.role || " ")} disabled={true} />
                </div>
              </div>

              {userData?.family_data?.members?.length > 0 ?
                <div className="row gap-4 items-start justify-between">
                  <div className="input-container">
                    <label htmlFor="" className="input-label">Members</label>
                    <div>
                      {userData?.family_data?.members?.map((member, index) => {
                        return (
                          <div key={index} className="cursor-pointer"
                            onClick={() => {
                              navigate(`/${type}/user?user_id=${member?.id}`)
                            }}
                            style={{ borderTopLeftRadius: 5, borderBottomLeftRadius: 5, borderLeft: "4px solid var(--primary)", paddingLeft: 10, marginBottom: 10 }}
                          >
                            <p style={{ fontSize: 15, color: "black", fontWeight: "bold" }}>{capitaliseFirstLetter(member?.first_name)} {capitaliseFirstLetter(member?.last_name)}</p>
                            <p style={{ fontSize: 12, color: "gray" }}>{capitaliseFirstLetter(member?.role)}</p>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
                : <div className="input-container">
                  <label htmlFor="" className="input-label">Members</label>
                  <p style={{ fontSize: 12, color: "gray" }}>No family members</p>
                </div>
              }
            </div>

            <div className="card rounded-md shadow-md p-4 mt-4">
              <p className="card-title">Grades</p>
              {Object.keys(userData?.grades_data)?.length > 0 ? Object.keys(userData?.grades_data).map((key, index) => {
                return (
                  <div className='mt-4'>
                    <p style={{ fontSize: 12, color: "gray", fontWeight: "bold" }}>{key}</p>
                    {userData?.grades_data[key]?.map((data, index) => {
                      let grade = parseFloat(data?.final_grade) / parseFloat(data?.max_grade)
                      let gradePercentage = grade * 100
                      let color = ""
                      let bgColor = ""
                      if (gradePercentage >= 80) {
                        color = "white"
                        bgColor = "var(--green)"
                      } else if (gradePercentage >= 60) {
                        color = "white"
                        bgColor = "orange"
                      } else if (gradePercentage >= 50) {
                        color = "white"
                        bgColor = "yellow"
                      } else {
                        color = "white"
                        bgColor = "red"
                      }

                      if (isNaN(gradePercentage)) gradePercentage = 0

                      return (
                        <div className='mb-4 p-all row items-center justify-between' style={{ borderBottom: "2px solid gray" }}>
                          <div>
                            <p>{data?.assessment_data?.title}</p>
                            <p>{tidyDate(data?.assessment_data?.createdAt)}</p>
                          </div>
                          <p style={{
                            color: color,
                            backgroundColor: bgColor,
                            padding: "5px 20px",
                            borderRadius: 20,
                            fontWeight: "900",
                          }}>{gradePercentage}%</p>
                        </div>
                      )
                    })}
                  </div>
                )
              }) :
                <div className="card rounded-md shadow-md p-4 mt-4">
                  <p style={{ fontWeight: "normal", fontSize: 12, color: "gray", marginBottom: 10 }}>No grades have been published yet</p>
                </div>
              }
            </div>
          </div>


          <div style={{ width: isMobile(width) ? "100%" : "30%" }}>
            {/* Feedbacks */}
            <div className="card rounded-md shadow-md p-4 mt-4">
              <p className="card-title">Feedbacks</p>
              {userData?.feedback_data?.length > 0 ? userData?.feedback_data?.map((feedback, index) => {
                return (
                  <div key={index} className="cursor-pointer mt-4" style={{ borderBottom: (userData?.feedback_data?.length == (index + 1)) ? "0px solid gray" : "0px", borderTopLeftRadius: 5, borderBottomLeftRadius: 5, borderLeft: "4px solid var(--primary)", paddingLeft: 10 }}
                    onClick={() => {
                      // http://127.0.0.1:3000/admin/feedback/review/form?request_id=2&student_id=3&form_id=1
                      navigate(`/${type}/feedback/review/form?request_id=${feedback?.id}&student_id=${user_id}&form_id=${feedback?.form_id}`)
                    }}
                  >
                    <p style={{ fontSize: 15, color: "black", fontWeight: "bold" }}>{feedback?.class_data?.course_data?.name}</p>
                    <p style={{ fontSize: 12, color: "gray" }}>{tidyDate(feedback?.createdAt)}</p>
                    {/* <pre>{JSON.stringify(feedback,null,2)}</pre> */}
                  </div>
                )
              })
                : <p style={{ color: "gray", fontSize: 12 }}>No feedbacks</p>
              }
            </div>

            {/* Certificates */}
            <div className="card rounded-md shadow-md p-4 mt-4" style={{}}>
              <p className="card-title">Certificates Issued</p>
              {userData?.certificates_data?.length > 0 ? userData?.certificates_data?.map((certificate, index) => {
                return (
                  <div key={index} className="cursor-pointer mt-4" style={{ borderBottom: (userData?.certificates_data?.length == (index + 1)) ? "0px solid gray" : "0px", borderTopLeftRadius: 5, borderBottomLeftRadius: 5, borderLeft: "4px solid var(--primary)", paddingLeft: 10 }}
                    onClick={() => {
                      navigate(`/${type}/certificates/details?certificate_id=${certificate?.id}`)
                    }}
                  >
                    <p style={{ fontSize: 15, color: "black", fontWeight: "bold" }}>{certificate?.course_data?.name}</p>
                    <p style={{ fontSize: 12, color: "gray" }}>{tidyDate(certificate?.createdAt)}</p>
                    {/* <pre>{JSON.stringify(certificate,null,2)}</pre> */}
                  </div>
                )
              })
                : <p style={{ color: "gray", fontSize: 12 }}>No certificates issued</p>
              }
            </div>

            {/* Courses */}
            <div className="card rounded-md shadow-md p-4 mt-4">
              <p className="card-title">Class Enrolled</p>
              {userData?.class_data?.length > 0 ? userData?.class_data?.map((classData, index) => {
                return (
                  <div key={index} className="cursor-pointer mt-4" style={{ borderBottom: (userData?.classes_data?.length == (index + 1)) ? "0px solid gray" : "0px", borderTopLeftRadius: 5, borderBottomLeftRadius: 5, borderLeft: "4px solid var(--primary)", paddingLeft: 10 }}
                    onClick={() => {
                      navigate(`/${type}/courses/classes/details?course_id=${classData?.id}&class_id=${classData?.id}`)
                    }}
                  >
                    <p style={{ fontSize: 15, color: "black", fontWeight: "bold" }}>{classData?.name}</p>
                    <p style={{ fontSize: 12, color: "gray" }}>[{classData?.course_data?.name}] {tidyDate(classData?.start_datetime)} - {classData?.is_forever == 1 ? "Forever" : tidyDate(classData?.end_datetime)}</p>
                    {/* <pre>{JSON.stringify(classData,null,2)}</pre> */}
                  </div>
                )
              })
                : <p style={{ color: "gray", fontSize: 12 }}>Not enrolled in any class</p>
              }
            </div>
          </div>
        </div>
      </>
        :
        <div className="card rounded-md shadow-md p-4 mt-4">
          <MdPerson style={{ fontSize: 50, color: "gray" }} />
          <p style={{ fontWeight: "normal", fontSize: 12, color: "gray" }}>Loading user full profile...</p>
        </div>
      }
    </div>
  )
}
