import React, { useEffect, useState } from 'react'
import Navbar from '../components/Navbar'
import UserHeader from '../components/UserHeader'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../utils/AppFunctions'
import config from '../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import AdminLayout from '../layout/AdminLayout'
import notify from '../utils/IANotification'
import IAPrompt from '../utils/IAPrompt'
import { isMobile } from '../utils/helper'

export default function TeacherForm() {
  const navigate = useNavigate()
  const [width, setWidth] = useState(window.innerWidth)
  
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = useFetchUserData()
  let teacher_id = new URLSearchParams(window.location.search)?.get("teacher_id")

  const [teacherData, setTeacherData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    contact_number: "",
  })

  const createTeacher = async () => {
    let endpoint = config.endpoint + "/teacher/"
    let fetchResponse = await fetch(endpoint, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(teacherData)
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      notify({ message: "Teacher Created", type: "success" })
      navigate(-1)
    } else {  
      notify({ message: data.message, type: "error" })
    }
  }


  const editTeacher = async () => {
    if (!teacher_id) return
    let endpoint = config.endpoint + "/teacher/id" + teacher_id
    let fetchResponse = await fetch(endpoint, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(teacherData)
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      notify({ message: "Teacher Updated", type: "success" })
      navigate(-1)
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  const deleteTeacher = async () => {
    if (!teacher_id) return
    let endpoint = config.endpoint + "/teacher/id" + teacher_id
    let fetchResponse = await fetch(endpoint, {
      method: "DELETE",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      },
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      navigate(-1)
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  const fetchTeacherById = async () => {
    if (!teacher_id) return
    if (teacher_id === "new") return
    let endpoint = config.endpoint + "/teacher/id/" + teacher_id
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      },
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      setTeacherData(data.data)
      // console.log(data.data)
    } else {
      notify({ message: data.message, type: "error" })
    }
  }


  useEffect(() => {
    fetchTeacherById()
  }, [teacher_id])

  return (
    <>
    {/* <pre>{JSON.stringify(teacherData,null,2)}</pre> */}
      <p className='mb-4' style={{ fontSize: 30, fontWeight: "500" }}>{teacher_id === "new" ? "New Teacher" : "Edit Teacher"}</p>
      <div className="mt-4 card rounded-md shadow-md p-4">
        <div className={`${isMobile(width) ? "column" : "row"}  gap-4 items-center`}>
          <div className="input-container">
            <label htmlFor="" className="input-label">First Name</label>
            <input type="text" className="text-input" value={teacherData?.first_name} onChange={(e) => {
              setTeacherData({ ...teacherData, first_name: e.target.value })
            }} />
          </div>
          <div className="input-container">
            <label htmlFor="" className="input-label">Last Name</label>
            <input type="text" className="text-input" value={teacherData?.last_name} onChange={(e) => {
              setTeacherData({ ...teacherData, last_name: e.target.value })
            }} />
          </div>
        </div>
        <div className={`${isMobile(width) ? "column" : "row"}  gap-4 items-center`}>
          <div className="input-container">
            <label htmlFor="" className="input-label">Email</label>
            <input type="email" className="text-input" value={teacherData?.email} onChange={(e) => {
              setTeacherData({ ...teacherData, email: e.target.value })
            }} />
          </div>

          <div className="input-container">
            <label htmlFor="" className="input-label">Contact Number</label>
            <input type="tel" className="text-input" value={teacherData?.contact_number} onChange={(e) => {
              setTeacherData({ ...teacherData, contact_number: e.target.value })
            }} />
          </div>
        </div>
        <div className="column right alignEnd">
          <div className="row gap-4 items-center">
            {teacher_id !== "new" && <button className="button-delete" onClick={() => {
              IAPrompt({
                title: "Delete Teacher",
                message: "Are you sure you want to delete this teacher?",
                buttons:[
                  {
                    label: "No",
                    onClick: () => {}
                  },
                  {
                    label: "Yes",
                    onClick: () => {
                      deleteTeacher()
                    }
                  }
                ]
              })
            }}>Delete</button>}
            <button className="button"
              onClick={() => {
                if (teacher_id === "new") {
                  createTeacher()
                } else {
                  editTeacher()
                }
              }}
            >{teacher_id === "new" ? "Create" : "Save"}</button>
          </div>
        </div>
      </div>
    </>
  )
}
