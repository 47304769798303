import React, { useEffect, useState, useRef } from 'react'
import Navbar from '../../components/Navbar'
import UserHeader from '../../components/UserHeader'
import { useNavigate, useParams } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import UserLayout from '../../layout/UserLayout'
import { MdAdd, MdArrowDownward, MdArrowUpward, MdComment, MdPerson } from 'react-icons/md'
import ModalLayout from '../../layout/ModalLayout'
import EmojiPicker from 'emoji-picker-react';
import notify from '../../utils/IANotification'
import { calculate_time_difference, tidyDate, calculate_time_difference_tidy } from '../../utils/helper'
import { IoArrowDown, IoArrowUp } from 'react-icons/io5'

export default function ForumsPostPage() {
  const modalRef = useRef(null)
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = useFetchUserData()
  const [searchQuery, setSearchQuery] = useState("")
  const [forumPostData, setForumPostData] = useState([])
  const [categoryData, setCategoryData] = useState({})

  let { category_id } = useParams()

  useEffect(() => {
    fetchPostFromCategory()
    fetchCategoryData()
  }, [category_id])

  const fetchPostFromCategory = async () => {
    if (!category_id) return
    let endpoint = config.endpoint + "/forums/post/category/id/" + category_id
    let response = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await response.json()
    if (data.status === 200) {
      setForumPostData(data.data)
    }
  }

  const fetchCategoryData = async () => {
    if (!category_id) return
    let endpoint = config.endpoint + "/forums/category/id/" + category_id
    let response = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await response.json()
    if (data.status === 200) {
      setCategoryData(data.data)
    }
  }



  return (
    <UserLayout navSelected={"Forums"}>
      <div className="row items-center justify-between w-full mb-20">
        <p style={{ fontWeight: "normal", fontSize: 25, }}>{categoryData?.category_name}</p>
        <button className="rounded-md button shadow-md px-4 py-2 text-white text-md row gap-4 items-center"
          onClick={() => {
            navigate(`/forums/category/post?post_id=new&category_id=${category_id}`)
          }}
        >
          <MdAdd size={12} color='white' />
          <p style={{ fontWeight: "normal", fontSize: 12 }}>Post</p>
        </button>
      </div>
      <div className="input-container">
        <input type="text" className='text-input' placeholder="Search for post..." value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
      </div>
      {forumPostData?.length > 0 ?
        forumPostData?.map((post, index) => {
          if (searchQuery && !JSON.stringify(post).toLowerCase().includes(searchQuery.toLowerCase())) return null
          let total_votes = post?.upvote?.length - post?.downvote?.length
          return (
            <div className="card rounded-md shadow-md w-full cursor-pointer mb-4" key={index}
              onClick={() => {
                navigate(`/forums/category/post?post_id=${post.id}&category_id=${category_id}`)
              }}
            >
              <div className="row justify-between items-center w-full">
                <div className="row gap-4 items-center">
                  {post?.user_data?.file_data?.ext_file_path ?
                    <img src={`${config.endpoint}${post?.user_data?.file_data?.ext_file_path}`} alt="profile" style={{ width: 20, height: 20, borderRadius: "50%" }} />
                    :
                    <div style={{ backgroundColor: "#DDD", borderRadius: "50%", padding: 10 }}>
                      <MdPerson size={12} color='gray' />
                    </div>
                  }
                  <p style={{ fontWeight: "normal", fontSize: 12,
                   color: post?.user_id === user_data?.user_id ? "var(--green)" : "black"
                   }}>{post?.user_id === user_data?.user_id ? "You" : `By ${post?.user_data?.first_name} ${post?.user_data?.last_name}`}</p>
                </div>
                <p style={{
                  // fontWeight: "800",
                  fontSize: 12,
                  fontStyle: "italic",
                }}>{calculate_time_difference_tidy(new Date(post?.updatedAt), new Date())}</p>
              </div>
              <div className="flex flex-col gap-2">
                <p style={{
                  fontWeight: "normal", fontSize: 15,
                  color: post?.user_id === user_data?.user_id ? "var(--green)" : "black",
                  marginTop: 10,
                }}>{post.title}</p>
                <div className="row gap-4 items-center">
                  <IoArrowDown size={15} color={total_votes < 0 ? "red" : "black"} />
                  <p style={{
                    color: total_votes > 0 ? "var(--green)" : "red",
                  }}>{total_votes}</p>
                  <IoArrowUp size={15} color={total_votes > 0 ? "var(--green)" : "black"} />
                  <div className="row ml-20 gap-4 items-center">
                    <MdComment size={15} color={"gray"} />
                    <p style={{ fontWeight: "normal", fontSize: 12 }}>{post?.comments} comments</p>
                  </div>
                </div>
              </div>
            </div>
          )
        })
        :
        <div className="card rounded-md shadow-md p-4 mt-4">
          <MdComment size={50} color={"gray"} />
          <p style={{ fontWeight: "normal", fontSize: 13 }}>It's a bit quiet here... be the first to start a post here</p>
        </div>
      }
      {/* <pre>{JSON.stringify(forumPostData,null,2)}</pre> */}
    </UserLayout >
  )
}
