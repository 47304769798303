import React, { useEffect, useState } from 'react'
import Navbar from '../../components/Navbar'
import UserHeader from '../../components/UserHeader'
import { useNavigate, useParams } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import UserLayout from '../../layout/UserLayout'
import notify from '../../utils/IANotification'
import AssessmentLayout from '../../layout/AssessmentLayout'

export default function AssessmentPage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = useFetchUserData()

  let { assessment_id } = useParams()
  let course_id = new URLSearchParams(window.location.search).get("course_id")

  const [assessmentData, setAssessmentData] = useState({})

  const fetchAssessmentData = async () => {
    if (!assessment_id) return
    let endpoint = config.endpoint + "/assessment/student/" + assessment_id
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      setAssessmentData(data.data)
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  const triggerNewAttempt = async () => {
    if (!assessment_id) return
    let endpoint = config.endpoint + "/assessment/student/attempt"
    let fetchResponse = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        assessment_id: assessment_id,
        schedule_assessment_id: assessmentData?.schedule_assessment_data?.id,
      })
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      navigate(`/assessment/attempt/submission?course_id=${course_id}&attempt_id=${data.data.id}`)
    } else {
      notify({ message: data.message, type: "error" })
    }
  }


  useEffect(() => {
    fetchAssessmentData()
  }, [assessment_id])

  return (
    <AssessmentLayout>
      {/* <pre>{JSON.stringify(assessmentData,null,2)}</pre> */}

      <h1 style={{fontSize:30, textAlign:"center", fontWeight:700}}>{assessmentData?.name}</h1>
      <h1 style={{fontSize:20, textAlign:"center", fontWeight:400}}>{config.client_name.toUpperCase()}</h1>
      <h1 style={{fontSize:15, textAlign:"center", fontWeight:400}}>{assessmentData?.course_data?.name}</h1>

      <div style={{marginTop:50}}>
        <p className="card-title">Instructions to Students</p>
        <div className='mt-4' dangerouslySetInnerHTML={{__html: assessmentData?.instructions}}/>
      </div>

      <div style={{marginTop:50}}>
        <div className="column">
          <div className="column">
            <p className="card-title">Time Allowed</p>
            <p>{assessmentData?.is_unlimited_time == 1 ? "No time limit": `${assessmentData?.time_limit} minutes`}</p>
          </div>
          <div className="column mt-30">
            <p className="card-title">Number of Attempts</p>
            <p>{assessmentData?.is_unlimited_attempts == 1 ? "Unlimited attempts": `${assessmentData?.attempts} attempts`}</p>
          </div>
        </div>
      </div>

      <div style={{marginTop:50}} className='row justifyCenter'>
        <button className="button"
          onClick={()=>{
            triggerNewAttempt()
          }}
        >Start Attempt</button>
      </div>
    </AssessmentLayout>
  )
}
