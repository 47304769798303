import React, { useState, useEffect } from 'react'
import Navbar from '../components/Navbar'
import UserHeader from '../components/UserHeader'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../utils/AppFunctions'
import config from '../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import AdminLayout from '../layout/AdminLayout'
import { useParams } from 'react-router'
import AdminCourseNavbar from '../components/AdminCourseNavbar'
import notify from '../utils/IANotification'
import { capitaliseFirstLetter, isMobile } from '../utils/helper'
import { MdEdit, MdEditDocument, MdLockClock, MdNewReleases } from 'react-icons/md'
import IAPrompt from '../utils/IAPrompt'

export default function AssessmentTypePage({ user_type }) {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = useFetchUserData()

  const course_id = new URLSearchParams(window.location.search)?.get("course_id")
  const lesson_id = new URLSearchParams(window.location.search)?.get("lesson_id")
  const type = new URLSearchParams(window.location.search)?.get("type")

  const [assessmentData, setAssessmentData] = useState([])
  const [width, setWidth] = useState(window.innerWidth)

  const getScheduledAssessmentByType = async () => {
    if (!course_id) return notify({ message: "Unable to fetch lessons", type: "error" })
    let endpoint = config.endpoint + `/assessment/schedule/type/${type ? `${type}` : ""}/course/id/${course_id}`
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      setAssessmentData(data.data)
    } else {
      notify({ message: data.message, type: "error" })
    }
  }


  const releaseGrades = async (id) => {
    let endpoint = config.endpoint + "/grades/release"
    let fetchResponse = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({ schedule_assessment_id: id })
    })
    let response = await fetchResponse.json()
    if (response.status === 200) {
      notify({ message: response.message, type: "success" })
      getScheduledAssessmentByType()
    } else {
      notify({ message: response.message, type: "error" })
    }
  }

  useEffect(() => {
    getScheduledAssessmentByType()
  }, [])

  return (
    <>
      {Object.keys(assessmentData)?.length > 0 ?
        Object.keys(assessmentData)?.map((data, index) => {
          data = assessmentData[data]
          return (
            <div key={index} className="card mb-4  cursor-pointer"
              onClick={() => {
                let temp = { ...data }
                temp.show_class = !temp?.show_class
                let temp2 = { ...assessmentData }
                temp2[Object.keys(assessmentData)[index]] = temp
                setAssessmentData(temp2)
              }}
            >
              {/* Top Row */}
              <div className={`${isMobile(width) ? "column" : "row items-center"} justify-between`}>
                <div className="">
                  <h5 className="card-title">{data.title}</h5>
                  <p>{data.description}</p>
                </div>
                <div className="row gap-4 items-center">
                  <button className="button gap-4 items-center row"

                    onClick={() => {
                      navigate(`/${user_type}/courses/assessments/schedule?course_id=${course_id}&schedule_id=${data.id}`)
                    }}
                  >
                    <MdEdit />
                    Edit Schedule
                  </button>
                  <button className="button gap-4 items-center row"
                    disabled={data?.is_grade_release == "1"}
                    onClick={() => {
                      IAPrompt({
                        title: "Release Grades",
                        message: "Are you sure you want to release the grades for this assessment?",
                        buttons: [
                          {
                            label: "Cancel",
                            onClick: () => { }
                          },
                          {
                            label: "Release Grades",
                            onClick: () => {
                              releaseGrades(data.id)
                            }
                          }
                        ]
                      })
                    }}
                  >
                    <MdLockClock />
                    Release Grades</button>
                </div>
              </div>

              {/* Class Data */}
              {data?.show_class &&
                <div className='mt-30'>
                  {data?.classes?.map((assessment) => {
                    return (
                      <div className={`row items-center justify-between w-full`}>
                        <div className="row gap-4 items-center justify-between w-full">
                          <h5 className="card-title">{assessment?.class_data?.name}</h5>
                          <div className="row gap-4 items-center">
                            <button className="button gap-4 items-center row backgroundLess" style={{ color: "black", fontSize: 15 }}
                              onClick={() => {
                                navigate(`/${user_type}/courses/assessments/submissions?assessment_id=${data.assessment_id}&schedule_id=${assessment.id}&course_id=${course_id}`)
                              }}
                            >
                              <MdEditDocument />
                              Submissions
                            </button>
                          </div>
                          {/* <pre>{JSON.stringify(assessment,null,2)}</pre> */}
                        </div>
                      </div>
                    )
                  })}
                </div>}
            </div>
          )
        })
        :
        <div className="card rounded-md shadow-md p-4 mt-4">
          <h5 className="card-title">No Assessments found</h5>
          <p className="card-text">There are no assessments created for this course.</p>
        </div>}
    </>
  )
}
