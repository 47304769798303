import React, { useEffect, useState } from 'react'
import Navbar from '../../components/Navbar'
import UserHeader from '../../components/UserHeader'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import AdminLayout from '../../layout/AdminLayout'
import notify from '../../utils/IANotification'
import TeacherForm from '../../subpages/TeacherForm'

export default function AdminTeacherFormPage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("staff")
  let user_data = useFetchUserData()
  let teacher_id = new URLSearchParams(window.location.search)?.get("teacher_id")

  return (
    <AdminLayout navSelected={"Teachers"}>
      <TeacherForm/>
    </AdminLayout>
  )
}
