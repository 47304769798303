import React, { useState, useEffect } from 'react'
import Navbar from '../components/Navbar'
import UserHeader from '../components/UserHeader'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../utils/AppFunctions'
import config from '../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import AdminLayout from '../layout/AdminLayout'
import { useParams } from 'react-router'
import notify from '../utils/IANotification'
import { FaChevronDown, FaChevronRight } from 'react-icons/fa'
import { calculate_time_difference, isMobile, tidyDate } from '../utils/helper'
import FileUploadAssessmentComponent from '../components/assessment/FileUploadAssessmentComponent'
import MultipleSelectAssessmentComponent from '../components/assessment/MultipleSelectAssessmentComponent'
import SingleTextAssessmentComponent from '../components/assessment/SingleTextAssessmentComponent'
import SingleSelectAssessmentComponent from '../components/assessment/SingleSelectAssessmentComponent'
import AdminCourseNavbar from '../components/AdminCourseNavbar'
import CodeEditorAssessmentComponent from '../components/assessment/CodeEditorAssessmentComponent'

export default function AssessmentSubmissionAttemptPage({ type }) {
  const navigate = useNavigate()
  const [width, setWidth] = useState(window.innerWidth)
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = useFetchUserData()
  const assessment_id = new URLSearchParams(window.location.search)?.get("assessment_id")
  const schedule_id = new URLSearchParams(window.location.search)?.get("schedule_id")
  const attempt_id = new URLSearchParams(window.location.search)?.get("attempt_id")
  const { course_id } = useParams()
  const [assessmentData, setAssessmentData] = useState([])
  const [submissionData, setSubmissionData] = useState([])

  const [markingData, setMarkingData] = useState([]) // marks, comments

  const fetchAssessmentById = async () => {
    if (assessment_id === "new") return
    let endpoint = config.endpoint + "/assessment/id/" + assessment_id
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      setAssessmentData(data.data)
    } else {
      notify({ message: data.message, type: "error" })
    }
  }


  const fetchAssessmentSubmissions = async () => {
    if (attempt_id === "new") return
    let endpoint = config.endpoint + "/assessment/attempt/id/" + attempt_id
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      setSubmissionData(data.data)
      // Set marking data
      let temp = []
      data.data?.assessment_questions?.map((question, index) => {
        temp.push({ question_id: question.id, grade: data.data?.question_attempt?.find(x => x.question_id == question.id)?.grade, notes: data.data?.question_attempt?.find(x => x.question_id == question.id)?.notes })
      })
      setMarkingData(temp)
    } else {
      notify({ message: data.message, type: "error" })
    }
  }


  const submitMarking = async () => {
    if (attempt_id === "new") return
    let endpoint = config.endpoint + "/assessment/attempt/id/" + attempt_id
    let fetchResponse = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        marking_data: markingData
      })
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      notify({ message: data.message, type: "success" })
      fetchAssessmentSubmissions()
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  useEffect(() => {
    fetchAssessmentById()
    fetchAssessmentSubmissions()
  }, [])


  return (
    <>
      {submissionData?.assessment_questions?.map((question, index) => {
        return (
          <div className="card mb-4">
            <div className={`${isMobile(width) ? "column" : "row items-start"}  gap-30`}>
              {/* <pre>{JSON.stringify(markingData,null,2)}</pre> */}
              {/* Questions */}
              <div style={{ width: "100%" }}>
                <div className="row justify-between items-center">
                  <p className="card-title">Question {index + 1}</p>
                </div>
                {/* <pre>Question Attempt ID {JSON.stringify(question, null, 2)}</pre> */}
                <div className="row gap-4 items-center">
                  <p className='w-full' style={{ fontSize: 13 }}>{question.question}</p>
                  <p style={{ fontSize: 12, whiteSpace: "nowrap" }}>{question.max_marks} marks</p>
                </div>
                {question.type === "single-select" && <SingleSelectAssessmentComponent item_index={index} state={"question"} data={question} options={question.options}
                  userAnswer={submissionData?.question_attempt?.find(x => x.question_id == question.id)?.user_answer}
                  onAnswered={(value) => {

                  }}
                  revealAnswer={true}
                />
                }

                {question.type === "single-text" && <SingleTextAssessmentComponent state={"question"} data={question} options={question.options}
                  userAnswer={submissionData?.question_attempt?.find(x => x.question_id == question.id)?.user_answer}
                  onAnswered={(value) => {

                  }}
                  revealAnswer={true}
                />
                }

                {question.type === "multi-select" && <MultipleSelectAssessmentComponent state={"question"} data={question} options={question.options}
                  userAnswer={submissionData?.question_attempt?.find(x => x.question_id == question.id)?.user_answer}
                  onAnswered={(value) => {

                  }}
                  revealAnswer={true}
                />
                }

                {question.type === "file" && <FileUploadAssessmentComponent item_index={index} state={"submission"} data={question} options={question.options}
                  userAnswer={submissionData?.question_attempt?.find(x => x.question_id == question.id)}
                  onAnswered={(value) => {

                  }}
                  revealAnswer={true}
                />
                }
                {question.type === "code" && <CodeEditorAssessmentComponent item_index={index} state={"submission"} data={question} options={question.options}
                  output={submissionData?.question_attempt?.find(x => x.question_id == question.id)?.output}
                  readOnly={true}
                  userAnswer={submissionData?.question_attempt?.find(x => x.question_id == question.id)?.user_answer || ""}
                  onAnswered={(value) => {

                  }}
                  revealAnswer={true}
                />
                }
              </div>


              {/* Marking */}
              <div className='w-full'>
                <div className="row justify-between items-center">
                  <p className="card-title">Marking</p>
                  {/* <pre>{JSON.stringify(submissionData?.question_attempt, null, 2)}</pre> */}
                </div>
                <div className={`${isMobile(width) ? "column" : "row"}  gap-4 items-start justify-between`}>
                  <div className="w-full">
                    <div className="input-container w-50" style={{ width: "50%" }} >
                      <p className='w-full' style={{ fontSize: 13 }}>Mark awarded</p>
                      <input type="number" className="text-input" required value={markingData?.find(x => x.question_id == question.id)?.grade || submissionData?.question_attempt?.find(x => x.question_id == question.id)?.grade}
                        min={0}
                        max={question.max_marks}
                        onChange={(e) => {
                          if (e.target.value > question.max_marks) {
                            e.target.value = question.max_marks
                          }
                          let temp = [...markingData]
                          let index = temp.findIndex(x => x.question_id == question.id)
                          if (index == -1) {
                            temp.push({ question_id: question.id, grade: e.target.value })
                            index = temp.length - 1
                          }
                          temp[index].grade = e.target.value
                          setMarkingData(temp)
                        }} />
                    </div>
                    <div className="input-container" >
                      <p className='w-full' style={{ fontSize: 13 }}>Marking comments</p>
                      <textarea type="text" className="textarea-input" required value={markingData?.find(x => x.question_id == question.id)?.notes || submissionData?.question_attempt?.find(x => x.question_id == question.id)?.notes} onChange={(e) => {
                        let temp = [...markingData]
                        let index = temp.findIndex(x => x.question_id == question.id)
                        if (index == -1) {
                          temp.push({ question_id: question.id, notes: e.target.value })
                          index = temp.length - 1
                        }
                        temp[index].notes = e.target.value
                        setMarkingData(temp)
                      }} />
                    </div>
                  </div>
                  <p style={{ fontSize: 12, whiteSpace: "nowrap" }}>Max marks: {question.max_marks}</p>
                </div>
              </div>

            </div>
          </div>
        )
      })}
      <div className="row w-full right">
        <button className="button" onClick={() => {
          submitMarking()
        }}>Submit marking</button>
      </div>
    </>
  )
}
